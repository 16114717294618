// ** React Imports
import React, { Fragment, useRef, useState } from 'react'

// ** MUI Imports
import { useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { TreeItem, TreeView, treeItemClasses } from '@mui/x-tree-view'
import Paper from '@mui/material/Paper'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'

// ** Icon Imports
import CheckIcon from '@mui/icons-material/Check'

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.common.black,
  [`& .${treeItemClasses.content}`]: {
    padding: '10px 0px 10px 0px',
    fontSize: 18,
    color: theme.palette.common.black,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular
    },
    '&:hover': {
      backgroundColor: '#3C40430A'
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, #3C40431F)`,
      color: 'var(--tree-view-color)'
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit'
    }
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2)
    }
  }
}))

const StyledTreeItem = React.forwardRef(function StyledTreeItem(props, ref) {
  const theme = useTheme()
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    colorForDarkMode,
    bgColorForDarkMode,
    selected,
    value,
    ...other
  } = props

  const styleProps = {
    '--tree-view-color': theme.palette.mode !== 'dark' ? color : colorForDarkMode,
    '--tree-view-bg-color': theme.palette.mode !== 'dark' ? bgColor : bgColorForDarkMode
  }

  return (
    <StyledTreeItemRoot
      label={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 0.5,
            pr: 0
          }}
        >
          <Box component={LabelIcon} sx={{ mr: 1 }} />
          <CheckIcon
            sx={{
              fontSize: '22px',
              mr: 10,
              ...(selected === value ? { color: 'primary.main' } : { color: 'transparent' })
            }}
          />
          <Typography
            variant='body1'
            sx={{
              fontFamily: 'Roboto,Helvetica Neue,sans-serif !important',
              fontSize: 14,
              lineHeight: '1.25rem',
              fontWeight: 'inherit',
              flexGrow: 1
            }}
          >
            {labelText}
          </Typography>
          <Typography variant='caption'>{labelInfo}</Typography>
        </Box>
      }
      style={styleProps}
      {...other}
      ref={ref}
    />
  )
})

const TreeViewDialog = props => {
  // ** Props
  const { setValue } = props
  // ** Ref
  const radioGroupRef = useRef(null)

  // ** State
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(null)

  // ** Functions
  const openHandler = () => setOpen(true)
  const handleOk = () => setOpen(false)
  const handleCancel = () => setOpen(false)

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus()
    }
  }
  const handleTreeViewItemClick = value => {
    setSelected(value)
    setValue('category', value)
  }

  return (
    <Fragment>
      <Paper elevation={2} style={{ padding: '0px', borderRadius: 0 }}>
        <ListItem
          divider
          aria-haspopup='true'
          aria-controls='ringtone-menu'
          aria-label='Category'
          onClick={openHandler}
        >
          <ListItemText secondary={selected} primary='Select a category' />
        </ListItem>
      </Paper>
      <Dialog
        maxWidth='sm'
        fullWidth={true}
        TransitionProps={{ onEntering: handleEntering }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Select the category for your case</DialogTitle>
        <Box sx={{ width: '100%' }}>
          <TreeView
            aria-label='category'
            defaultEndIcon={<div style={{ width: 24 }} />}
            sx={{ flexGrow: 1, minWidth: '100%', overflowY: 'auto' }}
          >
            <StyledTreeItem
              selected={selected}
              value='billing'
              nodeId='1'
              labelText='Billing'
              onClick={() => handleTreeViewItemClick('billing')}
            />
            <StyledTreeItem
              selected={selected}
              value='technical'
              nodeId='2'
              labelText='Technical'
              onClick={() => handleTreeViewItemClick('technical')}
            />
          </TreeView>
        </Box>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleOk} variant='contained' size='small'>
            Select
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default TreeViewDialog
