// ** React Imports
import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { templateSelector } from 'store/template'

// ** MUI Imports
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'

// ** Moment
import moment from 'moment'

// ** Custom Components
import DeleteTemplateDialog from '../dialog/DeleteTemplateDialog'

// ** Icon Imports
import EditIcon from 'mdi-material-ui/PencilOutline'

const Row = props => {
  // ** Hooks
  const navigate = useNavigate()

  // ** Props
  const { row, path } = props

  // ** Selectors
  const { loading, success, error } = useSelector(templateSelector)

  // ** Redirect to customer display
  const redirectHandler = type => {
    if (type === 'custom') navigate('/tools/editor-template/update/' + row.uid)
    else navigate('/tools/update-template/' + row.uid)
  }

  return (
    <Fragment key={row.uid}>
      <TableRow sx={{ '& > *': {}, cursor: 'pointer' }}>
        <TableCell component='th' scope='row' sx={{ color: 'common.blue' }}>
          {row.name}
        </TableCell>
        <TableCell>{moment(new Date(row.created)).format('MMM DD, YYYY HH:mm:ss')}</TableCell>
        <TableCell>{row.description}</TableCell>
        <TableCell sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <DeleteTemplateDialog template={row} loading={loading} success={success} />
          <IconButton
            size='medium'
            edge='start'
            sx={{ color: 'text.secondary', '&:disabled': { color: theme => theme.palette.grey[100] } }}
            onClick={() => redirectHandler(row.editorTemplate)}
          >
            <EditIcon sx={{ color: 'text.secondary' }} />
          </IconButton>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default Row
