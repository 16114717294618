// ** React Imports
import React, { Fragment, useState } from 'react'

// ** Redux Imports
import { editGroup, groupsSelector } from "store/groups"
import { useDispatch, useSelector } from 'react-redux'

// ** API CALLS
import { deleteAliasGroup } from 'configs/axios/api_helper'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress';

// ** Icon Imports
import DeleteIcon from 'mdi-material-ui/TrashCanOutline'


const DeleteDialog = (props) => {

    // ** Props
    const { setMessage, alias } = props

    // ** Hooks
    const dispatch = useDispatch()

    // ** Selectors
    const { group, success, error } = useSelector(groupsSelector);

    // ** State
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    // ** Functions
    const handleClickOpen = (e) => {
        e.stopPropagation()
        setOpen(true)
    }
    const handleClose = () => setOpen(false)

    // ** Functions
    const deleteDeleteHandler = async (e) => {
        e.stopPropagation()
        setLoading(true)
        try {
            const response = await deleteAliasGroup(group.email, alias)
            dispatch(editGroup({ ...group, aliases: group?.aliases?.filter(el => el !== alias) }))
            setMessage({ success: true, error: false, value: "Alias was deleted successfully" })
            setOpen(false)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setMessage({ success: false, error: true, value: "There was an error while deleting the alias" })
        }
    }


    return (
        <Fragment>
            <IconButton
                size="medium"
                edge="start"
                sx={{ color: "text.secondary" }}
                onClick={handleClickOpen}
            >
                <DeleteIcon sx={{fontSize: "1.175rem"}} />
            </IconButton>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>Delete Alias</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Are you sure you want to delete alias <strong>{alias}</strong> ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='dialog-actions-dense' sx={{ mt: "1rem" }}>
                    <Button
                        disabled={loading}
                        onClick={handleClose}
                        size="small"
                        variant="text"
                        color="inherit"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={loading}
                        onClick={deleteDeleteHandler}
                        endIcon={(loading ?
                            <CircularProgress sx={{ color: 'common.red', mt: 0 }} size="0.825rem" /> : null
                        )}
                        variant="text"
                        size="small"
                        sx={{ fontWeight: 600 }}
                        color="error"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default DeleteDialog