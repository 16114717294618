// ** React Imports
import React, { Fragment, useEffect, useState } from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { usersSelector } from 'store/users'

// ** API CALLS
import { createForwardingAddress, getAllUsers } from 'configs/axios/api_helper'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'

const defaultValues = {
  name: '',
  labelListVisibility: 'labelShow',
  messageListVisibility: 'show'
}

const AddDialog = props => {
  // ** Props
  const { setForwardingAddresses, setMessage, setReload } = props

  // ** Hooks
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues })

  // ** Selectors
  const { user } = useSelector(usersSelector)

  // ** State
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)

  // ** Fetch users on mount
  useEffect(() => {
    const fetchUsersData = async () => {
      const response = await getAllUsers()
      const {
        data: { result }
      } = response
      setOptions(result)
    }
    fetchUsersData()
  }, [])

  // ** Functions
  const handleClickOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  const onChangeHandler = (event, value) => {
    setSelectedUser(value)
  }

  const onSubmit = async () => {
    setLoading(true)
    try {
      const response = await createForwardingAddress(user.primaryEmail, { email: selectedUser?.primaryEmail })
      const { data } = response
      setForwardingAddresses(state => {
        return [...state, { ...data, id: state.length }]
      })
      setMessage({ success: true, error: false, value: 'Forwarding address was added successfully' })
      setReload(true)
      setLoading(false)
      setOpen(false)
    } catch (error) {
      setMessage({ success: false, error: true, value: 'There was an error while adding the forwarding address' })
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <Button
        size='medium'
        variant='text'
        sx={{ fontWeight: 600, ml: '1.325rem', mb: '1rem' }}
        onClick={handleClickOpen}
      >
        Add forwarding address
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle id='alert-dialog-title'>Add new forwarding address</DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Controller
                name='email'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    freeSolo
                    options={options}
                    onChange={onChangeHandler}
                    onInputChange={e => setSelectedUser(state => ({ primaryEmail: e.target.value }))}
                    getOptionLabel={option => option.primaryEmail}
                    sx={{ width: '100%', mb: '1rem', mr: '1rem' }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Email address'
                        variant='standard'
                        InputLabelProps={{ style: { fontSize: '0.875rem' } }} // font size of input label
                      />
                    )}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions className='dialog-actions-dense' sx={{ mt: '1rem' }}>
          <Button onClick={handleClose} size='small' variant='text' color='inherit'>
            Cancel
          </Button>
          <Button
            disabled={loading || !selectedUser}
            endIcon={loading ? <CircularProgress sx={{ color: 'common.white', mt: 0 }} size='0.825rem' /> : null}
            onClick={onSubmit}
            variant='contained'
            size='small'
            sx={{ fontWeight: 600, primary: 'common.blue' }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default AddDialog
