// ** React Imports
import React from 'react'

// ** MUI Imports
import Grid from '@mui/material/Grid'

// ** Custom Components
import MyDrive from './my-drive'





const DriveSettings = (props) => {

    // ** Props
    const { driveTab } = props


    return (
        <Grid item xs={12}>
            <MyDrive driveTab={driveTab} />
        </Grid>
    )
}

export default DriveSettings