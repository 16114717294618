

// ** React imports
import React, { useEffect, useState } from 'react'

// ** API Calls
import { getDriveFolders } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { DataGrid } from '@mui/x-data-grid'
import Snackbar from '@mui/material/Snackbar'
import Box from '@mui/material/Box'
import Grow from '@mui/material/Grow'

// ** Third party Libraries
import moment from 'moment'

// ** Custom Components
import CustomAlertMessage from 'pages/users/components/alert/CustomAlertMessage'
import CustomAvatar from 'core/components/mui/avatar'
import AddDialog from './dialog/AddDialog'
import DeleteDialog from './dialog/DeleteDialog'

// ** Icons Imports
import FolderDrive from 'mdi-material-ui/FolderGoogleDrive'
import BulkAddDialog from './dialog/BulkAddDialog'




const MembersTable = (props) => {

    // ** Props
    const { group, groupMembers, setGroupMembers } = props

    // ** State
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({ success: false, error: false, value: "" })

    // ** Functions
    const handleCloseMessage = () => {
        setMessage({ success: false, error: false, value: "" })
    }



    const columns = [
        {
            flex: 0.25,
            minWidth: 300,
            field: 'email',
            headerName: 'USER EMAIL',
            renderCell: params => {
                return (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CustomAvatar src="/" sx={{ mr: 3, width: '1.875rem', height: '1.875rem' }} />
                        <Typography
                            noWrap
                            variant='body2'
                            sx={{ color: 'common.black' }}
                        >
                            {params.row.email}
                        </Typography>
                    </Box>
                )
            }
        },
        {
            flex: 0.25,
            minWidth: 290,
            field: 'role',
            headerName: 'Role',
            renderCell: params => {
                return (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                            noWrap
                            variant='body2'
                        >
                            {params.row.role}
                        </Typography>
                    </Box>
                )
            }
        },

        {
            flex: 0.175,
            minWidth: 320,
            headerName: 'Action',
            field: 'Action',
            align: "right",
            headerAlign: "right",
            renderCell: params => (
                <DeleteDialog
                    setMessage={setMessage}
                    setGroupMembers={setGroupMembers}
                    member={params.row}
                    group={group}

                />
            )
        },
    ]


    return (
        <Grid item container xs={12}>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: "1rem" }}>
                <Typography variant="body2" sx={{ fontSize: "1rem" }}>
                    Showing all members
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <BulkAddDialog
                        setMessage={setMessage}
                        setGroupMembers={setGroupMembers}
                    />
                    <AddDialog
                        setMessage={setMessage}
                        setGroupMembers={setGroupMembers}
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <DataGrid
                    autoHeight
                    rows={groupMembers || []}
                    columns={columns}
                    loading={loading}
                    hideFooter
                />
            </Grid>
            <CustomAlertMessage
                message={message}
                handleCloseMessage={handleCloseMessage}
            />
        </Grid >
    )

}

export default MembersTable