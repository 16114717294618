// ** React imports
import React, { useEffect, useState } from 'react'

// ** API CALLS
import { getForwardingAddress } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { DataGrid } from '@mui/x-data-grid'

// ** Custom Components
import DeleteDialog from './components/dialog/DeleteDialog'

// ** Icons Imports
import AddDialog from './components/dialog/AddDialog'
import CustomAlertMessage from '../../alert/CustomAlertMessage'

const ForwardingAddressesTable = props => {
  // ** Props
  const { user, setReload } = props

  // ** State
  const [forwardingAddresses, setForwardingAddresses] = useState([])
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({ success: false, error: false, value: '' })

  // ** Fetch Labels Data
  useEffect(() => {
    const fetchForwardingAddressesData = async () => {
      setLoading(true)
      try {
        const response = await getForwardingAddress(user.primaryEmail)
        const {
          data: {
            result: { forwardingAddresses }
          }
        } = response
        setForwardingAddresses(forwardingAddresses.map((el, index) => ({ ...el, id: index })))
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    if (user?.primaryEmail) fetchForwardingAddressesData()
  }, [user])

  // ** Functions
  const handleCloseMessage = () => {
    setMessage({ success: false, error: false, value: '' })
  }

  const columns = [
    {
      flex: 0.25,
      minWidth: 290,
      field: 'forwardingEmail',
      headerName: 'Forwarding address',
      renderCell: params => {
        return (
          <Typography noWrap variant='body2' sx={{ color: 'common.blue' }}>
            {params.row.forwardingEmail}
          </Typography>
        )
      }
    },
    {
      flex: 0.25,
      minWidth: 290,
      field: 'verificationStatus',
      headerName: 'Status',
      renderCell: params => {
        return (
          <Typography noWrap variant='body2'>
            {params.row.verificationStatus}
          </Typography>
        )
      }
    },
    {
      flex: 0.175,
      minWidth: 120,
      headerName: 'Action',
      field: 'Action',
      align: 'right',
      headerAlign: 'right',
      renderCell: params => (
        <DeleteDialog
          setMessage={setMessage}
          setForwardingAddresses={setForwardingAddresses}
          forwardingAddress={params.row}
          user={user}
          setReload={setReload}
        />
      )
    }
  ]

  return (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <AddDialog setMessage={setMessage} setForwardingAddresses={setForwardingAddresses} setReload={setReload} />
      </Grid>
      <Grid item xs={12}>
        <DataGrid autoHeight rows={forwardingAddresses} columns={columns} loading={loading} hideFooter />
      </Grid>
      <CustomAlertMessage message={message} handleCloseMessage={handleCloseMessage} />
    </Grid>
  )
}

export default ForwardingAddressesTable
