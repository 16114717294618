

// ** React imports
import React, { useEffect, useState } from 'react'

// ** APIs
import { createCriteria } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'
import FormHelperText from '@mui/material/FormHelperText'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'


// ** Icons Imports
import EyeOutline from 'mdi-material-ui/EyeOutline'
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline'
import KeyIcon from "mdi-material-ui/ShieldKeyOutline"

// ** Styled Box
const Container = styled(Box, { shouldForwardProp: (prop) => prop !== "hover" })(({ theme, hover }) => ({
    padding: "1.425rem",
    cursor: "pointer",
    backgroundColor: "#fff",
    transition: "all .1s ease-in-out",
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
    },
    ...(hover ? {
        boxShadow: theme.shadows[10],
        margin: "12px -12px",
        cursor: "default"
    } : {}),
    ...(!hover ? { "&:hover": { backgroundColor: theme.palette.grey[100] } } : {})
}
))

const defaultValues = {
    bytes: "",
    excludeChats: false,
    from: "",
    hasAttachement: false,
    negateQuery: "",
    query: "",
    size: 0,
    sizeComparison: "",
    subject: "",
    to: "",
}



const CriteriaCard = (props) => {

    // ** Props
    const { user } = props

    // ** Hooks
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({ defaultValues })

    // ** State
    const [show, setShow] = useState(false)

    // ** Set Data
    useEffect(() => {
        if (!!user) {
            //reset({ makeAdmin: user.isAdmin || false })
        }
    }, [user])

    // ** Functions
    const toggleHandler = () => {
        if (!show)
            setShow(true)
    }


    const onSubmit = async (data) => {
        let convertedSize = 0
        if (data.bytes === "m") {
            convertedSize = data.size * 1000 * 1000;
        } else if (data.bytes === "k") {
            convertedSize = data.size * 1000;
        } else if (data.bytes === "b") {
            convertedSize = data.size;
        }
        const body = {
            criteria: {
                from: data.from,
                to: data.to,
                subject: data.subject,
                query: data.query,
                negatedQuery: data.negatedQuery,
                hasAttachment: data.hasAttachment ?? false,
                excludeChats: data.excludeChats,
                ...(convertedSize && {size: convertedSize}),
                ...(!!data.sizeComparison && {sizeComparison: data.sizeComparison}),
            },
            actions: {
                addLabelIds: ["Label_1", "Label_2"],
                forward: "raouf@gsuite.tn",
            },
        };
        try {
            const response = await createCriteria(user.primaryEmail, body)
            //console.log("ress", response)
        } catch (error) {
            //console.log("aaa", error)
        }
        setShow(false)
    }




    return (
        <Container hover={show} onClick={toggleHandler}>
            {show ?
                <Grid item container xs={12}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ fontWeight: 600, fontSize: "1rem" }}>
                            Criteria
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: "0.775rem" }}>
                            Please select at least 1 of the following criteria*
                        </Typography>
                    </Grid>
                    <Grid item xs={12} xl={8} sx={{ py: "0.5rem" }}>
                        <form id="role-form" onSubmit={handleSubmit(onSubmit)}>
                            <Grid item container xs={12} sx={{ my: "0.725rem" }}>
                                <Grid item xs={12} md={4}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mt: '1.325rem' }}>
                                        From* :
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <FormControl fullWidth>
                                        <Controller
                                            name='from'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange } }) => (
                                                <TextField
                                                    variant="standard"
                                                    value={value}
                                                    label="Sender's display name or email address"
                                                    onChange={onChange}
                                                    error={Boolean(errors.from)}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} sx={{ my: "0.725rem" }} spacing={4}>
                                <Grid item xs={12} md={4}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mt: '1.325rem' }}>
                                        To* :
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <FormControl fullWidth>
                                        <Controller
                                            name='to'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange } }) => (
                                                <TextField
                                                    variant="standard"
                                                    value={value}
                                                    label="Recipient's display name or email address"
                                                    onChange={onChange}
                                                    error={Boolean(errors.to)}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} sx={{ my: "0.725rem" }}>
                                <Grid item xs={12} md={4}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mt: '1.325rem' }}>
                                        Subject* :
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <FormControl fullWidth>
                                        <Controller
                                            name='subject'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange } }) => (
                                                <TextField
                                                    variant="standard"
                                                    value={value}
                                                    label="Message's subject"
                                                    onChange={onChange}
                                                    error={Boolean(errors.subject)}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} sx={{ my: "0.725rem" }}>
                                <Grid item xs={12} md={4}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mt: '1.325rem' }}>
                                        Query* :
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <FormControl fullWidth>
                                        <Controller
                                            name='query'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange } }) => (
                                                <TextField
                                                    variant="standard"
                                                    value={value}
                                                    label="Message has words"
                                                    onChange={onChange}
                                                    error={Boolean(errors.query)}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} sx={{ my: "0.725rem" }}>
                                <Grid item xs={12} md={4}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mt: '1.325rem' }}>
                                        Negate Query:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <FormControl fullWidth>
                                        <Controller
                                            name='negateQuery'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <TextField
                                                    variant="standard"
                                                    value={value}
                                                    label="Message doesn't have words"
                                                    onChange={onChange}
                                                    error={Boolean(errors.negateQuery)}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} sx={{ my: "0.725rem" }} spacing={4}>
                                <Grid item xs={12} md={4}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mt: '1.325rem' }}>
                                        Has Attachement:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <FormControl fullWidth sx={{ mt: "0.925rem" }}>
                                        <Controller
                                            name='hasAttachement'
                                            control={control}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    sx={errors.checkbox ? { color: 'error.main' } : null}
                                                    control={
                                                        <Switch
                                                            {...field}
                                                            name='validation-basic-checkbox'
                                                            sx={errors.checkbox ? { color: 'error.main' } : null}
                                                        />
                                                    }
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} sx={{ my: "0.725rem" }}>
                                <Grid item xs={12} md={4}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mt: '1.325rem' }}>
                                        Exclude Chats:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <FormControl fullWidth sx={{ mt: "0.925rem" }}>
                                        <Controller
                                            name='excludeChats'
                                            control={control}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    sx={errors.checkbox ? { color: 'error.main' } : null}
                                                    control={
                                                        <Switch
                                                            {...field}
                                                            name='validation-basic-checkbox'
                                                            sx={errors.checkbox ? { color: 'error.main' } : null}
                                                        />
                                                    }
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} sx={{ my: "0.725rem" }}>
                                <Grid item xs={12} md={4}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mt: '1.325rem' }}>
                                        Message Size:
                                    </Typography>
                                </Grid>
                                <Grid item container xs={12} md={8} spacing={2} sx={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id='sie-comparison-label'>Size Comparison</InputLabel>
                                            <Controller
                                                name='sizeComparison'
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <Select
                                                        variant="standard"
                                                        value={value}
                                                        label='Type'
                                                        onChange={onChange}
                                                        labelId='size-comparison-label'
                                                    >
                                                        <MenuItem value=''>
                                                            <em>None</em>
                                                        </MenuItem>
                                                        <MenuItem value="smaller">Smaller</MenuItem>
                                                        <MenuItem value="larger">Larger</MenuItem>
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth>
                                            <Controller
                                                name='size'
                                                type="number"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <TextField
                                                        variant="standard"
                                                        value={value}
                                                        label="Size"
                                                        onChange={onChange}
                                                        error={Boolean(errors.negateQuery)}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth>
                                            <InputLabel id='sie-comparison-label'>Bytes</InputLabel>
                                            <Controller
                                                name='bytes'
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <Select
                                                        variant="standard"
                                                        value={value}
                                                        label='Bytes'
                                                        onChange={onChange}
                                                        labelId='size-comparison-label'
                                                    >
                                                        <MenuItem value=''>
                                                            <em>None</em>
                                                        </MenuItem>
                                                        <MenuItem value="b">B</MenuItem>
                                                        <MenuItem value="k">kB</MenuItem>
                                                        <MenuItem value="m">mB</MenuItem>
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Divider sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", width: "100%" }} />
                    <Grid item xs={12} sx={{ textAlign: "end", mt: '1rem' }}>
                        <Button sx={{ fontWeight: 600, color: "text.secondary" }} variant="text" onClick={() => setShow(false)}>
                            CANCEL
                        </Button>
                        <Button
                            type='submit'
                            form="role-form"
                            variant="text"
                            sx={{ fontWeight: 600 }}
                        >
                            SAVE
                        </Button>
                    </Grid>
                </Grid >
                :
                <Grid container item xs={12} spacing={6}>
                    <Grid item xs={12} xl={3}>
                        <Typography sx={{ fontWeight: 600, fontSize: "0.775rem" }}>
                            Criteria
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} xl={8} spacing={6}>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ fontSize: "0.775rem" }}>
                                Select at least one of the following criteria
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Container >
    )

}

export default CriteriaCard