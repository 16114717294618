// ** React Imports
import React from 'react'

// ** MUI Imports
import { Grid } from "@mui/material"

// ** Component Imports
import SharedDriveTable from './containers/SharedDriveTable'



const SharedDrive = () => {


    return (
        <Grid item xs={12}>
            <SharedDriveTable />
        </Grid>
    )
}


export default SharedDrive