// ** React Imports
import React, { useState, useEffect } from 'react'

// ** APIs
import { getUserPermissionDrive } from 'configs/axios/api_helper'

// ** Hooks
import { useAuth } from 'hooks/useAuth'

// ** MUI Imports
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

// ** Custom Components
import FoldersTable from './components/FoldersTable'







const MyDrive = (props) => {

    // ** Props
    const { driveTab } = props

    // ** Hooks
    const auth = useAuth()

    // ** State
    const [loading, setLoading] = useState(false)
    const [aboutUserDrive, setAboutUserDrive] = useState({}) // holding info about the connected user which we can access from it the permission id

    // ** Fetch user permissions
    useEffect(() => {
        const fetchUserPermission = async () => {
            setLoading(true)
            try {
                const response = await getUserPermissionDrive(auth.user?.primaryEmail)
                const { data: { user } } = response
                setAboutUserDrive(user)
            } catch (error) {
                setLoading(false)
                setAboutUserDrive({})
            }
        }
        fetchUserPermission()
    }, [])


    return (
        <Card
            sx={{
                border: theme => `solid 1px ${theme.palette.grey[300]}`,
                borderRadius: 0,
                overflow: "visible",
                my: "0.5rem",
            }}>
            <CardHeader
                sx={{
                    backgroundColor: theme => theme.palette.grey[100],
                    height: 50,
                    border: theme => `solid 1px ${theme.palette.grey[300]}`,
                    mb: 10,
                }}
                title={
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ fontWeight: 600, fontSize: "1rem" }}>
                                {driveTab === 0 && "My drive list"}
                                {driveTab === 1 && "Owned drive list"}
                                {driveTab === 2 && "Shared with me list"}
                                {driveTab === 3 && "Starred drive list"}
                                {driveTab === 4 && "Trashed drive list"}
                                {driveTab === 5 && "Shared drive list"}
                            </Typography>
                        </Grid>
                    </Grid>
                }
            />
            <Grid item xs={12}>
                <FoldersTable
                    aboutUserDrive={aboutUserDrive}
                    driveTab={driveTab}
                />
            </Grid>
        </Card>
    )
}



export default MyDrive