// ** React Imports
import React from 'react'

// ** Mui Imports
import { Box, Divider, Grid, Typography } from "@mui/material"

// ** Images Imports
import EmptyImage from 'images/empty.png'

const TemplatePreviewV3 = (props) => {

    // ** Props
    const {
        placeHolders,
        dataOption,
        greetings,
        extraFields,
        image
    } = props

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: "0.725rem", color: "text.secondary", my: 2 }}>
                        To: &nbsp;
                    </Typography>
                    <Box sx={{ display: "inline-block", px: 3, whiteSpace: "nowrap", borderRadius: "10px", border: "1px solid #d9dce5" }}>
                        <Typography sx={{ fontSize: "0.695rem", fontWeight: 600, color: "text.secondary" }}>
                            {(placeHolders !== undefined && placeHolders[0]?.value) || dataOption?.name?.fullName || "Jhon Doe"}
                        </Typography>
                    </Box>
                </Box>
                <Divider sx={{ color: theme => theme.palette.grey[400], mt: "0.125rem" }} />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: "0.725rem", color: "text.secondary" }}>
                        Subject:
                    </Typography>
                    <Typography sx={{ fontSize: "0.695rem", fontWeight: 600, color: "text.secondary" }}>
                        &nbsp; Check out my new email subject
                    </Typography>
                </Box>
                <Divider sx={{ color: theme => theme.palette.grey[400] }} />
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{ marginTop: "2rem", color: "black", letterSpacing: 5, fontSize: "1.725rem" }}>
                    {greetings || "Best Regards"}
                </Typography>
            </Grid>
            <Grid item xs={2} sx={{ marginTop: "2rem", height: "100px" }}>
                <img alt="preview_image" height={65} width={110} src={(dataOption && dataOption?.thumbnailPhotoUrl) ? dataOption?.thumbnailPhotoUrl : image ? URL.createObjectURL(image[0]) : EmptyImage} />
            </Grid>
            <Grid item xs={10} sx={{ marginTop: "2rem", paddingLeft: "1rem" }}>
                <Typography sx={{ fontWeight: 600, color: "common.blue" }}>
                    {(placeHolders !== undefined && placeHolders[0]?.value) || dataOption?.name?.fullName || "Jhon Doe"}
                </Typography>
                <Typography sx={{ fontSize: "0.725rem", fontWeight: 600 }}>
                    {((placeHolders !== undefined && placeHolders[1]?.value) || (placeHolders !== undefined && placeHolders[2]?.value)) ?
                        (placeHolders[1]?.value ?
                            (placeHolders[2]?.value ?
                                placeHolders[1]?.value + ", " + placeHolders[2]?.value : placeHolders[1]?.value) : placeHolders[2]?.value ?
                                placeHolders[2]?.value : "") : ((dataOption && dataOption?.organizations) ? dataOption?.organizations[0]?.title + ", " + dataOption?.organizations[0]?.department : "Digital Marketing, JE Marketing")
                    }
                </Typography>
                <Divider sx={{ marginTop: "1rem", borderBottom: theme => `2px solid ${theme.palette.common.blue}` }} />
                <Typography sx={{ marginTop: "1rem", fontSize: "0.725rem" }}>
                    {extraFields?.map((el, index) => (
                        <span key={index}>
                            <span style={{ color: "#0072E5", fontWeight: 700 }}>
                                {el.label} :
                            </span>
                            <span>
                                &nbsp;&nbsp;
                                {el?.value ||
                                    (dataOption && Array.isArray(dataOption[el.name]) && dataOption[el.name][0]?.value) ||
                                    (dataOption && Array.isArray(dataOption[el.name]) && dataOption[el?.name][0]?.address) ||
                                    (dataOption && Array.isArray(dataOption[el.name]) && dataOption[el?.name][0]?.title) ||
                                    (dataOption && dataOption[el.name]?.type) ||
                                    (dataOption && dataOption?.customSchemas?.[el.name]?.[el.subType]) ||
                                    "{|" + el.name + "-" + el.subType + "|}"
                                } &nbsp;&nbsp;
                            </span>
                            {(index === 2 || index === 5 || index === 8 || index === 11) && <br />}
                        </span>
                    ))}
                </Typography>
                {extraFields?.length > 0 && <Divider sx={{ marginTop: "1rem", borderBottom: theme => `2px solid ${theme.palette.common.blue}` }} />}
            </Grid>
        </Grid>
    )
}


export default TemplatePreviewV3