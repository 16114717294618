// ** React imports
import React, { useEffect, useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import Snackbar from '@mui/material/Snackbar'
import Box from '@mui/material/Box'
import Grow from '@mui/material/Grow'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterMoment from '@mui/lab/AdapterMoment'
import DatePicker from '@mui/lab/DatePicker'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'
import moment from 'moment'

// ** Icons Imports
import { getVacationResponder, updateVacationResponder } from 'configs/axios/api_helper'

// ** Styled Box
const Container = styled(Box, { shouldForwardProp: prop => prop !== 'hover' })(({ theme, hover }) => ({
  padding: '1.425rem',
  cursor: 'pointer',
  backgroundColor: '#fff',
  transition: 'all .1s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2)
  },
  ...(hover
    ? {
        boxShadow: theme.shadows[10],
        margin: '12px -12px',
        cursor: 'default'
      }
    : {}),
  ...(!hover ? { '&:hover': { backgroundColor: theme.palette.grey[100] } } : {})
}))

const defaultValues = {
  enableAutoReply: false,
  restrictToContacts: false,
  restrictToDomain: false,
  startTime: '',
  endTime: '',
  responseBodyPlainText: '',
  responseSubject: ''
}

const VacationResponderCard = props => {
  // ** Props
  const { user } = props

  // ** Hooks
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch
  } = useForm({ defaultValues })

  // ** State
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState({ success: false, error: false })
  const [loading, setLoading] = useState(true)

  // ** Fetch Data
  useEffect(() => {
    const fetchVacationData = async () => {
      try {
        const response = await getVacationResponder(user.primaryEmail)
        const { data } = response
        reset({
          ...data,
          startTime: moment(data?.startTime, 'x'),
          endTime: moment(data?.endTime, 'x').subtract(1, 'days')
        })
      } catch (error) {}
    }
    if (user?.primaryEmail) fetchVacationData()
  }, [user])

  // ** Functions
  const toggleHandler = () => {
    if (!show) setShow(true)
  }

  const onSubmit = async data => {
    setLoading(true)
    try {
      const response = await updateVacationResponder(user.primaryEmail, {
        ...data,
        startTime: data.startTime ? moment(data.startTime).valueOf() : '',
        endTime: data.endTime ? moment(data.endTime).add(1, 'days').valueOf() : ''
      })
      setLoading(false)
      setShow(false)
      setMessage({ error: false, success: true })
    } catch (error) {
      setLoading(false)
      setMessage({ error: true, success: false })
    }
  }

  const handleCloseMessage = () => {
    setMessage({ success: false, error: false })
  }

  return (
    <Container hover={show} onClick={toggleHandler}>
      {show ? (
        <Grid item container xs={12}>
          <Grid item xs={12} xl={8} sx={{ py: '0.5rem' }}>
            <form id='vacation-form' onSubmit={handleSubmit(onSubmit)}>
              <Grid item container xs={12} sx={{ my: '0.725rem' }} spacing={4}>
                <Grid item xs={12} md={4}>
                  <Typography sx={{ fontWeight: 600, fontSize: '0.875rem', mt: '1.325rem' }}>
                    Vacation Responder:
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
                  <FormControl sx={{ mt: '0.925rem' }}>
                    <Controller
                      name='enableAutoReply'
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          sx={errors.checkbox ? { color: 'error.main' } : null}
                          control={
                            <Switch
                              {...field}
                              checked={field.value}
                              name='validation-basic-checkbox'
                              sx={errors.checkbox ? { color: 'error.main' } : null}
                            />
                          }
                        />
                      )}
                    />
                  </FormControl>
                  <Typography sx={{ fontWeight: 600, fontSize: '0.875rem', mt: '1.325rem' }}>
                    {watch('enableAutoReply') ? 'Enabled' : 'Disabled'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} sx={{ my: '0.725rem' }} spacing={4}>
                <Grid item xs={12} md={4}>
                  <Typography sx={{ fontWeight: 600, fontSize: '0.875rem', mt: '1.325rem' }}>
                    Restrict Response To Users's Contacts :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
                  <FormControl sx={{ mt: '0.925rem' }}>
                    <Controller
                      name='restrictToContacts'
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          sx={errors.checkbox ? { color: 'error.main' } : null}
                          control={
                            <Switch
                              disabled={!watch('enableAutoReply')}
                              {...field}
                              name='validation-basic-checkbox'
                              sx={errors.checkbox ? { color: 'error.main' } : null}
                            />
                          }
                        />
                      )}
                    />
                  </FormControl>
                  <Typography sx={{ fontWeight: 600, fontSize: '0.875rem', mt: '1.325rem' }}>
                    {watch('restrictToContacts') ? 'Enabled' : 'Disabled'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} sx={{ my: '0.725rem' }} spacing={4}>
                <Grid item xs={12} md={4}>
                  <Typography sx={{ fontWeight: 600, fontSize: '0.875rem', mt: '1.325rem' }}>
                    Restrict Response To Domain :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
                  <FormControl sx={{ mt: '0.925rem' }}>
                    <Controller
                      name='restrictToDomain'
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          sx={errors.checkbox ? { color: 'error.main' } : null}
                          control={
                            <Switch
                              disabled={!watch('enableAutoReply')}
                              {...field}
                              name='validation-basic-checkbox'
                              sx={errors.checkbox ? { color: 'error.main' } : null}
                            />
                          }
                        />
                      )}
                    />
                  </FormControl>
                  <Typography sx={{ fontWeight: 600, fontSize: '0.875rem', mt: '1.325rem' }}>
                    {watch('restrictToDomain') ? 'Enabled' : 'Disabled'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} sx={{ my: '0.725rem' }} spacing={4}>
                <Grid item xs={12} md={4}>
                  <Typography sx={{ fontWeight: 600, fontSize: '0.875rem', mt: '1.325rem' }}>Duration :</Typography>
                </Grid>
                <Grid item container xs={12} md={6} spacing={4}>
                  <Grid item xs={6}>
                    <FormControl sx={{ mt: '0.925rem' }}>
                      <Controller
                        name='startTime'
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              label='First Day'
                              control={control}
                              value={value}
                              onChange={event => {
                                onChange(event)
                                if (event.isAfter(watch('endTime'))) {
                                  setValue('endTime', event)
                                }
                              }}
                              renderInput={params => <TextField {...params} variant='standard' />}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl sx={{ mt: '0.925rem' }}>
                      <Controller
                        name='endTime'
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              label='Last Day'
                              control={control}
                              value={value}
                              onChange={event => {
                                onChange(event)
                              }}
                              renderInput={params => <TextField {...params} variant='standard' />}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={12} sx={{ my: '0.725rem' }} spacing={4}>
                <Grid item xs={12} md={4}>
                  <Typography sx={{ fontWeight: 600, fontSize: '0.875rem', mt: '1.325rem' }}>
                    Response Subject :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
                  <FormControl fullWidth>
                    <Controller
                      name='responseSubject'
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          variant='standard'
                          value={value ?? ''}
                          label='Plain Text'
                          onChange={onChange}
                          error={Boolean(errors.responseSubject)}
                          placeholder='description'
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container xs={12} sx={{ my: '0.725rem' }} spacing={4}>
                <Grid item xs={12} md={4}>
                  <Typography sx={{ fontWeight: 600, fontSize: '0.875rem', mt: '1.325rem' }}>
                    Response Body Plain Text :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
                  <FormControl fullWidth>
                    <Controller
                      name='responseBodyPlainText'
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          variant='standard'
                          multiline
                          minRows={6}
                          value={value ?? ''}
                          label='Subject'
                          onChange={onChange}
                          error={Boolean(errors.responseBodyPlainText)}
                          placeholder='description'
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Divider sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', width: '100%' }} />
          <Grid item xs={12} sx={{ textAlign: 'end', mt: '1rem' }}>
            <Button sx={{ fontWeight: 600, color: 'text.secondary' }} variant='text' onClick={() => setShow(false)}>
              CANCEL
            </Button>
            <Button type='submit' form='vacation-form' variant='text' sx={{ fontWeight: 600 }}>
              SAVE
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container item xs={12} spacing={6}>
          <Grid item xs={12} xl={3}>
            <Typography sx={{ fontWeight: 600, fontSize: '0.775rem' }}>Gmail Vacation responder settings</Typography>
          </Grid>
          <Grid container item xs={12} xl={8} spacing={6}>
            <Grid item xs={12}>
              <Typography variant='body2' sx={{ fontSize: '0.775rem' }}>
                Manage your email vacation responder settings
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Snackbar
        sx={{ mt: '3rem' }}
        open={message.success || message.error}
        onClose={handleCloseMessage}
        autoHideDuration={2000}
        key={'top' + 'right'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={Grow}
        message={
          <Box sx={{ textAlign: 'center' }}>
            {message.success && 'Vacation responder was updated successfully'}
            {message.error && 'There was an error while vacation responder the pop'}
          </Box>
        }
      />
    </Container>
  )
}

export default VacationResponderCard
