// ** React Imports
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

// ** Redux Imports
import { useDispatch } from "react-redux"
import { fetchUserData } from "store/users"

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

// ** Custom Components
import GeneralInfo from "../components/profile-header/GeneralInfo"
import GeneralSettings from "../components/general-informations/general-settings"
import SettingsHeader from "../components/profile-header/SettingsHeader"
import AliasesTable from "../components/aliases"
import SecuritySettings from "../components/general-informations/security"
import RolesSettings from "../components/general-informations/roles"
import NonEditableAliasesTable from "../components/aliases/components/NonEditableAliasesTable"
import GroupsTable from "../components/groups"
import GmailSettings from "../components/gmail-settings"
import CalendarSettings from "../components/calendars"
import DriveSettings from "../components/drive"
import ContactsTable from "../components/contacts"



// ** Styled Grid Container
const Container = styled(Grid)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: "2rem",
    [theme.breakpoints.down('lg')]: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },

}))


const UserProfile = (props) => {

    // ** Hooks
    const dispatch = useDispatch()
    const { id } = useParams()

    // ** State
    const [activeTab, setActiveTab] = useState(0)
    const [driveTab, setActiveDriveTab] = useState(0) // state hold current active tab for sub items of drive link

    // ** Fetch user data
    useEffect(() => {
        if (id) {
            dispatch(fetchUserData(id))
        }
    }, [])


    return (
        <Container container spacing={6}>
            <Grid item xs={12} md={4} lg={4} xl={3}>
                <GeneralInfo
                    activeTab={activeTab}
                    changeTabsHandler={setActiveTab}
                    driveTab={driveTab}
                    setActiveDriveTab={setActiveDriveTab}
                />
            </Grid>
            <Grid container item xs={12} md={8} lg={8} xl={9}>
                <Grid item xs={12}>
                    <SettingsHeader />
                    {activeTab === 6 &&
                        <DriveSettings driveTab={driveTab} />
                    }
                </Grid>
                {activeTab === 0 &&
                    <Grid item xs={12}>
                        <GeneralSettings />
                        <SecuritySettings />
                        <RolesSettings />
                    </Grid>
                }
                {activeTab === 1 &&
                    <Grid item xs={12}>
                        <AliasesTable />
                        <NonEditableAliasesTable />
                    </Grid>
                }
                {activeTab === 2 &&
                    <Grid item xs={12}>
                        <GroupsTable />
                    </Grid>
                }
                {activeTab === 3 &&
                    <Grid item xs={12}>
                        <ContactsTable />
                    </Grid>
                }
                {activeTab === 4 &&
                    <Grid item xs={12}>
                        <GmailSettings />
                    </Grid>
                }
                {activeTab === 5 &&
                    <Grid item xs={12}>
                        <CalendarSettings />
                    </Grid>
                }

            </Grid>
        </Container >
    )
}


export default UserProfile