export const placeHolders = [
    "{|name-fullName|}",
    "{|name-familyName|}",
    "{|name-givenName|}",
    "{|emails-primary|}",
    "{|emails-home|}",
    "{|emails-work|}",
    "{|emails-other|}",
    "{|phones-recovery|}",
    "{|phones-home|}",
    "{|phones-mobile|}",
    "{|phones-work|}",
    "{|phones-other|}",
    "{|phones-car|}",
    "{|phones-main|}",
    "{|phones-pager|}",
    "{|phones-radio|}",
    "{|phones-telex|}",
    "{|addresses-home|}",
    "{|addresses-work|}",
    "{|addresses-other|}",
    "{|relations-brother|}",
    "{|relations-child|}",
    "{|relations-father|}",
    "{|relations-friend|}",
    "{|relations-manager|}",
    "{|relations-mother|}",
    "{|relations-parent|}",
    "{|relations-partner|}",
    "{|relations-relative|}",
    "{|relations-sister|}",
    "{|relations-spouse|}",
    "{|organizations-work|}",
    "{|organizations-school|}",
    "{|organizations-domain_only|}",
    "{|organizations-unknown|}",
    "{|externalId-network|}",
    "{|externalId-organization|}",
    "{|externalId-customer|}",
    "{|externalId-login_id|}",
    "{|websites-blog|}",
    "{|websites-ftp|}",
    "{|websites-home|}",
    "{|websites-profile|}",
    "{|websites-reservations|}",
    "{|websites-resume|}",
    "{|websites-work|}",
    "{|gender-type|}",
    "{|employeeId-id|}",
    "{|jobTitle-title|}",
    "{|employeeType-type|}",
    "{|department-department|}",
    "{|costCenter-costCenter|}",
    "{|managersEmail-email|}",
]