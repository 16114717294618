// ** React Imports
import React, { Fragment, useState, useMemo } from 'react'
import ReactDOMServer from 'react-dom/server'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

// ** API CALLS
import { createSignatureTemplate, updateSignatureTemplate } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'

// ** Naitve Templates
import TemplateV1 from '../templates-preview/native-templates/TemplateV1'
import TemplateV2 from '../templates-preview/native-templates/TemplateV2'
import TemplateV3 from '../templates-preview/native-templates/TemplateV3'
import TemplateV4 from '../templates-preview/native-templates/TemplateV4'
import TemplateNoImageV1 from '../templates-preview/native-templates/TemplateNoImageV1'
import TemplateNoImageV2 from '../templates-preview/native-templates/TemplateNoImageV2'
import TemplateNoImageV3 from '../templates-preview/native-templates/TemplateNoImageV3'
import TemplateNoImageV4 from '../templates-preview/native-templates/TemplateNoImageV4'
// ** Hooks
import { useAuth } from 'hooks/useAuth'
import { useSelector } from 'react-redux'
import { templateSelector } from 'store/template'

// ** Styled Box
const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '1.725rem',
  boxShadow: theme.shadows[5],
  paddingTop: '0.125rem',
  paddingBottom: '0.125rem',
  paddingRight: '0.725rem',
  paddingLeft: '0.725rem',
  borderRadius: '1rem',
  cursor: 'pointer',
  width: 'fit-content'
}))

const defaultValues = {
  name: '',
  description: '',
  greetings: 'Kind Regards'
}

const AddTemplateDialog = props => {
  // ** Props
  const { setMessage } = props

  // ** Selectors
  const { template, selectedTemplate } = useSelector(templateSelector)

  // ** Hooks
  const { id } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { user } = useAuth()
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({ defaultValues })

  // ** State
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  // ** Functions
  const handleClickOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  const resetFields = useMemo(() => {
    if (pathname.includes('update') && id) {
      reset({ name: template.name ?? '', description: template.description ?? '', greetings: template.greetings ?? '' })
    }
  }, [template])

  const onSubmit = async data => {
    setLoading(true)
    let html = ''
    if (!!template.customTemplate) {
      const htmlObject = document.createElement('div')
      htmlObject.id = 'parent-div'
      htmlObject.innerHTML = template.customTemplate
      const placeholders = [...htmlObject.querySelectorAll('#parent-div [id]')].map(({ id }) => id)
      placeholders.map(element => {
        const node = htmlObject.querySelector(`#${element}`)
        if (element === 'avatar') {
          node.src = '{|avatar|}'
        } else if (element === 'name') {
          node.innerHTML = '{|name-fullName|}'
        } else if (element === 'job') {
          node.innerHTML = '{|jobTitle-title|}'
        } else if (element === 'department') {
          node.innerHTML = '{|department-department|}'
        } else {
          const extraField = template?.extraFields?.find(item => item.name?.toLowerCase()?.includes(element))
          if (!!extraField) {
            node.innerHTML = `{|${extraField.name + '-' + extraField.subType}|}`
          }
        }
      })
      html = htmlObject.innerHTML
    } else {
      if (selectedTemplate === 0) {
        html = ReactDOMServer.renderToStaticMarkup(
          <TemplateV1
            data={template?.placeHolders}
            extraFields={template?.extraFields}
            greetings={template?.greetings}
          />
        )
      } else if (selectedTemplate === 1) {
        html = ReactDOMServer.renderToStaticMarkup(
          <TemplateV2
            data={template?.placeHolders}
            extraFields={template?.extraFields}
            greetings={template?.greetings}
          />
        )
      } else if (selectedTemplate === 2) {
        html = ReactDOMServer.renderToStaticMarkup(
          <TemplateV3
            data={template?.placeHolders}
            extraFields={template?.extraFields}
            greetings={template?.greetings}
          />
        )
      } else if (selectedTemplate === 3) {
        html = ReactDOMServer.renderToStaticMarkup(
          <TemplateV4
            data={template?.placeHolders}
            extraFields={template?.extraFields}
            greetings={template?.greetings}
          />
        )
      } else if (selectedTemplate === 4) {
        html = ReactDOMServer.renderToStaticMarkup(
          <TemplateNoImageV1
            data={template?.placeHolders}
            extraFields={template?.extraFields}
            greetings={template?.greetings}
          />
        )
      } else if (selectedTemplate === 5) {
        html = ReactDOMServer.renderToStaticMarkup(
          <TemplateNoImageV2
            data={template?.placeHolders}
            extraFields={template?.extraFields}
            greetings={template?.greetings}
          />
        )
      } else if (selectedTemplate === 6) {
        html = ReactDOMServer.renderToStaticMarkup(
          <TemplateNoImageV3
            data={template?.placeHolders}
            extraFields={template?.extraFields}
            greetings={template?.greetings}
          />
        )
      } else if (selectedTemplate === 7) {
        html = ReactDOMServer.renderToStaticMarkup(
          <TemplateNoImageV4
            data={template?.placeHolders}
            extraFields={template?.extraFields}
            greetings={template?.greetings}
          />
        )
      }
    }
    try {
      const body = {
        name: data.name,
        editorTemplate: !template.customTemplate
          ? JSON.stringify([...(template?.extraFields || []), { name: 'selectedTemplate', value: selectedTemplate }])
          : 'custom',
        template: html,
        description: data.description,
        customerId: user.customerId, //checkedForAll ? "ALL" : user.customerId
        ...(pathname.includes('update') && { created: template.created })
      }
      if (pathname.includes('update') && id) {
        const response = await updateSignatureTemplate(id, body)
      } else {
        const response = await createSignatureTemplate(body)
      }
      setMessage({
        success: true,
        error: false,
        value: pathname.includes('update') ? 'Template was updated successfully' : 'Template was created successfully'
      })
      setLoading(false)
      setOpen(false)
      setTimeout(() => {
        navigate('/tools/templates')
      }, 1000)
    } catch (error) {
      setMessage({
        success: false,
        error: true,
        value: pathname.includes('update')
          ? 'There was an error while updating the template'
          : 'There was an error while creating the template'
      })
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <StyledBox onClick={handleClickOpen}>
        <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 36 36'>
          <path fill='#34A853' d='M16 16v14h4V20z'></path>
          <path fill='#4285F4' d='M30 16H20l-4 4h14z'></path>
          <path fill='#FBBC05' d='M6 16v4h10l4-4z'></path>
          <path fill='#EA4335' d='M20 16V6h-4v14z'></path>
          <path fill='none' d='M0 0h36v36H0z'></path>
        </svg>
        <Typography sx={{ color: 'text.secondary', fontSize: '0.825rem', fontWeight: 600, whiteSpace: 'nowrap' }}>
          Save Template
        </Typography>
      </StyledBox>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle id='alert-dialog-title'>Save template</DialogTitle>
        <DialogContent>
          <form id='add-template-form' onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Controller
                    name='name'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        variant='standard'
                        value={value ?? ''}
                        label='Template Name'
                        onChange={onChange}
                        placeholder='Template xyz'
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Controller
                    name='description'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        multiline
                        minRows={4}
                        variant='standard'
                        value={value ?? ''}
                        label='Description'
                        onChange={onChange}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className='dialog-actions-dense' sx={{ mt: '1rem' }}>
          <Button disabled={loading} onClick={handleClose} size='small' variant='text' color='inherit'>
            Cancel
          </Button>
          <Button
            disabled={loading}
            endIcon={loading ? <CircularProgress sx={{ color: 'common.white', mt: 0 }} size='0.825rem' /> : null}
            variant='contained'
            type='submit'
            form='add-template-form'
            size='small'
            sx={{ fontWeight: 600, primary: 'common.blue' }}
          >
            {pathname.includes('update') ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default AddTemplateDialog
