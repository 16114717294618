// Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** Countries Imports
import {
  getGroup,
  getGroupSettings,
  getGroups,
  getGroupTemplate,
  createGroup,
  updateGroupSettings
} from 'configs/axios/api_helper'

export const initialState = {
  loading: null,
  groups: [],
  group: {},
  groupSettings: {},
  success: '',
  error: '',
  nextPageToken: null,
  rowsPerPage: null
}
// A slice
const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    startLoading: (state, action) => {
      state.loading = action.payload
      state.error = ''
      state.success = ''
    },
    getGroupsSuccess: (state, action) => {
      const { groups, nextPageToken, limit } = action.payload
      state.groups = limit !== state.rowsPerPage ? groups : [...state.groups, ...groups]
      state.loading = null
      state.nextPageToken = nextPageToken
      state.rowsPerPage = limit
      state.success = 'GET_GROUPS'
    },
    getGroupSuccess: (state, action) => {
      state.group = action.payload
      state.loading = null
      state.success = 'GET_GROUP'
    },
    createGroupSuccess: (state, action) => {
      state.group = state.groups.push(action.payload)
      state.loading = null
      state.success = 'CREATE_GROUP'
    },
    getGroupSettingsSuccess: (state, action) => {
      state.groupSettings = action.payload
      state.loading = null
      state.success = 'GET_GROUP_SETTINGS'
    },
    updateGroupSettingsSuccess: (state, action) => {
      state.groupSettings = { ...action.payload }
      state.loading = null
      state.success = 'UPDATE_GROUP_SETTINGS'
    },
    updateGroupSuccess: (state, action) => {
      state.group = { ...action.payload }
      state.loading = null
      state.success = 'UPDATE_GROUP'
    },
    getGroupsFailure: state => {
      state.loading = null
      state.error = 'ERROR'
      state.success = ''
    },
    setErrorMessage: (state, action) => {
      state.loading = null
      state.error = action.payload
      state.success = ''
    },
    resetMessages: state => {
      state.success = ''
      state.error = ''
      state.loading = null
    }
  }
})

// Actions generated from the slice
const {
  resetMessages,
  startLoading,
  getGroupsSuccess,
  getGroupSuccess,
  getGroupsFailure,
  updateGroupSuccess,
  getGroupSettingsSuccess,
  updateGroupSettingsSuccess,
  createGroupSuccess
} = groupsSlice.actions

// export user selector to get the slice in any component
export const groupsSelector = state => state.groupsData

// export The reducer
const groupsReducer = groupsSlice.reducer
export default groupsReducer

// Actions
export const fetchGroups =
  ({ limit, page } = {}) =>
  async dispatch => {
    dispatch(startLoading('GET'))
    try {
      // if (query !== undefined) {
      //   // response = await getUserQuery({ limit, page, query })
      // } else {
      const response = await getGroups({ limit, page })
      // }
      const {
        data: { result }
      } = response
      dispatch(getGroupsSuccess({ ...result, limit }))
    } catch (error) {
      dispatch(getGroupsFailure())
    }
  }

export const fetchGroupData = id => async dispatch => {
  dispatch(startLoading('GET'))
  try {
    const response = await getGroup(id)
    const {
      data: { result }
    } = response
    dispatch(getGroupSuccess(result))
  } catch (error) {
    dispatch(getGroupsFailure())
  }
}

export const fetchGroupTemplateData = id => async dispatch => {
  dispatch(startLoading('GET'))
  try {
    const response = await getGroupTemplate(id)
    const { data } = response
    dispatch(getGroupSuccess(data))
  } catch (error) {
    dispatch(getGroupsFailure())
  }
}

export const fetchGroupSettings = email => async dispatch => {
  dispatch(startLoading('GET_SETTINGS'))
  try {
    const response = await getGroupSettings(email)
    const { data } = response
    dispatch(getGroupSettingsSuccess(data))
  } catch (error) {
    dispatch(getGroupsFailure())
  }
}

export const addGroup = (data, settings) => async dispatch => {
  try {
    dispatch(startLoading('CREATE_GROUP'))
    const newGroup = await createGroup(data)
    const response = await updateGroupSettings(data?.email, { groupSettings: { ...settings } })
    dispatch(createGroupSuccess(newGroup))
  } catch (error) {
    dispatch(getGroupsFailure())
  }
}

export const editGroup = data => async dispatch => {
  try {
    dispatch(startLoading('UPDATE_GROUP'))
    dispatch(updateGroupSuccess(data))
  } catch (error) {
    dispatch(getGroupsFailure())
  }
}

export const editGroupSettings = data => async dispatch => {
  try {
    dispatch(startLoading('UPDATE_GROUP_SETTINGS'))
    dispatch(updateGroupSettingsSuccess(data))
  } catch (error) {
    dispatch(getGroupsFailure())
  }
}

export const cleanMessages = () => dispatch => {
  dispatch(resetMessages())
}
