// ** React imports
import React, { useEffect, useState } from 'react'

// ** Redux Imports
import { editGroup, groupsSelector } from "store/groups"
import { useDispatch, useSelector } from 'react-redux'

// ** APIs
import { getDomainsList, createAliasGroup } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop'

// ** Custom Components
import CustomAlertMessage from 'pages/directory/components/alert/CustomAlertMessage'
import DeleteDialog from './dialog/DeleteDialog'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'

// ** Icon Imports
import DeleteIcon from 'mdi-material-ui/TrashCanOutline'

// ** Auth Hook
import { useAuth } from 'hooks/useAuth'


// ** Styled Box
const Container = styled(Box, { shouldForwardProp: (prop) => prop !== "hover" })(({ theme, hover }) => ({
    padding: "1.425rem",
    cursor: "pointer",
    backgroundColor: "#fff",
    transition: "all .1s ease-in-out",
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
    },
    ...(hover ? {
        boxShadow: theme.shadows[10],
        margin: "12px -12px",
        cursor: "default"
    } : {}),
    ...(!hover ? { "&:hover": { backgroundColor: theme.palette.grey[100] } } : {})
}
))

// ** Styled Box
const StyledBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.525rem",
    backgroundColor: "#fff",
    transition: "all .1s",
    "&:hover": {
        backgroundColor: theme.palette.grey[300]
    }
}
))


const defaultValues = {
    email: "",
    domain: ""
}



const AliasesInformation = (props) => {

    // ** Hooks
    const { user } = useAuth()
    const dispatch = useDispatch()
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({ defaultValues })

    // ** Selectors
    const { group, success, error } = useSelector(groupsSelector);

    // ** State
    const [show, setShow] = useState(false)
    const [domains, setDomains] = useState([])
    const [message, setMessage] = useState({ success: false, error: false, value: "" })
    const [loading, setLoading] = useState(false)

    // ** Set Data 
    useEffect(() => {
        const fetchDomainsData = async () => {
            try {
                const response = await getDomainsList(user.customerId)
                const { data: { domains } } = response
                setDomains(domains)
            } catch (error) {

            }
        }
        fetchDomainsData()
    }, [user])

    // ** Functions
    const toggleHandler = () => { if (!show) setShow(true) }
    const handleCloseMessage = () => { setMessage({ success: false, error: false, value: "" }) }

    const onSubmit = async (body) => {
        setLoading(true)
        try {
            const response = await createAliasGroup(group.email, { alias: `${body.email}@${body.domain}` })
            const { data } = response
            dispatch(editGroup({ ...group, aliases: [...(group.aliases ? group.aliases : []), data.alias] }))
            setMessage({ success: true, error: false, value: "Alias was added successfully" })
            setLoading(false)
        } catch (error) {
            setMessage({ success: false, error: true, value: "There was an error while adding the alias" })
            setLoading(false)
        }
    }




    return (
        <Container hover={show} onClick={toggleHandler}>
            {show ?
                <Grid item container xs={12} sx={{ position: "relative" }}>
                    <Grid item xs={12} xl={3}>
                        <Typography sx={{ fontWeight: 600, fontSize: "0.775rem" }}>
                            Contact Information
                        </Typography>
                    </Grid>
                    <Grid item xs={12} xl={8}>
                        <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
                            <Grid item container xs={12} spacing={4} sx={{ py: "0.5rem", alignItems: "center" }}>
                                <Grid item xs={6} md={4}>
                                    <FormControl fullWidth>
                                        <Controller
                                            name='email'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange } }) => (
                                                <TextField
                                                    variant="standard"
                                                    type='text'
                                                    value={value}
                                                    label='Email Address'
                                                    onChange={onChange}
                                                    placeholder='carterleonard'
                                                />
                                            )}
                                        />

                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={4} sx={{ display: "flex", alignItems: "center" }}>
                                    <Typography variant="body2" sx={{ mt: "1.725rem", mr: "1rem", fontSize: "1.325rem" }}>
                                        @
                                    </Typography>
                                    <FormControl fullWidth sx={{ mt: "0.725rem" }}>
                                        <Controller
                                            name='domain'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Select
                                                    variant="standard"
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder="Domain"
                                                >
                                                    {domains.map((el, index) => (<MenuItem key={index} value={el.domainName}>{el.domainName}</MenuItem>))}
                                                </Select>
                                            )}
                                        />
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        endIcon={(loading ? <CircularProgress sx={{ color: 'common.blue', mt: 0 }} size="0.825rem" /> : null)}
                                        type='submit'
                                        form="contact-form"
                                        variant="text"
                                        size="small"
                                        sx={{ fontWeight: 600, mt: "1rem" }}
                                    >
                                        ADD ALIAS
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid xl={3} />
                    <Grid item xl={8} sx={{ mt: "2rem" }}>
                        <Typography variant="body2" sx={{ fontSize: "0.875rem", p: "0.525rem" }}>
                            Active Aliases
                        </Typography>
                        {group?.aliases?.length > 0 ?
                            group?.aliases?.map((el, index) => (
                                <StyledBox key={index}>
                                    <Typography sx={{ fontSize: "0.925rem" }}>
                                        {el}
                                    </Typography>
                                    <DeleteDialog
                                        setMessage={setMessage}
                                        alias={el}
                                    />
                                </StyledBox>
                            ))
                            : null
                        }
                    </Grid>
                    <Grid xl={3} />
                    <Grid item xl={8} sx={{ mt: "2rem" }}>
                        <Typography variant="body2" sx={{ fontSize: "0.875rem", p: "0.525rem" }}>
                            Non Editable Aliases
                        </Typography>
                        {group?.nonEditableAliases?.length > 0 ?
                            group?.nonEditableAliases?.map((el, index) => (
                                <StyledBox key={index}>
                                    <Typography sx={{ fontSize: "0.925rem" }}>
                                        {el}
                                    </Typography>
                                </StyledBox>
                            ))
                            : null
                        }
                    </Grid>
                    <Divider sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", width: "100%" }} />
                    <Grid item xs={12} sx={{ textAlign: "end", mt: '1rem' }}>
                        <Button
                            sx={{ fontWeight: 600, color: "text.blue" }}
                            variant="text"
                            onClick={() => setShow(false)}
                        >
                            DONE
                        </Button>
                    </Grid>
                    <Backdrop
                        open={loading == "UPDATE_USER"}
                        sx={{
                            position: 'absolute',
                            backgroundColor: "transparent",
                            backdropFilter: 'blur(5px)',
                            color: theme => theme.palette.common.blue,
                            zIndex: theme => theme.zIndex.mobileStepper - 1,
                        }}
                    >
                        <CircularProgress color='inherit' />
                    </Backdrop>
                </Grid>
                :
                <Grid container item xs={12} spacing={6}>
                    <Grid item xs={12} xl={3}>
                        <Typography sx={{ fontWeight: 600, fontSize: "0.775rem" }}>
                            Aliases
                        </Typography>
                    </Grid>
                    <Grid item container xs={12} xl={8}>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
                                Alias
                            </Typography>
                            {group?.aliases?.length > 0 ?
                                group?.aliases?.map((el, index) => (
                                    <Typography key={index} sx={{ fontSize: "0.925rem" }}>
                                        {el}
                                    </Typography>
                                ))
                                :
                                <Typography sx={{ fontSize: "0.925rem" }}>
                                    Add a group alias
                                </Typography>
                            }
                        </Grid>
                        <Grid item xs={12} sx={{ mt: "1rem" }}>
                            <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
                                Non Editable Alias
                            </Typography>
                            {group?.nonEditableAliases?.length > 0 ?
                                group?.nonEditableAliases?.map((el, index) => (
                                    <Typography key={index} sx={{ fontSize: "0.925rem" }}>
                                        {el}
                                    </Typography>
                                ))
                                :
                                null
                            }
                        </Grid>
                    </Grid>
                </Grid>
            }
            <CustomAlertMessage
                message={message}
                handleCloseMessage={handleCloseMessage}
            />
        </Container >
    )

}

export default AliasesInformation