// ** React Imports
import React, { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

// ** Auth Context //
import { useAuth } from 'hooks/useAuth'

const AuthGuard = ({ children, redirectTo, protectedRoute }) => {
  // ** Hooks
  const auth = useAuth()
  const { pathname } = useLocation()

  // ** Reg Exp
  const regex = /\/support-case\//

  if (regex.test(pathname)) {
    return (protectedRoute && !auth.loading && auth.user === null) || false ? (
      <Navigate to='/' />
    ) : (
      children
    )
  }

  return protectedRoute && !auth.loading && auth.user === null ? <Navigate to={redirectTo} /> : children
}

export default AuthGuard
