// ** Authentification
export const GET_USER_AUTHORIZATION = '/direct'
export const GET_USER_APPS_ACCESS = '/auth/admins/appsaccess'
export const REFRESH_FRESHBOOKS_TOKEN = '/customers/freshbooks/refresh'
export const GET_ADMIN_SUPPORTCASE_ACCESS = '/access?email='

// ** Users
export const GET_USERS = '/users/get'
export const GET_USER_QUERY = '/users/get/query'
export const GET_USER = '/users/'
export const GET_USER_STATS = '/drive/user/'

// ** Tools
export const GET_CUSTOMER_DIRECTORY = '/directory/customer/'
export const GET_USERS_DIRECTORY = '/directory/users/'
export const GROUPS_DIRECTORY = '/directory/groups/'

// ** Groups
export const GET_USER_GROUPS = '/userGroups/'
export const GET_GROUPS_TEMPLATES = '/groupTemplates/list'
export const GET_GROUP_TEMPLATE = '/groupTemplates/'

// ** Contacts
export const GET_CONTACTS = '/contact/user/'

// ** Gmail Settings
export const GET_DELEGATIONS = '/user/'
export const GMAIL_SETTINGS = '/user/'

// ** Calendars
export const GET_CALENDARS = '/calendar/user/'

// ** Drive
export const GET_DRIVE = '/drive/user/'

// ** Groups
export const GET_GROUPS = '/groups/get/'
export const GET_GROUP = '/groups/'

// ** Signature Template
export const GET_SIGNATURE_TEMPLATE = '/signatureTemplate/'
export const CREATE_SIGNATURE = '/tools/signatureTemplate/'
export const UPDATE_SIGNATURE = '/tools/signatureTemplate/update/'
export const GET_BULK_SIGNATURE = '/bulk/signature/'
export const CREATE_BULK_SIGNATURE = '/bulk/signature/create'
// ** Shared Drive
export const SHARED_DRIVE = '/drive/user/'

// ** Licenses
export const LICENSES = '/purchaseLicences/subscriptions/orderHistory/list/'

// ** Support Case
export const GET_ALL_CASES = '/tickets/list/'
export const CREATE_NEW_CASE = '/tickets/create'
export const GET_CASE = '/tickets/'
export const UPDATE_CASE = '/tickets/update/'
