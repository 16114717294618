// ** React Imports
import React, { useEffect, useRef, Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { fetchUsers } from 'store/users'

// ** MUI Imports
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import Grid from '@mui/material/Grid'
import { DataGrid } from '@mui/x-data-grid'
import LinearProgress from '@mui/material/LinearProgress'

// ** 3rd Party Libraries
import { useDebouncedCallback } from 'use-debounce'

// ** Icons Imports
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'

// ** Context
import { useAuth } from 'hooks/useAuth'
import { casesSelector, getCasesData } from 'store/supportCase'
import SpecificCaseDialog from '../components/dialog/SpecificCaseDialog'

// Constants prios
const priorities = [
  { label: 'P4 - Critical', value: 'urgent', background: '#fce8e6', color: '#c5221f' },
  { label: 'P3 - High', value: 'high', background: '#fef7e0', color: '#c5221f' },
  { label: 'P2 - Medium', value: 'normal', background: '#fef7e0', color: '#000' },
  { label: 'P1 - Low', value: 'low', background: '#f1f3f4', color: '#000' }
]

// Columns
const columns = [
  {
    field: 'priority',
    flex: 0.125,
    headerName: 'Priority',
    sortable: false,
    renderCell: params => {
      const { background, color, label } = priorities.find(
        item => item.value?.toLowerCase() === (params.row?.priority?.toLowerCase() || 'low')
      )
      return (
        <Typography
          noWrap
          sx={{
            fontSize: '12px',
            p: '0 4px',
            color,
            backgroundColor: background
          }}
        >
          {label}
        </Typography>
      )
    }
  },

  {
    field: 'status',
    flex: 0.125,
    headerName: 'Status',
    sortable: false
  },
  {
    field: 'uid',
    flex: 0.25,
    headerName: 'Case number / title',
    sortable: false,
    renderCell: params => {
      return (
        <Box my={2}>
          <Typography variant='body1' fontSize={14} fontWeight={450}>
            {params.row.zendeskId}
          </Typography>
          <Typography variant='body1' fontSize={14} fontWeight={450}>
            {params.row.subject}
          </Typography>
        </Box>
      )
    }
  },
  {
    field: 'date',
    flex: 0.125,
    headerName: 'Updated date',
    sortable: false
  },

  {
    flex: 0.175,
    field: 'customerDomain',
    headerName: 'Domain',
    sortable: false
  }
]

const CasesTable = () => {
  // ** Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useAuth()

  // ** Selectors
  const { cases, success, error, loading } = useSelector(casesSelector)

  // ** State
  const [filters, setFilters] = useState({ status: '', priority: '' })

  // ** Get Cases data
  useEffect(() => {
    if (user?.customerId)
      dispatch(getCasesData({ id: user?.customerId, status: filters.status, priority: filters.priority }))
  }, [user, filters])

  // ** Functions
  const debounced = useDebouncedCallback(
    event => {
      const { name, value } = event.target
      if (value) dispatch(fetchUsers({ limit: filters.limit, query: value }))
      else dispatch(fetchUsers({ limit: filters.limit }))
    },
    500,
    // The maximum time func is allowed to be delayed before it's invoked:
    { maxWait: 3000 }
  )

  const handleRowClick = data => {
    const uid = data?.row?.uid
    navigate(`/support-case/case/${uid}`)
  }

  const onChangeSelect = event => {
    const { value, name } = event.target
    setFilters({ ...filters, [name]: value })
  }
  return (
    <Fragment>
      <Grid container sx={{ height: 120, mt: -3.5, backgroundColor: 'white', mb: '20px' }}>
        <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center', gap: 2, px: 5, py: 2 }}>
          <Typography
            variant='subtitle2'
            align='center'
            sx={{
              color: 'black',
              '&:hover': {
                color: theme => theme.palette.primary.main,
                cursor: 'pointer'
              }
            }}
          >
            Home
          </Typography>
          <Typography variant='subtitle2'>&gt;</Typography>
          <Typography variant='subtitle2'>All cases</Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            whiteSpace: 'nowrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 5,
            py: 3
          }}
        >
          <Typography variant='h5'>All cases</Typography>
          <Button
            onClick={() => navigate('/support-case/add')}
            variant='outlined'
            sx={{
              mx: 1,
              borderRadius: '5px !important',
              fontWeight: 600,
              color: 'common.blue',
              fontSize: '0.825rem'
            }}
          >
            Create Support Case
          </Button>
        </Grid>
      </Grid>

      <Card
        sx={{
          '&.MuiPaper-root': {
            borderRadius: '11px !important',
            boxShadow: 0,
            border: theme => `1px solid ${theme.palette.grey[300]}`
          }
        }}
      >
        <Box container sx={{ py: 3, px: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <FilterAltOutlinedIcon sx={{ color: '#5F6368', mt: 4 }} />
            <FormControl variant='standard' sx={{ width: 200 }}>
              <InputLabel id='priority-label'>Priority</InputLabel>
              <Select
                inputProps={{ IconComponent: () => null }}
                size='small'
                name='priority'
                onChange={onChangeSelect}
                lebelId='priority-label'
                defaultValue=''
              >
                <MenuItem value=''>
                  <em>None</em>
                </MenuItem>
                {priorities.map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant='standard' sx={{ width: 200 }}>
              <InputLabel id='status-label'>Status</InputLabel>
              <Select
                inputProps={{ IconComponent: () => null }}
                size='small'
                name='status'
                onChange={onChangeSelect}
                lebelId='status-label'
                defaultValue=''
              >
                <MenuItem value=''>
                  <em>None</em>
                </MenuItem>
                <MenuItem value='new'>New</MenuItem>
                <MenuItem value='Solved'>Solved</MenuItem>
                <MenuItem value='Closed'>Closed</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <SpecificCaseDialog debounced={debounced} />
        </Box>
        <Divider variant='fullWidth' sx={{ color: '#e8eaed' }} />

        <Box sx={{ height: 350 }}>
          <DataGrid
            disableColumnMenu
            getRowId={row => row.uid}
            rows={cases ?? []}
            columns={columns}
            loading={loading === 'GET'}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onRowClick={handleRowClick}
            components={{
              LoadingOverlay: LinearProgress
            }}
          />
        </Box>
      </Card>
    </Fragment>
  )
}

export default CasesTable
