// ** React Imports
import React from 'react'

// ** MUI Imports
import { Grid } from "@mui/material"

// ** Component Imports
import GroupsTable from './containers/GroupsTable'



const Directories = () => {


    return (
        <Grid container>
            <Grid item xs={12}>
                <GroupsTable />
            </Grid>
        </Grid>
    )
}


export default Directories