// ** React Imports
import React, { useEffect, useState } from "react";

// ** Redux Imports
import { useDispatch, useSelector } from "react-redux"
import { saveTemplate, templateSelector } from "store/template"

// ** MUI Imports
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { useMediaQuery } from "@mui/material";

// ** Third Party Libraries
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// ** Icons Imports
import DeleteIcon from 'mdi-material-ui/TrashCanOutline'
import DragIcon from "mdi-material-ui/Drag"


// ** Dummy Data 
// fake data generator
// const getItems = count =>
//     Array.from({ length: count }, (v, k) => k).map(k => ({
//         id: `item-${k}`,
//         content: `item ${k}`
//     }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    //padding: grid * 2,
    // change background colour if dragging
    background: isDragging ? "lightblue" : "white",
    display: 'flex',
    alignItems: "center",
    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    //background: isDraggingOver ? "lightblue" : "lightgrey",
    //padding: grid,
    width: "100%"
});


const DnDInputs = (props) => {

    // ** Props
    const { extraFields, setExtraFields, options, placeHolders, greetings, setInitialOptions, setDeletedItem, template } = props

    // ** Hooks
    const large = useMediaQuery(theme => theme.breakpoints.down('xl'))
    const dispatch = useDispatch()

    const [state, setState] = useState({
        items: []
    })

    useEffect(() => {
        if (extraFields.length > 0) {
            setState({ items: extraFields })
        }
    }, [extraFields])


    const onDragEnd = (result) => {

        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = reorder(
            state.items,
            result.source.index,
            result.destination.index
        );

        setState({ items });
        setExtraFields([...items])
    }




    const inputsChangeHandler = (event, property) => {
        const { value, name } = event.target
        setExtraFields(prevState => {
            return [...prevState.map(el => el.name === name ? { ...el, [property]: value } : el)]
        })
        dispatch(saveTemplate({ ...template, placeHolders: placeHolders, extraFields: extraFields.map(el => el.name === name ? { ...el, [property]: value } : el), greetings: greetings }))
    }


    const deleteHandler = (name) => {
        setExtraFields(extraFields.filter(el => el.name !== name))
        setState({ items: extraFields.filter(el => el.name !== name) });
        const { [name]: el, ...rest } = options
        setInitialOptions(rest)
        setDeletedItem({ name: name, subType: el })
        dispatch(saveTemplate({ ...template, placeHolders: placeHolders, extraFields: extraFields.filter(el => el.name !== name), greetings: greetings }))

    }


    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {state?.items?.map((item, index) => (
                            <Draggable key={item?.name} draggableId={item?.name} index={index}>
                                {(provided, snapshot) => (
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        spacing={4}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}

                                        style={
                                            getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}>
                                        {!template.customTemplate && <Grid item xs={0.5}>
                                            <DragIcon sx={{ fontSize: "1.425rem", color: "grey", mt: "1rem", mr: "-1.225rem" }} />
                                        </Grid>}
                                        <Grid item xs={5}>
                                            <TextField
                                                variant="standard"
                                                sx={{ width: "100%" }}
                                                name={item.name}
                                                label={item.label}
                                                InputLabelProps={{ style: { fontSize: "0.925rem" } }}
                                                onChange={(e) => inputsChangeHandler(e, "value")}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <FormControl size="small" variant="standard" sx={{ width: "90%", mt: 4.5, ml: "2rem" }} >
                                                <InputLabel
                                                    id="demo-simple-select-label"
                                                    sx={{ fontSize: "0.925rem" }}
                                                >
                                                    Sub Type
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left"
                                                        },
                                                        //getContentAnchorEl: null
                                                    }}
                                                    name={item?.name}
                                                    value={item?.subType}
                                                    onChange={(e) => inputsChangeHandler(e, "subType")}
                                                >
                                                    {options[item?.name]?.map((el, index) => (<MenuItem key={index} value={el}>{el}</MenuItem>))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        {!template.customTemplate && <Grid item xs={1}>
                                            <DeleteIcon onClick={() => deleteHandler(item?.name)} sx={{ marginTop: "1.725rem", fontSize: "1.225rem", color: "grey" }} />
                                        </Grid>}
                                    </Grid>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default DnDInputs