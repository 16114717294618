// ** React Imports
import React, { useEffect, useMemo, useState } from 'react'

// ** Api Calss
import { getAllUsers, getUser } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useMediaQuery } from '@mui/material'

// ** Data Imports
import { placeHolders } from '@fake-db/placeHolders'

// ** Styled Box
const Container = styled(Box)(({ theme }) => ({
  padding: '1.425rem',
  backgroundColor: '#fff',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2)
  }
}))

const EditorTemplatePreview = props => {
  // ** Props
  const { template } = props
  // ** Hooks
  const small = useMediaQuery(theme => theme.breakpoints.down('md'))

  // ** State
  const [previewTemplate, setPreview] = useState(null)
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)

  // ** Fetch users for preview
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersData = await getAllUsers().then(res => res.data?.result)
        setUsers(usersData)
      } catch (error) {}
    }
    fetchUsers()
  }, [])

  // ** Functions
  const selectUserHandler = async (event, user) => {
    try {
      const response = await getUser(user.id)
      const {
        data: { result }
      } = response
      setSelectedUser(result)
    } catch (error) {}
  }

  const returnPropertyValue = placeHolder => {
    if (!!selectedUser) {
      const properties = placeHolder.split('-')
      if (placeHolder.includes('managersEmail')) {
        return selectedUser?.relations?.find(item => item.type == 'manager')?.value ?? `${placeHolder}`
      } else if (placeHolder.includes('email')) {
        return selectedUser?.primaryEmail ?? `${placeHolder}`
      } else if (placeHolder.includes('jobTitle')) {
        return (
          (selectedUser?.organizations?.[0]?.title || selectedUser?.organizations?.[0]?.description) ?? `${placeHolder}`
        )
      } else if (placeHolder.includes('department')) {
        return selectedUser?.organizations?.[0]?.department ?? `${placeHolder}`
      } else if (placeHolder.includes('name')) {
        return selectedUser?.name?.fullName ?? `${placeHolder}`
      } else if (placeHolder?.includes('phone')) {
        const phone = properties?.[1]?.includes('recovery')
          ? selectedUser?.recoveryPhone
          : selectedUser?.phones?.find(item => item.type === properties?.[1]?.replace(/[^a-zA-Z]/g, ''))?.value ||
            selectedUser?.recoveryPhone ||
            placeHolder
        return phone
      } else if (placeHolder?.includes('organization')) {
        return selectedUser?.orgUnitPath ?? `${placeHolder}`
      } else if (placeHolder?.includes('addresses')) {
        const address =
          selectedUser?.addresses?.find(item => item.type?.toString() === properties?.[1]?.replace(/[^a-zA-Z]/g, ''))
            ?.formatted ||
          placeHolder ||
          placeHolder
        return address
      } else if (!!selectedUser?.[placeHolder]) {
        return '----'
      }
    } else return `----`
  }

  const replacePlaceHolders = () => {
    let string = template?.toString()
    placeHolders.map(item => {
      const escapedSubstring = item.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      const regex = new RegExp(escapedSubstring, 'i')
      if (regex.test(string)) {
        const sub = `${item}`
        string = string.replaceAll(sub, returnPropertyValue(item))
      } else {
        const sub = `${item}`
        string = string.replaceAll(sub, '---')
      }
      setPreview(string)
    })
  }

  const preview = useMemo(() => {
    replacePlaceHolders()
  }, [template, selectedUser?.id])

  return (
    <Container>
      <Grid
        item
        container
        xs={12}
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          ...(small ? { mt: '-4rem' } : { mt: '-3rem' })
        }}
      >
        <Grid item xs={12} sx={{ mb: '2rem', display: 'flex', justifyContent: 'start !important' }}>
          <Autocomplete
            onChange={selectUserHandler}
            options={users.map(option => option)}
            getOptionLabel={option => option.primaryEmail}
            sx={{ ...(small ? { width: 350 } : { width: 400, mt: '0.625rem' }) }}
            renderInput={params => (
              <TextField
                {...params}
                label='Users to preview'
                variant='standard'
                InputLabelProps={{ style: { fontSize: '0.875rem' } }} // font size of input label
              />
            )}
          />
        </Grid>

        <Card sx={{ borderRadius: 0 }}>
          {previewTemplate && (
            <>
              <CardHeader
                sx={{ backgroundColor: theme => theme.palette.grey[300], height: '1rem' }}
                title={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {[0, 1, 2].map((el, index) => (
                      <Box
                        key={index}
                        sx={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: '#fff', mx: 2 }}
                      />
                    ))}
                  </Box>
                }
              />
              <CardContent sx={{ mt: 5 }}>
                <div dangerouslySetInnerHTML={{ __html: previewTemplate }} />
              </CardContent>
            </>
          )}
        </Card>
      </Grid>
    </Container>
  )
}

export default EditorTemplatePreview
