

// ** React imports
import React, { useEffect, useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'
import { getForwardingAddress, getLabelsList } from 'configs/axios/api_helper'


// ** Styled Box
const Container = styled(Box, { shouldForwardProp: (prop) => prop !== "hover" })(({ theme, hover }) => ({
    padding: "1.425rem",
    cursor: "pointer",
    backgroundColor: "#fff",
    transition: "all .1s ease-in-out",
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
    },
    ...(hover ? {
        boxShadow: theme.shadows[10],
        margin: "12px -12px",
        cursor: "default"
    } : {}),
    ...(!hover ? { "&:hover": { backgroundColor: theme.palette.grey[100] } } : {})
}
))

const defaultValues = {
    labels: [],
    forward: ""
}



const ActionCard = (props) => {

    // ** Props
    const { user } = props

    // ** Hooks
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({ defaultValues })

    // ** State
    const [show, setShow] = useState(false)
    const [emails, setEmails] = useState([])
    const [labels, setLabels] = useState([])

    const fetchForwardEmails = async () => {
        try {
            const response = await getForwardingAddress(user.primaryEmail)
            const { data: { result: { forwardingAddresses } } } = response
            if (!!forwardingAddresses)
                setEmails(forwardingAddresses)
        } catch (error) {
            setEmails([])
        }
    }

    const fetchLabels = async () => {
        try {
            const response = await getLabelsList(user.primaryEmail)
            const { data } = response
            if (!!data)
                setLabels(data)
        } catch (error) {
            setLabels([])
        }
    }
    // ** Set Data
    useEffect(() => {
        if (!!user && show) {
            fetchForwardEmails()
            fetchLabels()
        }
    }, [user, show])

    // ** Functions
    const toggleHandler = () => {
        if (!show)
            setShow(true)
    }


    const onSubmit = (data) => {
        setShow(false)
    }




    return (
        <Container hover={show} onClick={toggleHandler}>
            {show ?
                <Grid item container xs={12}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ fontWeight: 600, fontSize: "1rem" }}>
                            Action
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: "0.775rem" }}>
                            Please select at least 1 of the following action*
                        </Typography>
                    </Grid>
                    <Grid item xs={12} xl={8} sx={{ py: "0.5rem" }}>
                        <form id="role-form" onSubmit={handleSubmit(onSubmit)}>
                            <Grid item container xs={12} sx={{ my: "0.725rem" }}>
                                <Grid item xs={12} md={4}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mt: '1.325rem' }}>
                                        Apply Labels:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <FormControl fullWidth>
                                        <Controller
                                            name='labels'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange } }) => (
                                                <Autocomplete
                                                    id='autocomplete-labels'
                                                    multiple
                                                    getOptionLabel={option => option.name ?? ""}
                                                    renderInput={params => <TextField {...params} variant="standard" label="Labels" />}
                                                    options={labels}
                                                    onChange={(event, value) => onChange(value)}
                                                    value={value}
                                                    limitTags={3}
                                                    renderTags={(tagValue, getTagProps) =>
                                                        tagValue.map((option, index) => (
                                                            <Chip
                                                                label={option.name}
                                                                {...getTagProps({ index })}
                                                                key={index}
                                                                sx={{ backgroundColor: theme => theme.palette.grey[200] }}
                                                            />
                                                        ))
                                                    }
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item container xs={12} sx={{ my: "0.725rem" }}>
                                <Grid item xs={12} md={4}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mt: '1.325rem' }}>
                                        Forward Email:
                                    </Typography>
                                </Grid>
                                <Grid item container xs={12} md={8}>
                                    <FormControl fullWidth>
                                        <InputLabel id='sie-comparison-label'>Select forwarding address</InputLabel>
                                        <Controller
                                            name='forward'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange } }) => (
                                                <Select
                                                    variant="standard"
                                                    value={value}
                                                    label='Select forwarding address'
                                                    onChange={onChange}
                                                    labelId='size-comparison-label'
                                                    error={Boolean(errors.label)}
                                                >
                                                    <MenuItem value=''> <em>None</em></MenuItem>
                                                    {emails.map((el, index) => (<MenuItem key={index} value={el.forwardingEmail}> {el.forwardingEmail}</MenuItem>))}
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Divider sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", width: "100%" }} />
                    <Grid item xs={12} sx={{ textAlign: "end", mt: '1rem' }}>
                        <Button sx={{ fontWeight: 600, color: "text.secondary" }} variant="text" onClick={() => setShow(false)}>
                            CANCEL
                        </Button>
                        <Button
                            type='submit'
                            form="role-form"
                            variant="text"
                            sx={{ fontWeight: 600 }}
                        >
                            SAVE
                        </Button>
                    </Grid>
                </Grid >
                :
                <Grid container item xs={12} spacing={6}>
                    <Grid item xs={12} xl={3}>
                        <Typography sx={{ fontWeight: 600, fontSize: "0.775rem" }}>
                            Actions
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} xl={8} spacing={6}>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ fontSize: "0.775rem" }}>
                                Select at least one of the following action
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Container >
    )

}

export default ActionCard