// ** React Imports
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// ** Axios
import axios from 'axios'

// ** Google Login Import
import { useGoogleLogin } from '@react-oauth/google'

// ** Hooks
import { useAuth } from 'hooks/useAuth'

// ** MUI Components
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { styled, useTheme } from '@mui/material/styles'
import MuiCard from '@mui/material/Card'
import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'

// ** Custome Component
import FallbackSpinner from 'core/components/spinner'

// ** Images Imports
import PremierCloudLogo from 'images/premier_cloud.png'
import GoogleLogo from 'images/logo/google-logo.svg'

// ** Styled Components
const Card = styled(MuiCard)(({ theme }) => ({
  zIndex: 1,
  display: 'block',
  boxShadow: 'none',
  height: 'auto',
  padding: '2.5rem'
}))

const GoogleButton = styled(Button)(({ theme }) => ({
  fontSize: '0.92rem',
  textTransform: 'none',
  //width: "fit-content",
  paddingLeft: '1.5rem',
  paddingRight: '1.5rem',
  fontWeight: 600,
  color: theme.palette.common.black,
  borderRadius: '0.3em',
  height: 48,
  width: 240,
  marginBottom: '1.725rem',
  textAlign: 'center',
  border: `1px solid ${theme.palette.grey[300]}`,
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
    border: `1px solid ${theme.palette.grey[300]}`
  }
}))

const LoginV1 = () => {
  // ** Hooks
  const auth = useAuth()
  const small = useMediaQuery(theme => theme.breakpoints.down('md'))

  const googleLogin = useGoogleLogin({
    // flow: "auth-code",
    // scope: "https://mail.google.com/ https://www.googleapis.com/auth/admin.directory.group https://www.googleapis.com/auth/admin.directory.orgunit",
    onSuccess: async ({ access_token }) => {
      // fetching userinfo can be done on the client or the server from google api
      const userInfo = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: { Authorization: `Bearer ${access_token}` }
      }).then(res => res.json())
      // console.log("ssss: ", res)
      auth.login({ ...userInfo, access_token }, ({ message, code }) => {})
    }
  })

  return (
    <Grid container spacing={4} p={10}>
      <Grid container spacing={4} item xs={12} lg={9}>
        <Grid item xs={12} py={10}>
          <Typography variant={small ? 'h4' : 'h2'} my={2} sx={{ color: 'primary.main' }}>
            Welcome to Admin Plus
          </Typography>
          <Typography variant='body2' my={5} fontSize={small ? 20 : 24}>
            Admin Plus is a dynamic and evolving tool designed to enhance the capabilities of Google Workspace for
            administrators. Building on the foundation of Google Admin Console, Admin Plus integrates additional
            functionalities and customization, crafted to meet the diverse needs of your organization.
          </Typography>
          <Typography variant={small ? 'h4' : 'h3'} sx={{ color: 'primary.main' }}>
            Key Features
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={4} p={small ? 0 : 10}>
          <Grid item xs={12} lg={4}>
            <Box
              sx={{
                backgroundColor: theme => theme.palette.grey[200],
                borderRadius: '12px',
                p: 8,
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Typography
                variant={small ? 'h6' : 'h5'}
                sx={{ color: 'primary.main', ...(small ? { whiteSpace: 'nowrap' } : {}) }}
              >
                Advanced User Management:
              </Typography>
              <Typography variant='body2' sx={{ fontSize: small ? 16 : 18 }}>
                Extends beyond basic user settings, offering nuanced control over aliases, group memberships, contacts,
                calendars, drive, and detailed user settings
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box
              sx={{
                backgroundColor: theme => theme.palette.grey[200],
                borderRadius: '12px',
                p: 8,
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Typography
                variant={small ? 'h6' : 'h5'}
                sx={{ color: 'primary.main', ...(small ? { whiteSpace: 'nowrap' } : {}) }}
              >
                Shared Drive Management:
              </Typography>
              <Typography variant='body2' sx={{ fontSize: small ? 16 : 18 }}>
                Manage shared drives with greater ease, including user permissions and content visibility. This ensures
                secure and appropriate access to resources.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box
              sx={{
                backgroundColor: theme => theme.palette.grey[200],
                borderRadius: '12px',
                p: 8,
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Typography
                variant={small ? 'h6' : 'h5'}
                sx={{ color: 'primary.main', ...(small ? { whiteSpace: 'nowrap' } : {}) }}
              >
                Group Management:
              </Typography>
              <Typography variant='body2' sx={{ fontSize: small ? 16 : 18 }}>
                Edit group information, settings, memberships, and aliases, as well as creating group templates, saving
                time and ensuring consistency in group settings and roles.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            gap: 10
          }}
        >
          <img width={180} height={85} alt='google_cloud' src={PremierCloudLogo} />
          <Typography variant='h6' sx={{ lineHeight: 2, fontWeight: 700, fontSize: '1.5rem !important' }}>
            Login with your Google superadmin account
          </Typography>
        </Box>
        <Box sx={{ mb: 6, textAlign: 'center', mt: '0.5rem' }} />
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <GoogleButton
            onClick={googleLogin}
            variant='outlined'
            startIcon={<img width={20} height={20} alt='google-logo' src={GoogleLogo} />}
          >
            Sign in with Google
          </GoogleButton>
        </Box>
        {auth.error && <Alert severity='error'>You are not authorized to use this application !</Alert>}
        {auth.loading && <FallbackSpinner />}
      </Grid>
    </Grid>
  )
}

export default LoginV1
