// ** React Imports
import React, { Fragment, useState } from 'react'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'

const ErrorDialog = props => {
  // ** Props
  const { error, setError, disconnectTerminal } = props

  // ** Functions

  const handleClose = (event, type) => {
    if (type === 'backdropClick') return
    else {
      setError(false)
      disconnectTerminal()
    }
  }

  const handleSave = () => {
    disconnectTerminal()
    setError(false)
  }

  return (
    <Fragment>
      <Dialog
        open={error}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle id='alert-dialog-title' fontWeight={600}>
          En error has occured during initializing your resources, please try again!
        </DialogTitle>
        <DialogActions className='dialog-actions-dense'>
          <Button onClick={handleSave}>Ok</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default ErrorDialog
