// ** React Imports
import React, { Fragment, useState } from 'react'

// ** API CALLS
import { trashDriveFolder, deleteFilePermanently } from 'configs/axios/api_helper'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress';

// ** Icon Imports
import DeleteIcon from 'mdi-material-ui/TrashCanOutline'


const DeleteDialog = (props) => {

    // ** Props
    const { setFolders, setMessage, folder, user, driveTab } = props

    // ** State
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    // ** Functions
    const handleClickOpen = (e) => {
        e.stopPropagation()
        setOpen(true)
    }
    const handleClose = () => setOpen(false)

    // ** Functions
    const deleteDeleteHandler = async (e) => {
        e.stopPropagation()
        setLoading(true)
        try {
            if (driveTab === 4) {
                const response = await deleteFilePermanently(user.id, folder.id)
            } else {
                const response = await trashDriveFolder(user.id, folder.id, { trashed: true })
            }
            setLoading(false)
            setFolders(state => { return state.filter(el => el.id !== folder.id) })
            setMessage({ success: true, error: false, value: "Folder was deleted successfully" })
            setOpen(false)
        } catch (error) {
            setLoading(false)
            setMessage({ success: false, error: true, value: "There was an error while deleting the folder" })
        }
    }


    return (
        <Fragment>
            <IconButton
                size="medium"
                edge="start"
                sx={{ color: "text.secondary" }}
                onClick={handleClickOpen}
            >
                <DeleteIcon />
            </IconButton>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>{driveTab === 4 ? "Delete forever ?" : "Delete folder ?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        <strong>{folder?.name}</strong> will be deleted forever and you won't be able to restore it.
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='dialog-actions-dense' sx={{ mt: "1rem" }}>
                    <Button
                        disabled={loading}
                        onClick={handleClose}
                        size="small"
                        variant="text"
                        color="inherit"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={loading}
                        onClick={deleteDeleteHandler}
                        endIcon={(loading ?
                            <CircularProgress sx={{ color: 'common.red', mt: 0 }} size="0.825rem" /> : null
                        )}
                        variant="text"
                        size="small"
                        sx={{ fontWeight: 600 }}
                        color="error"
                    >
                        Delete forever
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default DeleteDialog