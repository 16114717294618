// ** React Imports
import React, { useState, useEffect } from 'react'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { fetchUsers, usersSelector } from 'store/users'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

// ** 3rd Party Libraries
import { useDebouncedCallback } from 'use-debounce'

// ** Icons Imports
import SearchIcon from 'mdi-material-ui/Magnify'

// ** Component Imports
import ActionsButton from '../components/buttons/ActionsButton'
import CustomTable from '../components/tables/CustomTable'
import GoogleSpinner from 'core/components/google-spinner'

// Styled Divider component
const StyledDivider = styled(Divider)(({ theme }) => ({
  borderBottomWidth: 1,
  color: theme.palette.grey[300]
}))

const UsersTable = () => {
  // ** Hooks
  const dispatch = useDispatch()

  // ** State
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [page, setPage] = useState(0)
  const [queryPage, setQueryPage] = useState(0)

  // ** Selectors
  const { users, nextPageToken, success, error, loading } = useSelector(usersSelector)

  // ** Fetch users
  useEffect(() => {
    dispatch(fetchUsers({ limit: 25 }))
  }, [])

  // ** Functions
  const handleChangePage = (event, newPage) => {
    if (nextPageToken) {
      if (newPage > queryPage) {
        dispatch(fetchUsers({ limit: rowsPerPage, page: nextPageToken }))
        setQueryPage(newPage)
        setPage(newPage)
      } else {
        setPage(newPage)
      }
    } else {
      setPage(newPage)
    }
  }

  const handleChangeRowsPerPage = event => {
    dispatch(fetchUsers({ limit: parseInt(event.target.value, 10) }))
    setRowsPerPage(parseInt(event.target.value, 10))
    setQueryPage(0)
    setPage(0)
  }

  const debounced = useDebouncedCallback(
    event => {
      const { name, value } = event.target
      if (value) {
        dispatch(fetchUsers({ limit: rowsPerPage, query: value }))
        setQueryPage(0)
        setPage(0)
      } else {
        dispatch(fetchUsers({ limit: rowsPerPage }))
        setQueryPage(0)
        setPage(0)
      }
    },
    500,
    // The maximum time func is allowed to be delayed before it's invoked:
    { maxWait: 3000 }
  )

  return (
    <Card
      sx={{
        '&.MuiPaper-root': {
          borderRadius: '0px !important',
          boxShadow: 0,
          border: theme => `1px solid ${theme.palette.grey[300]}`
        },
        '& .customer-column-header': {
          backgroundColor: 'common.white',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 600
          }
        }
      }}
    >
      <CardHeader sx={{ mb: '-1rem' }} title={<Typography>User Management</Typography>} />
      <StyledDivider />
      <Box sx={{ mt: '1rem', px: 5, display: 'flex' }}>
        <TextField
          placeholder='Search users by: first name, last name or email'
          size='small'
          sx={{ width: 450 }}
          onChange={debounced}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon sx={{ color: 'common.blue' }} />
              </InputAdornment>
            )
          }}
        />
      </Box>
      <StyledDivider />
      <CustomTable
        data={users}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        columns={['USER EMAIL', 'NAME', 'ORG UNIT NAME']}
        nextPageToken={nextPageToken}
        loading={loading}
        page={page}
        rowsPerPage={rowsPerPage}
        path={'/user-profile'}
      />
    </Card>
  )
}

export default UsersTable
