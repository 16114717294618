// ** React Imports
import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'

// **  Custom Components
import Row from './Row'
import GoogleSpinner from 'core/components/google-spinner'






const CustomTable = (props) => {


    // ** Props
    const {
        loading,
        data,
        columns,
        handleChangeRowsPerPage,
        handleChangePage,
        nextPageToken,
        page,
        rowsPerPage,
        path
    } = props

    return (
        <Box sx={{position: 'relative'}}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((el, index) => <TableCell key={index}>{el}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map(row => (
                            <Row key={row.id} row={row} path={path}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50]}
                component='div'
                count={nextPageToken ? -1 : data?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Backdrop
                open={loading == "GET"}
                sx={{
                    position: 'absolute',
                    color: theme => theme.palette.common.blue,
                    zIndex: theme => theme.zIndex.mobileStepper - 1,
                    "&.MuiBackdrop-root": {
                        backgroundColor: "common.white"
                    }
                }}
            >
                <GoogleSpinner />
            </Backdrop>
        </Box>
    )
}


export default CustomTable