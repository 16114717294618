// ** React Imports
import React from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'


const MembersHeader = (props) => {

    // ** Props
    const { groupMembers, group, setCollapsed } = props


    return (
        <Grid container sx={{ mb: "1rem" }}>
            <Grid item xs={12}>
                <Typography variant="body2" sx={{ fontWeight: 600, fontSize: "1rem" }}>
                    Members
                </Typography>
            </Grid>
            {groupMembers?.length > 0 && <Grid item lg={4} sx={{ mt: "1.5rem" }}>
                <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mb: 3 }}>
                    Direct Member
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "0.825rem" }}>
                    {groupMembers?.reduce((total, item) => (item.role === "MEMBER" ? total + 1 : total), 0)}
                </Typography>
            </Grid>}
            {groupMembers?.length > 0 && <Grid item lg={4} sx={{ mt: '1.5rem' }}>
                <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mb: 3 }}>
                    Owners
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "0.825rem" }}>
                    {groupMembers?.reduce((total, item) => (item.role === "OWNER" ? total + 1 : total), 0)}
                </Typography>
            </Grid>}
            {groupMembers?.length > 0 &&
                <Grid item lg={4} sx={{ mt: '1.5rem' }}>
                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mb: 3 }}>
                        Managers
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: "0.825rem" }}>
                        {groupMembers?.reduce((total, item) => (item.role === "MANAGER" ? total + 1 : total), 0)}
                    </Typography>
                </Grid>}
            {!groupMembers || groupMembers?.length < 1 &&
                <Grid item xs={12} sx={{ mt: '1.5rem', textAlign: "center" }}>
                    <Typography variant="body2" sx={{ fontSize: "0.875rem", mb: 3 }}>
                        {group?.name} doesn't have any members
                    </Typography>
                    <Typography
                        onClick={() => setCollapsed(true)}
                        sx={{ color: "common.blue", fontWeight: 600, fontSize: "0.975rem", cursor: "pointer" }}>
                        ADD MEMBERS
                    </Typography>
                </Grid>}
        </Grid>
    )
}


export default MembersHeader