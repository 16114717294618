// ** React Imports
import React, { useState } from 'react'

// ** Redux Imports
import { usersSelector } from 'store/users'
import { useSelector } from 'react-redux'

// ** MUI Imports
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'

// ** Custom Components
import ForwardingAddressesTable from './ForwardingAddressesTable'
import ForwardingAddressesHeader from './ForwardingAddressesHeader'

// ** Icon Imports
import ChevronUp from 'mdi-material-ui/ChevronUp'
import ChevronDown from 'mdi-material-ui/ChevronDown'

const ForwardingAddress = props => {
  // ** Props
  const { setReload } = props

  // ** State
  const [collapsed, setCollapsed] = useState(false)

  // ** Selectors
  const { user } = useSelector(usersSelector)

  return (
    <Card
      sx={{
        border: theme => `solid 1px ${theme.palette.grey[300]}`,
        borderRadius: 0,
        overflow: 'visible',
        my: '0.5rem'
      }}
    >
      <CardHeader
        sx={{
          ...(collapsed && {
            backgroundColor: theme => theme.palette.grey[100],
            height: 50,
            border: theme => `solid 1px ${theme.palette.grey[300]}`,
            mb: 10
          })
        }}
        title={
          !collapsed ? (
            <ForwardingAddressesHeader user={user} />
          ) : (
            <Grid container>
              <Grid item xs={12}>
                <Typography variant='body2' sx={{ fontWeight: 600, fontSize: '1rem' }}>
                  Forwarding Addresses
                </Typography>
              </Grid>
            </Grid>
          )
        }
        action={
          <IconButton
            size='small'
            sx={{ color: 'text.secondary', ...(collapsed && { mt: '-1rem' }) }}
            onClick={() => setCollapsed(!collapsed)}
          >
            {!collapsed ? <ChevronDown fontSize='small' /> : <ChevronUp fontSize='small' />}
          </IconButton>
        }
      />
      <Collapse in={collapsed} open={collapsed}>
        <Grid container item xs={12} sx={{ mt: '-2rem' }}>
          <Grid item xs={12}>
            <ForwardingAddressesTable user={user} setReload={setReload} />
          </Grid>
        </Grid>
      </Collapse>
    </Card>
  )
}

export default ForwardingAddress
