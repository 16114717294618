// ** React Imports
import React, { useState } from 'react'

// ** Redux Imports
import { updateUser, usersSelector } from 'store/users'
import { useDispatch, useSelector } from 'react-redux'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import CircularProgress from '@mui/material/CircularProgress'

// ** Custom Components
import ForwardingHeader from './components/ForwardingHeader'
import ForwardingCard from './components/ForwardingCard'

// ** Icon Imports
import ChevronUp from 'mdi-material-ui/ChevronUp'
import ChevronDown from 'mdi-material-ui/ChevronDown'

const ForwardingSettings = props => {
  // ** Props
  const { reload, setReload } = props

  // ** State
  const [collapsed, setCollapsed] = useState(false)

  // ** Selectors
  const { user } = useSelector(usersSelector)

  return (
    <Card
      sx={{
        border: theme => `solid 1px ${theme.palette.grey[300]}`,
        borderRadius: 0,
        overflow: 'visible',
        my: '0.5rem'
      }}
    >
      <CardHeader
        sx={{
          ...(collapsed && {
            backgroundColor: theme => theme.palette.grey[100],
            height: 50,
            border: theme => `solid 1px ${theme.palette.grey[300]}`,
            mb: 10
          })
        }}
        title={
          !collapsed ? (
            <ForwardingHeader user={user} />
          ) : (
            <Grid container>
              <Grid item xs={12}>
                <Typography variant='body2' sx={{ fontWeight: 600, fontSize: '1rem' }}>
                  Forwarding Settings
                </Typography>
              </Grid>
            </Grid>
          )
        }
        action={
          <IconButton
            size='small'
            sx={{ color: 'text.secondary', ...(collapsed && { mt: '-1rem' }) }}
            onClick={() => setCollapsed(!collapsed)}
          >
            {!collapsed ? <ChevronDown fontSize='small' /> : <ChevronUp fontSize='small' />}
          </IconButton>
        }
      />
      <Collapse in={collapsed} open={collapsed}>
        <Grid container spacing={4}>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '0.925rem',
                  px: '1rem'
                }}
              >
                Forwarding settings
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <ForwardingCard user={user} setReload={setReload} reload={reload} />
            </Grid>
          </Grid>
          {/* Alert to show Error message for getting backup codes */}
        </Grid>
      </Collapse>
    </Card>
  )
}

export default ForwardingSettings
