// ** React Imports
import React, { useEffect, useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'



// ** Styled Tab List
const Container = styled(Box)(({ theme }) => ({
    paddingLeft: "1rem",
    paddingTop: "1rem",
    marginBottom: "2rem",
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(5),
    },
}))

// ** Styled Link
const Link = styled(Typography, { shouldForwardProp: (prop) => prop !== 'active' })(({ theme, active }) => ({
    fontFamily: "Roboto",
    textTransform: "uppercase",
    fontSize: "0.925rem",
    fontWeight: 600,
    lineHeight: "16px",
    marginTop: "0.525rem",
    marginBottom: "0.525rem",
    paddingTop: "0.875rem",
    paddingBottom: "0.875rem",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    borderRadius: 3,
    cursor: "pointer",
    transition: ".25s",
    ...(active ? { backgroundColor: theme.palette.grey[300] } : {}),
    "&:hover": { backgroundColor: theme.palette.grey[300] }
}))

const tabs = ["Users", "Groups", "Organizations unit"]


const SideCard = (props) => {

    // ** Props
    const { activeTab, changeTabsHandler } = props

    return (
        <Card
            sx={{
                borderRadius: 0,
                py: "0.5rem",
                border: theme => `solid 1px ${theme.palette.grey[300]}`,
            }}>
            <Container>
                <Box item xs={8} sx={{ display: "flex", flexDirection: "column", px: "1rem" }}>
                    <Box sx={{ display: 'flex', alignItems: "center" }}>
                        <Typography variant="body2" sx={{ fontSize: "1.325rem" }}>
                            Bulk Signature Update
                        </Typography>
                    </Box>
                </Box>
            </Container>
            <Divider sx={{ borderBottom: theme => `1px solid ${theme.palette.grey[300]}` }} />
            <Typography sx={{ m: "1.225rem", fontWeight: 600, fontSize: "0.925rem" }}>
                Targets :
            </Typography>
            <Box sx={{ px: "1rem" }}>
                {tabs.map((el, index) => (
                    <Link
                        active={activeTab === index}
                        variant="body2"
                        key={index}
                        onClick={() => changeTabsHandler(index)}
                    >
                        {el}
                    </Link>
                ))}
            </Box>
        </Card>
    )
}

export default SideCard