// ** React imports
import React, { useEffect, useState } from 'react'

// ** API CALLS
import { getLabelsList } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { DataGrid } from '@mui/x-data-grid'
import Snackbar from '@mui/material/Snackbar'
import Box from '@mui/material/Box'
import Grow from '@mui/material/Grow'

// ** Custom Components
import DeleteDialog from './components/dialog/DeleteDialog'

// ** Icons Imports
import AddDialog from './components/dialog/AddDialog'

const LabelsTable = props => {
  // ** Props
  const { user } = props

  // ** State
  const [labels, setLabels] = useState([])
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({ success: false, error: false })

  // ** Fetch Labels Data
  useEffect(() => {
    const fetchLabelsData = async () => {
      setLoading(true)
      try {
        const response = await getLabelsList(user.primaryEmail)
        const { data } = response
        setLabels(data)
        setLoading(false)
      } catch (error) {
        setLabels([])
        setLoading(false)
      }
    }
    if (user?.primaryEmail) fetchLabelsData()
  }, [user])

  // ** Functions
  const handleCloseMessage = () => {
    setMessage({ success: false, error: false })
  }

  const columns = [
    {
      flex: 0.25,
      minWidth: 290,
      field: 'name',
      headerName: 'Label Name',
      renderCell: params => {
        return (
          <Typography noWrap variant='body2' sx={{ color: 'common.blue' }}>
            {params.row.name}
          </Typography>
        )
      }
    },
    {
      flex: 0.25,
      minWidth: 290,
      field: 'labelListVisibility',
      headerName: 'Label Visibility',
      renderCell: params => {
        return (
          <Typography noWrap variant='body2'>
            {params.row.labelListVisibility === 'labelShow' && 'Show'}
            {params.row.labelListVisibility === 'labelHide' && 'Hide'}
            {params.row.labelListVisibility === 'labelShowIfUnread' && 'Hide'}
          </Typography>
        )
      }
    },
    {
      flex: 0.25,
      minWidth: 290,
      field: 'messageListVisibility',
      headerName: 'Message Visibility',
      renderCell: params => {
        return (
          <Typography noWrap variant='body2'>
            {params.row.messageListVisibility}
          </Typography>
        )
      }
    },
    {
      flex: 0.175,
      minWidth: 120,
      headerName: 'Action',
      field: 'Action',
      align: 'right',
      headerAlign: 'right',
      renderCell: params => (
        <DeleteDialog setMessage={setMessage} setLabels={setLabels} label={params.row} user={user} />
      )
    }
  ]

  return (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <AddDialog setMessage={setMessage} setLabels={setLabels} />
      </Grid>
      <Grid item xs={12}>
        <DataGrid autoHeight rows={labels} columns={columns} loading={loading} hideFooter />
      </Grid>
      <Snackbar
        sx={{ mt: '3rem' }}
        open={message.success || message.error}
        onClose={handleCloseMessage}
        autoHideDuration={2000}
        key={'top' + 'right'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={Grow}
        message={
          <Box sx={{ textAlign: 'center' }}>
            {message.success === 'DELETE_LABEL' && 'Label was deleted successfully'}
            {message.error === 'ERROR_DELETE' && 'There was an error deleting the label'}
            {message.success === 'ADD_LABEL' && 'Label was added successfully'}
            {message.error === 'ERROR_ADD' && 'There was an error while adding the label'}
          </Box>
        }
      />
    </Grid>
  )
}

export default LabelsTable
