// ** React Imports
import React, { useEffect, useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'

// ** Icons Imports
import AttachFileIcon from '@mui/icons-material/AttachFile'
import ImageIcon from '@mui/icons-material/Image'
import PDFIcon from '@mui/icons-material/PictureAsPdf'
import CloseIcon from '@mui/icons-material/Close'
import ZipIcon from '@mui/icons-material/FolderZip'
import FileIcon from '@mui/icons-material/InsertDriveFile'

// ** Third Party Imports
import axios from 'axios'
import { useDropzone } from 'react-dropzone'
import { useLocation } from 'react-router-dom'

// Styled header text
const HeadingTypography = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '0.785rem',
  fontWeight: 600,
  marginTop: 5,
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4)
  }
}))

// Styled img box container
const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '12px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}))

// Styled component for the upload image dropzone box
const DropZoneBox = styled(Box)(({ theme }) => ({
  minHeight: '56px',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  alignItems: 'start',
  justifyContent: 'start',
  cursor: 'pointer',
  position: 'relative',
  '-webkit-box-align': 'start',
  '-webkit-box-pack': 'start',
  gap: 3,
  border: '1.75px solid rgba(93, 89, 98, 0.22)'
}))

// Styled component for the upload image inside the dropzone area
const HeaderText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
  maxWidth: '125px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}))

// Styled component for the upload image inside the dropzone area
const File = styled('div')(({ theme }) => ({
  margin: '10px 14px 10px 5px',
  borderRadius: '8px',
  border: '1px solid #e8eaed',
  height: 40,
  maxWidth: 'fit-content',
  display: 'flex',
  gap: 2,
  alignItems: 'center'
}))

// ** Convert blob to base64
const blobToBase64 = (blob, callback) => {
  const reader = new FileReader()
  reader.onloadend = function () {
    callback(reader.result)
  }
  reader.readAsDataURL(blob)
}

// ** Function upload
const uploadFileToZendesk = (file, callback) => {
  blobToBase64(file, async base64String => {
    file['base64'] = base64String
    try {
      const response = await axios.post(process.env.REACT_APP_ZENDESK_UPLOAD_FILE_URL, file, {
        headers: { 'Content-Type': 'application/binary' },
        auth: {
          username: process.env.REACT_APP_ZENDESK_USERNAME,
          password: process.env.REACT_APP_ZENDESK_PASSWORD
        }
      })
      callback(response)
    } catch (error) {
      console.log('----error---', error)
    }
  })
}

const FileUploader = props => {
  // ** Props
  const { setValue, filesUrls, attachmentsData } = props

  // ** State
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(null)
  const [filesTokens, setFilesTokens] = useState([]) // holds the array of the zendesk file token

  // ** Hook
  const { pathname } = useLocation()
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    disabled: pathname.includes('/case/'),
    accept: {
      'text/plain': ['.txt', '.dif', '.csv'],
      'image/*': ['.png', '.jpg', '.jpeg'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/zip': ['.zip']
    },
    onDrop: acceptedFiles => {
      const file = [...acceptedFiles].pop()
      // console.log('----file----', file)
      setLoading(file.path)
      setFiles(state => {
        const newFiles = [...state, ...acceptedFiles.map(file => Object.assign(file))]
        return newFiles
      })
      uploadFileToZendesk(file, response => {
        setLoading(null)
        setFilesTokens(state => {
          const tokens = [...state, { path: file.path, token: response?.data?.upload?.token }]
          setValue && setValue('attachments', tokens)
          setValue && setValue('attachmentsData', tokens)
          return tokens
        })
      })
    }
  })

  // ** Transfer urls to blobs
  useEffect(() => {
    filesUrls?.map(async item => {
      try {
        const response = await fetch(item.content_url)
        const blobData = await response.blob()
        setFiles(state => [...state, blobData])
      } catch (error) {
        console.error('Error fetching image:', error)
      }
    })
    return () => {
      setFiles([])
    }
  }, [filesUrls])

  const handleDeleteFile = (event, path) => {
    event.stopPropagation()
    setFiles(state => state.filter(item => item.path !== path))
    setFilesTokens(state => {
      const tokens = state.filter(item => item.path !== path)
      setValue && setValue('attachments', tokens)
      return tokens
    })
  }

  const handleDownload = (event, fileBlob, index) => {
    event.stopPropagation()
    if (fileBlob && pathname.includes('/case/')) {
      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(fileBlob)
      downloadLink.download = `attachment ${index + 1}.${fileBlob.type?.split('/')?.[1]}` // Set your custom filename here
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    }
  }

  const attachemnts = files.map((file, index) => (
    <>
      {loading === file.path ? (
        <File key={file.name}>
          <Box>
            <LinearProgress sx={{ width: '100%', mb: -1.5 }} />
            <Box display='flex' alignItems='center' gap={1} p='8px 12px' component='div'>
              <FileIcon sx={{ color: 'primary.main', fontSize: 22 }} />
              <HeaderText>{file.name}</HeaderText>
              <Typography fontSize={14} fontWeight={450} whiteSpace='nowrap'>
                ({(file.size / 1024)?.toFixed(1)} kB)
              </Typography>
            </Box>
          </Box>
        </File>
      ) : (
        <File key={file.name} onClick={e => handleDownload(e, file, index)}>
          <Box display='flex' alignItems='center' gap={1} p='8px 12px' component='div'>
            {file.type?.includes('png') || file.type?.includes('jpg') || file.type?.includes('jpeg') ? (
              <ImageIcon sx={{ color: 'error.main', fontSize: 22 }} />
            ) : null}
            {file.type?.includes('csv') || file.type?.includes('txt') ? (
              <FileIcon sx={{ color: 'primary.main', fontSize: 22 }} />
            ) : null}
            {file.type?.includes('pdf') ? <PDFIcon sx={{ color: 'error.main', fontSize: 22 }} /> : null}
            {file.type?.includes('zip') ? <ZipIcon sx={{ color: 'primary.main', fontSize: 22 }} /> : null}
            {file.type?.includes('octet') ? <FileIcon sx={{ color: 'primary.main', fontSize: 22 }} /> : null}
            <HeaderText>{file.name || attachmentsData?.[index]?.path || ''}</HeaderText>
            <Typography fontSize={14} fontWeight={450} whiteSpace='nowrap'>
              ({file.size ? (file.size / 1024)?.toFixed(1) + 'kB' : null})
            </Typography>
          </Box>
          {!pathname.includes('/case/') ? (
            <Box p='8px 12px' display='flex' alignItems='center'>
              <CloseIcon sx={{ fontSize: 24, cursor: 'pointer' }} onClick={e => handleDeleteFile(e, file.path)} />
            </Box>
          ) : null}
        </File>
      )}
    </>
  ))


  return (
    <DropZoneBox {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} />
      {files.length ? (
        <BoxContainer sx={{ py: 0, px: 2, justifyContent: 'space-between' }}>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              alignItems: 'start',
              justifyContent: 'start'
            }}
          >
            {attachemnts}
          </Box>
          <AttachFileIcon sx={{ fontSize: '1.5rem' }} />
        </BoxContainer>
      ) : (
        <BoxContainer>
          <Typography variant='body2' fontSize={16}>
            Attachments
          </Typography>
          <AttachFileIcon sx={{ fontSize: '1.5rem' }} />
        </BoxContainer>
      )}
    </DropZoneBox>
  )
}

export default FileUploader
