// ** React Imports
import React, { useState } from 'react'

// ** Redux Imports
import { updateUser, usersSelector } from 'store/users'
import { useDispatch, useSelector } from 'react-redux'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import Snackbar from '@mui/material/Snackbar'
import CircularProgress from '@mui/material/CircularProgress'

// ** Custom Components
import SecurityInformationHeader from './components/SecurityInformationHeader'


// ** Icon Imports
import ChevronUp from 'mdi-material-ui/ChevronUp'
import ChevronDown from 'mdi-material-ui/ChevronDown'
import TwoStepVerificationCard from './components/TwoStepVerificationCard'
import PasswordCard from './components/PasswordCard'



// ** Styled Grid
const Container = styled(Grid)(({ theme }) => ({

}))

const initialValues = {
    familyName: "",
    fullName: "",
    givenName: "",
    password: "",
    changePasswordAtNextLogin: false,
    includeInGlobalAddressList: false,
    suspended: false,
    orgUnitPath: "",
    userName: "",
    domain: "",
    makeAdmin: false,
}



const SecuritySettings = (props) => {

    // ** Hooks 
    const dispatch = useDispatch()

    // ** State
    const [collapsed, setCollapsed] = useState(false)

    // ** Selectors
    const { user } = useSelector(usersSelector);


    // ** Functions





    return (
        <Card
            sx={{
                border: theme => `solid 1px ${theme.palette.grey[300]}`,
                borderRadius: 0,
                overflow: "visible",
                my: "0.5rem"
            }}>
            <CardHeader
                sx={{
                    ...(collapsed && {
                        backgroundColor: theme => theme.palette.grey[100],
                        height: 50,
                        border: theme => `solid 1px ${theme.palette.grey[300]}`,
                        mb: 10,
                    }),
                }}
                title={(!collapsed ?
                    <SecurityInformationHeader user={user} />
                    :
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ fontWeight: 600, fontSize: "1rem" }}>
                                Security Information
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                action={
                    <IconButton
                        size='small'
                        sx={{ color: 'text.secondary', ...(collapsed && { mt: "-1rem" }) }}
                        onClick={() => setCollapsed(!collapsed)}
                    >
                        {!collapsed ? <ChevronDown fontSize='small' /> : <ChevronUp fontSize='small' />}
                    </IconButton>
                }
            />
            <Collapse in={collapsed} open={collapsed}>
                <Container container spacing={4}>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: "0.925rem",
                                    mt: "-1.5rem",
                                    mb: "1rem",
                                    px: "2rem"
                                }}>
                                Password Settings
                            </Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <PasswordCard />
                        </Grid>
                        <Grid item xs={12} >
                            <TwoStepVerificationCard user={user} />
                        </Grid>

                    </Grid>

                    {/* Alert to show Error message for getting backup codes */}


                </Container>
            </Collapse>
        </Card>
    )
}



export default SecuritySettings