// ** React Imports
import React from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'


const GroupInformationHeader = (props) => {

    // ** Props
    const { group } = props



    return (
        <Grid container sx={{mb: "1rem"}}>
            <Grid item xs={12}>
                <Typography variant="body2" sx={{ fontWeight: 600, fontSize: "1rem" }}>
                    Group Information
                </Typography>
            </Grid>
            <Grid item lg={4} sx={{ mt: "1.5rem" }}>
                <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mb: 3 }}>
                    Group email
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "0.825rem" }}>
                    {group?.email || "Add an email"}
                </Typography>
            </Grid>
            <Grid item lg={4} sx={{ mt: '1.5rem' }}>
                <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mb: 3 }}>
                    Group description
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "0.825rem" }}>
                    {group?.description || "Add description"}
                </Typography>
            </Grid>
            <Grid item xs={4} sx={{ mt: "1.5rem" }}>
                <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mb: 3 }}>
                    Aliases
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "0.825rem" }}>
                    {group?.aliases?.length || "Add aliases"}
                </Typography>
            </Grid>
        </Grid>
    )
}


export default GroupInformationHeader