// ** React Imports
import React, { useState, useEffect } from 'react'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { cleanMessages, fetchTemplates, templateSelector } from 'store/template'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'

// ** Component Imports
import ActionsButton from '../components/buttons/ActionsButton'
import CustomTable from '../components/table/CustomTable'
import GoogleSpinner from 'core/components/google-spinner'

// ** Hooks
import { useAuth } from 'hooks/useAuth'
import AlertMessage from 'pages/tools/components/CustomAlertMessage'


// Styled Divider component
const StyledDivider = styled(Divider)(({ theme }) => ({
    borderBottomWidth: 1,
    color: theme.palette.grey[300],
}))


const TemplatesTable = () => {


    // ** Hooks
    const dispatch = useDispatch()
    const { user } = useAuth()

    // ** State
    //const [page, setPage] = useState(0)
    //const [rowsPerPage, setRowsPerPage] = useState(0)

    // ** Selectors
    const { templates, loading, success, error } = useSelector(templateSelector)


    // ** Fetch users 
    useEffect(() => {
        if (user?.customerId)
            dispatch(fetchTemplates(user.customerId))
    }, [user])


    // ** Functions
    const handleCloseMessage = () => {
        dispatch(cleanMessages())
    }
    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10))
    //     setPage(0)
    // }
    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage)
    // }


    return (
        <Card
            sx={{
                '&.MuiPaper-root': {
                    borderRadius: "0px !important",
                    boxShadow: 0,
                    border: theme => `1px solid ${theme.palette.grey[300]}`
                },
                '& .customer-column-header': {
                    backgroundColor: 'common.white',
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 600
                    }
                },
            }}>
            <CardHeader
                sx={{ mb: "-1rem" }}
                title={<Typography>Template</Typography>}
                action={<ActionsButton path='/tools/create-template' />}
            />
            <StyledDivider />
            <CustomTable
                data={templates}
                columns={["Template name", "Creacted on", "Description", "Action"]}
                loading={loading}
            />
            {success === "DELETE" && <AlertMessage
                message={{ value: "Template was deleted successfully", success: true, error: false }}
                handleCloseMessage={handleCloseMessage}
            />}
        </Card>


    )
}

export default TemplatesTable
