// ** React Imports
import React, { useEffect, useState } from "react"

// ** Redux Imports
import { useDispatch, useSelector } from "react-redux"
import { editUser, usersSelector } from "store/users"

// ** Apis Calls
import {
    getDomainsList,
    getOrganizationsList,
} from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop'

// ** Custom Components
import AlertMessages from "../../../alert"

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'

// ** Hooks
import { useAuth } from "hooks/useAuth"

// ** Styled Box
const Container = styled(Box, { shouldForwardProp: (prop) => prop !== "hover" })(({ theme, hover }) => ({
    padding: "1.425rem",
    cursor: "pointer",
    backgroundColor: "#fff",
    transition: "all .1s ease-in-out",
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
    },
    ...(hover ? {
        boxShadow: theme.shadows[10],
        margin: "12px -12px",
        cursor: "default"
    } : {}),
    ...(!hover ? { "&:hover": { backgroundColor: theme.palette.grey[100] } } : {})
}
))

const defaultValues = {
    familyName: "",
    givenName: "",
    suspended: false,
    orgUnitPath: "",
    userName: "",
    domain: ""
}



const PrimaryInformation = (props) => {

    // ** Hooks
    const auth = useAuth()
    const dispatch = useDispatch()
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        watch
    } = useForm({ defaultValues })

    // ** Selectors
    const { user, loading, success, error } = useSelector(usersSelector);

    // ** State
    const [show, setShow] = useState(false)
    const [domains, setDomains] = useState([])
    const [organizations, setOrganizations] = useState([])

    // ** Fetch domains & organizations data
    useEffect(() => {
        if (user?.id) {
            reset({
                familyName: user.name.familyName,
                givenName: user.name.givenName,
                suspended: user.suspended,
                orgUnitPath: user.orgUnitPath === "/" ? "" : user.orgUnitPath,
                userName: user.primaryEmail.split("@")[0] || "",
                domain: user.primaryEmail.split("@")[1] || "",
            })
        }
        const fetchDomainsData = async () => {
            try {
                const response = await getDomainsList(auth.user?.customerId)
                let { data: { domains } } = response
                setDomains(domains)
            } catch (error) {

            }
        }
        const fetchOrganizationsData = async () => {
            try {
                const response = await getOrganizationsList(auth.user?.customerId)
                let { data: { organizationUnits } } = response
                let formattedOrganizationArray = organizationUnits
                    ?.sort((organization) => organization.parentOrgUnitPath === "/" ? -1 : 1)
                    ?.reduce((accumulator, organization) => {
                        if (organization.parentOrgUnitPath === "/") {
                            return [...accumulator, { name: organization.name, path: organization.orgUnitPath, parentOrgUnitPath: "", children: [] }]
                        } else {
                            return [...accumulator, { name: organization.name, path: organization.orgUnitPath, parentOrgUnitPath: organization.parentOrgUnitPath, children: [] }]
                        }
                    }, [])
                setOrganizations(formattedOrganizationArray)
            } catch (error) {

            }
        }

        if (auth.user?.customerId) {
            fetchDomainsData()
            fetchOrganizationsData()
        }
    }, [user])

    // ** Functions
    const toggleHandler = () => {
        if (!show)
            setShow(true)
    }


    const onSubmit = (data) => {
        const { familyName, givenName, userName, domain, ...rest } = data
        // Formatting the body
        let body = {
            name: {
                fullName: `${givenName} ${familyName}`,
                givenName,
                familyName
            },
            primaryEmail: `${userName}@${domain}`,
            ...rest,
        }
        dispatch(editUser(user?.id, body))
        setShow(false)
    }

    return (
        <Container hover={show} onClick={toggleHandler}>
            {show ?
                <Grid item container xs={12} sx={{ position: 'relative' }}>
                    <Grid item xl={3}>
                        <Typography sx={{ fontWeight: 600, fontSize: "0.775rem" }}>
                            Mandatory Information
                        </Typography>
                    </Grid>
                    <Grid container item xl={9}>
                        <form id="primary-form" onSubmit={handleSubmit(onSubmit)}>
                            <Grid item xs={12}>
                                <FormControl>
                                    <Controller
                                        name='suspended'
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <FormControlLabel
                                                label={watch("suspended") ? "Suspended" : "Active"}
                                                sx={errors.checkbox ? { color: 'error.main' } : null}
                                                control={
                                                    <Switch
                                                        value={!value}
                                                        onChange={() => onChange(!value)}
                                                        name='suspended'
                                                        sx={errors.checkbox ? { color: 'error.main' } : null}
                                                    />
                                                }
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item container spacing={4} xs={12} sx={{ my: "0.725rem" }}>
                                <Grid item xl={6} xs={12}>
                                    <FormControl fullWidth>
                                        <Controller
                                            name='givenName'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <TextField
                                                    variant="standard"
                                                    value={value}
                                                    label='First Name'
                                                    onChange={onChange}
                                                    error={Boolean(errors.lastName)}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={6} xs={12}>
                                    <FormControl fullWidth>
                                        <Controller
                                            name='familyName'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <TextField
                                                    variant="standard"
                                                    value={value}
                                                    label='Last Name'
                                                    onChange={onChange}
                                                    error={Boolean(errors.lastName)}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ my: "0.725rem" }}>
                                <FormControl fullWidth>
                                    <Controller
                                        name='userName'
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <TextField
                                                variant="standard"
                                                value={value}
                                                label='User Name'
                                                onChange={onChange}
                                                error={Boolean(errors.lastName)}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sx={{ my: "0.725rem" }}>
                                <FormControl fullWidth>
                                    <InputLabel id='domain-label'>Domain</InputLabel>
                                    <Controller
                                        name='domain'
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <Select
                                                variant="standard"
                                                value={value}
                                                label='Domain'
                                                onChange={onChange}
                                                labelId='domain-label'
                                            >
                                                {domains.map((el, index) => <MenuItem key={index} value={el.domainName}>{el.domainName}</MenuItem>)}
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: "0.825rem" }} >
                                <FormControl variant='standard' sx={{ width: "100%" }}>
                                    <Controller
                                        name='orgUnitPath'
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <Autocomplete
                                                id='autocomplete-grouped'
                                                defaultValue={organizations?.find(unit => unit?.path === user?.orgUnitPath)}
                                                groupBy={option => option.parentOrgUnitPath}
                                                getOptionLabel={option => option.name}
                                                renderInput={params => <TextField {...params} variant="standard" label='Organizations' />}
                                                options={organizations}
                                                value={organizations?.find(unit => unit?.path === value)}
                                                onChange={(event, values) => onChange(values.path)}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </form>
                    </Grid>
                    <Divider sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", width: "100%" }} />
                    <Grid item xs={12} sx={{ textAlign: "end", mt: '1rem' }}>
                        <Button sx={{ fontWeight: 600, color: "text.secondary" }} variant="text" onClick={() => setShow(false)}>
                            CANCEL
                        </Button>
                        <Button
                            type='submit'
                            form="primary-form"
                            variant="text"
                            sx={{ fontWeight: 600 }}
                            disabled={loading}
                            endIcon={
                                ((loading == "UPDATE_USER")) &&
                                <CircularProgress sx={{ color: 'common.blue' }} size="0.925rem" />
                            }
                        >
                            SAVE
                        </Button>
                    </Grid>
                    <Backdrop
                        open={loading == "UPDATE_USER"}
                        sx={{
                            position: 'absolute',
                            backgroundColor: "transparent",
                            backdropFilter: 'blur(5px)',
                            color: theme => theme.palette.common.blue,
                            zIndex: theme => theme.zIndex.mobileStepper - 1,
                        }}
                    >
                        <CircularProgress color='inherit' />
                    </Backdrop>
                </Grid>
                :
                <Grid container item xs={12} spacing={6}>
                    <Grid item xs={12} xl={3}>
                        <Typography sx={{ fontWeight: 600, fontSize: "0.775rem" }}>
                            Mandatory Information
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} xl={8} spacing={6}>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ fontSize: "0.775rem" }}>
                                Full Name
                            </Typography>
                            <Typography sx={{ fontSize: "0.925rem" }}>
                                {(user?.name?.givenName || "-----") + " " + (user?.name?.familyName || "-----")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ fontSize: "0.775rem" }}>
                                Domain
                            </Typography>
                            <Typography sx={{ fontSize: "0.925rem" }}>
                                {(user?.primaryEmail?.split("@")[1] || "-----")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ fontSize: "0.775rem" }}>
                                Organization
                            </Typography>
                            <Typography sx={{ fontSize: "0.925rem" }}>
                                {(user?.orgUnitPath || "-----")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            }
            <AlertMessages
                success={success}
                error={error}
                messageValue={"UPDATE_USER"}
                message={"User was updated successfully !"}
            />
        </Container>
    )

}


export default PrimaryInformation