// ** React Imports
import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { selectTemplate, setTemplate, templateSelector } from 'store/template'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

// ** Custom Components
import GeneralInfo from './components/GeneralInfo'
import TemplateCardsContainer from './components/TemplateCardsContainer'
import TemplateForm from './containers/TemplateForm'
import TemplatePreview from './containers/TemplatePreview'

// ** Styled Grid Container
const Container = styled(Grid)(({ theme }) => ({
  paddingLeft: '2rem',
  paddingRight: '2rem',
  marginBottom: '2rem',
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 0,
    paddingRight: 0
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}))

const SignatureTemplate = props => {
  // ** Hooks
  const dispatch = useDispatch()
  const { id } = useParams()
  const { pathname } = useLocation()

  // ** Selectors
  const { templates } = useSelector(templateSelector)

  // ** State
  const [activeTab, setActiveTab] = useState(0)

  // ** Set Template on update
  useEffect(() => {
    if (pathname.includes('update') && id && templates.length > 0) {
      // get the template and parse the editor template array
      const template = templates.find(el => el.uid === id)
      let extraFields = []
      try {
        extraFields = JSON.parse(template?.editorTemplate)
      } catch {
        extraFields = []
      }
      const selectedTemplate = extraFields.find(item => item.name === 'selectedTemplate')?.value || 0
      setActiveTab(selectedTemplate)
      if (!!template)
        dispatch(
          setTemplate({
            ...template,
            extraFields: extraFields?.filter(item => item.name !== 'selectedTemplate'),
            selectedTemplate
          })
        )
    }
  }, [id])

  // ** Functions
  const changeTabsHandler = tab => {
    dispatch(selectTemplate(tab))
    setActiveTab(tab)
  }

  return (
    <Container container spacing={6}>
      <Grid item xs={12} md={4} lg={4.5} xl={3}>
        <GeneralInfo activeTab={activeTab} changeTabsHandler={changeTabsHandler} />
        <TemplateCardsContainer activeTab={activeTab} setActiveTab={changeTabsHandler} />
      </Grid>
      <Grid container item xs={12} md={8} lg={7.5} xl={9}>
        <Grid item xs={12}>
          <TemplateForm />
        </Grid>
        <Grid item xs={12}>
          <TemplatePreview />
        </Grid>
      </Grid>
    </Container>
  )
}

export default SignatureTemplate
