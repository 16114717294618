import React from 'react'

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    margin: '20px',
    lineHeight: '1.6',
    padding: "20px 60px 20px 60px"
  },
  header: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#1976d2'
  },
  subHeader: {
    width: '100%',
    textAlign: 'start',
    
    fontSize: '14px',
    fontWeight: 600
  },
  sectionTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '20px',
    marginBottom: '10px'
  },
  paragraph: {
    marginBottom: '10px'
  },
  listItem: {
    marginBottom: '5px'
  }
}

const PrivacyPolicy = () => {
  return (
    <div style={styles.container}>
      <div style={styles.subHeader}>
        <p>PREMIER CLOUD INC.</p>
        <p>
          Version: <span>1.0</span>
        </p>
        <p>
          Last Updated: <span>June 26, 2024</span>
        </p>
      </div>
      <div style={styles.header}>MASTER SERVICES AGREEMENT - PREMIER CLOUD INC.</div>

      <div style={styles.sectionTitle}>MASTER SERVICES AGREEMENT</div>
      <p style={styles.paragraph}>
        This <strong>MASTER SERVICES AGREEMENT</strong> (the <strong>“Agreement”</strong>) is made and entered into as of the date the last party executes
        the corresponding Order Form (the <strong>“Order Form”</strong>) and/or Statement of Work (the <strong>“Statement of Work”</strong> or <strong>“SOW”</strong>) by
        electronic or manual signature (the <strong>“Effective Date”</strong>), thereby indicating acceptance of the terms of this
        Agreement between:
      </p>
      <p style={styles.paragraph}>
      <strong>PREMIER CLOUD INC.</strong>, a corporation organized and existing under the laws of the province of British Columbia,
        with its registered and records office at 504-1803 Douglas Street, Victoria, British Columbia, V8T 5C3, Canada
        (<strong>“Premier Cloud”</strong>)
      </p>
      <p style={styles.paragraph}>AND</p>
      <p style={styles.paragraph}>
        The customer identified in the Order Form and/or Statement of Work (<strong>"Customer"</strong>) (each a <strong>“Party”</strong>, collectively
        the <strong>“Parties”</strong>).
      </p>

      <div style={styles.sectionTitle}>WITNESSES THAT WHEREAS:</div>
      <p style={styles.paragraph}>
        A. Premier Cloud is an authorized reseller of certain SaaS and PaaS products owned by third-parties (each a
        “Vendor” and collectively, the “Vendors”) and provides related professional services; and
      </p>
      <p style={styles.paragraph}>
        B. Parties have agreed that Premier Cloud will provide Customer with one or more services as per the terms and
        conditions set out in this Agreement.
      </p>
      <p style={styles.paragraph}>
        NOW THEREFORE, in consideration of the premises set forth above and other good and valuable consideration, the
        receipt and sufficiency of which are hereby acknowledged, the Parties agree as follows:
      </p>

      <div style={styles.sectionTitle}>1. DEFINITIONS</div>
      <p style={styles.paragraph}>
        a) “Applications” means all web applications Customer or End Users use, produce, develop, or create, including
        any source code written by Customer or End Users, using the Vendor Products;
      </p>
      <p style={styles.paragraph}>
        b) “Background IP” means all IP owned, licensed, conceived, originated, created, or developed by a Party prior
        to the execution of the respective SOW and/or Order Form;
      </p>
      <p style={styles.paragraph}>
        c) “Billing Subaccount” means a billing subaccount owned by Premier Cloud’s parent billing account which is
        billed by a Vendor to Premier Cloud’s parent billing account arising from Customer's use of a Vendor Product or
        Application;
      </p>
      <p style={styles.paragraph}>
        d) “Business Day” means any day except Saturday, Sunday, or a statutory holiday in the Province of British
        Columbia;
      </p>
      <p style={styles.paragraph}>
        e) “Confidential Information” means information in any form or medium (whether oral, written, electronic, or
        other) that is considered confidential by Customer or End Users, in each case whether or not marked, designated,
        or otherwise identified as "confidential", including, without limitation, names, contact information (including,
        without limitation, e-mail addresses, postal addresses and telephone numbers), government identification
        numbers, financial account numbers, payment card information, transaction information, credit report
        information, biometric information, IP addresses, network and hardware identifiers, protected health
        information, geolocation information, and any other information about individual persons or their use of the
        Services;
      </p>
      <p style={styles.paragraph}>
        f) “Customer Data” means information, data, Confidential Information, and other content, in any form or medium,
        that is displayed, submitted, posted, transmitted, transferred, or migrated by or on behalf of the Customer or
        any End Users through an Application, Vendor Product, or both;
      </p>
      <p style={styles.paragraph}>
        g) “Deliverables” means, with respect to each Order Form, the work product specified as deliverables created
        specifically for or produced as a result of the delivery of Professional Services to Customer by Premier Cloud;
      </p>
      <p style={styles.paragraph}>
        h) “Data Protection Laws” means all applicable laws, rules, and regulations relating to the processing,
        controlling, storage, protection, or privacy of Customer Data in any jurisdiction;
      </p>
      <p style={styles.paragraph}>i) “Disclosing Party” has the meaning set out in Section 6.1;</p>
      <p style={styles.paragraph}>j) “Due Date” has the meaning set out in Section 5.1(a);</p>
      <p style={styles.paragraph}>
        k) “End Users” means any person whom Customer permits to use an Application, Vendor Product, or both;
      </p>
      <p style={styles.paragraph}>l) “Fees” has the meaning set out in Section 5.1(a);</p>
      <p style={styles.paragraph}>m) “Initial Term” has the meaning set out in Section 4.1;</p>
      <p style={styles.paragraph}>
        n) “IP” means all, registered or unregistered, right to intellectual property, including, but not limited to,
        patent rights, copyrights, trademark rights, rights in trade secrets, design rights, database rights, domain
        name rights, moral rights, and any other intangible property right throughout the world;
      </p>
      <p style={styles.paragraph}>o) “Losses” has the meaning set out in Section 8.2;</p>
      <p style={styles.paragraph}>p) “Marks” means the Customer’s tradenames, trademarks, copyrights, and logos;</p>
      <p style={styles.paragraph}>
        q) “Order Form” means the form used by Customer to order a Vendor Product from Premier Cloud;
      </p>
      <p style={styles.paragraph}>r) “Premier Cloud Indemnitee” has the meaning set out in Section 8.2;</p>
      <p style={styles.paragraph}>
        s) “Professional Services” means the professional services described in the Statement of Work and incorporated
        into this Agreement to be provided by Premier Cloud for or on behalf of Customer;
      </p>
      <p style={styles.paragraph}>t) “Receiving Party" has the meaning set out in Section 6.1;</p>
      <p style={styles.paragraph}>u) “Renewal Term” has the meaning set out in Section 4.2(a);</p>
      <p style={styles.paragraph}>v) “Services” means, collectively, the Vendor Products and Professional Service;</p>
      <p style={styles.paragraph}>
        w) “SLA” means the respective service level agreement, as updated from time to time, for a Vendor Product, a
        link which may be found in the applicable Order Form;
      </p>
      <p style={styles.paragraph}>
        x) “Software License” means any third-party software license made available to Customer or End User on a
        subscription basis which requires prepayment, a commitment to pay for usage, or a commitment to pay for a
        specific time period;
      </p>
      <p style={styles.paragraph}>
        y) “Statement of Work” or “SOW” means the document(s) agreed upon between Premier Cloud and Customer and
        incorporated by reference into this Agreement which define the Professional Services to be performed and the
        Deliverables to be provided for or on behalf of Customer by Premier Cloud pursuant to this Agreement, and any
        amendments thereto;
      </p>
      <p style={styles.paragraph}>z) “Term” means the Initial Term together with any Renewal Terms;</p>
      <p style={styles.paragraph}>
        aa) “Vendor’s TOS” means those terms and conditions of service, a link to which may be found in the Order Form
        and incorporated by reference into this Agreement, that govern Customer or End User use of a Vendor Product,
        including, without limitation, acceptable use policies, terms of service, technical support service guidelines,
        license agreements, service specific terms, data processing agreements, professional service terms, and SLAs, as
        updated from time to time;
      </p>
      <p style={styles.paragraph}>
        bb) “Vendor” means a third-party software or service provider who offers Vendor Products for use by Customer;
        and
      </p>
      <p style={styles.paragraph}>
        cc) “Vendor Product” means a third-party Software Licence, service, software developmental kit, downloadable
        tool, dataset, or other proprietary software made available by a Vendor to Customer or End Users.
      </p>

      <div style={styles.sectionTitle}>2. SERVICES</div>
      <p style={styles.paragraph}>
        2.1 Provision of Services. Premier Cloud will provide the Services to Customer in accordance with the terms set
        forth in this Agreement, including, without limitation, any applicable Order Form and each SOW agreed upon by
        the Parties, each incorporated by reference into this Agreement.
      </p>
      <p style={styles.paragraph}>
        2.2 Software Licenses. Premier Cloud represents that it is an authorized reseller of each Software License
        purchased using an Order Form under this Agreement.
      </p>
      <p style={styles.paragraph}>
        2.3 Additional Software Licenses. Customer may purchase additional Software Licenses at any time during the
        Term, the price of which is listed on an Order Form for a specific Vendor Product.
      </p>
      <p style={styles.paragraph}>
        2.4 Support. Customer consents and authorizes Premier Cloud to submit and disclose Customer Data to each
        respective Vendor, as is reasonably required, for the following:
      </p>
      <ul>
        <li style={styles.listItem}>a) for support issues related to each Software License;</li>
        <li style={styles.listItem}>b) to provide updates, notices, and customer service; and</li>
        <li style={styles.listItem}>c) to provide the Services to the Customer.</li>
      </ul>
      <p style={styles.paragraph}>
        2.5 Vendor Breach. If Vendor breaches the Vendor’s TOS, then Customer will be eligible to receive only those
        remedies set out by the Vendor’s TOS for each Vendor Product (the “Remedies”).
      </p>
      <p style={styles.paragraph}>
        2.6 Premier Cloud Breach. Subject to the terms and conditions of this Agreement, if Premier Cloud breaches the
        terms and conditions of a SOW or Order Form, then Customer may request remedies directly from Premier Cloud.
      </p>

      <div style={styles.sectionTitle}>3. REPRESENTATIONS AND WARRANTIES BY CUSTOMER</div>
      <p style={styles.paragraph}>3.1 Use. Customer represents, warrants, and covenants that Customer will:</p>
      <ul>
        <li style={styles.listItem}>
          a) ensure that Customer and End Users' use of any Vendor Product complies with this Agreement and with all
          Data Protection Laws applicable to the use of any Vendor Product in their respective state, province, or
          country;
        </li>
        <li style={styles.listItem}>
          b) use commercially reasonable efforts to prevent and terminate any unauthorized use of, or access to, any
          Vendor Product;
        </li>
        <li style={styles.listItem}>
          c) promptly notify Premier Cloud of any unauthorized use of, or access to, any Vendor Product of which
          Customer becomes aware;
        </li>
        <li style={styles.listItem}>
          d) ensure that Customer and End Users’ will not resell, distribute, lease, or allow another party to use any
          Vendor Products except in accordance with the Vendor’s TOS;
        </li>
        <li style={styles.listItem}>
          e) At the beginning of the Initial Term and any Renewal Terms thereafter, Customer will indicate to Premier
          Cloud the maximum amount they are willing to spend on the Services within a set time period (the “Maximum
          Spent”); and
        </li>
        <li style={styles.listItem}>
          f) Customer will review their Maximum Spend from time to time to ensure it accurately estimates the Fees for
          their projected use of Services.
        </li>
      </ul>
      <p style={styles.paragraph}>3.2 Terms of Service. Customer warrants, represents, and covenants that:</p>
      <ul>
        <li style={styles.listItem}>
          a) Customer and End Users will accept and comply with the Vendor’s TOS and periodically review such terms to
          ensure compliance with any updates;
        </li>
        <li style={styles.listItem}>
          b) Customer and End Users will not install, access, or use any Vendor Product unless they agree to abide by
          the Vendor’s TOS;
        </li>
        <li style={styles.listItem}>
          c) Customer will provide reasonable and timely cooperation in connection with the Professional Services
          offered by Premier Cloud; and
        </li>
        <li style={styles.listItem}>
          d) Nothing in this Agreement prevents Customer or an End User to use Customer’s Background IP in relation to
          the Services.
        </li>
      </ul>

      <div style={styles.sectionTitle}>4. TERM, TERMINATION, AND SUSPENSION</div>
      <p style={styles.paragraph}>
        4.1 Initial Term. The Initial Term of this Agreement (the “Initial Term”) commences on the Effective Date and
        continues thereafter until the latter of:
      </p>
      <ul>
        <li style={styles.listItem}>a) the term set out in any Order Form; or</li>
        <li style={styles.listItem}>b) the receipt of Deliverables outlined in a SOW and/or Order Form.</li>
      </ul>
      <p style={styles.paragraph}>4.2 Renewal Term.</p>
      <ul>
        <li style={styles.listItem}>
          a) At the end of the Initial Term and any subsequent renewal term, this Agreement will automatically renew for
          an additional period of one (1) year, (each, a “Renewal Term” and collectively the “Renewal Terms”) unless:
        </li>
        <li style={styles.listItem}>
          i) terminated by either Party by providing the other Party written notice of non-renewal at least thirty (30)
          days prior to the end of the Term; or
        </li>
        <li style={styles.listItem}>ii) an Order Form and/or SOW provides otherwise; and</li>
        <li style={styles.listItem}>
          b) If the Initial Term or any subsequent Renewal Term is renewed for any Renewal Term pursuant to this
          section, the terms and conditions of this Agreement during each such Renewal Term and the terms and conditions
          of any Order Form and/or SOW will be the same as the terms and conditions in effect immediately prior to such
          renewal, subject to:
        </li>
        <li style={styles.listItem}>
          i) any change in the fees payable hereunder by Customer during the applicable Renewal Term; and
        </li>
        <li style={styles.listItem}>
          ii) any change in the Professional Services outlined in the SOW applicable to the Renewal Term.
        </li>
      </ul>
      <p style={styles.paragraph}>4.3 Termination for Breach.</p>
      <ul>
        <li style={styles.listItem}>
          a) Either Party may terminate the Agreement upon notice given in accordance with Section 11.1, if the other
          Party:
        </li>
        <li style={styles.listItem}>
          i) is in material breach of the Agreement and fails to cure that breach within thirty (30) days after receipt
          of written notice of such breach; or
        </li>
        <li style={styles.listItem}>
          ii) ceases its business operations or becomes subject to insolvency proceedings.
        </li>
      </ul>
      <p style={styles.paragraph}>
        b) This Section 4.3 sets out Customer’s sole and exclusive remedy in the case of a material breach of this
        Agreement by Premier Cloud.
      </p>
      <p style={styles.paragraph}>4.4 Effects of Termination.</p>
      <ul>
        <li style={styles.listItem}>
          a) If this Agreement terminates, then the rights granted hereunder by any Party to the other will cease
          immediately, subject to those rights explicitly set out in Section 11.17; and
        </li>
        <li style={styles.listItem}>
          b) All payments owed by Customer under this Agreement, any applicable Order Form, and each SOW agreed upon by
          the Parties, become immediately due and payable to Premier Cloud, including, without limitation:
        </li>
        <li style={styles.listItem}>
          i) all unpaid fees owed for any Vendor Product, including payment of all additional fees Customer agreed to
          incur by opting to continue using and accessing Services after reaching their Maximum Spend;
        </li>
        <li style={styles.listItem}>
          ii) all unpaid fees owed for any Billing Subaccount, including payment of all fees for the duration of any
          unused subscription term; and
        </li>
        <li style={styles.listItem}>
          iii) the balance of any minimum spending requirement that Customer agreed to spend during the Term.
        </li>
      </ul>
      <p style={styles.paragraph}>4.5 Services Suspension.</p>
      <ul>
        <li style={styles.listItem}>
          a) If Premier Cloud or Vendor become aware that Customer or End Users’ use of any Vendor Product or
          Application breaches the terms of the Vendor’s TOS, then Premier Cloud will notify Customer and take action as
          directed by Vendor, or as determined in the sole discretion of Premier Cloud with notice to Customer,
          including, without limitation, suspension of some or all of Customer and End Users’ access and use of any
          Vendor Product or Application;
        </li>
        <li style={styles.listItem}>
          b) If Customer fails to pay any invoice amount due for access or use of Vendor Product or Application within
          thirty (30) days after the invoice date, then Premier Cloud, with notice to Customer, may suspend some or all
          of the Customer and End Users access and use of the Vendor Product or Application; and
        </li>
        <li style={styles.listItem}>
          c) If Customer reaches their Maximum Spend prematurely, and if Customer opted to avoid incurring additional
          fees for when they reach their Maximum Spend, then Premier Cloud may take action to suspend some or all of
          Customer and End User’s access and use of any Vendor Product or Application until the next billing period,
          without Customer’s further agreement.
        </li>
      </ul>

      <div style={styles.sectionTitle}>5. INVOICES AND PAYMENTS</div>
      <p style={styles.paragraph}>5.1 Fees.</p>
      <ul>
        <li style={styles.listItem}>
          a) In consideration for the performance of the Services, Customer will pay Premier Cloud in accordance with
          the fees set forth in each Order Form and each SOW (the “Fees”). Customer will pay the Fees to Premier Cloud
          following receipt of invoice by Customer within thirty (30) days of issuance (the “Due Date”). Customer will
          pay all Fees related to each Order Form and each SOW without offsets or deductions of any kind and payment of
          Fees is non-refundable;
        </li>
        <li style={styles.listItem}>
          b) Payment for each Order Form and each SOW may be made by way of Wire Transfer/EFT, cheque, credit card, or
          PayPal;
        </li>
        <li style={styles.listItem}>
          c) Customer will pay interest to Premier Cloud for all unpaid Fees that become due and owing after the Due
          Date at an interest rate of 26.82% per annum compounded monthly. Premier Cloud may assign or sell overdue
          accounts receivables in excess of ninety (90) days to a collection agency;
        </li>
        <li style={styles.listItem}>
          d) Premier Cloud reserves the right to adjust the Fees for Services for reasons including, without limitation,
          Vendor price changes, foreign exchange rate fluctuations, temporary unavailability, discontinuation, and
          changes to applicable taxes; and
        </li>
        <li style={styles.listItem}>
          e) Premier Cloud shall not reimburse Customer for dissatisfaction with Vendor Product. Customer must dispute
          any Fees for any Vendor Product directly with Vendor.
        </li>
      </ul>

      <div style={styles.sectionTitle}>6. CONFIDENTIAL INFORMATION</div>
      <p style={styles.paragraph}>
        6.1 Confidentiality. From time to time during the Term, a party hereunder (the "Disclosing Party") may disclose
        or make available to the other Party (as the "Receiving Party") Confidential Information.
      </p>
      <p style={styles.paragraph}>6.2 Use, Disclosure, Care. The Receiving Party covenants and agrees:</p>
      <ul>
        <li style={styles.listItem}>
          a) to use the Confidential Information of the Disclosing Party only for the purposes of performing its
          obligations under this Agreement;
        </li>
        <li style={styles.listItem}>
          b) to immediately notify the Disclosing Party in the event it becomes aware of any loss or disclosure of any
          of the Confidential Information of Disclosing Party; and
        </li>
        <li style={styles.listItem}>
          c) to use reasonable care, at least equivalent to the care used for its own similar Confidential Information,
          to protect the confidentiality of the Disclosing Party’s Confidential Information.
        </li>
      </ul>
      <p style={styles.paragraph}>6.3 Exclusions. The Receiving Party’s obligations hereunder do not extend to:</p>
      <ul>
        <li style={styles.listItem}>
          a) information that is publicly known at the time of disclosure or subsequently becomes publicly known through
          no fault of the Receiving Party; and
        </li>
        <li style={styles.listItem}>
          b) information that is communicated to the Receiving Party by a third-party that had no confidentiality
          obligations with respect to such information.
        </li>
      </ul>
      <p style={styles.paragraph}>
        6.4 Compelled Disclosure. If, either during the Term or after the termination of this Agreement, the Receiving
        Party is compelled or required to disclose any Confidential Information by law or court order or pursuant to any
        requirement, request or process of any legal, regulatory or governmental authority, the Receiving Party will
        give the Disclosing Party immediate prior written notice of such requirement, request, or process so that the
        Receiving Party may seek, at its sole cost and expense, an appropriate protective order or other remedy.
      </p>

      <div style={styles.sectionTitle}>7. DATA PROTECTION</div>
      <p style={styles.paragraph}>
        7.1 Customer will obtain and maintain all consents, permissions, and authorizations from End Users to allow
        Premier Cloud to resell each Vendor Product under this Agreement as set forth in each Order Form. Customer has
        informed Premier Cloud of any Data Protection Laws affecting Customer and End Users. In consenting, authorizing,
        and permitting Premier Cloud to resell each Vendor Product, Customer has determined that the performance and use
        of each Vendor Product complies with all applicable Data Protection Laws.
      </p>
      <p style={styles.paragraph}>7.2 Customer hereby consents, authorizes, and permits Premier Cloud to:</p>
      <ul>
        <li style={styles.listItem}>
          a) access, use, and otherwise process Customer Data as may be necessary for Premier Cloud to create and assign
          a Billing Subaccount for Customer;
        </li>
        <li style={styles.listItem}>
          b) access, use, and perform all acts with respect to Customer Data as may be necessary for Premier Cloud to
          manage Customer’s order history and usage data for Vendor Products; and
        </li>
        <li style={styles.listItem}>c) store Customer Data when required by Data Protection Laws.</li>
      </ul>
      <p style={styles.paragraph}>7.3 Customer represents, warrants, acknowledges, and agrees that:</p>
      <ul>
        <li style={styles.listItem}>
          a) the Customer has taken necessary and reasonable steps to ensure that the security measures implemented and
          maintained by Premier Cloud and Vendor as applicable provide a level of security appropriate to the risks to
          the Customer Data;
        </li>
        <li style={styles.listItem}>
          b) the Customer will take necessary and reasonable steps to inform themselves of any new data security best
          practices from time to time; and
        </li>
        <li style={styles.listItem}>
          c) the Customer is solely responsible for its and its End Users’ authentication credentials, systems, devices,
          and backing up of Customer Data.
        </li>
      </ul>
      <p style={styles.paragraph}>
        7.4 In relation to Customer Data processed in connection with the resale of Vendor Products under this Agreement
        or any Order Form by Premier Cloud, Customer will:
      </p>
      <ul>
        <li style={styles.listItem}>
          a) ensure that it has in place appropriate technical and organizational measures to protect against
          unauthorized or unlawful processing of Customer Data and against accidental loss, disclosure, destruction, or
          damage to Customer Data;
        </li>
        <li style={styles.listItem}>
          b) assist Premier Cloud, at Customer's cost, in responding to any request from End Users regarding Vendor
          Products and Customer’s obligations under this Agreement; and
        </li>
        <li style={styles.listItem}>
          c) notify Premier Cloud without undue delay on becoming aware of a breach of Customer Data.
        </li>
      </ul>

      <div style={styles.sectionTitle}>8. WARRANTY DISCLAIMERS AND LIMITATION OF LIABILITY</div>
      <p style={styles.paragraph}>
        8.1 Disclaimer of Warranties. To the fullest extent permitted by applicable law, except as expressly provided
        for herein, neither Premier Cloud, nor its representatives and affiliates, if any, and each of its and their
        respective employees, agents, successors, and permitted assigns, makes any warranty of any kind, whether
        express, implied, statutory, or otherwise, that the Services or Deliverables from the use thereof will:
      </p>
      <ul>
        <li style={styles.listItem}>a) meet the requirements of Customer or End Users;</li>
        <li style={styles.listItem}>b) operate without interruption;</li>
        <li style={styles.listItem}>c) achieve any intended result;</li>
        <li style={styles.listItem}>
          d) be compatible or work with any third-party or Customer-owned software, system, device, or other service;
        </li>
        <li style={styles.listItem}>e) be secure, accurate, complete, error free, or free of harmful code.</li>
      </ul>
      <p style={styles.paragraph}>
        8.2 Indemnity. Customer will indemnify, defend, and hold harmless Premier Cloud and its representatives,
        affiliates, and each of its and their respective employees, agents, successors, and permitted assigns (each, a
        "Premier Cloud Indemnitee") from and against any and all losses, damages, liabilities, claims, actions,
        judgments, settlements, interest, awards, penalties, fines, costs, or expenses (“Losses”) incurred by a Premier
        Cloud Indemnitee in connection with the Services or Applications to the extent that such Losses arise out of or
        relate to any:
      </p>
      <ul>
        <li style={styles.listItem}>
          a) Data Protection Laws including, any processing of such personal information controlled by Customer using
          Premier Cloud's systems;
        </li>
        <li style={styles.listItem}>
          b) the performance or use of the Services or Applications, including Premier Cloud's compliance with any
          instructions provided by or on behalf of the Customer or End Users;
        </li>
        <li style={styles.listItem}>
          c) allegation of fact that, if true, would constitute Customer’s breach of any of its representations,
          warranties, covenants, or obligations under this Agreement, Vendor’s TOS, SOW, or Order Forms; or
        </li>
        <li style={styles.listItem}>
          d) any action or failure to take a required action, negligence, willful misconduct, or more culpable act or
          omission by the Customer or End Users in connection with this Agreement, Vendor’s TOS, SOW, or Order Forms or
          data security best practices.
        </li>
      </ul>
      <p style={styles.paragraph}>
        8.3 Limitation of Liability. To the maximum extent permitted by applicable law and regardless of whether any
        remedy herein fails of its essential purpose, in no event will Premier Cloud be liable to Customer, End Users,
        or any third-party arising out of the use of or inability to use any Service, or any Application, under any
        contract, negligence, strict liability or other theory, for any lost revenue or damages, whether direct,
        indirect, special, incidental, exemplary, punitive or consequential damages (including lost profits), or for
        loss of or corruption of data or for loss of or corruption of Application, lost profits, or for the cost of
        procurement of substitute goods or technology, regardless of whether Premier Cloud has been advised of the
        possibility of such damages. Premier Cloud shall not be held liable under this Agreement for more than $100.00
        as liquidated damages.
      </p>

      <div style={styles.sectionTitle}>9. MUTUAL REPRESENTATIONS AND WARRANTIES</div>
      <p style={styles.paragraph}>
        9.1 Each Party represents and warrants to the other Party that when executed and delivered by each Party, this
        Agreement will constitute the legal, valid, and binding obligation of such party, enforceable against such Party
        in accordance with its terms.
      </p>

      <div style={styles.sectionTitle}>10. INTELLECTUAL PROPERTY</div>
      <p style={styles.paragraph}>
        10.1 Assignment. For good and valuable consideration, Premier Cloud hereby conveys, transfers, and assigns to
        Customer, and Customer hereby accepts, all of Premier Cloud’s right, title, and interest in and to the
        Deliverables, excluding Premier Cloud’s Background IP in accordance with Section 10.3 of this Agreement.
      </p>
      <p style={styles.paragraph}>
        10.2 Customer Background IP. Customer retains all rights, titles, and interests in Customer’s Background IP.
        Customer grants Premier Cloud a non-exclusive, worldwide, revocable, royalty-free license to use Customer’s
        Background IP to provide the Professional Services for the Term.
      </p>
      <p style={styles.paragraph}>
        10.3 Premier Cloud Background IP. Premier Cloud retains all rights, titles, and interests in Premier Cloud’s
        Background IP. If Premier Cloud’s Background IP is integrated into the Applications or Deliverables, Premier
        Cloud grants Customer a non-exclusive, worldwide, non-sublicensable, non-assignable, non-transferable license to
        use Premier Cloud’s Background IP integrated into the Applications and Deliverables in perpetuity.
      </p>
      <p style={styles.paragraph}>
        10.4 Further Actions. Premier Cloud, at Customer’s cost, will take such steps and actions, and provide such
        cooperation and assistance to Customer and its successors, assigns, and legal representatives, including the
        execution and delivery of any affidavits, declarations, oaths, exhibits, or assignments as may be reasonably
        necessary to effect, evidence, or perfect the assignment outlined in Section 10.1 of this Agreement to Customer,
        or any assignee, or successor thereto.
      </p>

      <div style={styles.sectionTitle}>11. GENERAL PROVISIONS</div>
      <p style={styles.paragraph}>
        11.1 Notices. Any and all notices required or permitted under this Agreement will be in writing and will be
        personally delivered, sent by registered mail, a recognized overnight delivery service, electronic mail, or by
        other means which affords the sender evidence of delivery to the respective Party at the address set forth in
        the preamble to this Agreement and as per the following:
      </p>
      <ul>
        <li style={styles.listItem}>Premier Cloud Inc.: Daniel Azad, CEO Email: daniel@premiercloud.com</li>
        <li style={styles.listItem}>Customer: Name, Title, and Email as provided on the Order Form</li>
      </ul>
      <p style={styles.paragraph}>11.2 Time. Time shall be of the essence hereof.</p>
      <p style={styles.paragraph}>
        11.3 Marketing. Customer hereby consents to Premier Cloud using, displaying, and advertising Customer’s
        tradenames, trademarks, copyrights, and logos (collectively, the “Marks”) in connection with Premier Cloud’s
        marketing and advertising campaigns in any medium, format, or platform without any royalty, payment, or other
        compensation to Customer. Neither this Agreement, nor any act, omission, or statement by Premier Cloud or
        Customer, conveys any ownership right to Premier Cloud in the Marks of Customer. Customer may, upon written
        notice to Premier Cloud, withdraw consent, and Premier Cloud will cease using, displaying, and advertising the
        Marks within seven (7) business days of receipt of written notice.
      </p>
      <p style={styles.paragraph}>
        11.4 Force Majeure. Neither Party will be liable for inadequate performance to the extent caused by a condition
        (for example, natural disaster, act of war or terrorism, riot, labor condition, governmental action, and
        Internet disturbance) that was beyond the Party’s reasonable control. If force majeure continues for a period of
        more than three (3) months, without the Parties being able to develop an alternative satisfactory arrangement,
        then either Party has the option of immediately terminating this Agreement. This Section will not, however,
        apply to Customer’s payment obligations under this Agreement.
      </p>
      <p style={styles.paragraph}>
        11.5 Severability. If any provision of this Agreement is found unenforceable, the balance of the Agreement will
        remain in full force and effect.
      </p>
      <p style={styles.paragraph}>
        11.6 No Waiver. Failure to enforce any provision of this Agreement will not constitute a waiver.
      </p>
      <p style={styles.paragraph}>
        11.7 Governing Law. This Agreement and the rights and obligations of the Parties hereto will be governed by and
        construed and enforced in accordance with, the laws of the Province of British Columbia.
      </p>
      <p style={styles.paragraph}>
        11.8 Non-Solicit. Customer will not recruit nor engage, directly or indirectly, any current Premier Cloud
        employee or contractor for the Term of this Agreement or the subsequent twelve (12) month period after
        termination or expiration of this Agreement.
      </p>
      <p style={styles.paragraph}>
        11.9 Disputes. The Parties will use their best efforts to resolve any dispute amicably between them within a
        period of thirty (30) days from the time the complaining Party raises the matter. All unresolved disputes will
        have exclusive venue in the federal and provincial courts in British Columbia, and both Parties consent to the
        jurisdiction of these courts.
      </p>
      <p style={styles.paragraph}>
        11.10 Further Assurances. Each Party will promptly execute such documents and perform such acts as may be
        necessary to give full effect to the terms of this Agreement.
      </p>
      <p style={styles.paragraph}>
        11.11 Assignability. Customer will not assign or otherwise transfer any of its rights, or delegate or otherwise
        transfer any of its obligations or performance, under this Agreement, in each case whether voluntarily,
        involuntarily, by operation of law or otherwise, without Premier Cloud's prior written consent. This Agreement
        is binding upon and inures to the benefit of the Parties and their respective permitted successors and assigns.
      </p>
      <p style={styles.paragraph}>
        11.12 Counterparts. This Agreement may be executed electronically in counterparts, each of which is deemed an
        original, but all of which together is deemed to be one and the same agreement.
      </p>
      <p style={styles.paragraph}>
        11.13 Entire Agreement. This Agreement, including and together with any related exhibits, SOW, Order Forms,
        schedules, attachments, and appendices constitutes the sole and entire agreement of the Parties with respect to
        the subject matter contained herein and therein, and supersedes all prior and contemporaneous understandings,
        agreements, representations, and warranties, both written and oral, regarding such subject matter.
      </p>
      <p style={styles.paragraph}>
        11.14 Headings. The headings in this Agreement are inserted for convenience or reference only and are in no way
        intended to describe, interpret, define, affect the construction of or limit the scope, extent, or intent of
        this Agreement or any provision of this Agreement.
      </p>
      <p style={styles.paragraph}>
        11.15 Plural. Wherever in this Agreement the context so requires, the singular words will include the plural
        words and vice versa.
      </p>
      <p style={styles.paragraph}>
        11.16 No Contra Proferentem. This Agreement has been reviewed and approved by the Parties and, notwithstanding
        any rule or maxim of law or construction to the contrary, any ambiguity or uncertainty will not be construed
        against any Party based on authorship of this Agreement.
      </p>
      <p style={styles.paragraph}>
        11.17 Survival. Any provisions of this Agreement which expressly or by their nature are intended to survive
        termination of this Agreement, including the sections titled Definitions; Representations and Warranties by
        Customer; Term, Termination, and Suspension; Invoices and Payments; Confidential Information; Data Protection;
        Warranty Disclaimers and Limitation of Liability; Mutual Representations and Warranties; General Provisions will
        continue in full force and effect subsequent to and notwithstanding such termination, until such provisions are
        satisfied or by their nature expire.
      </p>

      <div style={styles.sectionTitle}>12. ACCEPTANCE OF TERMS</div>
      <p style={styles.paragraph}>
        BY SIGNING AN ORDER FORM OR STATEMENT OF WORK (SOW) REFERENCING THIS MASTER SERVICES AGREEMENT, CUSTOMER
        ACKNOWLEDGES THAT IT HAS READ AND UNDERSTANDS THIS MASTER SERVICES AGREEMENT, AND AGREES TO BE LEGALLY BOUND BY
        ITS TERMS. THE EXECUTION OF ANY ORDER FORM OR STATEMENT OF WORK BY CUSTOMER CONSTITUTES ACCEPTANCE OF THIS
        MASTER SERVICES AGREEMENT.
      </p>
    </div>
  )
}

export default PrivacyPolicy
