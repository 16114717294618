// ** React Imports
import React, { Fragment, useEffect, useState } from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { usersSelector } from 'store/users'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'

// ** Icon Imports
import DeleteIcon from 'mdi-material-ui/Delete'
import { createAliasesList, deleteAliasesList, getDomainsList } from 'configs/axios/api_helper'


const defaultValues = {
    username: '',
    domain: '',
}


const AddDialog = (props) => {

    // ** Props
    const { addAliasHandler, setMessage } = props

    // ** Hooks
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues })

    // ** Selectors
    const { user } = useSelector(usersSelector);

    // ** State
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [domains, setDomains] = useState([])


    // ** Fetch Domains
    useEffect(() => {
        const fetchDomains = async () => {
            try {
                const response = await getDomainsList(user.customerId)
                let { data: { domains } } = response
                setDomains(domains)
            } catch (error) {

            }
        }
        if (user?.id)
            fetchDomains()
    }, [])

    // ** Functions
    const handleClickOpen = (e) => {
        e.stopPropagation()
        setOpen(true)
    }
    const handleClose = () => setOpen(false)

    // ** Functions


    const onSubmit = async (data) => {
        setLoading(true)
        let email = `${data.username}@${data.domain}`
        try {
            const response = await createAliasesList(user.primaryEmail, { alias: email })
            setLoading(false)
            addAliasHandler({ ...response.data, primaryEmail: user.primaryEmail })
            setMessage({ success: "ADD_ALIAS", error: false })
            setOpen(false)
        } catch (error) {
            setLoading(false)
            setMessage({ success: "", error: "ERROR_ADD" })
        }
    }

    return (
        <Fragment>
            <Typography
                size="medium"
                edge="start"
                sx={{ color: "common.blue", cursor: "pointer" }}
                onClick={handleClickOpen}
            >
                create
            </Typography>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle id='alert-dialog-title'>Add new alias</DialogTitle>
                <DialogContent>
                    <form id="add-alias-form" onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <Controller
                                        name='username'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <TextField
                                                variant="standard"
                                                type='tel'
                                                value={value}
                                                label='Username'
                                                onChange={onChange}
                                                error={Boolean(errors.username)}
                                                placeholder='jhondoe'
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id='domain-label'>Domain</InputLabel>
                                    <Controller
                                        name='domain'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <Select
                                                variant="standard"
                                                value={value}
                                                label='Domain'
                                                onChange={onChange}
                                                labelId='domain-label'
                                            >
                                                <MenuItem value=''>
                                                    <em>None</em>
                                                </MenuItem>
                                                {domains.map((el, index) => <MenuItem key={index} value={el.domainName}>{el.domainName}</MenuItem>)}
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions className='dialog-actions-dense' sx={{ mt: "1rem" }}>
                    <Button
                        disabled={loading}
                        onClick={handleClose}
                        size="small"
                        variant="text"
                        color="inherit"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={loading}
                        type="submit"
                        form="add-alias-form"
                        endIcon={(loading ?
                            <CircularProgress sx={{ color: 'common.white', mt: 0 }} size="0.825rem" /> : null
                        )}
                        variant="contained"
                        size="small"
                        sx={{ fontWeight: 600, primary: "common.blue" }}
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default AddDialog