// ** React Imports
import React, { useState, useEffect } from 'react'

// ** Redux Imports
import { groupsSelector } from 'store/groups'
import { useDispatch, useSelector } from 'react-redux'

// ** Api Call
import { getGroupMembers } from 'configs/axios/api_helper'

// ** MUI Imports
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'

// ** Custom Components
import MembersTable from './components/MembersTable'
import MembersHeader from './components/MembersHeader'

// ** Icon Imports
import ChevronUp from 'mdi-material-ui/ChevronUp'
import ChevronDown from 'mdi-material-ui/ChevronDown'
import CustomAlertMessage from '../../alert/CustomAlertMessage'


const MembersSettings = (props) => {

    // ** State
    const [collapsed, setCollapsed] = useState(false)
    const [groupMembers, setGroupMembers] = useState([])

    // ** Selectors
    const { group } = useSelector(groupsSelector)

    // ** Fetch Members
    useEffect(() => {
        const fetchGroupMembers = async () => {
            try {
                const response = await getGroupMembers(group.email, 10)
                const { data: { result: { members } } } = response
                setGroupMembers(members)
            } catch (error) {
                setGroupMembers([])
            }

        }

        if (group?.email)
            fetchGroupMembers()

    }, [group])




    return (
        <Card
            sx={{
                border: theme => `solid 1px ${theme.palette.grey[300]}`,
                borderRadius: 0,
                overflow: "visible",
                my: "0.5rem"
            }}>
            <CardHeader
                sx={{
                    ...(collapsed && {
                        backgroundColor: theme => theme.palette.grey[100],
                        height: 50,
                        border: theme => `solid 1px ${theme.palette.grey[300]}`,
                        mb: 10,
                    }),
                }}
                title={(!collapsed ?
                    <MembersHeader
                        groupMembers={groupMembers}
                        group={group}
                        setCollapsed={setCollapsed}
                    />
                    :
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ fontWeight: 600, fontSize: "1rem" }}>
                                Members
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                action={
                    <IconButton
                        size='small'
                        sx={{ color: 'text.secondary', ...(collapsed && { mt: "-1rem" }) }}
                        onClick={() => setCollapsed(!collapsed)}
                    >
                        {!collapsed ? <ChevronDown fontSize='small' /> : <ChevronUp fontSize='small' />}
                    </IconButton>
                }
            />
            <Collapse in={collapsed} open={collapsed}>
                <Grid container spacing={4}>
                    <Grid container item xs={12}>
                        <Grid item xs={12} sx={{ mt: "-2rem" }}>
                            <MembersTable
                                group={group}
                                groupMembers={groupMembers}
                                setGroupMembers={setGroupMembers}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Collapse>

        </Card>
    )
}



export default MembersSettings