// ** React Imports
import React, { useState } from 'react'

// ** MUI Imports
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'

// ** Custom Components
import TemplateCard from './TemplateCard'

// ** Icon Imports
import ChevronUp from 'mdi-material-ui/ChevronUp'
import ChevronDown from 'mdi-material-ui/ChevronDown'


const tabs = ["Classic", "Horizontal", "Wide", "Compact", "Classic 2", "Horizontal 2", "Wide 2", "Compact 2" ]


const TemplateCardsContainer = (props) => {

    // ** Props
    const { activeTab, setActiveTab } = props

    // ** State
    const [collapsed, setCollapsed] = useState(true)


    return (
        <Card
            sx={{
                border: theme => `solid 1px ${theme.palette.grey[300]}`,
                borderRadius: 0,
                overflow: "visible",
                my: "0.5rem"
            }}>
            <CardHeader
                title={(
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ fontWeight: 600, fontSize: "1rem" }}>
                                Templates
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                action={
                    <IconButton
                        size='small'
                        sx={{ color: 'text.secondary' }}
                        onClick={() => setCollapsed(!collapsed)}
                    >
                        {!collapsed ? <ChevronDown fontSize='small' /> : <ChevronUp fontSize='small' />}
                    </IconButton>
                }
            />
            <Collapse in={collapsed} open={collapsed}>
                <Grid container spacing={4} sx={{mb: "2rem"}}>
                    {tabs.map((el, index) => (
                        <Grid key={index} item xs={12} sm={6} sx={{display: "flex", justifyContent: "center"}}>
                            <TemplateCard
                                activeTemplate={activeTab}
                                template={el}
                                setTemplate={setActiveTab}
                                templateIndex={index}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Collapse>
        </Card>
    )
}



export default TemplateCardsContainer