// ** React Imports
import React from 'react'

// ** MUI Imports
import { Grid } from "@mui/material"

// ** Component Imports
import BulkSignatureTable from './containers/BulkSignatureTable'



const BulkSignature = () => {


    return (
        <Grid container>
            <Grid item xs={12}>
                <BulkSignatureTable />
            </Grid>
        </Grid>
    )
}


export default BulkSignature