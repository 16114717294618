// ** React Imports
import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'


const Row = props => {

    // ** Hooks 
    const navigate = useNavigate()

    // ** Props
    const { row, path } = props

    // ** Redirect to customer display
    const redirectHandler = (email) => {
        navigate("/groups-management/group-details/" + email)
    }


    return (
        <Fragment key={row.id}>
            <TableRow sx={{ '& > *': {}, cursor: 'pointer' }}>
                <TableCell component='th' scope='row' sx={{ color: 'common.blue' }} onClick={() => redirectHandler(row.email)}>
                    {row.email}
                </TableCell>
                <TableCell>{row.name}</TableCell>
            </TableRow>
        </Fragment>
    )
}


export default Row