export const options = {
    name: ["fullName", "familyName", "givenName"],
    emails: ["primary", "home", "work", "other"],
    phones: [
        "recovery",
        "home",
        "mobile",
        "work",
        "other",
        "car",
        "main",
        "pager",
        "radio",
        "telex",
    ],
    // instantMesseger: ["message"],
    addresses: ["home", "work", "other"],
    relations: [
        "brother",
        "child",
        "father",
        "friend",
        "manager",
        "mother",
        "parent",
        "partner",
        "relative",
        "sister",
        "spouse",
    ],
    organizations: ["work", "school", "domain_only", "unknown"],
    externalId: ["network", "organization", "customer", "login_id"],
    websites: [
        "blog",
        "ftp",
        "home",
        "profile",
        "reservations",
        "resume",
        "work",
    ],
    gender: ["type"],
    employeeId: ["id"],
    jobTitle: ["title"],
    employeeType: ["type"],
    department: ["department"],
    costCenter: ["costCenter"],
    managersEmail: ["email"],
};

export const dummyData = {
    id: "1111111111111111111",
    primaryEmail: "name@domain.com",
    name: {
        familyName: "App",
        fullName: "Admin App",
        givenName: "Admin",
    },
    emails: [
        {
            address: "name@work.com",
            type: "work",
        },
        {
            address: "name@domain.com",
            primary: true,
        },
    ],
    addresses: [
        {
            type: "home",
            formatted: `1600 Home Amphitheatre Parkway,
        Mountain View, CA 94043, USA`,
        },
        {
            type: "work",
            formatted: `1600 Work Amphitheatre Parkway,
        Mountain View, CA 94043, USA`,
        },
        {
            type: "other",
            formatted: `1600 Other Amphitheatre Parkway,
        Mountain View, CA 94043, USA`,
        },
    ],
    organizations: [
        {
            description: "This is school Organization",

            title: "School  Title Organization",
            name: "School Organization",
            type: "school",
            location: `1600 Amphitheatre Parkway,
        Mountain View, CA 94043, USA`,
        },
        {
            description: "This is domain only",
            title: "Domain  Title only Organization",
            name: "Domain only Organization",
            type: "domain_only",
            location: `1600 Amphitheatre Parkway,
        Mountain View, CA 94043, USA`,
        },
        {
            description: "This is unknown Organization",
            title: "Unknown  Title Organization",
            name: "Unknown Organization",
            type: "unknown",
            location: `1600 Amphitheatre Parkway,
        Mountain View, CA 94043, USA`,
        },
        {
            title: "Administrator",
            primary: true,
            customType: "",
            department: "Admin Department",
            description: "Regular",
            costCenter: "Service cost center",
        },
    ],
    phones: [
        {
            type: "recovery",
            value: "+16506661212",
        },
        {
            type: "home",
            value: "+16506661212",
        },
        {
            type: "mobile",
            value: "+16506661212",
        },
        {
            type: "work",
            value: "+16506661212",
        },
        {
            type: "other",
            value: "+16506661212",
        },
        {
            type: "car",
            value: "+16506661212",
        },
        {
            type: "main",
            value: "+16506661212",
        },
        {
            type: "pager",
            value: "+16506661212",
        },
        {
            type: "radio",
            value: "+16506661212",
        },
        {
            type: "telex",
            value: "+16506661212",
        },
    ],

    // websites: value,
    // locations: value,
    gender: { type: "male" },
    orgUnitPath: "/admin",
    recoveryEmail: "mail@recovery.com",
    recoveryPhone: "+16506661212",
    externalIds: [
        {
            value: "12345",
            type: "organization",
        },
    ],
    relations: [
        {
            value: "manager@email.com",
            type: "manager",
        },
        {
            value: "brother@email.com",
            type: "brother",
        },
        {
            value: "child@email.com",
            type: "child",
        },
        {
            value: "father@email.com",
            type: "father",
        },
        {
            value: "mother@email.com",
            type: "mother",
        },
        {
            value: "parent@email.com",
            type: "parent",
        },
        {
            value: "partner@email.com",
            type: "partner",
        },
        {
            value: "relative@email.com",
            type: "relative",
        },
        {
            value: "sister@email.com",
            type: "sister",
        },
        {
            value: "spouse@email.com",
            type: "spouse",
        },
    ],
};