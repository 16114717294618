// ** React Imports
import React from 'react'
import { useNavigate } from 'react-router-dom'
// ** MUI Imports
import { Grid, Button, useMediaQuery } from "@mui/material"

// ** Icons Imports
import PlusIcon from 'mdi-material-ui/Plus'

const ActionButton = (props) => {

    // ** Props
    const { path } = props

    // ** Hooks
    const navigate = useNavigate()
    const xsmall = useMediaQuery(theme => theme.breakpoints.down('sm'))

    // ** Functions
    const redirectionHandler = () => navigate(path)

    return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Grid item xs={10} sm={6} sx={{ whiteSpace: "nowrap" }}>
            <Button
                onClick={redirectionHandler}
                variant='contained'
                sx={{
                    mx: 1,
                    borderRadius: "5px !important",
                    fontWeight: 600,
                    color: "common.white",
                    fontSize: "0.825rem"
                }}
                startIcon={<PlusIcon />}
            >
                New bulk signature
            </Button>
        </Grid>
    )
}


export default ActionButton