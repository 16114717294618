// ** React Imports 
import React from 'react'

// ** MUI Imports
import { styled, useTheme } from '@mui/material/styles'
import MuiSwipeableDrawer from '@mui/material/SwipeableDrawer'


// ** SwipeableDrawer
const SwipeableDrawer = styled(MuiSwipeableDrawer)({
  overflowX: 'hidden',
  transition: 'width .2s ease-in-out',
  flexShrink: 0,
  '& ul': {
    listStyle: 'none'
  },
  '& .MuiListItem-gutters': {
    paddingLeft: 4,
    paddingRight: 4
  },
  '& .MuiDrawer-paper': {
    // left: 'unset',
    // right: 'unset',
    zIndex: "100",
    overflow: 'hidden',
    boxSizing: "border-box",
    transition: 'width .2s ease-in-out, box-shadow .2s ease-in-out'
  }
})

const Drawer = props => {

  // ** Props
  const {
    hidden,
    children,
    navHover,
    navWidth,
    settings,
    navVisible,
    setNavHover,
    setNavVisible,
    collapsedNavWidth,
    navigationBorderWidth,
    toggleNavVisibility
  } = props

  // ** Hook
  const theme = useTheme()

  // ** Vars
  const { skin, navCollapsed } = settings

  const drawerColor = () => {
    if (skin === 'semi-dark' && theme.palette.mode === 'light') {
      return {
        '& .MuiTypography-root': {
          color: `rgba(${theme.palette.customColors.dark}, 0.87)`
        }
      }
    } else if (skin === 'semi-dark' && theme.palette.mode === 'dark') {
      return {
        '& .MuiTypography-root': {
          color: `rgba(${theme.palette.customColors.light}, 0.87)`
        }
      }
    } else return {}
  }

  const drawerBgColor = () => {
    if (skin === 'semi-dark' && theme.palette.mode === 'light') {
      return {
        backgroundColor: theme.palette.customColors.darkBg
      }
    } else if (skin === 'semi-dark' && theme.palette.mode === 'dark') {
      return {
        backgroundColor: theme.palette.customColors.lightBg
      }
    } else {
      return {
        backgroundColor: theme.palette.background.default
      }
    }
  }

  // Drawer Props for Mobile & Tablet screens
  const MobileDrawerProps = {
    open: navVisible,
    onOpen: () => setNavVisible(true),
    onClose: () => setNavVisible(false),
    ModalProps: {
      keepMounted: true // Better open performance on mobile.
    }
  }

  // Drawer Props for Desktop screens
  const DesktopDrawerProps = {
    open: navVisible,
    onOpen: () => toggleNavVisibility(true),
    onClose: () => toggleNavVisibility(false),
  }


  return (
    <SwipeableDrawer
      className='layout-vertical-nav'
      variant={!hidden ? 'permanent' : "temporary"}
      {...(hidden ? { ...MobileDrawerProps } : { ...DesktopDrawerProps })}
      sx={{
        ...(!hidden && { [`& .MuiDrawer-paper`]: { mt: "3.975rem", pt: "1.25rem", width: navVisible ? navWidth : collapsedNavWidth, } }),
        ...(hidden && { [`& .MuiDrawer-paper`]: { mt: "3.975rem", pt: "1.25rem" } }),
      }}
      PaperProps={{
        sx: {
          ...drawerColor(),
          ...drawerBgColor(),
          backgroundColor: '#FFF',
          width: navCollapsed && !navHover ? collapsedNavWidth : navWidth,
          // ...(!hidden && navCollapsed && navHover ? { boxShadow: 10 } : {}),
          borderRight: navigationBorderWidth === 0 ? 0 : `${navigationBorderWidth}px solid ${theme.palette.divider}`
        }
      }}
    >
      {children}
    </SwipeableDrawer>
  )
}

export default Drawer
