// ** React Imports
import React from 'react'

const TemplateNoImageV2 = (props) => {

    // ** Props
    const { color, data, extraFields, greetings } = props

    // Split placeHolders array to objects
    let name = data?.find(el => el.name === "name")
    let jobTitle = data?.find(el => el.name === "jobTitle")
    let department = data?.find(el => el.name === "department")


    return (
        <div
            style={{
                height: "250px",
                width: "650px",
                borderRadius: "8px",
                backgroundColor: "#FFF",
            }}
        >
            <div
                style={{
                    marginTop: "2rem",
                    marginLeft: "2rem",
                }}>
                <text
                    style={{
                        color: "#8F8F8F",
                        letterSpacing: 5,
                        fontSize: "1.725rem"
                    }}
                >
                    {greetings}
                </text>
            </div>
            <div
                style={{
                    marginTop: "2rem",
                    paddingLeft: "2rem",
                    position: "relative"
                }}
            >
                <div
                    style={{
                        paddingLeft: "1rem",
                        color: "#8F8F8F",
                        display: "inline-block",
                        width: "400px",
                        verticalAlign: "top"
                        
                    }}
                >
                    <div
                        style={{
                           
                            paddingBottom: "10px",
                            borderBottom: "2px solid #8F8F8F",
                           
                        }}
                    >
                        <text
                            style={{
                                fontWeight: 600,
                                fontSize: "1.125rem",
                                color: "0000FF"
                            }}
                        >
                            {"{|" + name?.name + "-" + name?.subType + "|}"}
                        </text>
                        <br />
                        <text
                            style={{
                                fontSize: "0.825rem",
                                fontWeight: 600
                            }}
                        >
                            {"{|" + jobTitle?.name + "-" + jobTitle?.subType + "|}"} , {"{|" + department?.name + "-" + department?.subType + "|}"}
                        </text>
                    </div>
                    <div
                        style={{
                            marginTop: "1rem",
                            fontSize: "0.785rem"
                        }}
                    >

                        {extraFields?.map((el, index) => (
                            <>
                                {(index !== 0 && index !== 3 && index !== 6 && index !== 9 && index !== 12) && "|"}
                                &nbsp;&nbsp;{"{|" + el?.name + "-" + el.subType + "|}"}&nbsp;&nbsp;
                                {(index === 2 || index === 5 || index === 8 || index === 11) && <br />}
                            </>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TemplateNoImageV2