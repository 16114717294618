// ** React Imports
import React from "react"

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// ** Configs
import { useSettings } from 'core/hooks/useSettings'


// ** Images Imports
import TemplateV1 from 'images/templateV1.svg'
import TemplateV2 from 'images/templateV2.svg'
import TemplateV3 from 'images/templateV3.svg'
import TemplateV4 from 'images/templateV4.svg'
import TemplateNoImageV1 from 'images/template_noimage_V1.svg'
import TemplateNoImageV2 from 'images/template_noimage_V2.svg'
import TemplateNoImageV3 from 'images/template_noimage_V3.svg'
import TemplateNoImageV4 from 'images/template_noimage_V4.svg'


// ** Styled Link
const Container = styled(Box, { shouldForwardProp: (prop) => prop !== 'active' }, { shouldForwardProp: (prop) => prop !== 'compact' })(({ theme, active, compact }) => ({
    ...(compact ?
        {
            width: "150px",
            height: "110px",
            [theme.breakpoints.down('xl')]: {
                width: "130px",
            },
        }
        :
        {
            width: "180px",
            height: "120px",
            [theme.breakpoints.down('xl')]: {
                width: "170px",
            },
            [theme.breakpoints.down('lg')]: {
                width: "170px",
            },
        }),
    border: "1px solid #e8e8e8",
    borderRadius: "4px",
    ...(active ? {} : { filter: "grayscale(100%) opacity(50%)" }),
    cursor: "pointer",
    transition: ".2s",
    "&:hover": {
        boxShadow: "0 6px 12px rgb(0 0 0 / 20%)",
        filter: "none",
        top: "-2px"
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    ...(active ? {
        border: "2px solid blue",
        borderRadius: "4px",
        padding: "15px",
        cursor: "pointer",
        transition: "0s",
        boxShadow: "0 6px 12px rgb(0 0 0 / 20%)",
        top: "-2px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    } : {}),
}))

// ** Styled Img
const Img = styled("img", { shouldForwardProp: (prop) => prop !== 'compact' })(({ theme, compact }) => ({
    transition: "all .2s",
    marginBottom: "1.725rem",
    ...(compact ?
        {
            width: "120px",
            [theme.breakpoints.down('xl')]: {
                width: "100px",
            },
        }
        :
        {
            height: "50px",
            width: "150px",
            [theme.breakpoints.down('lg')]: {
                width: "150px",
            },
        }),
}))

const images = [
    { id: 0, path: TemplateV1 },
    { id: 1, path: TemplateV2 },
    { id: 2, path: TemplateV3 },
    { id: 3, path: TemplateV4 },
    { id: 4, path: TemplateNoImageV1 },
    { id: 5, path: TemplateNoImageV2 },
    { id: 6, path: TemplateNoImageV3 },
    { id: 7, path: TemplateNoImageV4 },
]

const TemplateCard = (props) => {

    // ** Hooks
    const { settings } = useSettings()

    // ** Props 
    const { setTemplate, template, activeTemplate, templateIndex } = props

    return (

        <Container
            active={activeTemplate === templateIndex}
            compact={settings.drawer}
            onClick={() => setTemplate(templateIndex)}
        >
            <Img
                alt=""
                compact={settings.drawer}
                src={images.find(el => el.id === templateIndex).path}
            />
            <Typography sx={{
                fontSize: "0.75rem",
                fontWeight: 700,
                color: "#191c2b !important"
            }}>
                {template}
            </Typography>
        </Container>
    )
}


export default TemplateCard