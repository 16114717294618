// ** React Imports
import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'


const Row = props => {

    // ** Hooks 
    const navigate = useNavigate()

    // ** Props
    const { row, path } = props

    // ** Redirect to customer display
    const redirectHandler = (id) => {
        navigate("/user-profile/" + id)
    }


    return (
        <Fragment key={row.id}>
            <TableRow sx={{ '& > *': {}, cursor: 'pointer' }}>
                <TableCell component='th' scope='row' sx={{ color: 'common.blue' }} onClick={() => redirectHandler(row.primaryEmail)}>
                    {row.primaryEmail}
                </TableCell>
                <TableCell>{row.name?.fullName}</TableCell>
                <TableCell>{row.orgUnitPath}</TableCell>
            </TableRow>
        </Fragment>
    )
}


export default Row