// ** React Imports
import React from 'react'

// ** MUI Imports
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

// ** Custom Components
import TemplatePlaceHolders from '../components/TemplatePlaceHolders'



const TemplateForm = (props) => {


    return (
        <Card
            sx={{
                border: theme => `solid 1px ${theme.palette.grey[300]}`,
                borderRadius: 0,
                overflow: "visible",
                my: "0.5rem"
            }}>
            <CardHeader
                sx={{
                    backgroundColor: theme => theme.palette.grey[100],
                    height: 50,
                    border: theme => `solid 1px ${theme.palette.grey[300]}`,
                    mb: 10,
                }}
                title={(
                    <Typography variant="body2" sx={{ fontWeight: 600, fontSize: "1rem" }}>
                        Template Placeholders Information
                    </Typography>
                )}
            />
            <Grid item xs={12}>
                <TemplatePlaceHolders />
            </Grid>
        </Card>
    )
}



export default TemplateForm