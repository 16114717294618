

// ** React imports
import React, { useEffect, useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import Snackbar from '@mui/material/Snackbar'
import Box from '@mui/material/Box'
import Grow from '@mui/material/Grow'
import FormHelperText from '@mui/material/FormHelperText'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'


// ** Icons Imports
import EyeOutline from 'mdi-material-ui/EyeOutline'
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline'
import KeyIcon from "mdi-material-ui/ShieldKeyOutline"
import { getPop, updatePop } from 'configs/axios/api_helper'

// ** Styled Box
const Container = styled(Box, { shouldForwardProp: (prop) => prop !== "hover" })(({ theme, hover }) => ({
    padding: "1.425rem",
    cursor: "pointer",
    backgroundColor: "#fff",
    transition: "all .1s ease-in-out",
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
    },
    ...(hover ? {
        boxShadow: theme.shadows[10],
        margin: "12px -12px",
        cursor: "default"
    } : {}),
    ...(!hover ? { "&:hover": { backgroundColor: theme.palette.grey[100] } } : {})
}
))

const defaultValues = {
    accessWindow: "allMail",
    disposition: "leaveInInbox"
}



const PopCard = (props) => {

    // ** Props
    const { user } = props

    // ** Hooks
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        watch
    } = useForm({ defaultValues })

    // ** State
    const [show, setShow] = useState(false)
    const [message, setMessage] = useState({ success: false, error: false })
    const [loading, setLoading] = useState(true)
    
    // ** Fetch Data
    useEffect(() => {
        const fetchPopData = async () => {
            try {
                const response = await getPop(user.primaryEmail)
                const { data } = response
                if (data?.accessWindow && data?.disposition) {
                    if (data.accessWindow === "disbaled") {
                        reset({ accessWindow: false, disposition: data.disposition })
                    } else {
                        reset({ accessWindow: data.accessWindow, disposition: data.disposition })
                    }
                }
            } catch (error) {

            }

        }
        if (user?.primaryEmail)
            fetchPopData()
    }, [user])

    // ** Functions
    const toggleHandler = () => {
        if (!show)
            setShow(true)
    }


    const onSubmit = async (data) => {
        let body = {}
        if (!data.accessWindow) {
            body = {
                accessWindow: "disabled",
                disposition: data.disposition
            }
        } else {
            if (data.accessWindow && typeof (data.accessWindow) === "boolean") {
                body = {
                    accessWindow: "allMail",
                    disposition: data.disposition
                }
            } else {
                body = {
                    accessWindow: data.accessWindow,
                    disposition: data.disposition
                }
            }
        }
        setLoading(true)
        try {
            const response = await updatePop(user.primaryEmail, body)
            setLoading(false)
            setMessage({ error: false, success: true })
            setShow(false)
        } catch (error) {
            setLoading(false)
            setMessage({ error: true, success: false })
        }
    }



    const handleCloseMessage = () => {
        setMessage({ success: false, error: false })
    }



    return (
        <Container hover={show} onClick={toggleHandler}>
            {show ?
                <Grid item container xs={12}>
                    <Grid item xs={12} xl={8} sx={{ py: "0.5rem" }}>
                        <form id="pop-form" onSubmit={handleSubmit(onSubmit)}>
                            <Grid item container xs={12} sx={{ my: "0.725rem" }} spacing={4}>
                                <Grid item xs={12} md={4}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mt: '1.325rem' }}>
                                        POP Enabled:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ display: "flex" }}>
                                    <FormControl sx={{ mt: "0.925rem" }}>
                                        <Controller
                                            name='accessWindow'
                                            control={control}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    sx={errors.checkbox ? { color: 'error.main' } : null}
                                                    control={
                                                        <Switch
                                                            {...field}
                                                            name='validation-basic-checkbox'
                                                            sx={errors.checkbox ? { color: 'error.main' } : null}
                                                        />
                                                    }
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mt: "1.325rem" }}>
                                        {watch("accessWindow") ? "Enabled" : "Disabled"}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} sx={{ my: "0.725rem" }} spacing={4}>
                                <Grid item xs={12} md={4}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mt: '1.325rem' }}>
                                        Enable Pop for:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <FormControl fullWidth>
                                        <Controller
                                            name='accessWindow'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Select
                                                    disabled={(!watch("accessWindow") || watch("accessWindow") === "disabled") ? true : false}
                                                    variant="standard"
                                                    value={value ? value : "allMail"}
                                                    onChange={onChange}
                                                >
                                                    <MenuItem value="allMail">All Mail</MenuItem>
                                                    <MenuItem value="fromNowOn">Mail that arrives from now on </MenuItem>
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} sx={{ my: "0.725rem" }}>
                                <Grid item xs={12} md={4}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mt: '1.325rem' }}>
                                        Action:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <FormControl fullWidth>
                                        <Controller
                                            name='disposition'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Select
                                                    disabled={(!watch("accessWindow") || watch("accessWindow") === "disabled") ? true : false}
                                                    variant="standard"
                                                    value={value ?? "leaveInInbox"}
                                                    onChange={onChange}
                                                >
                                                    <MenuItem value="leaveInInbox">Leave the message in the inbox </MenuItem>
                                                    <MenuItem value="archive">Archive the message</MenuItem>
                                                    <MenuItem value="trash">Move the message to the trash</MenuItem>
                                                    <MenuItem value="markRead">Leave the message in the inbox and mark it as read</MenuItem>
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Divider sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", width: "100%" }} />
                    <Grid item xs={12} sx={{ textAlign: "end", mt: '1rem' }}>
                        <Button sx={{ fontWeight: 600, color: "text.secondary" }} variant="text" onClick={() => setShow(false)}>
                            CANCEL
                        </Button>
                        <Button
                            type='submit'
                            form="pop-form"
                            variant="text"
                            sx={{ fontWeight: 600 }}
                        >
                            SAVE
                        </Button>
                    </Grid>
                </Grid >
                :
                <Grid container item xs={12} spacing={6}>
                    <Grid item xs={12} xl={3}>
                        <Typography sx={{ fontWeight: 600, fontSize: "0.775rem" }}>
                            Gmail Pop settings
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} xl={8} spacing={6}>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ fontSize: "0.775rem" }}>
                                Manage your email pop settings
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            }
            <Snackbar
                sx={{ mt: "3rem" }}
                open={message.success || message.error}
                onClose={handleCloseMessage}
                autoHideDuration={2000}
                key={"top" + "right"}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                TransitionComponent={Grow}
                message={
                    <Box sx={{ textAlign: 'center' }}>
                        {message.success && "Pop was updated successfully"}
                        {message.error && "There was an error while updating the pop"}


                    </Box>
                }
            />
        </Container >
    )

}

export default PopCard