// ** React Imports
import React, { Fragment, useState } from 'react'

// ** Redux Imports
import { usersSelector } from 'store/users'
import { useSelector } from 'react-redux'

// ** APIs
import { trashDriveFolder } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress';

// ** Icon Imports
import DeleteIcon from 'mdi-material-ui/TrashCanOutline'



const StyledBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: 5,
    boxShadow: theme.shadows[5],
    paddingTop: "0.125rem",
    paddingBottom: "0.125rem",
    paddingRight: "0.725rem",
    paddingLeft: "0.725rem",
    borderRadius: "1rem",
    cursor: "pointer",
    width: "fit-content"
}))

const BulkDeleteDialog = (props) => {

    // ** Props
    const { setFolders, setMessage, folders } = props

    // ** State
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    // ** Selectors
    const { user } = useSelector(usersSelector);

    // ** Functions
    const handleClickOpen = (e) => {
        e.stopPropagation()
        setOpen(true)
    }
    const handleClose = () => setOpen(false)

    // ** Functions
    const deleteDeleteHandler = async (e) => {
        e.stopPropagation()
        setLoading(true)
        Promise.all(
            folders.map(async (id) => {
                const response = await trashDriveFolder(user.id, id, { trashed: true })
                const { data } = response
                return data
            })
        )
            .then(data => {
                setLoading(false)
                setFolders(state => { return state.filter(el => !folders?.includes(el.id)) })
                setMessage({ success: true, error: false, value: "Folders moved to trash successfully" })
                setOpen(false)
            })
            .catch(error => {
                setMessage({ success: false, error: true, value: "There was an error while moving the folders to the trash" })
                setLoading(false)
            })
    }


    return (
        <Fragment>
            <StyledBox
                onClick={handleClickOpen}
            >
                <DeleteIcon sx={{ color: "text.secondary" }} />
                <Typography variant="body2" sx={{ fontFamily: "Roboto", fontSize: "0.825rem", fontWeight: 600 }}>
                    Delete Selected
                </Typography>
            </StyledBox>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title' sx={{ fontFamily: "Roboto", fontWeight: 700, color: theme => theme.palette.grey[800] }}>Move to trash?</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        {folders?.length} items will be deleted forever after 30 days.
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='dialog-actions-dense' sx={{ mt: "1rem" }}>
                    <Button
                        disabled={loading}
                        onClick={handleClose}
                        size="small"
                        variant="text"
                        color="inherit"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={loading}
                        onClick={deleteDeleteHandler}
                        endIcon={(loading ?
                            <CircularProgress sx={{ color: 'common.red', mt: 0 }} size="0.825rem" /> : null
                        )}
                        variant="text"
                        size="small"
                        sx={{ fontWeight: 600 }}
                        color="error"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default BulkDeleteDialog