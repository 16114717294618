// ** React Imports
import React, { Fragment, useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { templateSelector } from 'store/template'

// ** API Calls
import { getSchemasList } from 'configs/axios/api_helper'

// ** MUI Imports
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    FormControl,
    Select,
    MenuItem,
} from "@mui/material"

// ** Hooks
import { useAuth } from 'hooks/useAuth'

// ** Icons Imports
import Icon from 'mdi-material-ui/Plus'



const initialOptions = [
    { name: "organizations", label: 'Organizations', value: "", subType: ["work"] },
    { name: "externalId", label: 'External ID', subType: ["organization"], value: "" },
    { name: "websites", label: 'Websites', subType: ["work"], value: "" },
    { name: "gender", label: 'Gender', subType: ["type"], value: "" },
    { name: "employeeType", label: 'Employee Type', value: "", subType: ["type"] },
    { name: "department", label: 'Department', value: "", subType: ["department"] },
    { name: "costCenter", label: 'Cost Center', value: "", subType: ["constCenter"] },
    { name: "managersEmail", label: 'Managers Email', value: "", subType: ["manager"] },
    { name: "emails", subType: ["primary"], label: "Email", value: "" },
    { name: "addresses", subType: ['home', 'work', 'other'], label: "Address", value: "" },
    { name: "phones", subType: ["recovery", "home", "mobile", "work", "other", "main"], label: "Phone", value: "" },
]

const AddFieldDialog = (props) => {

    // ** Props
    const { addFieldToForm, setInitialOptions, deletedItem } = props

    // ** Hooks
    const { user } = useAuth()
    const { pathname } = useLocation()

    // ** Selectors
    const { template } = useSelector(templateSelector)

    // ** State
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState(null)
    const [options, setOptions] = useState([...initialOptions])


    // ** Update option
    useEffect(() => {
        const fetchSchemasList = async () => {
            try {
                // Get extra fields from this api and merge it with the existing options
                const response = await getSchemasList(user.customerId)
                const { data: { schemas } } = response
                if (schemas && schemas.length > 0) {
                    let extraOptions = schemas.map(el => (
                        { name: el.schemaName, subType: el.fields.map(el => el.fieldName), label: el.schemaName, value: "" }
                    ))
                    setOptions([...initialOptions, ...extraOptions])
                }
            } catch (error) {

            }
        }
        fetchSchemasList()
    }, [])

    // ** Memo
    const updateOptionsHandler = useMemo(() => {
        if (!!template && !!template.extraFields && template?.extraFields?.length > 0 && (pathname.includes("update") || template.customTemplate)) {
            template.extraFields.map(el => {
                let optionObject = options.find(option => option.name == el.name)
                if (!!optionObject) {
                    setInitialOptions(state => ({ ...state, [optionObject.name]: optionObject.subType }))
                    setOptions(state => state.filter(el => el.name !== optionObject.name))
                }
            })
        }
    }, [template, options])

    const addFieldsToOptions = useMemo(() => {
        if (!!deletedItem) {
            setOptions([...options, { ...deletedItem, value: "", label: deletedItem.name }])
        }
    }, [deletedItem])


    // ** Functions
    const handleClickOpen = () => setOpen(true)

    const handleClose = () => setOpen(false)

    const submitHandler = () => {
        let object = options.find(el => el.name === value)
        if (object && object !== undefined) {
            addFieldToForm({ ...object, subType: object.subType[0] })
            setInitialOptions(state => ({ ...state, [object.name]: object.subType }))
            setOptions(options.filter(el => el.name !== value))
            setOpen(false)
        }
    }


    return (
        <Fragment>
            <Button
                disabled={!!template.customTemplate}
                color="primary"
                size="small"
                startIcon={<Icon fontSize="small" sx={{ marginRight: "-0.5rem", fontSize: "0.925rem !important", }} />}
                variant='contained'
                onClick={handleClickOpen}
                sx={{ marginTop: "2rem", textTransform: "none", fontWeight: 600, "&.Mui-disabled": { backgroundColor: `${theme => theme.palette.grey[500]} !important` }  }}
            >
                Add a field
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id='alert-dialog-title'>Add a field</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        <FormControl fullWidth variant='standard'>
                            <Select
                                labelId='demo-simple-select-label'
                                id='demo-simple-select'
                                placeholder='Select a field'
                                onChange={(e) => setValue(e.target.value)}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                }}
                            >
                                {/* Shared options (hard coded) */}
                                {options.map((el, index) => (<MenuItem key={el.name} value={el.name}>{el.label}</MenuItem>))}
                            </Select>
                        </FormControl>

                    </DialogContentText>
                </DialogContent>
                <DialogActions className='dialog-actions-dense'>
                    <Button
                        sx={{ textTransform: "none", fontWeight: 600, color: "text.secondary" }}
                        onClick={handleClose}
                    >
                        cancel
                    </Button>
                    <Button
                        disabled={!value}
                        sx={{ textTransform: "none", fontWeight: 600 }}
                        color="primary"
                        onClick={submitHandler}
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default AddFieldDialog