// ** React Imports
import React, { useEffect, useState } from "react"

// ** APIs
import { updateGroup } from "configs/axios/api_helper"

// ** Redux Imports
import { useDispatch, useSelector } from "react-redux"
import { editGroup, groupsSelector } from "store/groups"

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop'

// ** Custom Components
import CustomAlertMessage from 'pages/directory/components/alert/CustomAlertMessage'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'

// ** Styled Box
const Container = styled(Box, { shouldForwardProp: (prop) => prop !== "hover" })(({ theme, hover }) => ({
    padding: "1.425rem",
    cursor: "pointer",
    backgroundColor: "#fff",
    transition: "all .2s ease-in-out",
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
    },
    ...(hover ? {
        boxShadow: theme.shadows[10],
        margin: "12px -12px",
        cursor: "default"
    } : {}),
    ...(!hover ? { "&:hover": { backgroundColor: theme.palette.grey[100] } } : {})
}
))

const defaultValues = {
    description: "",
    email: "",
    name: "",
}



const PrimaryInformation = (props) => {

    // ** Hooks
    const dispatch = useDispatch()
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        watch
    } = useForm({ defaultValues })

    // ** Selectors
    const { group, success, error } = useSelector(groupsSelector);

    // ** State
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({ success: false, error: false, value: "" })

    // ** Fetch domains & organizations data
    useEffect(() => {
        if (group?.email) {
            reset({ name: group.name, email: group.email, description: group.description })
        }
    }, [group])

    // ** Functions
    const toggleHandler = () => {
        if (!show)
            setShow(true)
    }
    const handleCloseMessage = () => { setMessage({ success: false, error: false, value: "" }) }

    const onSubmit = async (body) => {
        setLoading(true)
        try {
            const response = await updateGroup(group.email, body)
            dispatch(editGroup({ ...response }))
            setMessage({ success: true, error: false, value: "Group was updated successfully" })
            setLoading(false)
        } catch (error) {
            setMessage({ success: false, error: true, value: "There was an error while updating the group information" })
            setLoading(false)
        }
    }

    return (
        <Container hover={show} onClick={toggleHandler}>
            {show ?
                <Grid item container xs={12} sx={{ position: 'relative' }}>
                    <Grid item xl={3}>
                        <Typography sx={{ fontWeight: 600, fontSize: "0.775rem" }}>
                            General Information
                        </Typography>
                    </Grid>
                    <Grid container item xl={9}>
                        <form id="primary-form" onSubmit={handleSubmit(onSubmit)}>
                            <Grid item container spacing={4} xs={12} sx={{ my: "0.725rem" }}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Controller
                                            name='name'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <TextField
                                                    variant="standard"
                                                    value={value}
                                                    label='Group Name'
                                                    onChange={onChange}
                                                    error={Boolean(errors.name)}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Controller
                                            name='email'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <TextField
                                                    disabled
                                                    variant="standard"
                                                    value={value}
                                                    label='Group Email Address'
                                                    onChange={onChange}
                                                    error={Boolean(errors.email)}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ my: "0.725rem" }}>
                                <FormControl fullWidth>
                                    <Controller
                                        name='description'
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <TextField
                                                multiline
                                                minRows={4}
                                                variant="standard"
                                                value={value}
                                                label='Group Description'
                                                onChange={onChange}
                                                error={Boolean(errors.description)}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>


                        </form>
                    </Grid>
                    <Divider sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", width: "100%" }} />
                    <Grid item xs={12} sx={{ textAlign: "end", mt: '1rem' }}>
                        <Button sx={{ fontWeight: 600, color: "text.secondary" }} variant="text" onClick={() => setShow(false)}>
                            CANCEL
                        </Button>
                        <Button
                            type='submit'
                            form="primary-form"
                            variant="text"
                            sx={{ fontWeight: 600 }}
                            disabled={loading}
                        //endIcon={loading && <CircularProgress sx={{ color: 'common.blue' }} size="0.925rem" />}
                        >
                            SAVE
                        </Button>
                    </Grid>
                    <Backdrop
                        open={loading}
                        sx={{
                            position: 'absolute',
                            backgroundColor: "transparent",
                            backdropFilter: 'blur(5px)',
                            color: theme => theme.palette.common.blue,
                            zIndex: theme => theme.zIndex.mobileStepper - 1,
                        }}
                    >
                        <CircularProgress color='inherit' />
                    </Backdrop>
                </Grid>
                :
                <Grid container item xs={12} spacing={6}>
                    <Grid item xs={12} xl={3}>
                        <Typography sx={{ fontWeight: 600, fontSize: "0.775rem" }}>
                            Group details
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} xl={8} spacing={6}>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
                                Group email
                            </Typography>
                            <Typography sx={{ fontSize: "0.925rem" }}>
                                {(group?.email || "Add a group email")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
                                Decription
                            </Typography>
                            <Typography sx={{ fontSize: "0.925rem" }}>
                                {(group?.description || "Add a group description")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            }
            <CustomAlertMessage
                message={message}
                handleCloseMessage={handleCloseMessage}
            />
        </Container>
    )

}


export default PrimaryInformation