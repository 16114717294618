// ** React Imports
import React, { Fragment, useEffect, useState } from 'react'

// ** Redux Imports
import { useDispatch } from 'react-redux'
import { cleanMessages, removeTemplate } from 'store/template'

// ** API CALLS
import { deleteTemplate } from 'configs/axios/api_helper'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress';

// ** Icon Imports
import DeleteIcon from 'mdi-material-ui/TrashCanOutline'


const DeleteTemplateDialog = (props) => {

    // ** Props
    const { template, loading, success } = props

    // ** Hooks 
    const dispatch = useDispatch()

    // ** State
    const [open, setOpen] = useState(false)

    // ** Side effect
    useEffect(() => {
        if (success === "DELETE") {
            setOpen(false)
        }
    }, [success])

    // ** Functions
    const handleClickOpen = (e) => {
        e.stopPropagation()
        setOpen(true)
    }
    const handleClose = () => setOpen(false)

    // ** Functions
    const deleteTemplateHandler = async (e) => {
        e.stopPropagation()
        dispatch(removeTemplate(template.uid))
    }

    return (
        <Fragment>
            <IconButton
                size="medium"
                edge="start"
                sx={{ color: "text.secondary" }}
                onClick={handleClickOpen}
            >
                <DeleteIcon />
            </IconButton>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>Delete Template</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Are you sure you want to delete signature template <strong>{template?.name}</strong>?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='dialog-actions-dense' sx={{ mt: "1rem" }}>
                    <Button
                        disabled={loading}
                        onClick={handleClose}
                        size="small"
                        variant="text"
                        color="inherit"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={loading}
                        onClick={deleteTemplateHandler}
                        endIcon={(loading ?
                            <CircularProgress sx={{ color: 'common.red', mt: 0 }} size="0.825rem" /> : null
                        )}
                        variant="text"
                        size="small"
                        sx={{ fontWeight: 600 }}
                        color="error"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default DeleteTemplateDialog