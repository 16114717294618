// ** React Imports
import React from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'


const UserInformationHeader = (props) => {

    // ** Props
    const { user } = props



    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="body2" sx={{ fontWeight: 600, fontSize: "1rem" }}>
                    User Information
                </Typography>
            </Grid>
            <Grid item lg={4} sx={{ mt: "1.5rem" }}>
                <Typography sx={{fontWeight: 600, fontSize: "0.925rem"}}>
                    Secondray email | Work
                </Typography>
                <Typography variant="body2">
                    {user?.emails?.length > 0 ? user?.emails[0]?.address : "Add a secondary email"}
                </Typography>
            </Grid>
            <Grid item lg={4} sx={{ mt: '1.5rem' }}>
                <Typography sx={{fontWeight: 600, fontSize: "0.925rem"}}>
                    Phone Number
                </Typography>
                <Typography variant="body2">
                    {user?.phones?.length > 0 ? user?.phones[0]?.value : "Add a phone number"}
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: "1.5rem" }}>
                <Typography variant="body2" sx={{ fontSize: "0.925rem" }}>
                    User details
                </Typography>

            </Grid>
        </Grid>
    )
}


export default UserInformationHeader