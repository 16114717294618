// ** React Imports
import React, { Fragment, useState, useEffect } from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { groupsSelector } from 'store/groups';

// ** API CALLS
import { createSharedDrive } from 'configs/axios/api_helper'

// ** Third Party Liraries
import { useDebounce } from 'use-debounce';

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'

// ** Hooks
import { useAuth } from 'hooks/useAuth';


// ** Styled Box 
const StyledBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    boxShadow: theme.shadows[5],
    paddingTop: "0.125rem",
    paddingBottom: "0.125rem",
    paddingRight: "0.725rem",
    paddingLeft: "0.725rem",
    marginBottom: "1rem",
    borderRadius: "1rem",
    cursor: "pointer",
    width: "fit-content"
}))

const defaultValues = {
    name: '',
}


const CreateDrive = (props) => {

    // ** Props
    const { setSharedDrives, setMessage } = props

    // ** Hooks
    const { user } = useAuth()
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues })

    // ** State
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    // ** Functions
    const handleClickOpen = (e) => {
        e.stopPropagation()
        setOpen(true)
    }
    const handleClose = () => setOpen(false)


    const onSubmit = async (body) => {
        setLoading(true)
        try {
            const response = await createSharedDrive(user.primaryEmail, body)
            const { data } = response
            setSharedDrives(state => { return [...state, { ...data }] })
            setMessage({ success: true, error: false, value: "Shared drive was created successfully" })
            setLoading(false)
            setOpen(false)
        } catch (error) {
            setMessage({ success: false, error: true, value: "You don't have enough permissions to create a new shared drive" })
            setLoading(false)
        }
    }


    return (
        <Fragment>
            <StyledBox onClick={handleClickOpen}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 36 36">
                    <path fill="#34A853" d="M16 16v14h4V20z"></path>
                    <path fill="#4285F4" d="M30 16H20l-4 4h14z"></path>
                    <path fill="#FBBC05" d="M6 16v4h10l4-4z"></path>
                    <path fill="#EA4335" d="M20 16V6h-4v14z"></path>
                    <path fill="none" d="M0 0h36v36H0z"></path>
                </svg>
                <Typography sx={{ color: "text.secondary", fontSize: "0.825rem", fontWeight: 600 }}>
                    New shared drive
                </Typography>
            </StyledBox>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle id='alert-dialog-title'>Add new shared drive</DialogTitle>
                <DialogContent>
                    <form id="add-sharedDrive-form" onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Controller
                                        name='name'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <TextField
                                                variant="standard"
                                                value={value ?? ""}
                                                label='Name'
                                                onChange={onChange}
                                                placeholder='Drive xyz'
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions className='dialog-actions-dense' sx={{ mt: "1rem" }}>
                    <Button
                        disabled={loading}
                        onClick={handleClose}
                        size="small"
                        variant="text"
                        color="inherit"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={loading}
                        endIcon={(loading ? <CircularProgress sx={{ color: 'common.white', mt: 0 }} size="0.825rem" /> : null)}
                        type="submit"
                        form="add-sharedDrive-form"
                        variant="contained"
                        size="small"
                        sx={{ fontWeight: 600, primary: "common.blue", "&:disabled": { cursor: "not-allowed !important" } }}
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default CreateDrive