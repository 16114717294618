// ** React Imports
import React, { useEffect, useState } from 'react'

// ** Redux 
import { useDispatch, useSelector } from 'react-redux'
import { setTemplate, templateSelector } from 'store/template'

// ** APIs
import { getSchemasList } from 'configs/axios/api_helper'

// ** MUI Imports
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'

// ** Hooks
import { useAuth } from 'hooks/useAuth'

// ** Custom Components
import TemplatePreviewContainer from '../components/templates-preview/TemplatePreviewContainer'

// ** Third Party Components
import Prism from 'prismjs'
import { useDebouncedCallback } from "use-debounce"

// ** Icon Imports
import CodeTags from 'mdi-material-ui/CodeTags'
import Edit from 'mdi-material-ui/PencilOutline'
import Show from 'mdi-material-ui/Eye'




const initialOptions = [
    { name: "organizations", label: 'Organizations', value: "", subType: ["work"] },
    { name: "externalId", label: 'External ID', subType: ["organization"], value: "" },
    { name: "websites", label: 'Websites', subType: ["work"], value: "" },
    { name: "gender", label: 'Gender', subType: ["type"], value: "" },
    { name: "employeeType", label: 'Employee Type', value: "", subType: ["type"] },
    { name: "department", label: 'Department', value: "", subType: ["department"] },
    { name: "costCenter", label: 'Cost Center', value: "", subType: ["constCenter"] },
    { name: "managersEmail", label: 'Managers Email', value: "", subType: ["manager"] },
    { name: "emails", subType: ["primary"], label: "Email", value: "" },
    { name: "addresses", subType: ['home', 'work', 'other'], label: "Address", value: "" },
    { name: "phones", subType: ["recovery", "home", "mobile", "work", "other", "main"], label: "Phone", value: "" },
]


const initialPlaceHolders = [
    { name: "name", subType: "fullName", label: "Name", value: "" },
    { name: "jobTitle", subType: "title", label: "Job Title", value: "" },
    { name: "department", subType: "department", label: "Department", value: "" },
]

const TemplatePreview = (props) => {

    // ** Hooks
    const hidden = useMediaQuery(theme => theme.breakpoints.down('md'))
    const { user } = useAuth()
    const dispatch = useDispatch()

    // ** Selectors
    const { template } = useSelector(templateSelector)

    // ** States
    const [showCode, setShowCode] = useState(true)
    const [tabValue, setTabValue] = useState('show')
    const [options, setOptions] = useState([...initialOptions])

    // ** Highlight code on mount
    useEffect(() => {
        Prism.highlightAll()
    }, [showCode, tabValue])

    // ** Update option
    useEffect(() => {
        const fetchSchemasList = async () => {
            try {
                // Get extra fields from this api and merge it with the existing options
                const response = await getSchemasList(user.customerId)
                const { data: { schemas } } = response
                if (schemas && schemas.length > 0) {
                    let extraOptions = schemas.map(el => (
                        { name: el.schemaName, subType: el.fields.map(el => el.fieldName), label: el.schemaName, value: "" }
                    ))
                    setOptions([...initialOptions, ...extraOptions])
                }
            } catch (error) {
                console.log("error", error)
            }
        }
        fetchSchemasList()
    }, [])



    // ** Functions

    const debounced = useDebouncedCallback(
        (event) => {
            const { value } = event.target;
            const htmlObject = document.createElement('div')
            htmlObject.id = "parent-div"
            htmlObject.innerHTML = value;
            const placeholders = [...htmlObject.querySelectorAll("#parent-div [id]")].map(({ id }) => id)
            let extraFields = []
            placeholders.map(item => {
                const element = options.find(el => el.name.toLocaleLowerCase() === item)
                if (!!element) {
                    extraFields = [...extraFields, { ...element, subType: element.subType[0] }]
                }
            })
            if (extraFields.length > 0) {
                dispatch(setTemplate({ placeholders: initialPlaceHolders, greetings: "Best Regards", extraFields: extraFields, customTemplate: value }))
            } else {
                dispatch(setTemplate({ placeholders: initialPlaceHolders, greetings: "Best Regards", extraFields: [], customTemplate: value }))
            }

            // const node = htmlObject.querySelector('#name')
            // console.log("aaaa", node.childNodes[0])
            // node.innerHTML = "Koussai Mestiri"
            // console.log("aaaa", node)
        },
        500,
        // The maximum time func is allowed to be delayed before it's invoked:
        { maxWait: 3000 }
    );




    const renderCode = () => {
        if (tabValue === "show") {
            return (
                <pre className='language-jsx'>
                    <code className='language-jsx'>
                        {`${template.customTemplate ?? "No code to render"}`}
                    </code>
                </pre>
            )
        } else {
            return <TextField
                fullWidth
                rows={20}
                multiline
                label='Basic code'
                defaultValue={template.customTemplate ?? ""}
                onChange={debounced}
                placeholder='Type your code here'
                id='textarea-outlined-static'
            />
        }
    }

    return (
        <Card
            sx={{
                border: theme => `solid 1px ${theme.palette.grey[300]}`,
                borderRadius: 0,
                overflow: "visible",
                my: "0.5rem",
                '& .MuiCardHeader-action': { lineHeight: 0.8 }
            }}
            id={`card-snippet--${"preview".toLowerCase().replace(/ /g, '-')}`}
        >
            <CardHeader
                sx={{
                    backgroundColor: theme => theme.palette.grey[100],
                    height: 50,
                    border: theme => `solid 1px ${theme.palette.grey[300]}`,
                    mb: 10,
                }}
                title={(
                    <Typography variant="body2" sx={{ fontWeight: 600, fontSize: "1rem" }}>
                        Template Preview
                    </Typography>
                )}
                {...(hidden
                    ? {}
                    : {
                        action: (
                            <Tooltip title="Show code">
                                <IconButton sx={{ mt: "-0.75rem" }} onClick={() => setShowCode(!showCode)}>
                                    <CodeTags fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        )
                    })}
            />
            <TemplatePreviewContainer />
            {hidden ? null : (
                <Collapse in={showCode}>
                    <Divider sx={{ my: 0, borderBottomColor: theme => theme.palette.grey[300] }} />
                    <CardContent sx={{ position: 'relative', '& pre': { m: '0 !important', maxHeight: 500 } }}>
                        <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <ToggleButtonGroup
                                exclusive
                                size='small'
                                color='primary'
                                value={tabValue}
                                onChange={(e, newValue) => (newValue !== null ? setTabValue(newValue) : null)}
                            >
                                <ToggleButton value='show'>
                                    <Show fontSize='small' />
                                </ToggleButton>
                                <ToggleButton value='edit'>
                                    <Edit fontSize='small' />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                        <Box>{renderCode()}</Box>
                    </CardContent>
                </Collapse>
            )}
        </Card>
    )
}



export default TemplatePreview