// ** React Imports
import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import moment from 'moment'
import { Box } from '@mui/material'

// ** Icon Imports
import EditIcon from 'mdi-material-ui/PencilOutline'
import DeleteIcon from 'mdi-material-ui/TrashCanOutline'

// **  Custom Components
import UpdateGroupTemplateDialog from '../dialog/UpdateGroupTemplateDialog'
import DeleteGroupTemplateDialog from '../dialog/DeleteGroupTemplateDialog'

const Row = props => {

    // ** Hooks 
    const navigate = useNavigate()

    // ** Props
    const { row, setGroupTemplate } = props

    // ** Redirect to customer display
    const redirectHandler = (id) => {
        navigate("/groups-template/" + id)
    }


    return (
        <Fragment key={row.id}>
            <TableRow sx={{ '& > *': {}, cursor: 'pointer' }}>
                <TableCell component='th' scope='row' sx={{ color: 'common.blue' }} onClick={() => redirectHandler(row.id)}>
                    {row.name}
                </TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{moment(new Date(row.createdOn)).format("MMM DD, YYYY")}</TableCell>
                <TableCell>
                    <Box sx={{ dispaly: "flex", alignItems: "center" }}>
                        <UpdateGroupTemplateDialog setGroupTemplate={setGroupTemplate} group={row} />
                        <DeleteGroupTemplateDialog setGroupTemplate={setGroupTemplate} group={row}/>
                    </Box>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}


export default Row