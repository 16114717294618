// ** React Imports
import React, { useEffect, useState } from "react"

// ** Redux Imports
import { useDispatch, useSelector } from "react-redux"
import { groupsSelector } from "store/groups"

// ** APIs
import { updateGroupSettings } from "configs/axios/api_helper"

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'

// ** Custom Components
import CustomAlertMessage from "pages/directory/components/alert/CustomAlertMessage"
import PermissionsTablePreview from "./PermissionsTablePreview"

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'

// ** Icon Imports
import SettingsIcon from 'mdi-material-ui/Tune'


// ** Styled Box
const Container = styled(Box, { shouldForwardProp: (prop) => prop !== "hover" })(({ theme, hover }) => ({
    padding: "1.425rem",
    cursor: "pointer",
    backgroundColor: "#fff",
    transition: "all .2s ease-in-out",
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
    },
    ...(hover ? {
        boxShadow: theme.shadows[10],
        margin: "12px -12px",
        cursor: "default"
    } : {}),
    ...(!hover ? { "&:hover": { backgroundColor: theme.palette.grey[100] } } : {})
}
))

const defaultValues = {
    whoCanViewGroup: "ALL_OWNERS_CAN_VIEW",
    whoCanPostMessage: "NONE_CAN_POST",
    whoCanViewMembership: "ALL_OWNERS_CAN_VIEW",
    whoCanInvite: "NONE_CAN_INVITE",
    whoCanJoin: "CAN_REQUEST_TO_JOIN",
    whoCanLeaveGroup: "ALL_MEMBERS_CAN_LEAVE",
    whoCanContactOwner: "ALL_MANAGERS_CAN_CONTACT",
    whoCanModerateMembers: "NONE",
    whoCanModerateContent: "NONE",
    whoCanAssistContent: "NONE"
}



const PermissionsCard = (props) => {

    // ** Props
    const { groupSettings } = props

    // ** Hooks
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        watch
    } = useForm({ defaultValues })

    // ** Selectors
    const { group } = useSelector(groupsSelector);

    // ** State
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({ success: false, error: false, value: "" })

    // ** Reset form fields
    useEffect(() => {
        if (groupSettings) {
            reset({ ...groupSettings })
        }
    }, [groupSettings])

    // ** Functions
    const toggleHandler = () => {
        if (!show)
            setShow(true)
    }

    const handleCloseMessage = () => setMessage({ success: false, error: false, value: "" })

    const onSubmit = async (data) => {
        setLoading(true)
        try {
            const response = await updateGroupSettings(group.email, { groupSettings: data })
            setLoading(false)
            setMessage({ success: true, error: false, value: "Group permissions was updated successfully" })
        } catch {
            setLoading(false)
            setMessage({ error: true, success: false, value: "There was an error while updating the permissions" })
        }
    }

    return (
        <Container hover={show} onClick={toggleHandler}>
            {show ?
                <Grid item container xs={12} sx={{ position: 'relative' }}>
                    <Grid item xs={12} xl={3}>
                        <Typography sx={{ fontWeight: 600, fontSize: "0.775rem" }}>
                            General Information
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} xl={9}>
                        <form id="permissions-form" onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                            <Grid container item xs={12} sx={{ alignItems: 'center', mt: "1rem" }}>
                                <Grid item xs={12} md={3}>
                                    <Typography sx={{ fontSize: "0.825rem", fontWeight: 600 }}>
                                        View Topics :
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="standard">
                                        <InputLabel id="view-topics">Topics</InputLabel>
                                        <Controller
                                            name='whoCanViewGroup'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Select
                                                    labelId="view-topics"
                                                    id="select-topics"
                                                    value={value}
                                                    label='View Topics'
                                                    onChange={onChange}
                                                >
                                                    <MenuItem value={"ANYONE_CAN_VIEW"}>Public</MenuItem>
                                                    <MenuItem value={"ALL_MANAGERS_CAN_VIEW"}>  Managers of the group</MenuItem>
                                                    <MenuItem value={"ALL_MEMBERS_CAN_VIEW"}>All members of the group </MenuItem>
                                                    <MenuItem value={"ALL_IN_DOMAIN_CAN_VIEW"}>All organization members</MenuItem>
                                                    <MenuItem value={"ALL_OWNERS_CAN_VIEW"}>All owners</MenuItem>
                                                </Select>
                                            )}
                                        />
                                        <FormHelperText >
                                            These users can view topics in this group.
                                        </FormHelperText>
                                    </FormControl>

                                </Grid>
                            </Grid>
                            <Grid container item xs={12} sx={{ alignItems: 'center', mt: "1rem" }}>
                                <Grid item xs={12} md={3}>
                                    <Typography sx={{ fontSize: "0.825rem", fontWeight: 600 }}>
                                        Post :
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="standard">
                                        <InputLabel id="post-message">Post</InputLabel>
                                        <Controller
                                            name='whoCanPostMessage'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Select
                                                    labelId="post-message"
                                                    id="post-simple-select"
                                                    value={value}
                                                    label='Post'
                                                    onChange={onChange}
                                                >
                                                    <MenuItem value={"NONE_CAN_POST"}>None</MenuItem>
                                                    <MenuItem value={"ANYONE_CAN_POST"}>Public</MenuItem>
                                                    <MenuItem value={"ALL_MANAGERS_CAN_POST"}>Managers of the group</MenuItem>
                                                    <MenuItem value={"ALL_MEMBERS_CAN_POST"}>All members of the group </MenuItem>
                                                    <MenuItem value={"ALL_IN_DOMAIN_CAN_POST"}>All organization members</MenuItem>
                                                    <MenuItem value={"ALL_OWNERS_CAN_POST"}>All owners</MenuItem>
                                                </Select>
                                            )}
                                        />
                                        <FormHelperText>
                                            Users that can post messages to forum.
                                        </FormHelperText>
                                    </FormControl>

                                </Grid>
                            </Grid>
                            <Grid container item xs={12} sx={{ alignItems: 'center',mt: "1rem" }}>
                                <Grid item xs={12} md={3}>
                                    <Typography sx={{ fontSize: "0.825rem", fontWeight: 600 }}>
                                        View Members :
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="standard">
                                        <InputLabel id="view-topics">Members</InputLabel>
                                        <Controller
                                            name='whoCanViewMembership'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Select
                                                    labelId="view-topics"
                                                    id="select-topics"
                                                    value={value}
                                                    label='View Topics'
                                                    onChange={onChange}
                                                >
                                                    <MenuItem value={"ALL_OWNERS_CAN_VIEW"}>Owners of the group </MenuItem>
                                                    <MenuItem value={"ALL_MANAGERS_CAN_VIEW"}> Managers of the group </MenuItem>
                                                    <MenuItem value={"ALL_MEMBERS_CAN_VIEW"}>All members of the group</MenuItem>
                                                    <MenuItem value={"ALL_IN_DOMAIN_CAN_VIEW"}>All organization members</MenuItem>
                                                </Select>
                                            )}
                                        />
                                        <FormHelperText >
                                            Users that can post messages to forum.
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} sx={{ alignItems: 'center', mt: "1rem" }}>
                                <Grid item xs={12} md={3}>
                                    <Typography sx={{ fontSize: "0.825rem", fontWeight: 600 }}>
                                        Invite Members :
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="standard">
                                        <InputLabel id="view-topics">Members</InputLabel>
                                        <Controller
                                            name='whoCanInvite'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Select
                                                    labelId="view-topics"
                                                    id="select-topics"
                                                    value={value}
                                                    label='View Topics'
                                                    onChange={onChange}
                                                >
                                                    <MenuItem value={"ALL_MEMBERS_CAN_INVITE"}>All members of the group</MenuItem>
                                                    <MenuItem value={"ALL_MANAGERS_CAN_INVITE"}> All managers of the group</MenuItem>
                                                    <MenuItem value={"ALL_OWNERS_CAN_INVITE"}>Only owners</MenuItem>
                                                    <MenuItem value={"NONE_CAN_INVITE"}>None</MenuItem>
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} sx={{ alignItems: 'center',mt: "1rem" }}>
                                <Grid item xs={12} md={3}>
                                    <Typography sx={{ fontSize: "0.825rem", fontWeight: 600 }}>
                                        Join the group :
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="standard">
                                        <InputLabel id="view-topics">Members</InputLabel>
                                        <Controller
                                            name='whoCanJoin'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Select
                                                    labelId="view-topics"
                                                    id="select-topics"
                                                    value={value}
                                                    label='View Topics'
                                                    onChange={onChange}
                                                >
                                                    <MenuItem value={"ANYONE_CAN_JOIN"}>Public</MenuItem>
                                                    <MenuItem value={"ALL_IN_DOMAIN_CAN_JOIN"}>All organizations members</MenuItem>
                                                    <MenuItem value={"INVITED_CAN_JOIN"}>Invited member can join</MenuItem>
                                                    <MenuItem value={"CAN_REQUEST_TO_JOIN"}>Request an invitaion to joins</MenuItem>
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>


                            </Grid>
                            <Grid container item xs={12} sx={{ alignItems: 'center',mt: "1rem" }}>
                                <Grid item xs={12} md={3}>
                                    <Typography sx={{ fontSize: "0.825rem", fontWeight: 600 }}>
                                        Leave the group :
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="standard">
                                        <InputLabel id="view-topics">Members</InputLabel>
                                        <Controller
                                            name='whoCanLeaveGroup'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Select
                                                    labelId="view-topics"
                                                    id="select-topics"
                                                    value={value}
                                                    label='View Topics'
                                                    onChange={onChange}
                                                >
                                                    <MenuItem value={"ALL_MANAGER_CAN_LEAVE"}>Managers of the group</MenuItem>
                                                    <MenuItem value={"ALL_MEMBERS_CAN_LEAVE"}>All organization members</MenuItem>
                                                    <MenuItem value={"NONE_CAN_LEAVE"}>None</MenuItem>
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>


                            </Grid>
                            <Grid container item xs={12} sx={{ alignItems: 'center',mt: "1rem" }}>
                                <Grid item xs={12} md={3}>
                                    <Typography sx={{ fontSize: "0.825rem", fontWeight: 600 }}>
                                        Who can contact owner: :
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="standard">
                                        <InputLabel id="view-topics">Contact owner</InputLabel>
                                        <Controller
                                            name='whoCanContactOwner'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Select
                                                    labelId="view-topics"
                                                    id="select-topics"
                                                    value={value}
                                                    label='View Topics'
                                                    onChange={onChange}
                                                >
                                                    <MenuItem value={"ALL_IN_DOMAIN_CAN_CONTACT"}>All organization members</MenuItem>
                                                    <MenuItem value={"ALL_MANAGERS_CAN_CONTACT"}>Managers of the group</MenuItem>
                                                    <MenuItem value={"ALL_MEMBERS_CAN_CONTACT"}>All members of the group</MenuItem>
                                                    <MenuItem value={"ANYONE_CAN_CONTACT"}>Public</MenuItem>
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} sx={{ alignItems: 'center',mt: "1rem" }}>
                                <Grid item xs={12} md={3}>
                                    <Typography sx={{ fontSize: "0.825rem", fontWeight: 600 }}>
                                        Who can Moderate Members: :
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="standard">
                                        <InputLabel id="view-topics">Members</InputLabel>
                                        <Controller
                                            name='whoCanModerateMembers'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Select
                                                    labelId="view-topics"
                                                    id="select-topics"
                                                    value={value}
                                                    label='View Topics'
                                                    onChange={onChange}
                                                >
                                                    <MenuItem value={"ALL_MEMBERS"}>All Members</MenuItem>
                                                    <MenuItem value={"OWNERS_AND_MANAGERS"}>Ownres and managers</MenuItem>
                                                    <MenuItem value={"OWNERS_ONLY"}>Owners only</MenuItem>
                                                    <MenuItem value={"NONE"}>None</MenuItem>
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} sx={{ alignItems: 'center',mt: "1rem" }}>
                                <Grid item xs={12} md={3}>
                                    <Typography sx={{ fontSize: "0.825rem", fontWeight: 600 }}>
                                        Who can Moderate Content: :
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="standard">
                                        <InputLabel id="view-topics">Moderate Content</InputLabel>
                                        <Controller
                                            name='whoCanModerateContent'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Select
                                                    labelId="view-topics"
                                                    id="select-topics"
                                                    value={value}
                                                    label='View Topics'
                                                    onChange={onChange}
                                                >
                                                    <MenuItem value={"ALL_MEMBERS"}>All Members</MenuItem>
                                                    <MenuItem value={"OWNERS_AND_MANAGERS"}>Ownres and managers</MenuItem>
                                                    <MenuItem value={"OWNERS_ONLY"}>Owners only</MenuItem>
                                                    <MenuItem value={"NONE"}>None</MenuItem>
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>


                            </Grid>
                            <Grid container item xs={12} sx={{ alignItems: 'center',mt: "1rem" }}>
                                <Grid item xs={12} md={3}>
                                    <Typography sx={{ fontSize: "0.825rem", fontWeight: 600 }}>
                                        Who can Assist Content: :
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="standard">
                                        <InputLabel id="view-topics">Assist Content</InputLabel>
                                        <Controller
                                            name='whoCanAssistContent'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Select
                                                    labelId="view-topics"
                                                    id="select-topics"
                                                    value={value}
                                                    label='View Topics'
                                                    onChange={onChange}
                                                >
                                                    <MenuItem value={"ALL_MEMBERS"}>All Members</MenuItem>
                                                    <MenuItem value={"OWNERS_AND_MANAGERS"}>Ownres and managers</MenuItem>
                                                    <MenuItem value={"OWNERS_ONLY"}>Owners only</MenuItem>
                                                    <MenuItem value={"NONE"}>None</MenuItem>
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>


                            </Grid>
                        </form>
                    </Grid>
                    <Divider sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", width: "100%" }} />
                    <Grid item xs={12} sx={{ textAlign: "end", mt: '1rem' }}>
                        <Button sx={{ fontWeight: 600, color: "text.secondary" }} variant="text" onClick={() => setShow(false)}>
                            CANCEL
                        </Button>
                        <Button
                            type='submit'
                            form="permissions-form"
                            variant="text"
                            sx={{ fontWeight: 600 }}
                            disabled={loading}
                            endIcon={loading && <CircularProgress sx={{ color: 'common.blue' }} size="0.925rem" />}
                        >
                            SAVE
                        </Button>
                    </Grid>
                    <Backdrop
                        open={loading}
                        sx={{
                            position: 'absolute',
                            backgroundColor: "transparent",
                            backdropFilter: 'blur(5px)',
                            color: theme => theme.palette.common.blue,
                            zIndex: theme => theme.zIndex.mobileStepper - 1,
                        }}
                    >
                        <CircularProgress color='inherit' />
                    </Backdrop>
                </Grid>
                :
                <Grid item container xs={12}>
                    <Grid item xs={12}>
                        <Typography fontSize="14px">
                            Access type
                        </Typography>
                        <Typography fontSize="15px" sx={{ my: 1 }}>
                            Custom
                        </Typography>
                        <Typography variant="body2" fontSize="13px">
                            This access type determines the access settings below
                        </Typography>
                    </Grid>
                    <Divider sx={{ borderBottom: "2px solid rgba(0, 0, 0, 0.05)", width: "100%", mt: 5 }} />
                    <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                        <SettingsIcon sx={{ fontSize: "2.5rem", mr: "1rem" }} />
                        <Box>
                            <Typography variant="body2" sx={{ fontSize: "0.825rem" }}>
                                Custom
                            </Typography>
                            <Typography variant="body2" sx={{ fontSize: "0.825rem" }}>
                                When you change a setting for another access type, it becomes Custom
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xl={8} lg={12} sx={{ mt: "2rem" }}>
                        <PermissionsTablePreview groupSettings={groupSettings} />
                    </Grid>
                </Grid>
            }
            <CustomAlertMessage
                message={message}
                handleCloseMessage={handleCloseMessage}
            />
        </Container>
    )

}


export default PermissionsCard