// ** React Imports
import React from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { Typography, Divider, Grid } from '@mui/material'
import Link from '@mui/material/Link'
import useMediaQuery from '@mui/material/useMediaQuery'

// ** Icon Imports
import FacebookIcon from 'mdi-material-ui/Facebook'
import TwitterIcon from 'mdi-material-ui/Twitter'
import LinkedinIcon from 'mdi-material-ui/Linkedin'

// ** Images Imports
import MiniLogo from 'images/logo/mini_logo.svg'
import CloudPartner from 'images/logo/Google_Cloud_Partner_outline_horizontal.svg'

// Styled component for Blank Layout component
const BlankLayoutWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: '#FFF',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  overflow: 'clip'
}))

const AppBar = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 10,
  backgroundColor: '#FFF',
  boxShadow: '0px 2px 6px 0px #00000016',
  height: '65px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0px 20px 0px 20px'
}))

const Img = styled('img')(({ theme }) => ({
  height: 34,
  width: 206
}))

const Text = styled(Typography)(({ theme }) => ({
  fontWeight: 550,
  color: theme.palette.grey[600]
}))

const TextSecondary = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '14px',
  color: theme.palette.grey[600]
}))

const BlankLayout = ({ children }) => {
  const small = useMediaQuery(theme => theme.breakpoints.down('md'))
  return (
    <BlankLayoutWrapper className='layout-wrapper'>
      <AppBar>
        <Link href='/login'>
          <Img alt='billing_automation' src={MiniLogo} />
        </Link>
        <Link target='_blank' href='https://premiercloud.com/contact/'>
          <Button variant='contained' sx={{ width: 124, height: 33, fontWeight: 600, borderRadius: 0.5 }}>
            Contact Us
          </Button>
        </Link>
      </AppBar>
      <Box className='app-content' sx={{ flex: 1 }}>
        {children}
      </Box>
      <Grid container>
        {small ? (
          <Grid container item xs={12}>
            <Grid item lg={2} xs={1} />
            <Grid item lg={8} xs={10}>
              <Divider sx={{ borderBottomWidth: 1, color: theme => theme.palette.grey[300] }} />
            </Grid>
            <Grid item lg={2} xs={1} />
          </Grid>
        ) : null}
        <Grid item container xs={12}>
          <Grid item lg={2} xs={1} />
          <Grid item lg={8} xs={10}>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              flexDirection={small ? 'column' : 'row'}
              gap={small ? 5 : 0}
            >
              <Box display='flex' alignItems='center' gap={2}>
                <Text sx={{ fontSize: small ? 12 : null }}>
                  Follow our <span style={{ textDecoration: 'underline' }}>Blog</span>
                </Text>
                <Text>|</Text>
                <Link href='https://www.facebook.com/PremierCloudInc' target='_blank' rel='noopener noreferrer'>
                  <FacebookIcon sx={{ color: theme => `${theme.palette.grey[600]} !important` }} />
                </Link>
                <Link href='https://twitter.com/PremierCloudInc' target='_blank' rel='noopener noreferrer'>
                  <TwitterIcon sx={{ color: theme => theme.palette.grey[600] }} />
                </Link>
                <Link
                  href='https://www.linkedin.com/company/premier-cloud-inc'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <LinkedinIcon sx={{ color: theme => theme.palette.grey[600] }} />
                </Link>
              </Box>
              <Box>
                <img height={small ? 40 : 61} width={small ? 180 : 200} alt='google_cloud_partner' src={CloudPartner} />
              </Box>
            </Box>
          </Grid>
          <Grid item lg={2} xs={1} />
        </Grid>
        <Grid container item xs={12}>
          <Grid item lg={2} xs={1} />
          <Grid item lg={8} xs={10}>
            <Divider sx={{ borderBottomWidth: 1, color: theme => theme.palette.grey[300] }} />
          </Grid>
          <Grid item lg={2} xs={1} />
        </Grid>
        <Grid container item xs={12} mb={5}>
          <Grid item lg={2} xs={1} />
          <Grid item lg={8} xs={10}>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              flexDirection={small ? 'column' : 'row'}
            >
              <Box display='flex' alignItems='center' gap={5}>
                <Img alt='billing_automation' src={MiniLogo} />
                <Link href='/login'>
                  <TextSecondary sx={{ textDecoration: 'underline', fontWeight: 600, fontSize: 12 }}>
                    Home
                  </TextSecondary>
                </Link>
                |
                <Link href='/privacy-policy'>
                  <TextSecondary sx={{ textDecoration: 'underline', fontWeight: 600, fontSize: 12 }}>
                    Privacy
                  </TextSecondary>
                </Link>
              </Box>
              <TextSecondary sx={{ fontSize: 12 }}>© 2024 Premier Cloud Inc. All Rights Reserved.</TextSecondary>
            </Box>
          </Grid>
          <Grid item lg={2} xs={1} />
        </Grid>
      </Grid>
    </BlankLayoutWrapper>
  )
}

export default BlankLayout
