// ** React Import
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** Redux Imports
import { useSelector, useDispatch } from 'react-redux'
import { usersSelector } from 'store/users'

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import { DataGrid } from '@mui/x-data-grid'
import Stack from '@mui/material/Stack'

const NonEditableAliasesTable = () => {


    // ** Hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // ** Selectors
    const { user } = useSelector(usersSelector);

    // ** State
    // const [aliases, setAliases] = useState([])
    // const [loading, setLoading] = useState(false)
    // const [message, setMessage] = useState({
    //     success: false,
    //     error: false
    // })

    // **

    const columns = [
        {
            flex: 0.25,
            minWidth: 290,
            field: 'alias',
            headerName: 'Aliases',
            renderCell: params => {
                return (
                    <Typography
                        noWrap
                        variant='body2'
                        sx={{ color: 'common.blue' }}
                    >
                        {params.row.alias}
                    </Typography>
                )
            }
        },
        {
            flex: 0.175,
            minWidth: 120,
            headerName: 'Action',
            field: 'Action',
            align: "right",
            headerAlign: "right",
            renderCell: params => (
                <Typography></Typography>
            )
        },
    ]

    return (
        <Card sx={{ borderRadius: 0, border: theme => `solid 1px ${theme.palette.grey[300]}`, mt: "1rem" }}>
            <CardHeader
                sx={{
                    backgroundColor: theme => theme.palette.customColors.tableHeaderBg,
                    height: "58px"
                }}
                title={
                    <Stack direction="row" alignItems="center" gap={1} sx={{ px: "0.25rem" }}>
                        <Typography sx={{ fontWeight: 600, fontSize: "1rem" }}>
                            Non Editable Aliases
                        </Typography>
                    </Stack>
                }
            />
            <Box sx={{ height: 10, px: 12, display: 'flex', alignItems: 'center' }}>
                {/* <Filters /> */}
            </Box>
            <DataGrid
                autoHeight
                rows={[]}
                columns={columns}
                //loading={loading}
                hideFooter
          
            />
        </Card>
    )
}

export default NonEditableAliasesTable
