// ** React Imports
import React, { Fragment, useState } from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { usersSelector } from 'store/users'

// ** API CALLS
import { createLabel } from 'configs/axios/api_helper'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'

const defaultValues = {
    name: '',
    labelListVisibility: 'labelShow',
    messageListVisibility: "show"
}


const AddDialog = (props) => {

    // ** Props
    const { setLabels, setMessage } = props

    // ** Hooks
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues })

    // ** Selectors
    const { user } = useSelector(usersSelector);

    // ** State
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    // ** Functions
    const handleClickOpen = (e) => {
        e.stopPropagation()
        setOpen(true)
    }
    const handleClose = () => setOpen(false)



    const onSubmit = async (data) => {
        setLoading(true)
        let obj = { ...data }
        try {
            const response = await createLabel(user.primaryEmail, { obj })
            const { data } = response
            setLabels(state => { return [...state, { ...data, id: state.length }] })
            setMessage({ success: "ADD_LABEL", error: false })
            setLoading(false)
            setOpen(false)
        } catch (error) {
            setMessage({ success: "", error: "ERROR_ADD" })
            setLoading(false)
        }
    }


    return (
        <Fragment>
            <Button
                size="medium"
                variant="text"
                sx={{ fontWeight: 600, ml: "1.325rem", mb: "1rem" }}
                onClick={handleClickOpen}
            >
                Add new label
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle id='alert-dialog-title'>Add new label</DialogTitle>
                <DialogContent>
                    <form id="add-label-form" onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Controller
                                        name='name'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <TextField
                                                variant="standard"
                                                value={value ?? ""}
                                                label='Name'
                                                onChange={onChange}
                                                error={Boolean(errors.name)}
                                                placeholder='jhondoe'
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id='visibility-label'>Label List visibility</InputLabel>
                                    <Controller
                                        name='labelListVisibility'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <Select
                                                variant="standard"
                                                value={value ?? ""}
                                                label='Label list visibility'
                                                onChange={onChange}
                                                labelId='visibility-label'
                                            >
                                                <MenuItem value="labelShow">Show the label in the labels list</MenuItem>
                                                <MenuItem value="labelShowIfUnread">Show the label if there are any unread messages with that label</MenuItem>
                                                <MenuItem value="labelHide">Do not show the label in the labels list</MenuItem>
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id='visibility-label'>Message List visibility</InputLabel>
                                    <Controller
                                        name='messageListVisibility'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <Select
                                                variant="standard"
                                                value={value ?? ""}
                                                label='Message List visibility'
                                                onChange={onChange}
                                                labelId='visibility-label'
                                            >
                                                <MenuItem value="show">Show the label in the message list</MenuItem>
                                                <MenuItem value="hide">Do not show the label in the message list</MenuItem>
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions className='dialog-actions-dense' sx={{ mt: "1rem" }}>
                    <Button
                        disabled={loading}
                        onClick={handleClose}
                        size="small"
                        variant="text"
                        color="inherit"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={loading}
                        endIcon={(loading ?
                            <CircularProgress sx={{ color: 'common.white', mt: 0 }} size="0.825rem" /> : null
                        )}
                        type="submit"
                        form="add-label-form"
                        variant="contained"
                        size="small"
                        sx={{ fontWeight: 600, primary: "common.blue" }}
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default AddDialog