// ** React Imports
import React from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import { Grid, Button, useMediaQuery } from "@mui/material"

// ** Icons Imports
import ExportIcon from 'mdi-material-ui/TrayArrowDown'
import PlusIcon from 'mdi-material-ui/Plus'

const ActionButton = (props) => {

    // ** Props
    const { path } = props

    // ** Hooks
    const navigate = useNavigate()
    const xsmall = useMediaQuery(theme => theme.breakpoints.down('sm'))


    return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Grid container spacing={2}>
            <Grid item xs={10} sm={6} sx={{ whiteSpace: "nowrap" }}>
                <Button
                    onClick={() => navigate("/tools/editor-template")}
                    variant='contained'
                    sx={{
                        background: "#0F9D58 !important",
                        "&:hover": {background: "#0F9D58 !important"},
                        mx: 1,
                        borderRadius: "5px !important",
                        fontWeight: 600,
                        color: "common.white",
                        fontSize: "0.825rem"
                    }}
                    startIcon={<PlusIcon />}
                >
                    Custom Template
                </Button>
            </Grid>
            <Grid item xs={10} sm={6} sx={{ whiteSpace: "nowrap" }}>
                <Button
                    onClick={() => navigate(path)}
                    variant='contained'
                    sx={{
                        mx: 1,
                        borderRadius: "5px !important",
                        fontWeight: 600,
                        color: "common.white",
                        fontSize: "0.825rem"
                    }}
                    startIcon={<PlusIcon />}
                >
                    Predefined Template
                </Button>
            </Grid>
        </Grid>
    )
}


export default ActionButton