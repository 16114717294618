// ** React imports
import React, { useEffect, useMemo, useState } from 'react'

// ** API Calls
import { getDriveFolders, getAllSharedDrives, getSharedDriveFiles } from 'configs/axios/api_helper'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { usersSelector } from 'store/users'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { DataGrid } from '@mui/x-data-grid'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

// ** Third party Libraries
import moment from 'moment'

// ** Custom Components
import CustomAlertMessage from 'pages/users/components/alert/CustomAlertMessage'
import CustomAvatar from 'core/components/mui/avatar'
import AddDialog from './dialog/AddDialog'
import DeleteDialog from './dialog/DeleteDialog'
import UpdateDialog from './dialog/UpdateDialog'
import MoveDialog from './dialog/MoveDialog'
import ShareDialog from './dialog/ShareDialog'
import BulkDeleteDialog from './dialog/BulkDeleteDialog'
import RestoreDialog from './dialog/RestoreDialog'
import BulkMoveDialog from './dialog/BulkMoveDialog'
import SharedUsersDialog from './dialog/SharedUsersDialog'
import SharedDriveSettingsDialog from './dialog/SharedDriveSettingsDialog'
import DeleteSharedDrive from './dialog/DeleteSharedDrive'

// ** Icons Imports
import FolderDrive from 'mdi-material-ui/Folder'
import SharedFolderDrive from 'mdi-material-ui/FolderAccount'
import StarredIcon from 'mdi-material-ui/Star'

// ** Styled component for Images
const Img = styled('img')(({ theme }) => ({
  height: 20,
  width: 20
}))

const RenderIcon = props => {
  // ** Props
  const { folder, openRowHandler } = props

  return (
    <>
      {folder?.kind?.includes('file') && folder?.mimeType?.includes('folder') && (
        <Box
          sx={{ display: 'flex', alignItems: 'center', gap: 3, cursor: 'pointer' }}
          onClick={() => openRowHandler(folder)}
        >
          {folder.permissions?.length > 1 ? (
            <SharedFolderDrive sx={{ color: 'text.secondary' }} />
          ) : (
            <FolderDrive sx={{ color: 'text.secondary' }} />
          )}
          <Typography noWrap sx={{ fontWeight: 600, fontSize: '0.875rem' }}>
            {folder.name}
          </Typography>
          {folder.starred && <StarredIcon sx={{ color: 'text.secondary', fontSize: '1rem' }} />}
        </Box>
      )}
      {folder?.kind?.includes('file') && !folder.mimeType.includes('folder') && (
        <Box
          sx={{ display: 'flex', alignItems: 'center', gap: 4, cursor: 'pointer' }}
          onClick={() => openRowHandler(folder)}
        >
          <Img src={folder.iconLink ?? ''} />
          <Typography noWrap sx={{ fontWeight: 600, fontSize: '0.875rem' }}>
            {folder.name}
          </Typography>
        </Box>
      )}
      {!folder?.kind?.includes('file') && (
        <Box
          sx={{ display: 'flex', alignItems: 'center', gap: 4, cursor: 'pointer' }}
          onClick={() => openRowHandler(folder)}
        >
          <Img src={folder.backgroundImageLink ?? ''} />
          <Typography noWrap sx={{ fontWeight: 600, fontSize: '0.875rem' }}>
            {folder.name}
          </Typography>
        </Box>
      )}
    </>
  )
}

const FoldersTable = props => {
  // ** Props
  const { aboutUserDrive, driveTab } = props

  // ** State
  const [folders, setFolders] = useState([])
  const [treeFolders, setTreeFolders] = useState([])
  const [selectionModel, setSelectionModel] = useState([])
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({ success: false, error: false, value: '' })
  const [query, setQuery] = useState("trashed = false and 'root' in parents")
  const [rows, setRows] = useState(25)
  const [pageSize, setPageSize] = useState(5)
  const [page, setPage] = useState(0)
  const [nextPageToken, setNextPageToken] = useState('')
  const [queryPage, setQueryPage] = useState(0)

  // ** Memo for handling tabs navigation to request the new data
  const queryHandler = useMemo(() => {
    if (driveTab === 0) setQuery("trashed = false and 'root' in parents")
    if (driveTab === 1) setQuery('"me" in owners and trashed=false and "root" in parents')
    if (driveTab === 2) setQuery('sharedWithMe=true and trashed=false')
    if (driveTab === 3) setQuery('starred=true and trashed=false')
    if (driveTab === 4) setQuery('trashed=true and explicitlyTrashed=true')
    if (driveTab === 5) setQuery('')
  }, [driveTab])

  // ** Selectors
  const { user } = useSelector(usersSelector)

  // fetch folders on my drive
  const fetchFoldersData = async () => {
    setLoading(true)
    try {
      const response = await getDriveFolders(user.id, rows, query)
      const {
        data: { files }
      } = response
      setFolders(files)
      setPage(0)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  // fetch shared drive and it's sub files and folders
  const fetchSharedDriveData = async () => {
    setLoading(true)
    try {
      if (!query) {
        const response = await getAllSharedDrives({ id: user.id, rows: 100 })
        const {
          data: { drives }
        } = response
        setFolders(drives)
      } else {
        const response = await getSharedDriveFiles(user.id, rows, query)
        const {
          data: { files }
        } = response
        setFolders(files)
      }
      setPage(0)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  // ** Fetch Delegations Data
  useEffect(() => {
    if (user?.id && driveTab !== 5) fetchFoldersData()
    else if (user?.id && driveTab === 5) fetchSharedDriveData()
  }, [user, query])

  // ** Functions
  const handleCloseMessage = () => {
    setMessage({ success: false, error: false, value: '' })
  }

  // redirect to file link and check user permission to log any error
  const openRowHandler = async folder => {
    if (driveTab !== 4) {
      // check if the selected row folder or a shared folder (shared folder doesn't have a mimetype property)
      if (folder.mimeType === 'application/vnd.google-apps.folder' && !loading) {
        setTreeFolders(state => [...state, folder])
        setQuery(`trashed = false and '${folder.id}' in parents`)
      } else if (!folder.mimeType) {
        setTreeFolders(state => [...state, folder])
        setQuery(`trashed=false and '${folder.id}' in parents`)
      } else if (folder.mimeType === 'application/vnd.google-apps.shortcut' && !loading) {
        console.log('shortcut')
      } else {
        // if the selected row is a file
        if (folder.permissionIds && !loading) {
          // if folder have permissions then check it with the current auth user permissions to verify if he can access sub files
          if (
            folder.permissionIds.includes('anyoneWithLink') ||
            folder.permissionIds.includes(aboutUserDrive?.permissionId)
          ) {
            try {
              const newWindow = window.open(folder.webViewLink, '_blank', 'noopener,noreferrer')
              if (newWindow) newWindow.opener = null
            } catch (error) {
              console.log('error')
            }
          } else {
            console.log('Access Denied')
          }
        } else {
          try {
            const newWindow = window.open(folder.webViewLink, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
          } catch (error) {
            console.log('error')
          }
        }
      }
    }
  }

  const redirectToFolderHandler = id => {
    if (driveTab !== 4) {
      // find index of the selected item then remove all sub opened folders
      const index = treeFolders.findIndex(el => el.id === id)
      const array = [...treeFolders]
      const removed = array.splice(index + 1)
      setTreeFolders([...array])
      setQuery(`trashed = false and '${id}' in parents`)
    }
  }

  const resetTreeFolders = () => {
    if (driveTab !== 4) {
      if (driveTab === 0) {
        setQuery("trashed = false and 'root' in parents")
        setTreeFolders([])
      }
      if (driveTab === 1) {
        setQuery('"me" in owners and trashed=false and "root" in parents')
        setTreeFolders([])
      }
      if (driveTab === 2) {
        setQuery('sharedWithMe=true and trashed=false')
        setTreeFolders([])
      }
      if (driveTab === 3) {
        setQuery('starred=true and trashed=false')
        setTreeFolders([])
      }
      if (driveTab === 5) {
        setQuery('')
        setTreeFolders([])
      }
    }
  }

  const columns = [
    {
      flex: 0.25,
      minWidth: 200,
      field: 'name',
      headerName: 'Folder Name',
      renderCell: params => {
        return <RenderIcon folder={params.row} openRowHandler={openRowHandler} />
      }
    },
    {
      flex: 0.25,
      minWidth: 250,
      field: 'owners',
      headerName: 'Owner',
      renderCell: params => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CustomAvatar
              src={`${!!params.row.owners ? params.row?.owners[0]?.photoLink : ''}`}
              sx={{ mr: 3, width: '1.875rem', height: '1.875rem' }}
            />
            <Typography noWrap variant='body2'>
              {!!params.row.owners ? params.row.owners[0]?.displayName : ''}
            </Typography>
          </Box>
        )
      }
    },
    {
      flex: 0.25,
      minWidth: 160,
      field: 'modifiedTime',
      headerName: `${driveTab === 4 ? 'Trashed Date' : 'Last Modified'}`,
      renderCell: params => {
        return (
          <Typography noWrap variant='body2'>
            {driveTab === 5
              ? moment(new Date(params.row.createdTime ?? '')).format('MMM DD, YYYY')
              : moment(new Date(params.row.modifiedTime ?? '')).format('MMM DD, YYYY')}
          </Typography>
        )
      }
    },
    {
      flex: 0.175,
      minWidth: 180,
      headerName: 'Action',
      field: 'Action',
      align: 'left',
      headerAlign: 'left',
      renderCell: params => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {selectionModel.length < 1 && (
            <>
              {driveTab !== 5 && (
                <DeleteDialog
                  setMessage={setMessage}
                  setFolders={setFolders}
                  folder={params.row}
                  user={user}
                  driveTab={driveTab}
                />
              )}
              {driveTab === 4 && (
                <RestoreDialog setMessage={setMessage} setFolders={setFolders} folder={params.row} user={user} />
              )}
              {(driveTab === 0 || driveTab === 1 || driveTab === 3) && (
                <UpdateDialog setMessage={setMessage} setFolders={setFolders} folder={params.row} user={user} />
              )}
              {(driveTab === 0 || driveTab === 1 || driveTab === 3) && (
                <MoveDialog
                  setMessage={setMessage}
                  setFolders={setFolders}
                  folder={params.row}
                  setTreeFolders={setTreeFolders}
                  setSelectionModel={setSelectionModel}
                />
              )}
              {(driveTab === 0 || driveTab === 1 || driveTab === 3 || driveTab === 2) && (
                <ShareDialog
                  setMessage={setMessage}
                  setFolders={setFolders}
                  folder={params.row}
                  fetchFoldersData={fetchFoldersData}
                />
              )}
              {driveTab === 5 && <SharedUsersDialog folder={params.row} setMessage={setMessage} />}
              {driveTab === 5 && <SharedDriveSettingsDialog folder={params.row} setMessage={setMessage} />}
              {driveTab === 5 && (
                <DeleteSharedDrive folder={params.row} setMessage={setMessage} setSharedDrives={setFolders} />
              )}
            </>
          )}
        </Box>
      )
    }
  ]

  return (
    <Grid item container xs={12}>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', p: '1rem', mt: '-2.625rem' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography
            onClick={resetTreeFolders}
            sx={{ fontWeight: 600, fontSize: '0.925rem', mt: '0.425rem', cursor: 'pointer' }}
          >
            My Drive
          </Typography>
          {treeFolders.map(el => (
            <Typography
              sx={{ fontWeight: 600, fontSize: '0.925rem', mt: '0.425rem', color: 'text.secondary', cursor: 'pointer' }}
              key={el.id}
              onClick={() => redirectToFolderHandler(el.id)}
            >
              {'>'}&nbsp; {el.name} &nbsp;
            </Typography>
          ))}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 5 }}>
          <AddDialog setMessage={setMessage} setFolders={setFolders} driveTab={driveTab} />
          {selectionModel.length > 0 && (
            <BulkDeleteDialog setMessage={setMessage} setFolders={setFolders} folders={selectionModel} />
          )}
          {selectionModel.length > 0 && driveTab !== 4 && (
            <BulkMoveDialog
              setMessage={setMessage}
              setFolders={setFolders}
              folders={selectionModel}
              rootFolders={folders}
              setTreeFolders={setTreeFolders}
              setSelectionModel={setSelectionModel}
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          autoHeight
          rows={folders}
          columns={columns}
          columnVisibilityModel={{
            // Hide columns owners, the other columns will remain visible
            owners: driveTab === 5 ? false : true
          }}
          components={{
            LoadingOverlay: LinearProgress
          }}
          loading={loading}
          //hideFooter
          checkboxSelection
          disableSelectionOnClick
          selectionModel={selectionModel}
          onSelectionModelChange={selection => {
            setSelectionModel(selection)
          }}
          pageSize={pageSize}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20, 50]}
          pagination
          onPageChange={newPage => setPage(newPage)}
          page={page}
        />
      </Grid>
      <CustomAlertMessage message={message} handleCloseMessage={handleCloseMessage} />
    </Grid>
  )
}

export default FoldersTable
