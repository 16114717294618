// ** React Imports
import React from 'react'

// ** MUI Imports
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

// ** Third Party Imports
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

const FormInput = props => {
  // ** Props
  const { name, control, label, placeholder, errors, fullWidth, size, multiline, rows } = props
  return (
    <FormControl fullWidth={fullWidth}>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField
            value={value ?? ''}
            size={size || 'medium'}
            label={label}
            multiline={multiline}
            rows={rows}
            onChange={onChange}
            placeholder={placeholder}
            error={Boolean(errors?.[name])}
            aria-describedby={`validation-basic-${name}`}
            InputProps={{
              style: { borderRadius: 0 }
            }}
            InputLabelProps={{
              sx: {
                color: theme => theme.palette.grey[500]
              }
            }}
          />
        )}
      />
      {errors?.[name] && (
        <FormHelperText sx={{ color: 'error.main' }} id={`validation-basic-${name}`}>
          {errors?.[name].message}
        </FormHelperText>
      )}
    </FormControl>
  )
}

FormInput.propTypes = {
  control: PropTypes.object.isRequired, // Required string prop
  errors: PropTypes.object.isRequired, // Optional string prop
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number
}

export default FormInput
