// ** React Imports
import React from 'react'
// ** MUI Import
import Box from '@mui/material/Box'
import "./Spinner.css"

const GoogleSpinner = () => {

    return (
        <div className="container">
            <svg className="spinner" width="45px" height="45px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
            </svg>
        </div>
    )
}

export default GoogleSpinner
