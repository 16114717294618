// ** React imports
import React from 'react'

// ** MUI Imports
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { useMediaQuery } from '@mui/material'

// ** Components
import UserDropdown from 'core/layouts/components/shared-components/UserDropdown'
import NotificationDropdown from 'core/layouts/components/shared-components/NotificationDropdown'
import ApplicationsDropdown from 'core/layouts/components/shared-components/ApplicationsDropdown'

// ** Icons Imports
import TooltipIcon from 'mdi-material-ui/HelpCircleOutline'
import ConsoleIcon from 'mdi-material-ui/Console'

// ** Auth Hook
import { useAuth } from 'hooks/useAuth'
import { useSettings } from 'core/hooks/useSettings'

const AppBarContent = props => {
  // ** Props
  const { hidden } = props

  //** Hooks
  const auth = useAuth()
  const medium = useMediaQuery(theme => theme.breakpoints.down('md'))
  const { saveSettings, settings, disconnect, setDisconnect } = useSettings()

  // ** Functions

  const toggleConsoleVisibility = () => {
    if (settings?.consoleLine && disconnect) {
      saveSettings(state => ({ ...state, timer: true }))
      disconnect()
    } else if (!settings?.consoleLine && !settings?.timer) {
      setDisconnect(null)
      saveSettings(state => ({ ...state, consoleLine: true }))
    }
  }
  // saveSettings(state => ({ ...state, consoleLine: !state.consoleLine }))
  // window.open(`http://34.173.179.105?clientId=${auth.user?.id}`, '_blank', 'noreferrer')

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          ...(!medium ? { mx: '2rem' } : { mx: 1 })
        }}
      >
        {!medium && (
          <Box
            sx={{
              border: theme => `1px solid ${theme.palette.grey[300]}`,
              ...(!medium && { mx: '1rem' }),
              px: '1rem',
              py: '0.2rem',
              borderRadius: '1em'
            }}
          >
            <Typography sx={{ color: 'text.secondary', fontSize: '0.8125rem', fontWeight: 600 }}>
              {auth.user?.email}
            </Typography>
          </Box>
        )}
        <TooltipIcon
          sx={{
            color: 'text.secondary',
            ...(!medium ? { mx: '0.5rem' } : { mx: 1 })
          }}
        />
        <IconButton aria-haspopup='true' aria-controls='customized-menu' onClick={toggleConsoleVisibility}>
          <ConsoleIcon
            sx={{
              color: 'text.secondary',
              ...(!medium ? { mx: '0.5rem' } : { mx: 1 })
            }}
          />
        </IconButton>
        <NotificationDropdown settings={settings} />
        {auth?.user?.access_apps?.filter(app => app.access === true)?.length > 0 && (
          <ApplicationsDropdown settings={settings} />
        )}
      </Box>
      <UserDropdown settings={settings} saveSettings={saveSettings} />
    </Box>
  )
}

export default AppBarContent
