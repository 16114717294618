// ** React Imports
import React, { Fragment, useState } from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { usersSelector } from 'store/users'

// ** API CALLS
import { createCalendar } from 'configs/axios/api_helper'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Autocomplete from '@mui/material/Autocomplete'

// ** Import Data
import { timezone } from '@fake-db/timezone'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'

// ** Custom Components
import CustomAlertMessage from 'pages/users/components/alert/CustomAlertMessage'

const defaultValues = {
    summary: "",
    description: "",
    timeZone: "UTC-8",
    location: ""
}


const AddDialog = (props) => {

    // ** Props
    const { setCalendars } = props

    // ** Hooks
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues })

    // ** Selectors
    const { user } = useSelector(usersSelector);

    // ** State
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({ success: false, error: false, value: "" })

    // ** Functions
    const handleClickOpen = (e) => {
        e.stopPropagation()
        setOpen(true)
    }

    const handleClose = () => setOpen(false)

    const onSubmit = async (body) => {
        setLoading(true)
        try {
            const response = await createCalendar(user.id, body)
            const { data } = response
            setCalendars(state => { return [...state, { ...data }] })
            setMessage({ success: true, error: false, value: "Calendar was added successfully" })
            setLoading(false)
            setOpen(false)
        } catch (error) {
            setMessage({ success: false, error: true, value: "There was an error while adding the calendar" })
            setLoading(false)
        }
    }

    const handleCloseMessage = () => {
        setMessage({ success: false, error: false, value: "" })
    }

    return (
        <Fragment>
            <Button
                size="medium"
                variant="text"
                sx={{ fontWeight: 600, ml: "1.325rem", mb: "1rem" }}
                onClick={handleClickOpen}
            >
                Add new calendar
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle id='alert-dialog-title'>Add new calendar</DialogTitle>
                <DialogContent>
                    <form id="add-calendar-form" onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Controller
                                        name='summary'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <TextField
                                                variant="standard"
                                                value={value ?? ""}
                                                label='Title'
                                                onChange={onChange}
                                                error={Boolean(errors.title)}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Controller
                                        name='location'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <TextField
                                                variant="standard"
                                                value={value ?? ""}
                                                label='Location'
                                                onChange={onChange}
                                                error={Boolean(errors.location)}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Controller
                                        name='timeZone'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <Autocomplete
                                                value={timezone?.find(zone => zone?.value === value)}
                                                onChange={(event, values) => onChange(values?.value)}
                                                options={timezone}
                                                getOptionLabel={option => option.name}
                                                renderInput={params =>
                                                    <TextField
                                                        variant="standard"
                                                        {...params}
                                                        label='TimeZone'
                                                    />}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Controller
                                        name='description'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <TextField
                                                variant="standard"
                                                multiline
                                                minRows={4}
                                                value={value ?? ""}
                                                label='Description'
                                                onChange={onChange}
                                                error={Boolean(errors.description)}
                                                placeholder='description'
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions className='dialog-actions-dense' sx={{ mt: "1rem" }}>
                    <Button
                        disabled={loading}
                        onClick={handleClose}
                        size="small"
                        variant="text"
                        color="inherit"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={loading}
                        endIcon={(loading ?
                            <CircularProgress sx={{ color: 'common.white', mt: 0 }} size="0.825rem" /> : null
                        )}
                        type="submit"
                        form="add-calendar-form"
                        variant="contained"
                        size="small"
                        sx={{ fontWeight: 600, primary: "common.blue" }}
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
            <CustomAlertMessage
                message={message}
                handleCloseMessage={handleCloseMessage}
            />
        </Fragment>
    )
}

export default AddDialog