// ** React Imports
import React, { useEffect, useState } from 'react'

// ** Api Calss
import { getAllUsers, getUser } from 'configs/axios/api_helper'

// ** Redux Imports
import { templateSelector } from 'store/template'
import { useSelector } from 'react-redux'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useMediaQuery } from '@mui/material'

// ** Custom Components
import TemplatePreviewV1 from './previews/TemplatePreviewV1'
import TemplatePreviewV2 from './previews/TemplatePreviewV2'
import TemplatePreviewV3 from './previews/TemplatePreviewV3'
import TemplatePreviewV4 from './previews/TemplatePreviewV4'
import TemplatePreviewNoImageV1 from './previews/TemplatePreviewNoImageV1'
import TemplatePreviewNoImageV2 from './previews/TemplatePreviewNoImageV2'
import TemplatePreviewNoImageV3 from './previews/TemplatePreviewNoImageV3'
import TemplatePreviewNoImageV4 from './previews/TemplatePreviewNoImageV4'

// ** Dummy Data
import { options } from '@fake-db/options'
import AddTemplateDialog from '../dialog/AddTemplateDialog'
import AlertMessage from 'pages/tools/components/CustomAlertMessage'

// ** Styled Box
const Container = styled(Box)(({ theme }) => ({
  padding: '1.425rem',
  backgroundColor: '#fff',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2)
  }
}))

const TemplatePreviewContainer = props => {
  // ** Hooks
  const small = useMediaQuery(theme => theme.breakpoints.down('md'))

  // ** Selectors
  const { template, selectedTemplate } = useSelector(templateSelector)

  // ** State
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)

  // ** Fetch users for preview
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersData = await getAllUsers().then(res => res.data?.result)
        setUsers(usersData)
      } catch (error) {}
    }
    fetchUsers()
  }, [])

  // ** Functions
  const selectUserHandler = async (event, user) => {
    try {
      const response = await getUser(user.id)
      const {
        data: { result }
      } = response
      setSelectedUser(result)
    } catch (error) {}
  }

  return (
    <Container>
      <Grid
        item
        container
        xs={12}
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          ...(small ? { mt: '-4rem' } : { mt: '-3rem' })
        }}
      >
        <Grid item xs={12} sx={{ mb: '2rem', display: 'flex', justifyContent: 'start !important' }}>
          <Autocomplete
            onChange={selectUserHandler}
            options={users.map(option => option)}
            getOptionLabel={option => option.primaryEmail}
            sx={{ ...(small ? { width: 350 } : { width: 400, mt: '0.625rem' }) }}
            renderInput={params => (
              <TextField
                {...params}
                label='Users to preview'
                variant='standard'
                InputLabelProps={{ style: { fontSize: '0.875rem' } }} // font size of input label
              />
            )}
          />
        </Grid>

        <Card sx={{ borderRadius: 0 }}>
          <CardHeader
            sx={{ backgroundColor: theme => theme.palette.grey[300], height: '1rem' }}
            title={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {[0, 1, 2].map((el, index) => (
                  <Box
                    key={index}
                    sx={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: '#fff', mx: 2 }}
                  />
                ))}
              </Box>
            }
          />
          <CardContent>
            {selectedTemplate === 0 && !template.customTemplate && (
              <TemplatePreviewV1
                placeHolders={template.placeHolders}
                dataOption={selectedUser}
                greetings={template.greetings}
                extraFields={template.extraFields}
                image={template.image}
              />
            )}
            {selectedTemplate === 1 && !template.customTemplate && (
              <TemplatePreviewV2
                placeHolders={template.placeHolders}
                dataOption={selectedUser}
                greetings={template.greetings}
                extraFields={template.extraFields}
                image={template.image}
              />
            )}

            {selectedTemplate === 2 && !template.customTemplate && (
              <TemplatePreviewV3
                placeHolders={template.placeHolders}
                dataOption={selectedUser}
                greetings={template.greetings}
                extraFields={template.extraFields}
                image={template.image}
              />
            )}
            {selectedTemplate === 3 && !template.customTemplate && (
              <TemplatePreviewV4
                placeHolders={template.placeHolders}
                dataOption={selectedUser}
                greetings={template.greetings}
                extraFields={template.extraFields}
                image={template.image}
              />
            )}
            {selectedTemplate === 4 && !template.customTemplate && (
              <TemplatePreviewNoImageV1
                placeHolders={template.placeHolders}
                dataOption={selectedUser}
                greetings={template.greetings}
                extraFields={template.extraFields}
              />
            )}
            {selectedTemplate === 5 && !template.customTemplate && (
              <TemplatePreviewNoImageV2
                placeHolders={template.placeHolders}
                dataOption={selectedUser}
                greetings={template.greetings}
                extraFields={template.extraFields}
              />
            )}
            {selectedTemplate === 6 && !template.customTemplate && (
              <TemplatePreviewNoImageV3
                placeHolders={template.placeHolders}
                dataOption={selectedUser}
                greetings={template.greetings}
                extraFields={template.extraFields}
              />
            )}
            {selectedTemplate === 7 && !template.customTemplate && (
              <TemplatePreviewNoImageV4
                placeHolders={template.placeHolders}
                dataOption={selectedUser}
                greetings={template.greetings}
                extraFields={template.extraFields}
              />
            )}
            {template.customTemplate && <div dangerouslySetInnerHTML={{ __html: template.customTemplate }}></div>}
          </CardContent>
        </Card>
      </Grid>
    </Container>
  )
}

export default TemplatePreviewContainer
