// ** React Imports
import React, { useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'


// ** Icons Imports
import UserIcon from "mdi-material-ui/Account"

// ** Third Party Imports
import { useDropzone } from 'react-dropzone'


// Styled header text
const HeadingTypography = styled(Typography)(({ theme }) => ({
  color: "white",
  fontSize: "0.785rem",
  fontWeight: 600,
  marginTop: 5,
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4)
  }
}))

// Styled img box container
const BoxContainer = styled(Box)(({ theme }) => ({
  textAlign: ['center', 'center', 'inherit']
}))

// Styled component for the upload image dropzone box
const DropZoneBox = styled(Box)(({ theme }) => ({
  minHeight: "60px",
  width: "60px",
  display: "flex",
  flexWrap: "wrap",
  flexDirection: 'column',
  cursor: "pointer",
  position: "relative",
  "-webkit-box-align": "center",
  alignItems: "center",
  "-webkit-box-pack": "center",
  justifyContent: "center",
  //padding: "0.725rem",
  borderRadius: "50%",
  backgroundColor: "rgba(0,0,0,0.25)",
  //border: "2px solid rgba(93, 89, 98, 0.22)",
}))

// Styled component for the upload image inside the dropzone area
const Img = styled('img')(({ theme }) => ({
  objectFit: "cover",
  position: "absolute",
  width: "calc(100% - 8px)",
  height: "calc(100% - 8px)",
  borderRadius: "10px",
  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(10)
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(4)
  },
  [theme.breakpoints.down('sm')]: {
    width: 250
  }
}))

const FileUploader = (props) => {

  // ** Props
  const { file } = props
  
  // ** State
  const [files, setFiles] = useState([])

  // ** Hook
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.gif']
    },
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file)))
      // setForm(prevState => {
      //     return {
      //         ...prevState,
      //         image: Object.assign(acceptedFiles[0])
      //     }
      // })
    }
  })

  const handleLinkClick = event => {
    event.preventDefault()
  }

  const img = files.map(file => (
    <Img key={file.name} alt={file.name} src={URL.createObjectURL(file)} />
  ))

  return (
    <DropZoneBox {...getRootProps({ className: 'dropzone' })} style={{ height: 60 }}>
      <input {...getInputProps()} />
      {files.length ? (
        img
      ) : (
        <BoxContainer>
          <UserIcon sx={{ fontSize: "3rem", color: "common.white" }} />
          {/* <HeadingTypography>
            Upload image
          </HeadingTypography> */}
        </BoxContainer>
      )}
    </DropZoneBox>
  )
}

export default FileUploader