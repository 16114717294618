// ** React Imports
import React, { useEffect, useState } from "react"

// ** Apis Calls
import {
    getAllUsers,
    getTemplates,
    getGroupsListing,
    getOrganizationsList
} from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop'

// ** Custom Components
import AddBulkSignatureDialog from "./dialog/AddBulkSignatureDialog"

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'

// ** Hooks
import { useAuth } from "hooks/useAuth"

// ** Icon Imports
import PlusIcon from 'mdi-material-ui/Plus'
import DeleteIcon from 'mdi-material-ui/TrashCanOutline'
import AlertMessage from "pages/tools/components/CustomAlertMessage"


// ** Styled Box
const Container = styled(Box)(({ theme }) => ({
    padding: "1.425rem",
    backgroundColor: "#fff",
    transition: "all .1s ease-in-out",
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
    },
}
))

// ** Styled Box
const StyledBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.525rem",
    backgroundColor: "#fff",
    transition: "all .1s",
    "&:hover": {
        backgroundColor: theme.palette.grey[300]
    }
}
))

const defaultValues = { entity: null, template: null }


const BulkSignatureUpdate = (props) => {

    // ** Props
    const { activeTab } = props

    // ** Hooks
    const auth = useAuth()
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue
    } = useForm({ defaultValues })

    // ** State
    const [show, setShow] = useState(false)
    const [groups, setGroups] = useState([])
    const [organizations, setOrganizations] = useState([])
    const [users, setUsers] = useState([])
    const [templates, setTemplates] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState([])
    const [signature, setSignature] = useState(null)
    const [message, setMessage] = useState({ success: false, error: false, value: "" })


    // ** Fetch data
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const templatesData = await getTemplates(auth.user?.customerId).then(res => res.data)
                const usersData = await getAllUsers().then(res => res.data?.result)
                setTemplates(templatesData)
                setUsers(usersData)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        fetchData()

    }, [])

    // ** Fetch data ontab changes 
    useEffect(() => {
        setValue("entity", null)
        const fetchGroups = async () => {
            try {
                const groupsData = await getGroupsListing().then(res => res.data.result)
                setGroups(groupsData)
            } catch (error) {
                setGroups([])
            }
        }
        const fetchOrganizations = async () => {
            try {
                const organizationsData = await getOrganizationsList(auth.user.customerId).then(res => res.data.organizationUnits)
                let formattedOrganizationArray = organizationsData
                    ?.sort((organization) => organization.parentOrgUnitPath === "/" ? -1 : 1)
                    ?.reduce((accumulator, organization) => {
                        if (organization.parentOrgUnitPath === "/") {
                            return [...accumulator, { name: organization.name, path: organization.orgUnitPath, parentOrgUnitPath: "", children: [] }]
                        } else {
                            return [...accumulator, { name: organization.name, path: organization.orgUnitPath, parentOrgUnitPath: organization.parentOrgUnitPath, children: [] }]
                        }
                    }, [])
                setOrganizations(formattedOrganizationArray)
            } catch (error) {
                setOrganizations([])
            }
        }
        if (groups.length < 1 && activeTab === 1)
            fetchGroups()
        if (organizations.length < 1 && activeTab === 2)
            fetchOrganizations()
    }, [activeTab])

    // ** Functions
    const toggleHandler = () => {
        if (!show)
            setShow(true)
    }

    const deleteHandler = (item) => {
        setSelectedUsers(state => state.filter(el => el !== item))
    }

    const handleCloseMessage = () => {
        setMessage({ success: false, error: false, value: "" })
    }

    const onSubmit = (data) => {
        const entity = (activeTab === 0 && data.entity.primaryEmail) || (activeTab === 1 && data.entity.email) || (activeTab === 2 && data.entity.name)
        setSelectedUsers([...selectedUsers, entity])
        setSignature(state => ({ template: data.template, selectedUsers: [...(state ? state.selectedUsers : []), data.entity] }))
    }

    return (
        <Container >
                <Grid item container xs={12} sx={{ position: 'relative' }}>
                    <Grid container item xs={12}>
                        <form id="primary-form" onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                            <Grid item container xs={12} lg={10} sx={{ mt: "0.825rem" }} >
                                <Grid item xs={12} md={4} sx={{ display: "flex", alignItems: "center" }}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.825rem", mt: '1rem' }}>
                                        Signature template name:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <FormControl variant='standard' fullWidth>
                                        <Controller
                                            name='template'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange } }) => (
                                                <Autocomplete
                                                    id='autocomplete-grouped'
                                                    getOptionLabel={option => option.name}
                                                    renderInput={params => <TextField {...params} variant="standard" label='Template Name' />}
                                                    options={templates}
                                                    onChange={(event, value) => onChange(value)}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container item xs={10} sx={{ mt: "0.825rem" }} >
                                <Grid item xs={12} md={4} sx={{ display: "flex", alignItems: "center" }}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "0.825rem", mt: '1rem' }}>
                                        Targets:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>

                                    <FormControl variant='standard' fullWidth>
                                        <Controller
                                            name='entity'
                                            control={control}
                                            //rules={{ required: true }}
                                            render={({ field: { value, onChange } }) => (
                                                <Autocomplete
                                                    id='autocomplete-grouped'
                                                    groupBy={option => (activeTab === 2 && option.parentOrgUnitPath)}
                                                    getOptionLabel={option => (activeTab === 0 && option.primaryEmail) || (activeTab === 1 && option.email) || (activeTab === 2 && option.name) || ""}
                                                    renderInput={params => <TextField {...params} variant="standard" label={(activeTab === 0 && "Users") || (activeTab === 1 && "Groups") || (activeTab === 2 && "Organizaitons unit")} />}
                                                    options={(activeTab === 0 && users) || (activeTab === 1 && groups) || (activeTab === 2 && organizations) || []}
                                                    onChange={(event, value) => onChange(value)}
                                                    value={value}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid item xs={10} sx={{ textAlign: "end" }}>
                        <Button
                            sx={{ fontWeight: 600, mt: "2rem" }}
                            variant="contained"
                            size="small"
                            type='submit'
                            form="primary-form"
                            endIcon={<PlusIcon sx={{ fontSize: "0.925rem" }} />}
                        >
                            add
                        </Button>
                    </Grid>
                    <Grid item xs={10} sx={{ mt: "1rem" }}>
                        {selectedUsers.length < 1 &&
                            <Typography variant="body2" sx={{ fontWeight: 600, fontSize: "0.875rem" }}>
                                No users added yet.
                            </Typography>
                        }
                        {selectedUsers?.map((el, index) => (
                            <StyledBox key={index}>
                                <Typography sx={{ fontSize: "0.925rem" }}>
                                    {el}
                                </Typography>
                                <IconButton onClick={() => deleteHandler(el)}>
                                    <DeleteIcon />
                                </IconButton>
                            </StyledBox>
                        ))}
                    </Grid>
                    <Divider sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", width: "100%" }} />
                    <Grid item xs={12} sx={{ mt: '1rem', display: "flex", justifyContent: "end", alignItems: "center" }}>
                        <Button sx={{ fontWeight: 600, color: "text.secondary" }} variant="text" onClick={() => setShow(false)}>
                            CANCEL
                        </Button>
                        <AddBulkSignatureDialog
                            signature={signature}
                            setMessage={setMessage}
                        />
                    </Grid>
                    <Backdrop
                        open={loading == "UPDATE_USER"}
                        sx={{
                            position: 'absolute',
                            backgroundColor: "transparent",
                            backdropFilter: 'blur(5px)',
                            color: theme => theme.palette.common.blue,
                            zIndex: theme => theme.zIndex.mobileStepper - 1,
                        }}
                    >
                        <CircularProgress color='inherit' />
                    </Backdrop>
                </Grid>
            <AlertMessage
                message={message}
                handleCloseMessage={handleCloseMessage}
            />
        </Container>
    )

}


export default BulkSignatureUpdate