// ** React Imports
import React, { useState, useEffect } from 'react'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { cleanMessages, fetchBulkSignatureList, templateSelector } from 'store/template'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'

// ** Component Imports
import ActionsButton from '../components/buttons/ActionsButton'
import CustomTable from '../components/table/CustomTable'
import GoogleSpinner from 'core/components/google-spinner'

// ** Hooks
import { useAuth } from 'hooks/useAuth'
import AlertMessage from 'pages/tools/components/CustomAlertMessage'


// Styled Divider component
const StyledDivider = styled(Divider)(({ theme }) => ({
    borderBottomWidth: 1,
    color: theme.palette.grey[300],
}))


const BulkSignatureTable = () => {


    // ** Hooks
    const dispatch = useDispatch()
    const { user } = useAuth()

    // ** Selectors
    const { bulkSignatures, loading, success, error } = useSelector(templateSelector)


    // ** Fetch users 
    useEffect(() => {
        if (user?.customerId)
            dispatch(fetchBulkSignatureList(user.customerId))
    }, [user])


    // ** Functions
    const handleCloseMessage = () => {
        dispatch(cleanMessages())
    }
   


    return (
        <Card
            sx={{
                '&.MuiPaper-root': {
                    borderRadius: "0px !important",
                    boxShadow: 0,
                    border: theme => `1px solid ${theme.palette.grey[300]}`
                },
                '& .customer-column-header': {
                    backgroundColor: 'common.white',
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 600
                    }
                },
            }}>
            <CardHeader
                sx={{ mb: "-1rem" }}
                title={<Typography>Bulk Signature</Typography>}
                action={<ActionsButton path='/tools/bulk-signatures/update' />}
            />
            <StyledDivider />
            <CustomTable
                data={bulkSignatures}
                columns={["Operation name", "Signature applied", "Created on", "Status", "Actions"]}
                loading={loading}
            />
            {success === "DELETE" && <AlertMessage
                message={{ value: "Signature was deleted successfully", success: true, error: false }}
                handleCloseMessage={handleCloseMessage}
            />}
        </Card>


    )
}

export default BulkSignatureTable
