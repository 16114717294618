// ** React Imports
import React, { Fragment, useState, useEffect } from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { groupsSelector } from 'store/groups';

// ** API CALLS
import { getGroupMemberByQuery, addGroupMembers } from 'configs/axios/api_helper'

// ** Third Party Liraries
import { useDebounce } from 'use-debounce';

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Autocomplete from '@mui/material/Autocomplete'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'


// ** Styled Box 
const StyledBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    boxShadow: theme.shadows[5],
    paddingTop: "0.125rem",
    paddingBottom: "0.125rem",
    paddingRight: "0.725rem",
    paddingLeft: "0.725rem",
    borderRadius: "1rem",
    cursor: "pointer",
    width: "fit-content"
}))

const defaultValues = {
    name: '',
}


const AddDialog = (props) => {

    // ** Props
    const { setGroupMembers, setMessage } = props

    // ** Hooks
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues })

    // ** Selectors
    const { group } = useSelector(groupsSelector);

    // ** State
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([])
    const [query, setQuery] = useState(null)
    const [selectedEmail, setSelectedEmail] = useState(null)
    const [selectedRole, setSelectedRole] = useState(null)


    // ** Debouncing the input value
    const [value] = useDebounce(query, 1000)

    // ** Fetch members by emails query
    useEffect(() => {
        const fetchMembersByQuery = async (query) => {
            try {
                const response = await getGroupMemberByQuery(group.email, query)
                const { data: { users } } = response
                setOptions(users)
            } catch (error) {
                setOptions([])
            }
        }
        if (value) {
            fetchMembersByQuery(value)
        } else {
            setOptions([])
        }
    }, [value])

    // ** Functions
    const handleClickOpen = (e) => {
        e.stopPropagation()
        setOpen(true)
    }
    const handleClose = () => setOpen(false)

    const filterHandler = async (event, email) => {
        const { value } = event.target
        setQuery(value)
    }

    const selectMemberHandler = (event, value) => {
        setSelectedEmail(value)
    }

    const onChangeRoleHandler = (event) => {
        const { value } = event.target
        setSelectedRole(value)
    }

    const onSubmit = async () => {
        setLoading(true)
        try {
            const response = await addGroupMembers(group.email, { obj: { email: selectedEmail, role: selectedRole } })
            const { data } = response
            setGroupMembers(state => { return [...state, { ...data }] })
            setMessage({ success: true, error: false, value: "Member was added successfully" })
            setLoading(false)
            setOpen(false)
        } catch (error) {
            setMessage({ success: false, error: true, value: "There was an error while adding the member" })
            setLoading(false)
        }
    }


    return (
        <Fragment>
            <StyledBox onClick={handleClickOpen}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 36 36">
                    <path fill="#34A853" d="M16 16v14h4V20z"></path>
                    <path fill="#4285F4" d="M30 16H20l-4 4h14z"></path>
                    <path fill="#FBBC05" d="M6 16v4h10l4-4z"></path>
                    <path fill="#EA4335" d="M20 16V6h-4v14z"></path>
                    <path fill="none" d="M0 0h36v36H0z"></path>
                </svg>
                <Typography sx={{ color: "text.secondary", fontSize: "0.825rem", fontWeight: 600 }}>
                    New Member
                </Typography>
            </StyledBox>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle id='alert-dialog-title'>Add new fodler</DialogTitle>
                <DialogContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Autocomplete
                                onInputChange={filterHandler}
                                onChange={selectMemberHandler}
                                options={options.map((option) => option)}
                                sx={{ width: "100%", mb: "1rem", mr: "1rem" }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Member email address"
                                        variant="standard"
                                        InputLabelProps={{ style: { fontSize: "0.875rem" } }} // font size of input label
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant='standard' fullWidth>
                                <InputLabel id='role-label'>Role</InputLabel>
                                <Select
                                    onChange={onChangeRoleHandler}
                                    lebelId="role-label"
                                    defaultValue=""
                                >
                                    <MenuItem value=''><em>None</em></MenuItem>
                                    <MenuItem value="MEMBER">Member</MenuItem>
                                    <MenuItem value="OWNER">Owner</MenuItem>
                                    <MenuItem value="MANAGER">Manager</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className='dialog-actions-dense' sx={{ mt: "1rem" }}>
                    <Button
                        disabled={loading}
                        onClick={handleClose}
                        size="small"
                        variant="text"
                        color="inherit"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={loading || !selectedEmail || !selectedRole}
                        endIcon={(loading ? <CircularProgress sx={{ color: 'common.white', mt: 0 }} size="0.825rem" /> : null)}
                        onClick={onSubmit}
                        variant="contained"
                        size="small"
                        sx={{ fontWeight: 600, primary: "common.blue", "&:disabled": { cursor: "not-allowed !important" } }}
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default AddDialog