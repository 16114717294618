// ** React Imports
import React from 'react'

const TemplateNoImageV4 = (props) => {

    // ** Props
    const { color, data, extraFields, greetings } = props

    // Split placeHolders array to objects
    let name = data?.find(el => el.name === "name")
    let jobTitle = data?.find(el => el.name === "jobTitle")
    let department = data?.find(el => el.name === "department")


    return (
        <div
            style={{
                height: "300px",
                width: "650px",
                borderRadius: "8px",
                backgroundColor: "#FFF",
            }}
        >
            <div
                style={{
                    marginTop: "2rem",
                    marginLeft: "2rem",
                }}>
                <text
                    style={{
                        color: "#8F8F8F",
                        letterSpacing: 5,
                        fontSize: "1.725rem"
                    }}
                >
                    {greetings}
                </text>
            </div>
            <div
                style={{
                    marginTop: "2rem",
                    paddingLeft: "2rem",

                }}
            >
                <div
                    style={{
                        paddingLeft: "1rem",
                        color: "#8F8F8F",
                        display: "inline-block",
                        width: "400px",
                        verticalAlign: "top"
                    }}
                >
                    <div

                    >
                        <text
                            style={{
                                fontWeight: 600,
                                fontSize: "1.125rem",
                                color: "#0000FF"
                            }}
                        >
                            {"{|" + name?.name + "-" + name?.subType + "|}"}
                        </text>
                        <br />
                        <text
                            style={{
                                fontSize: "0.825rem",
                                fontWeight: 600
                            }}
                        >
                            {"{|" + jobTitle?.name + "-" + jobTitle?.subType + "|}"} ,
                        </text>
                        <text
                            style={{
                                fontSize: "0.825rem",
                                fontWeight: 600
                            }}
                        >
                            {"{|" + department?.name + "-" + department?.subType + "|}"}
                        </text>
                    </div>
                    <div
                        style={{
                            marginTop: "1rem",
                            paddingBottom: "10px",
                            fontSize: "0.725rem",
                        }}
                    >
                        {extraFields?.map((el, index) => (
                            <div key={index} style={{
                                marginBottom: 5
                            }}>
                                <span style={{
                                    color: "#0000FF",
                                    fontWeight: 700,
                                }}>
                                    {el.label} :
                                </span>
                                <span>
                                    &nbsp;&nbsp;{"{|" + el?.name + "-" + el.subType + "|}"}&nbsp;&nbsp;
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TemplateNoImageV4