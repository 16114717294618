// ** React Imports
import React, { Fragment, useState, useEffect } from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { usersSelector } from 'store/users'

// ** API CALLS
import {
  getAllUsers,
  getSharedUsers,
  addSharedUser,
  removeSharedUser,
  addSharedUserPermission
} from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'

// ** Custom Components
import CustomAvatar from 'core/components/mui/avatar'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'

// ** Icon Imports
import ShareIcon from 'mdi-material-ui/ShareVariantOutline'

const ShareDialog = props => {
  // ** Props
  const { setFolders, setMessage, folder, fetchFoldersData } = props

  // ** Hooks
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm({ defaultValues: { role: 'reader', user: '' } })

  // ** Selectors
  const { user } = useSelector(usersSelector)

  // ** State
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [sharedList, setSharedList] = useState([]) // state which hold the users that are currently sharing access to this folder

  // ** Fetch Users
  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const usersData = await getAllUsers().then(res => res.data?.result)
        setUsers(usersData || [])
      } catch (error) {
        setUsers([])
      }
    }
    const fetchFolderSharedUsers = async () => {
      try {
        const data = await getSharedUsers(user?.id, folder?.id).then(res => res.data?.permissions)
        setSharedList(data || [])
      } catch (error) {
        setUsers([])
      }
    }

    if (open && users.length < 1) {
      fetchUsersData()
    }
    if (open) {
      fetchFolderSharedUsers()
    }
  }, [open])

  // ** Functions
  const handleClickOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  const onChangeRoleHandler = async (event, sharedUser) => {
    const { value } = event.target
    if (value === 'delete') {
      try {
        setLoading(true)
        const response = await removeSharedUser(user?.id, folder?.id, sharedUser?.id)
        setSharedList(state => state.filter(el => el.id !== sharedUser.id))
        setMessage({ success: true, error: false, value: 'User was removed from the folder access successfully' })
        fetchFoldersData()
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    } else {
      try {
        const data = await addSharedUserPermission(user?.id, folder?.id, sharedUser?.id, { role: value })
        setSharedList(state => {
          return state.map(user => (user.id.toString() === data.id.toString() ? { user, ...data } : user))
        })
        setMessage({ success: true, error: false, value: 'User role was updated successfully' })
      } catch (error) {
        if (sharedUser?.role === 'owner')
          setMessage({ success: false, error: true, value: "You can't change the owner role of the folder" })
        else setMessage({ success: false, error: true, value: 'There was a problem when updating the user role' })
      }
    }
  }

  const onSubmit = async body => {
    try {
      setLoading(true)
      const data = await addSharedUser(user.id, folder.id, {
        obj: [{ emailAddress: body.user?.primaryEmail, role: body?.role, type: 'user' }]
      })
      setTimeout(() => fetchFoldersData(), 1000)
      const shareUserResponse = await getSharedUsers(user?.id, folder?.id).then(res => res.data?.permissions)
      setSharedList(shareUserResponse)
      setMessage({ success: true, error: false, value: 'User was added to the folder successfully' })
      setLoading(false)
      setOpen(false)
    } catch (error) {
      setMessage({ success: false, error: true, value: 'There was an error while adding the user to the folder' })
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <IconButton size='medium' edge='start' sx={{ color: 'text.secondary', mx: '0.325rem' }} onClick={handleClickOpen}>
        <ShareIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle id='alert-dialog-title'>Share folder</DialogTitle>
        <DialogContent>
          <form id='share-folder-form' onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
              <Grid item xs={8}>
                <FormControl variant='standard' fullWidth>
                  <Controller
                    name='user'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete
                        id='autocomplete-grouped'
                        getOptionLabel={option => option.primaryEmail || ''}
                        renderInput={params => <TextField {...params} variant='standard' label='Users' />}
                        options={users}
                        onChange={(event, value) => onChange(value)}
                        onInputChange={(e, data) => onChange({ primaryEmail: data })}
                        value={value}
                        freeSolo
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth variant='standard'>
                  <InputLabel id='role-label'>Role</InputLabel>
                  <Controller
                    name='role'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Select labelId='role-label' id='select-role' value={value} label='Role' onChange={onChange}>
                        <MenuItem value='reader'>Viewer</MenuItem>
                        <MenuItem value='commenter'>Commenter</MenuItem>
                        <MenuItem value='writer'>Editor</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </form>
          <Grid container spacing={4} sx={{ mt: '1rem' }}>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 600, fontSize: '0.925rem' }}>Shared with</Typography>
            </Grid>
            {sharedList.map(sharedUser =>
              sharedUser?.type === 'anyone' ? (
                <></>
              ) : (
                <Grid
                  key={sharedUser?.id}
                  item
                  xs={12}
                  sx={{
                    mt: '0.625rem',
                    px: '1rem',
                    py: '0.625rem',
                    display: 'flex',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: theme => theme.palette.grey[200] }
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CustomAvatar
                      src={sharedUser?.photoLink || '/'}
                      sx={{ mr: 3, width: '2.875rem', height: '2.875rem' }}
                    />
                    <Box>
                      <Typography sx={{ fontSize: '0.875rem', fontWeight: 600 }}>
                        {sharedUser?.displayName || '----'}
                      </Typography>
                      <Typography sx={{ fontSize: '0.875rem' }}>{sharedUser?.emailAddress || '----'}</Typography>
                    </Box>
                  </Box>
                  <Box>
                    <FormControl fullWidth variant='standard'>
                      <InputLabel id='role-label'>Role</InputLabel>
                      <Select
                        labelId='role-label'
                        id='select-role'
                        value={sharedUser?.role}
                        label='Role'
                        onChange={event => onChangeRoleHandler(event, sharedUser)}
                      >
                        <MenuItem value='reader'>Viewer</MenuItem>
                        <MenuItem value='commenter'>Commenter</MenuItem>
                        <MenuItem value='writer'>Editor</MenuItem>
                        <MenuItem value='owner'>Owner</MenuItem>
                        <MenuItem value='delete'>Remove</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              )
            )}
            {sharedList?.some(el => el.type === 'anyone') && (
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '0.875rem', fontWeight: 600 }}>Anyone can view this file.</Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions className='dialog-actions-dense' sx={{ mt: '1rem' }}>
          <Button disabled={loading} onClick={handleClose} size='small' variant='text' color='inherit'>
            Cancel
          </Button>
          <Button
            disabled={loading}
            endIcon={loading ? <CircularProgress sx={{ color: 'common.white', mt: 0 }} size='0.825rem' /> : null}
            type='submit'
            form='share-folder-form'
            variant='contained'
            size='small'
            sx={{ fontWeight: 600, primary: 'common.blue' }}
          >
            Share
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default ShareDialog
