
// Redux Imports
import { createSlice } from '@reduxjs/toolkit';

// ** APIs
import { getTemplates, deleteTemplate, getBulkSignatures } from 'configs/axios/api_helper';


export const initialState = {
    loading: null,
    bulkSignatures: [],
    templates: [],
    template: {},
    selectedTemplate: 0,
    customTemplate: "",
    success: "",
    error: "",
};
// A slice
const templateSlice = createSlice({
    name: 'template',
    initialState,
    reducers: {
        startLoading: (state, action) => {
            state.loading = action.payload
            state.error = ""
            state.success = ""
        },
        saveTemplateSuccess: (state, action) => {
            state.template = action.payload
            state.loading = null
            state.success = 'SAVE'
        },
        saveSelectTemplateSuccess: (state, action) => {
            state.selectedTemplate = action.payload
            state.loading = null
            state.success = 'SAVE'
        },
        getTemplatesSuccess: (state, action) => {
            state.templates = action.payload
            state.loading = null
            state.success = 'GET'
        },
        setTemplatesSuccess: (state, action) => {
            state.template = action.payload
            state.loading = null
            state.success = 'SET'
        },
        getBulkSignaturesSuccess: (state, action) => {
            state.bulkSignatures = action.payload
            state.loading = null
            state.success = 'GET_BULK'
        },
        saveTemplateFailure: (state) => {
            state.loading = null
            state.error = "ERROR"
            state.success = ""
        },
        deleteSelectTemplateSuccess: (state, action) => {
            state.templates = state.templates.filter(el => el.uid !== action.payload)
            state.loading = null
            state.success = 'DELETE'
        },
        setErrorMessage: (state, action) => {
            state.loading = null
            state.error = action.payload
            state.success = ""
        },
        resetMessages: (state) => {
            state.success = ""
            state.error = ""
            state.loading = null
        }
    },
});

// Actions generated from the slice
const {
    resetMessages,
    startLoading,
    saveTemplateSuccess,
    saveTemplateFailure,
    saveSelectTemplateSuccess,
    setErrorMessage,
    deleteSelectTemplateSuccess,
    getTemplatesSuccess,
    getBulkSignaturesSuccess,
    setTemplatesSuccess
} = templateSlice.actions;

// export user selector to get the slice in any component
export const templateSelector = (state) => state.templateData;


// export The reducer
const templateReducer = templateSlice.reducer;
export default templateReducer;



// Actions
export const fetchTemplates = (id) => async (dispatch) => {
    dispatch(startLoading("GET"));
    try {
        const response = await getTemplates(id)
        const { data } = response
        dispatch(getTemplatesSuccess(data))
    } catch (error) {
        dispatch(saveTemplateFailure())
    }
}

export const setTemplate = (data) => async (dispatch) => {
    dispatch(startLoading("SET"));
    try {
        dispatch(setTemplatesSuccess(data))
    } catch (error) {
        dispatch(saveTemplateFailure())
    }
}

export const fetchBulkSignatureList = (id) => async (dispatch) => {
    dispatch(startLoading("GET"));
    try {
        const response = await getBulkSignatures(id)
        const { data } = response
        dispatch(getBulkSignaturesSuccess(data[0] || []))
    } catch (error) {
        dispatch(saveTemplateFailure())
    }
}

export const saveTemplate = (data) => async (dispatch) => {
    dispatch(startLoading("SAVE"));
    try {
        dispatch(saveTemplateSuccess(data))
    } catch (error) {
        dispatch(saveTemplateFailure())
    }
}

export const selectTemplate = (template) => async (dispatch) => {
    dispatch(startLoading("SAVE"));
    try {
        dispatch(saveSelectTemplateSuccess(template))
    } catch (error) {
        dispatch(setErrorMessage())
    }
}

export const removeTemplate = (uid) => async (dispatch) => {
    dispatch(startLoading("DELETE"));
    try {
        const response = await deleteTemplate(uid)
        dispatch(deleteSelectTemplateSuccess(uid))
    } catch (error) {
        dispatch(setErrorMessage())
    }
}

export const cleanMessages = () => (dispatch) => {
    dispatch(resetMessages())
}
