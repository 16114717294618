// ** React Imports
import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

// ** Moment
import moment from 'moment'

// ** Custom Components
import SharedUsersDialog from '../dialog/SharedUsersDialog'
import SharedDriveSettingsDialog from '../dialog/SharedDriveSettingsDialog'
import DeleteSharedDrive from '../dialog/DeleteSharedDrive'

const Row = props => {

    // ** Hooks 
    const navigate = useNavigate()

    // ** Props
    const { row, setMessage, setSharedDrives } = props

    // ** Redirect to customer display
    const redirectHandler = (email) => {
        //navigate("/groups-management/group-details/" + email)
    }


    return (
        <Fragment key={row.id}>
            <TableRow sx={{ '& > *': {}, cursor: 'pointer' }}>
                <TableCell component='th' scope='row' sx={{ color: 'common.blue' }}>
                    {row.name}
                </TableCell>
                <TableCell>{moment(new Date(row.createdTime)).format("MMM DD, YYYY")}</TableCell>
                <TableCell sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                    <SharedUsersDialog folder={row} setMessage={setMessage} />
                    <SharedDriveSettingsDialog folder={row} setMessage={setMessage} />
                    <DeleteSharedDrive folder={row} setMessage={setMessage} setSharedDrives={setSharedDrives} />
                </TableCell>
            </TableRow>
        </Fragment>
    )
}


export default Row