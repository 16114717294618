// ** React imports
import React, { useState } from 'react'

// ** Redux Imports
import { editUser, usersSelector } from 'store/users'
import { useDispatch, useSelector } from 'react-redux'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import FormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Checkbox from '@mui/material/Checkbox'

import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

// ** Icons Imports
import EyeOutline from 'mdi-material-ui/EyeOutline'
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline'
import KeyIcon from "mdi-material-ui/ShieldKeyOutline"

// ** Hooks
import { useAuth } from 'hooks/useAuth'

// ** Styled Box
const Container = styled(Box, { shouldForwardProp: (prop) => prop !== "hover" })(({ theme, hover }) => ({
    padding: "1.425rem",
    cursor: "pointer",
    backgroundColor: "#fff",
    transition: "all .1s ease-in-out",
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
    },
    ...(hover ? {
        boxShadow: theme.shadows[10],
        margin: "12px -12px",
        cursor: "default"
    } : {}),
    ...(!hover ? { "&:hover": { backgroundColor: theme.palette.grey[100] } } : {})
}
))

const defaultValues = {
    password: '',
    changePasswordAtNextLogin: false,
}

const showErrors = (field, valueLen, min) => {
    if (valueLen === 0) {
        return `${field} field is required`
    } else if (valueLen > 0 && valueLen < min) {
        return `${field} must be at least ${min} characters`
    } else {
        return ''
    }
}


const schema = yup.object().shape({
    password: yup
        .string()
        .min(8, obj => showErrors('password', obj.value.length, obj.min))
        .required()
})


const PasswordCard = (props) => {

    // ** Hooks
    const auth = useAuth()
    const dispatch = useDispatch()
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(schema)
    })


    // ** Selectors
    const { user, loading, success, error } = useSelector(usersSelector);

    // ** State
    const [show, setShow] = useState(false)
    const [state, setState] = useState({
        password: '',
        showPassword: false
    })
    // ** Functions
    const toggleHandler = () => {
        if (!show)
            setShow(true)
    }
    const handleClickShowPassword = () => {
        setState({ ...state, showPassword: !state.showPassword })
    }

    const handleMouseDownPassword = event => {
        event.preventDefault()
    }

    const generateRandomPassword = () => setValue("password", Math.random().toString(36).slice(-8))

    const onSubmit = (data) => {
        dispatch(editUser(user.id, data))
        setShow(false)

    }



    return (
        <Container hover={show} onClick={toggleHandler}>
            {show ?
                <Grid item container xs={12} sx={{ position: "relative" }}>
                    <Grid item xl={3}>
                        <Typography sx={{ fontWeight: 600, fontSize: "0.775rem" }}>
                            Password
                        </Typography>
                    </Grid>
                    <Grid item xs={12} xl={6} sx={{ py: "0.5rem" }}>
                        <form id="password-form" onSubmit={handleSubmit(onSubmit)}>
                            <Grid item xl={8} sx={{ py: '1rem' }}>
                                <FormControl>
                                    <Controller
                                        name='password'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <TextField
                                                variant="standard"
                                                value={value}
                                                label='Password'
                                                onChange={onChange}
                                                id='validation-basic-password'
                                                error={Boolean(errors.password)}
                                                type={state.showPassword ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            <IconButton onClick={generateRandomPassword}>
                                                                <KeyIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                edge='end'
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                aria-label='toggle password visibility'
                                                            >
                                                                {state.showPassword ? <EyeOutline /> : <EyeOffOutline />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.password && (
                                        <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-password'>
                                            {errors.password.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xl={8} sx={{ py: '1rem' }}>
                                <FormControl>
                                    <Controller
                                        name='changePasswordAtNextLogin'
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                label="Require user to change password at next sign-in."
                                                sx={errors.checkbox ? { color: 'error.main' } : null}
                                                control={
                                                    <Checkbox
                                                        {...field}
                                                        sx={errors.checkbox ? { color: 'error.main' } : null}
                                                    />
                                                }
                                            />
                                        )}
                                    />

                                </FormControl>
                            </Grid>
                        </form>
                    </Grid>
                    <Divider sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", width: "100%" }} />
                    <Grid item xs={12} sx={{ textAlign: "end", mt: '1rem' }}>
                        <Button sx={{ fontWeight: 600, color: "text.secondary" }} variant="text" onClick={() => setShow(false)}>
                            CANCEL
                        </Button>
                        <Button
                            type='submit'
                            form="password-form"
                            variant="text"
                            sx={{ fontWeight: 600 }}
                        >
                            SAVE
                        </Button>
                    </Grid>
                    <Backdrop
                        open={loading == "UPDATE_USER"}
                        sx={{
                            position: 'absolute',
                            backgroundColor: "transparent",
                            backdropFilter: 'blur(5px)',
                            color: theme => theme.palette.common.blue,
                            zIndex: theme => theme.zIndex.mobileStepper - 1,
                        }}
                    >
                        <CircularProgress color='inherit' />
                    </Backdrop>
                </Grid >
                :
                <Grid container item xs={12} spacing={6}>
                    <Grid item xs={12} xl={3}>
                        <Typography sx={{ fontWeight: 600, fontSize: "0.775rem" }}>
                            Password Information
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} xl={8} spacing={6}>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ fontSize: "0.775rem" }}>
                                Change your password
                            </Typography>
                            <Typography sx={{ fontSize: "0.925rem" }}>
                                *********
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Container >
    )

}

export default PasswordCard