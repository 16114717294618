// ** React Imports
import React from 'react'

// ** MUI Imports
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import Typography from '@mui/material/Typography'

// ** Icon Imports
import GroupOwners from 'mdi-material-ui/AccountOutline'
import GroupManagers from 'mdi-material-ui/Account'
import GroupMembers from 'mdi-material-ui/AccountMultiple'
import Organization from 'mdi-material-ui/Domain'
import External from 'mdi-material-ui/Web'
import CheckIcon from 'mdi-material-ui/Check'


const PermissionsTablePreview = (props) => {

    // ** Props
    const { groupSettings } = props

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontSize: "0.875rem !important", color: "text.secondary", textTransform: "none" }}>Access Settings</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <GroupOwners sx={{ color: "text.secondary" }} />
                            <Typography sx={{ fontSize: "0.725rem !important", color: "text.secondary", textTransform: "none" }}>
                                Group<br /> Owners
                            </Typography>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <GroupManagers sx={{ color: "text.secondary" }} />
                            <Typography sx={{ fontSize: "0.725rem !important", color: "text.secondary", textTransform: "none" }}>
                                Group<br /> Managers
                            </Typography>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <GroupMembers sx={{ color: "text.secondary" }} />
                            <Typography sx={{ fontSize: "0.725rem !important", color: "text.secondary", textTransform: "none" }}>
                                Group<br /> Members
                            </Typography>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <Organization sx={{ color: "text.secondary" }} />
                            <Typography sx={{ fontSize: "0.725rem !important", color: "text.secondary", textTransform: "none" }}>
                                Entire<br /> Organization
                            </Typography>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <External sx={{ color: "text.secondary" }} />
                            <Typography sx={{ fontSize: "0.725rem !important", color: "text.secondary", textTransform: "none" }}>
                                External
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell sx={{ color: "text.secondary", fontSize: "0.825rem !important" }}>
                            Who can contact group owners
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ color: "text.secondary", fontSize: "0.825rem !important" }}>
                            Who can view topics
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ color: "text.secondary", fontSize: "0.825rem !important" }}>
                            Who can moderate content
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ color: "text.secondary", fontSize: "0.825rem !important" }}>
                            Who can moderate members
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ color: "text.secondary", fontSize: "0.825rem !important" }}>
                            Who can post
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ color: "text.secondary", fontSize: "0.825rem !important" }}>
                            Who can view members
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ color: "text.secondary", fontSize: "0.825rem !important" }}>
                            Who can manager members<br /> Add, invite, approve
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                            <CheckIcon sx={{ color: "text.secondary" }} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default PermissionsTablePreview