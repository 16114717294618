// ** React Imports
import React, { Fragment, useState } from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { usersSelector } from 'store/users'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import Autocomplete from '@mui/material/Autocomplete'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

// ** Icon Imports
import DeleteIcon from 'mdi-material-ui/Delete'
import { addDelegation, getDelegationListByQuery } from 'configs/axios/api_helper'



const AddDialog = (props) => {

    // ** Props
    const { setDelegates, setMessage } = props

    // ** Selectors
    const { user } = useSelector(usersSelector);

    // ** State
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState(null)
    const [options, setOptions] = useState([])

    // ** Functions
    const handleClickOpen = (e) => {
        e.stopPropagation()
        setOpen(true)
    }
    const handleClose = () => setOpen(false)

    const filterHandler = async (event, email) => {
        const { value } = event.target
        if (email) {
            setEmail(email)
        }
        if (value) {
            const response = await getDelegationListByQuery(user.primaryEmail, value)
            const { data: { users } } = response
            setOptions(users)
        } else {
            setOptions([])
        }
    }


    const onSubmit = async () => {
        setLoading(true)
        try {
            const response = await addDelegation(user.primaryEmail, { delegateEmail: email })
            const { data } = response
            setDelegates(state => { return [...state, { ...data, id: state.length }] })
            setMessage({ success: "ADD_DELEGATE", error: false })
            setLoading(false)
            setOpen(false)
        } catch (error) {
            setMessage({ success: "", error: "ERROR_ADD" })
            setLoading(false)
        }
    }


    return (
        <Fragment>
            <Button
                size="medium"
                variant="text"
                sx={{ fontWeight: 600, ml: "1.325rem", mb: "1rem" }}
                onClick={handleClickOpen}
            >
                Add Delegate
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle id='alert-dialog-title'>Add new delegate</DialogTitle>
                <DialogContent>
                    <Grid item xs={12}>
                        <Autocomplete
                            freeSolo
                            onInputChange={filterHandler}
                            options={options.map((option) => option)}
                            sx={{ width: "100%", mb: "1rem", mr: "1rem" }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Delegates email address"
                                    variant="standard"
                                    InputLabelProps={{ style: { fontSize: "0.875rem" } }} // font size of input label
                                />
                            )}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions className='dialog-actions-dense' sx={{ mt: "1rem" }}>
                    <Button
                        disabled={loading}
                        onClick={handleClose}
                        size="small"
                        variant="text"
                        color="inherit"
                    >
                        Cancel
                    </Button>
                    <Button

                        disabled={loading}
                        endIcon={(loading ?
                            <CircularProgress sx={{ color: 'common.white', mt: 0 }} size="0.825rem" /> : null
                        )}
                        onClick={onSubmit}
                        variant="contained"
                        size="small"
                        sx={{ fontWeight: 600, primary: "common.blue" }}
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default AddDialog