// ** React Imports
import React from 'react'

const TemplateV3 = (props) => {

    // ** Props
    const { color, data, extraFields, greetings } = props

    // Split placeHolders array to objects
    let name = data.find(el => el.name === "name")
    let jobTitle = data.find(el => el.name === "jobTitle")
    let department = data.find(el => el.name === "department")


    return (
        <div
            style={{
                height: "250px",
                width: "650px",
                borderRadius: "8px",
                backgroundColor: "#FFF",
                // boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
            }}
        >
            <div
                style={{
                    marginTop: "2rem",
                    marginLeft: "2rem",
                }}>
                <text
                    style={{
                        color: "#8F8F8F",
                        letterSpacing: 5,
                        fontSize: "1.725rem"
                    }}
                >
                    {greetings}
                </text>
            </div>
            <div
                style={{
                    marginTop: "2rem",
                    paddingLeft: "1rem",
                    position: "relative"
                    // display: "flex",
                    // justifyContent: "start"
                }}
            >

                <div
                    style={{
                        borderRadius: "10px",
                        width: "85px",
                        display: "inline-block"
                    }}
                >
                    <img alt="" height={85} width={85} src={"{|avatar|}"} />
                </div>

                <div
                    style={{
                        paddingLeft: "1rem",
                        color: "#8F8F8F",
                        display: "inline-block",
                        width: "400px",
                        verticalAlign: "top"
                    }}
                >
                    <div
                        style={{

                            paddingBottom: "10px",
                            borderBottom: "1.5px solid #8F8F8F",

                        }}
                    >
                        <text
                            style={{
                                fontWeight: 600,
                                fontSize: "1.125rem",
                                color: "0000FF"
                            }}
                        >
                            {"{|" + name?.name + "-" + name?.subType + "|}"}
                        </text>
                        <br />
                        <text
                            style={{
                                fontSize: "0.825rem",
                                fontWeight: 600
                            }}
                        >
                            {"{|" + jobTitle?.name + "-" + jobTitle?.subType + "|}"} , {"{|" + department?.name + "-" + department?.subType + "|}"}
                        </text>
                    </div>
                    <div
                        style={{
                            marginTop: "1rem",
                            paddingBottom: "10px",
                            fontSize: "0.725rem",
                            ...(extraFields?.length > 0 && { borderBottom: "1.5px solid #8F8F8F" }),
                            // display: "flex",
                            // flexDirection: "column"
                        }}
                    >
                        {extraFields?.map((el, index) => (
                            <span key={index}>
                                <span style={{
                                    color: "0000FF",
                                    fontWeight: 700,
                                }}>
                                    {el.label} :
                                </span>
                                <span>
                                    &nbsp;&nbsp;{"{|" + el?.name + "-" + el.subType + "|}"}&nbsp;&nbsp;
                                </span>
                                {(index === 1 || index === 3 || index === 5 || index === 7 || index === 9 || index === 11) && <br />}
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TemplateV3