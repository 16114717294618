// ** React Imports
import React, { Fragment, useState } from 'react'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'
import CircularProgress from '@mui/material/CircularProgress'

// ** 3rd Party Libraries
import { useDebouncedCallback } from 'use-debounce'

// ** Hooks
import { useSettings } from 'core/hooks/useSettings'

// ** Icons Imports
import DoneIcon from 'mdi-material-ui/Check'

const StoragePreference = props => {
  // ** Props
  const { setBucketName, email } = props

  // ** Hooks
  const { saveSettings } = useSettings()

  // ** State
  const [open, setOpen] = useState(true)
  const [value, setValue] = useState('app-hosted')
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  // ** Functions
  const checkBucketHandler = async (bucketName, email) => {
    setLoading(true)
    setSuccess(null)
    const access = await fetch(`${process.env.REACT_APP_GAM_BASE_URL}/validate-bucket-access/${bucketName}/${email}/`)
      .then(res => res.json())
      .catch(() => ({ message: '0' }))
    if (access.message === '1') {
      setSuccess(true)
      setError(null)
      setLoading(false)
    } else {
      setError('This bucket is not valid. Please enter a valid bucket name.')
      setLoading(false)
    }
  }

  const debounced = useDebouncedCallback(
    event => {
      const { name, value } = event.target
      if (value) {
        setBucketName(value)
        checkBucketHandler(value, email)
      } else {
        setError('Bucket name is required')
      }
      setName(value)
    },
    700,
    // The maximum time func is allowed to be delayed before it's invoked:
    { maxWait: 3000 }
  )

  const handleChange = event => {
    if (event.target.value === 'app-hosted') {
      setError(null)
      setSuccess(null)
      setName('')
      setBucketName('')
    }
    setValue(event.target.value)
  }

  const handleClose = (event, type) => {
    if (type === 'backdropClick') return
    else {
      setOpen(false)
      saveSettings(state => ({ ...state, consoleLine: false }))
    }
  }
  const handleSave = () => {
    if (value === 'self-hosted' && (!name || error)) {
      setError('Bucket name is required.')
      return
    } else {
      setOpen(false)
    }
  }

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle id='alert-dialog-title' fontWeight={600}>
          Choose your storage preference :
        </DialogTitle>
        <DialogContent>
          <FormControl sx={{ flexWrap: 'wrap', flexDirection: 'column' }}>
            <RadioGroup value={value} name='preference-radio' onChange={handleChange} aria-label='prefrence-radio'>
              <FormControlLabel value='app-hosted' control={<Radio />} label='App Hosted Bucket' />
              <FormControlLabel value='self-hosted' control={<Radio />} label='Self Hosted Bucket' />
            </RadioGroup>
          </FormControl>
          {value === 'self-hosted' ? (
            <Box mt={5}>
              <TextField
                autoFocus
                fullWidth
                placeholder='Enter bucket name...'
                onChange={debounced}
                error={error}
                helperText={
                  error ? (
                    <FormHelperText sx={{ ml: -2, color: 'error.main' }}>{error}</FormHelperText>
                  ) : success ? (
                    <FormHelperText sx={{ ml: -2, color: 'primary.main' }}>Valid bucket name.</FormHelperText>
                  ) : null
                }
                InputProps={{
                  endAdornment: loading ? (
                    <InputAdornment position='end'>
                      <CircularProgress sx={{ color: 'primary.main', mt: 0 }} size='1.25rem' />
                    </InputAdornment>
                  ) : success ? (
                    <InputAdornment position='end'>
                      <DoneIcon sx={{ color: 'primary.main' }} size='1.25rem' />
                    </InputAdornment>
                  ) : null
                }}
              />
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions className='dialog-actions-dense'>
          <Button onClick={handleClose}>Close</Button>
          <Button disabled={error} onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default StoragePreference
