// ** React Imports
import React, { useState, useEffect } from 'react'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'

// ** APIs
import { getGroupTemplates } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import InputAdornment from '@mui/material/InputAdornment'
import Divider from '@mui/material/Divider'

// ** 3rd Party Libraries
import { useDebouncedCallback } from "use-debounce"

// ** Icons Imports
import SearchIcon from 'mdi-material-ui/Magnify'


// ** Component Imports
import ActionsButton from '../components/buttons/ActionsButton'
import CustomTable from '../components/group-template-table/CustomTable'
import AddGroupTemplateDialog from '../components/dialog/AddGroupTemplateDialog'


// Styled Divider component
const StyledDivider = styled(Divider)(({ theme }) => ({
    borderBottomWidth: 1,
    color: theme.palette.grey[300],
}))


const GroupsTemplateTable = () => {


    // ** Hooks
    const dispatch = useDispatch()

    // ** State
    const [open, setOpen] = useState(null)
    const [groupTemplates, setGroupTemplate] = useState([])
    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState({
        limit: 10,
        page: 0,
        query: "",
    })



    // ** Fetch users 
    useEffect(() => {
        const fetchGroupTemplate = async () => {
            setLoading(true)
            try {
                const response = await getGroupTemplates()
                const { data } = response
                setGroupTemplate(data)
                setLoading(false)
            } catch (error) {
                setLoading(false)
                setGroupTemplate([])
            }
        }
        fetchGroupTemplate()

    }, [])

    // ** Functions
    const handleChangeRowsPerPage = (event) => {

    }



    const handleChangePage = (event, newPage) => {

    }




    return (
        <Card
            sx={{
                '&.MuiPaper-root': {
                    borderRadius: "0px !important",
                    boxShadow: 0,
                    border: theme => `1px solid ${theme.palette.grey[300]}`
                },
                '& .customer-column-header': {
                    backgroundColor: 'common.white',
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 600
                    }
                },
            }}>
            <CardHeader
                title={<Typography>Groups Templates</Typography>}
                action={<AddGroupTemplateDialog setGroupTemplate={setGroupTemplate} />}
            />
            <StyledDivider />
            <CustomTable
                data={groupTemplates || []}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangePage={handleChangePage}
                columns={["Template Name", "Description", "Created On", "Action"]}
                nextPageToken={""}
                loading={loading}
                page={filters.page}
                rowsPerPage={filters.limit}
                setGroupTemplate={setGroupTemplate}
            />
        </Card>


    )
}

export default GroupsTemplateTable
