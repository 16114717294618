// ** React Imports
import React, { useEffect, useState } from "react"

// ** APIs
import { updateGroupSettings } from "configs/axios/api_helper"

// ** Redux Imports
import { useDispatch, useSelector } from "react-redux"
import { editGroupSettings, groupsSelector } from "store/groups"

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Autocomplete from '@mui/material/Autocomplete'

// ** Custom Components
import CustomAlertMessage from 'pages/directory/components/alert/CustomAlertMessage'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'

// ** Styled Box
const Container = styled(Box, { shouldForwardProp: (prop) => prop !== "hover" })(({ theme, hover }) => ({
    padding: "1.425rem",
    cursor: "pointer",
    backgroundColor: "#fff",
    transition: "all .2s ease-in-out",
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
    },
    ...(hover ? {
        boxShadow: theme.shadows[10],
        margin: "12px -12px",
        cursor: "default"
    } : {}),
    ...(!hover ? { "&:hover": { backgroundColor: theme.palette.grey[100] } } : {})
}
))

const languages = [
    { value: "", label: "None" },
    { value: "arabic", label: "Arabic" },
    { value: "bengali", label: "Bengali" },
    { value: "bulgarian", label: "Bulgarian" },
    { value: "catalan", label: "Catalan" },
    { value: "chinese-simplified", label: "Chinese-Simplified" },
    { value: "chinese-traditional", label: "Chinese-Traditional" },
    { value: "croatian", label: "Croatian" },
    { value: "czech", label: "Czech" },
    { value: "danish", label: "Danish" },
    { value: "dutch", label: "Dutch" },
    { value: "english-us", label: "English-United States" },
    { value: "english-uk", label: "English-United Kingdom" },
    { value: "estonian", label: "Estonian" },
    { value: "finnish", label: "Finnish" },
    { value: "french", label: "French" },
    { value: "german", label: "German" },
    { value: "greek", label: "Greek" },
    { value: "gujrati", label: "Gujrati" },
    { value: "hebrew", label: "Hebrew" },
    { value: "hindi", label: "Hindi" },
    { value: "hungarian", label: "Hungarian" },
    { value: "icelandic", label: "Icelandic" },
    { value: "indonesian", label: "Indonesian" },
    { value: "italian", label: "Italian" },
    { value: "japanese", label: "Japanese" },
    { value: "kannad", label: "Kannad" },
    { value: "korean", label: "Korean" },
    { value: "latvian", label: "Latvian" },
    { value: "lithunian", label: "Lithunian" },
    { value: "malay", label: "Malay" },
    { value: "malyalam", label: "Malyalam" },
    { value: "norwegian", label: "Norwegian" },
    { value: "oriya", label: "Oriya" },
    { value: "persian", label: "Persian" },
    { value: "polish", label: "Polish" },
    { value: "portuguese-brazil", label: "Portuguese-Brazil" },
    { value: "portuguese-portugal", label: "Portuguese-Portugal" },
    { value: "romanian", label: "Romanian" },
    { value: "russian", label: "Russian" },
    { value: "serbian", label: "Serbian" },
    { value: "slovek", label: "Slovenian" },
    { value: "slovenian", label: "Slovenian" },
    { value: "spanish", label: "Spanish" },
    { value: "swedish", label: "Swedish" },
    { value: "tagalog", label: "Tagalog" },
    { value: "tamil", label: "Tamil" },
    { value: "telugu", label: "Telugu" },
    { value: "thai", label: "Thai" },
    { value: "turkish", label: "Turkish" },
    { value: "ukrainian", label: "Ukrainian" },
    { value: "urdu", label: "Urdu" },
    { value: "vietnamese", label: "Vietnamese" },
]

const defaultValues = {
    primaryLanguage: "",
    allowWebPosting: false,
    includeInGlobalAddressList: false,
    isArchived: false,
    membersCanPostAsTheGroup: false,
    messageModerationLevel: "MODERATE_NONE",
    sendMessageDenyNotification: false,
    spamModerationLevel: "MODERATE",
    disableGroup: false
}



const PrimaryInformation = (props) => {

    // ** Hooks
    const dispatch = useDispatch()
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        watch
    } = useForm({ defaultValues })

    // ** Selectors
    const { group, groupSettings, success, error } = useSelector(groupsSelector);

    // ** State
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({ success: false, error: false, value: "" })

    // ** Fetch domains & organizations data
    useEffect(() => {
        if (groupSettings?.allowWebPosting) {
            reset({
                allowWebPosting: groupSettings.allowWebPosting === "true" ? true : false,
                includeInGlobalAddressList: groupSettings.includeInGlobalAddressList === "true" ? true : false,
                isArchived: groupSettings.isArchived === "true" ? true : false,
                membersCanPostAsTheGroup: groupSettings.membersCanPostAsTheGroup === "true" ? true : false,
                messageModerationLevel: groupSettings.messageModerationLevel,
                sendMessageDenyNotification: groupSettings.sendMessageDenyNotification === "true" ? true : false,
                spamModerationLevel: groupSettings.spamModerationLevel,
            })
        }
    }, [groupSettings])

    // ** Functions
    const toggleHandler = () => {
        if (!show)
            setShow(true)
    }
    const handleCloseMessage = () => { setMessage({ success: false, error: false, value: "" }) }

    const onSubmit = async (body) => {
        setLoading(true)
        const settings = {
            allowWebPosting: body.allowWebPosting ? "true" : "false",
            includeInGlobalAddressList: body.includeInGlobalAddressList ? "true" : "false",
            isArchived: body.isArchived ? "true" : "false",
            membersCanPostAsTheGroup: body.membersCanPostAsTheGroup ? "true" : "false",
            messageModerationLevel: body.messageModerationLevel,
            sendMessageDenyNotification: body.sendMessageDenyNotification ? "true" : "false",
            spamModerationLevel: body.spamModerationLevel,
        }
        try {
            const response = await updateGroupSettings(group.email, { groupSettings: settings })
            dispatch(editGroupSettings({ ...response }))
            setMessage({ success: true, error: false, value: "Group was updated successfully" })
            setLoading(false)
        } catch (error) {
            setMessage({ success: false, error: true, value: "There was an error while updating the group information" })
            setLoading(false)
        }
    }

    return (
        <Container hover={show} onClick={toggleHandler}>
            {show ?
                <Grid item container xs={12} sx={{ position: 'relative' }}>
                    <Grid item xl={3}>
                        <Typography sx={{ fontWeight: 600, fontSize: "0.775rem" }}>
                            General Information
                        </Typography>
                    </Grid>
                    <Grid container item xl={9}>
                        <form id="primary-form" onSubmit={handleSubmit(onSubmit)}>
                            <Grid item container spacing={4} xs={12} sx={{ my: "0.725rem" }}>
                                <Grid container item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={12} md={4}>
                                        <Typography sx={{ fontWeight: 600, fontSize: "0.875rem" }}>
                                            Group's Primary Language:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <FormControl fullWidth sx={{ mt: "0.725rem" }}>
                                            <Controller
                                                name='primaryLanguage'
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <Autocomplete
                                                        options={languages}
                                                        //onChange={onChangeHandler}
                                                        getOptionLabel={(option) => option.label}
                                                        sx={{ width: "100%", mb: "1rem", mr: "1rem" }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Language"
                                                                variant="standard"
                                                                InputLabelProps={{ style: { fontSize: "0.875rem" } }} // font size of input label
                                                            />
                                                        )}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={12} md={4}>
                                        <Typography sx={{ fontWeight: 600, fontSize: "0.875rem" }}>
                                            Posting option:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <FormControl fullWidth>
                                            <Controller
                                                name='allowWebPosting'
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControlLabel
                                                        label="Allow users to post to the group on the web."
                                                        control={
                                                            <Checkbox
                                                                {...field}
                                                                checked={!!field.value}
                                                                name='validation-basic-checkbox'
                                                            />
                                                        }
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", width: "100%" }} />
                                <Grid container item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={12} md={4}>
                                        <Typography sx={{ fontWeight: 600, fontSize: "0.875rem" }}>
                                            Group's Address List:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <FormControl fullWidth>
                                            <Controller
                                                name='includeInGlobalAddressList'
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControlLabel
                                                        label="Include this group in Global Address Lists."
                                                        sx={errors.checkbox ? { color: 'error.main' } : null}
                                                        control={
                                                            <Checkbox
                                                                {...field}
                                                                checked={!!field.value}
                                                                name='validation-basic-checkbox'
                                                            />
                                                        }
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", width: "100%" }} />
                                <Grid container item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={12} md={4}>
                                        <Typography sx={{ fontWeight: 600, fontSize: "0.875rem" }}>
                                            Archive options:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <FormControl fullWidth>
                                            <Controller
                                                name='isArchived'
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControlLabel
                                                        label="Archive messages to the group."
                                                        control={
                                                            <Checkbox
                                                                {...field}
                                                                checked={!!field.value}
                                                                name='validation-basic-checkbox'
                                                            />
                                                        }
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", width: "100%" }} />
                                <Grid container item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={12} md={4}>
                                        <Typography sx={{ fontWeight: 600, fontSize: "0.875rem" }}>
                                            Can post as group:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <FormControl fullWidth>
                                            <Controller
                                                name='membersCanPostAsTheGroup'
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControlLabel
                                                        label="Allow members to post as the group."
                                                        control={
                                                            <Checkbox
                                                                {...field}
                                                                checked={!!field.value}
                                                                name='validation-basic-checkbox'
                                                            />
                                                        }
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={12} md={4}>
                                        <Typography sx={{ fontWeight: 600, fontSize: "0.875rem" }}>
                                            Moderate messages:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <FormControl fullWidth>
                                            <Controller
                                                name='messageModerationLevel'
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <Select
                                                        variant="standard"
                                                        value={value}
                                                        onChange={onChange}
                                                    >
                                                        <MenuItem value="MODERATE_ALL_MESSAGES">Moderate all messages</MenuItem>
                                                        <MenuItem value="MODERATE_NEW_MEMBERS">Moderate messages by new members</MenuItem>
                                                        <MenuItem value="MODERATE_NON_MEMBERS">Moderate messages by non members</MenuItem>
                                                        <MenuItem value="MODERATE_NONE">Don't moderate messages</MenuItem>
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={12} md={4}>
                                        <Typography sx={{ fontWeight: 600, fontSize: "0.875rem" }}>
                                            Rejected Author Notification:
                                        </Typography>
                                    </Grid>
                                    <Grid container item xs={12} md={8}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <Controller
                                                    name='sendMessageDenyNotification'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <FormControlLabel
                                                            label="Notify authors when moderators reject their posts."
                                                            control={
                                                                <Checkbox
                                                                    {...field}
                                                                    checked={!!field.value}
                                                                    name='validation-basic-checkbox'
                                                                />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                type='text'
                                                id='rejectMsg'
                                                label='Rejection Message'
                                                variant="standard"
                                                sx={{ width: "100%" }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={12} md={4}>
                                        <Typography sx={{ fontWeight: 600, fontSize: "0.875rem" }}>
                                            Spam messages:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <FormControl fullWidth>
                                            <Controller
                                                name='spamModerationLevel'
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <Select
                                                        variant="standard"
                                                        value={value}
                                                        onChange={onChange}
                                                    >
                                                        <MenuItem value="MODERATE">Send them to the moderators queue and send notification to moderators</MenuItem>
                                                        <MenuItem value="SILENTLY_MODERATE">Send them to the moderators queue, but do not send notification to moderators</MenuItem>
                                                        <MenuItem value="REJECT">Immediately reject them</MenuItem>
                                                        <MenuItem value="ALLOW">Skip the moderation queue and post to the group</MenuItem>
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} sx={{ display: "flex", alignItems: "center", mt: "2rem" }}>
                                    <Grid item xs={12} md={4}>
                                        <Typography sx={{ fontWeight: 600, fontSize: "0.875rem" }}>
                                            Disbale Group:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <FormControl fullWidth>
                                            <Controller
                                                name='disableGroup'
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControlLabel
                                                        label="This group is currently enabled.If you disable it all new posts to the group will be rejected. Existing messages will be viewable and searchable online by anyone that already has the permission to view the group's content. If the group is re-enabled, the setting for 'Who can post messages' will automatically be set to 'Managers' and 'Owners' only."
                                                        control={
                                                            <Checkbox
                                                                {...field}
                                                                name='validation-basic-checkbox'
                                                            />
                                                        }
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Divider sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", width: "100%" }} />
                    <Grid item xs={12} sx={{ textAlign: "end", mt: '1rem' }}>
                        <Button sx={{ fontWeight: 600, color: "text.secondary" }} variant="text" onClick={() => setShow(false)}>
                            CANCEL
                        </Button>
                        <Button
                            type='submit'
                            form="primary-form"
                            variant="text"
                            sx={{ fontWeight: 600 }}
                            disabled={loading}
                        >
                            SAVE
                        </Button>
                    </Grid>
                    <Backdrop
                        open={loading}
                        sx={{
                            position: 'absolute',
                            backgroundColor: "transparent",
                            backdropFilter: 'blur(5px)',
                            color: theme => theme.palette.common.blue,
                            zIndex: theme => theme.zIndex.mobileStepper - 1,
                        }}
                    >
                        <CircularProgress color='inherit' />
                    </Backdrop>
                </Grid>
                :
                <Grid container item xs={12} spacing={6}>
                    <Grid item xs={12} xl={3}>
                        <Typography sx={{ fontWeight: 600, fontSize: "0.775rem" }}>
                            General information
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} xl={8} spacing={4}>
                        <Grid item xs={12} >
                            <Typography variant="body2" sx={{ fontSize: "0.875rem", fontWeight: 600 }}>
                                Group's primary language
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ fontSize: "0.875rem", fontWeight: 600 }}>
                                Group's address list
                            </Typography>
                            <Typography sx={{ fontSize: "0.925rem" }}>
                                {(groupSettings?.includeInGlobalAddressList === "true" ? "True" : "False")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ fontSize: "0.875rem", fontWeight: 600 }}>
                                Archive's option
                            </Typography>
                            <Typography sx={{ fontSize: "0.925rem" }}>
                                {(groupSettings?.isArchived === "true" ? "True" : "False")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ fontSize: "0.875rem", fontWeight: 600 }}>
                                Settings
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ fontSize: "0.875rem", fontWeight: 600 }}>
                                Advanced
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            }
            <CustomAlertMessage
                message={message}
                handleCloseMessage={handleCloseMessage}
            />
        </Container>
    )

}


export default PrimaryInformation