// ** React Imports
import React, { useState } from 'react'

// ** MUI Imports
import Grid from '@mui/material/Grid'

// ** Custom Components
import AccountDelegation from './account-delegation'
import FiltersSettings from './filters'
import SendAsSettings from './sendAs'
import Labels from './labels'
import PopSettings from './pop-settings'
import ImapSettings from './imap-settings'
import VacationResponderSettings from './vacation-responder'
import ForwardingSettings from './forwarding'
import ForwardingAddress from './forwarding-addresses'

const GmailSettings = () => {
  // State
  const [reload, setReload] = useState(false)

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <AccountDelegation />
      </Grid>
      <Grid item xs={12}>
        <FiltersSettings />
      </Grid>
      <Grid item xs={12}>
        <SendAsSettings />
      </Grid>
      <Grid item xs={12}>
        <Labels />
      </Grid>
      <Grid item xs={12}>
        <PopSettings />
      </Grid>
      <Grid item xs={12}>
        <ImapSettings />
      </Grid>
      <Grid item xs={12}>
        <VacationResponderSettings />
      </Grid>
      <Grid item xs={12}>
        <ForwardingAddress setReload={setReload} />
      </Grid>
      <Grid item xs={12}>
        <ForwardingSettings reload={reload} setReload={setReload} />
      </Grid>
    </Grid>
  )
}

export default GmailSettings
