// ** React Imports
import React from 'react'



const TemplateV1 = (props) => {

    // ** Props
    const { color, data, extraFields, greetings } = props

    // Split placeHolders array to objects
    let name = data?.find(el => el.name === "name")
    let jobTitle = data?.find(el => el.name === "jobTitle")
    let emails = data?.find(el => el.name === "emails")
    let addresses = data?.find(el => el.name === "addresses")
    let phones = data?.find(el => el.name === "phones")
    let department = data?.find(el => el.name === "department")

    return (
        <div
            style={{
                height: "250px",
                width: "650px",
                borderRadius: "8px",
                backgroundColor: "#FFF",
            }}
        >
            <div
                style={{
                    marginTop: "2rem",
                    marginLeft: "2rem",
                }}>
                <p
                    style={{
                        color: "#8F8F8F",
                        letterSpacing: 5,
                        fontSize: "1.725rem"
                    }}
                >
                    {greetings}
                </p>
            </div>
            <div
                style={{
                    marginTop: "2rem",
                    position: "relative"
                    //width: "100%"
                    //paddingLeft: "2rem",
                    //display: "block",
                    
                }}
            >
                {/* <div
                    style={{
                        width: "5px",
                        borderRight: "1px solid rgba(93, 89, 98, 0.22)"
                    }}
                > */}
                    <div
                        style={{
                            marginLeft: "2rem",
                            borderRadius: "10px",
                            ///border: "2px dashed rgba(93, 89, 98, 0.22)",
                            width: "85px",
                            display: "inline-block"

                        }}
                    >
                        <img alt="" height={85} width={85} src={"{|avatar|}"} />
                    </div>
                {/* </div> */}

                <div
                    style={{
                        marginLeft: "2rem",
                        color: "#8F8F8F",
                        display: "inline-block",
                        width: "400px",
                        verticalAlign: "top"
                        //flexDirection: "column"
                    }}
                >
                    <p
                        style={{
                            fontWeight: 600,
                            fontSize: "1.125rem",
                            color: "#0000FF"
                        }}
                    >
                        {"{|" + name?.name + "-" + name?.subType + "|}"}
                    </p>
                    <p
                        style={{
                            fontSize: "0.825rem",
                            fontWeight: 600
                        }}
                    >
                        {"{|" + jobTitle?.name + "-" + jobTitle?.subType + "|}"}, {"{|" + department?.name + "-" + department?.subType + "|}"}
                    </p>
                    <div
                        style={{
                            marginTop: "1rem",
                            fontSize: "0.785rem"
                        }}
                    >

                        {extraFields?.map((el, index) => (
                            <>
                                {index !== 0 && (index !== 3 || index !== 6 || index !== 9 || index !== 12) && "|"} &nbsp;
                                {"{|" + el?.name + "-" + el.subType + "|}"}&nbsp;
                                {(index === 2 || index === 5 || index === 8 || index === 11) && <br />}
                            </>
                        ))}
                    </div>
                </div>
            </div>

        </div>
    )
}


export default TemplateV1