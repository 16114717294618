// ** React Imports
import React from 'react'

// ** MUI Imports
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

// ** Custom Components
import BulkSignatureHeader from './BulkSignatureHeader'
import BulkSignatureUpdate from './BulkSignatureUpdate'


const BulkSignatureFormCard = (props) => {

    // ** Props
    const { activeTab } = props

    return (
        <Card
            sx={{
                border: theme => `solid 1px ${theme.palette.grey[300]}`,
                borderRadius: 0,
                overflow: "visible",
            }}>
            <CardHeader
                sx={{
                    backgroundColor: theme => theme.palette.grey[100],
                    height: 50,
                    border: theme => `solid 1px ${theme.palette.grey[300]}`,
                    mb: 10,
                }}
                title={(
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ fontWeight: 600, fontSize: "1rem" }}>
                                Bulk signature update
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            />
            <Grid item xs={12}>
                <BulkSignatureUpdate activeTab={activeTab} />
            </Grid>
        </Card>
    )
}



export default BulkSignatureFormCard