// ** React Imports
import React, { createContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// ** Third party libraries
import { useCookies } from 'react-cookie'
import axios from 'axios'

// ** Api Calls
import {
  getUserAppsAccess,
  getUserAuthorization,
  getAuthUser,
  getAdminSupportCaseAccess
} from 'configs/axios/api_helper'

// ** MUI Imports
import Box from '@mui/material/Box'

// ** SPINNER
import GoogleSpinner from 'core/components/google-spinner'

// ** Defaults
const defaultProvider = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  error: false,
  isInitialized: false,
  login: () => null,
  logout: () => null,
  setIsInitialized: () => Boolean,
  register: () => Promise.resolve()
}
const AuthContext = createContext(defaultProvider)

const AuthProvider = ({ children }) => {
  // ** States
  const [user, setUser] = useState(defaultProvider.user)
  const [loading, setLoading] = useState(defaultProvider.loading)
  const [error, setError] = useState(defaultProvider.error)
  const [isInitialized, setIsInitialized] = useState(defaultProvider.isInitialized)

  // ** Hooks
  const navigate = useNavigate()

  // ** Cookies
  const [cookies, setCookie, removeCookie] = useCookies([
    'gToken',
    'userData',
    'externalToken',
    'token',
    'refreshToken'
  ])

  // ** Get Token from external app redirection
  const search = window.location.search
  const params = new URLSearchParams(search)
  const externalToken = params.get('token')

  useEffect(() => {
    const initAuth = async () => {
      setIsInitialized(true)
      setLoading(true)
      const storedGoogleToken = cookies.gToken
      const token = cookies.token
      const refreshToken = cookies.refreshToken
      const storedUser = cookies.userData
      try {
        const googleUser = await axios
          .get(`https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=${storedGoogleToken || externalToken}`)
          .then(res => res.data)
        const authUser = await getAuthUser(googleUser.email, { headers: { 'x-auth-token': `${token}` } }).then(
          res => res.data?.result
        )
        const gamStatus = await fetch(
          `${process.env.REACT_APP_GAM_BASE_URL}/check-customer/` + authUser?.customerId?.toLowerCase()
        )
          .then(res => res.json())
          .catch(() => ({
            message: '1'
          }))
        // const appsAccess = await axios.post(`https://backend-yyxlncaila-uc.a.run.app/api/auth/admins/appsaccess`, { email: googleUser.email }).then(res => res.data)
        if (authUser?.customerId) {
          setUser({
            ...authUser,
            email: storedUser.email,
            name: storedUser.name,
            picture: storedUser.picture,
            access_apps: [],
            gamStatus: gamStatus?.message === '1'
          })
        } else {
          // Get new access & refresh token
          const tokens = await axios
            .get(`${process.env.REACT_APP_AP_BASE_URL}/refresh?refresh=` + refreshToken)
            .then(res => res.data)
          const storedUser = cookies.userData
          setCookie('token', tokens.token)
          setCookie('refreshToken', tokens.refreshToken)
          setUser({ ...storedUser })
        }
        setLoading(false)
        if (window.location.pathname == '/login') navigate('/')
        else navigate(window.location.pathname)
      } catch (error) {
        removeCookie('gToken')
        removeCookie('token')
        removeCookie('userData')
        setUser(null)
        setLoading(false)
        if (window.location.pathname == '/privacy-policy') navigate('/privacy-policy')
        else navigate('/login')
      }
    }
    initAuth()
  }, [])

  const handleLogin = async (data, errorCallback) => {
    setLoading(true)
    setError(false)
    try {
      const { token, refreshToken } = await getUserAuthorization(data.email).then(res => res.data)
      if (!token) {
        setLoading(false)
        errorCallback({ message: 'not authorized', code: 401 })
        setError(true)
        return
      }
      // const appsAccess = await axios.post(`https://backend-yyxlncaila-uc.a.run.app/api/auth/admins/appsaccess`, { email: data.email }).then(res => res.data)
      // const userDataAuthResponse = await axios.get("https://adminplus-accelerant.oa.r.appspot.com/api/users/" + googleUser.email, { headers: { "x-auth-token": `${token}` } }).then(res => res.data?.result)
      const userDataAuthResponse = await getAuthUser(data.email, { headers: { 'x-auth-token': `${token}` } }).then(
        res => res.data?.result
      )
      const supportCaseAccess = await fetch(`${process.env.REACT_APP_AP_BASE_URL}/access?email=` + data.email)
      const gamStatus = await fetch(
        `${process.env.REACT_APP_GAM_BASE_URL}/check-customer/` + userDataAuthResponse?.customerId?.toLowerCase()
      )
        .then(res => res.json())
        .catch(() => ({
          message: '1'
        }))
      setCookie('gToken', data.access_token)
      setCookie('token', token)
      setCookie('refreshToken', refreshToken)
      const userData = {
        ...userDataAuthResponse,
        email: data.email,
        name: data.name,
        picture: data.picture,
        access_apps: [],
        supportCaseAccess: supportCaseAccess.status === 200 ? true : false,
        gamStatus: gamStatus?.message === '1'
      }
      setCookie('userData', userData)
      setUser(userData)
      setLoading(false)
      navigate('/')
    } catch (error) {
      setLoading(false)
      errorCallback({ message: 'not authorized', code: 401 })
      setError(true)
    }
  }

  const handleLogout = () => {
    setUser(null)
    setIsInitialized(false)
    removeCookie('userData')
    removeCookie('gToken')
    removeCookie('token')
    navigate('/login')
  }

  const values = {
    user,
    loading,
    error,
    setUser,
    setLoading,
    isInitialized,
    setIsInitialized,
    login: handleLogin,
    logout: handleLogout
  }
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          mt: '20%'
        }}
      >
        <GoogleSpinner />
      </Box>
    )
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
