// ** React Import
import React, { useEffect, useState } from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { usersSelector } from 'store/users'

// ** API CALLS
import { getContacts } from 'configs/axios/api_helper'

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import { DataGrid } from '@mui/x-data-grid'
import Stack from '@mui/material/Stack'
import Snackbar from '@mui/material/Snackbar'
import Grow from '@mui/material/Grow'
import TextField from '@mui/material/TextField'

// ** Custom Components
import CustomAvatar from 'core/components/mui/avatar'

// ** Icon Imports
import DeleteIcon from 'mdi-material-ui/Delete'
import DeleteDialog from './components/dialog/DeleteDialog'
import AddDialog from './components/dialog/AddDialog'
// ** Hooks
import { useAuth } from 'hooks/useAuth'


const ContactsTable = () => {


    // ** Hooks
    const auth = useAuth()

    // ** Selectors
    const { user } = useSelector(usersSelector);

    // ** State
    const [contacts, setContacts] = useState([])
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({
        success: false,
        error: false,
        value: ""
    })

    // ** Fetch Aliases
    useEffect(() => {
        const fetchContacts = async () => {
            setLoading(true)
            try {
                // Second parameter is page change it when you work with pagination
                const response = await getContacts(user.primaryEmail, 100)
                const { data: { result: { connections } } } = response
                setContacts(connections?.map((el, index) => ({ ...el, id: index })))
                setLoading(false)
            } catch (error) {
                setContacts([])
                setLoading(false)
            }
        }
        if (user?.primaryEmail)
            fetchContacts()
    }, [])

    // ** Functions
    const handleCloseMessage = () => {
        setMessage({ success: false, error: false, value: "" })
    }


    const columns = [
        {
            flex: 0.25,
            minWidth: 290,
            field: 'names',
            headerName: 'Name',
            renderCell: params => {
                return (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CustomAvatar src={params.row.photos[0]?.value} sx={{ mr: 3, width: '1.875rem', height: '1.875rem' }} />
                        <Typography
                            noWrap
                            variant='body2'
                            sx={{ color: 'common.blue' }}
                        >
                            {params.row.names[0]?.displayName || ""}
                        </Typography>
                    </Box>
                )
            }
        },
        {
            flex: 0.25,
            minWidth: 290,
            field: 'email',
            headerName: 'Email',
            renderCell: params => {
                return (
                    <Typography
                        noWrap
                        variant='body2'
                        sx={{ color: 'common.blue' }}
                    >
                        {params.row.emailAddresses[0]?.value || ""}
                    </Typography>
                )
            }
        },
        {
            flex: 0.175,
            minWidth: 120,
            field: 'id',
            headerName: 'Action',
            align: "right",
            headerAlign: "right",
            renderCell: params => (
                <DeleteDialog
                    setMessage={setMessage}
                    setContacts={setContacts}
                    contact={params.row}
                    user={user}
                />
            )
        },
    ]

    return (
        <Card sx={{ borderRadius: 0, border: theme => `solid 1px ${theme.palette.grey[300]}` }}>
            <CardHeader
                sx={{
                    backgroundColor: theme => theme.palette.customColors.tableHeaderBg,
                    height: "58px"
                }}
                title={
                    <Stack direction="row" alignItems="center" gap={1} sx={{ px: "0.25rem" }}>
                        <Typography sx={{ fontWeight: 600, fontSize: "1rem" }}>
                            Groups
                        </Typography>
                        <Typography sx={{ color: 'secondary.light', fontWeight: 600 }}>
                            | Display all contact list
                        </Typography>

                    </Stack>
                }
            />
            <Box sx={{ height: 60, px: 12, pt: 3, display: "flex", justifyContent: "end", width: "100%" }}>
                <AddDialog setMessage={setMessage} setContacts={setContacts} user={user} />
            </Box>
            <DataGrid
                autoHeight
                rows={contacts || []}
                columns={columns}
                loading={loading}
                hideFooter
            />
            <Snackbar
                sx={{ mt: "3rem" }}
                open={message.success || message.error}
                onClose={handleCloseMessage}
                autoHideDuration={2000}
                key={"top" + "right"}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                TransitionComponent={Grow}
                message={
                    <Box sx={{ textAlign: 'center' }}>
                        {message.value}

                    </Box>
                }
            />
        </Card>
    )
}

export default ContactsTable
