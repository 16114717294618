// ** React Imports
import React from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'


const RolesAndPrivilegesHeader = (props) => {

    // ** Props
    const { user } = props



    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="body2" sx={{ fontWeight: 600, fontSize: "1rem" }}>
                    Admin roles and privileges
                </Typography>
            </Grid>
            <Grid item lg={4} sx={{ mt: "1.5rem" }}>
                <Typography sx={{ fontWeight: 600, fontSize: "0.925rem" }}>
                    Roles
                </Typography>
                <Typography variant="body2">
                    {user?.isAdmin ? "Super Admin" : "Add role"}
                </Typography>
            </Grid>
        </Grid>
    )
}


export default RolesAndPrivilegesHeader