// ** React Imports
import React, { Fragment, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// ** API CALLS
import { createBulkSignature } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'
import moment from 'moment'

// ** Hooks
import { useAuth } from 'hooks/useAuth'

// ** Styled Box
const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  boxShadow: theme.shadows[5],
  paddingTop: '0.125rem',
  paddingBottom: '0.125rem',
  paddingRight: '0.725rem',
  paddingLeft: '0.725rem',
  borderRadius: '1rem',
  cursor: 'pointer',
  width: 'fit-content'
}))

const AddBulkSignatureDialog = props => {
  // ** Props
  const { setMessage, signature } = props

  // ** Hooks
  const { user } = useAuth()
  const navigate = useNavigate()

  // ** State
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [operationName, setOperationName] = useState('')

  // ** Functions
  const handleClickOpen = e => {
    e.stopPropagation()
    if (signature) setOpen(true)
  }
  const handleClose = () => setOpen(false)

  const handleChange = event => {
    const { value } = event.target
    setOperationName(value)
  }

  const onSubmit = async () => {
    const data = {
      adminId: user?.primaryEmail,
      customerId: user?.customerId,
      operationName,
      signature: signature?.template?.template,
      status: 'pending',
      targets: signature?.selectedUsers?.map(el => {
        if (el.kind?.includes('user')) return { email: el.primaryEmail, type: 'user', status: 'pending' }
        else if (el.kind?.includes('group'))
          return { email: el.email, groupId: el.id, type: 'group', status: 'pending' }
        else return { email: el.orgUnitPath, type: 'orgUnit', status: 'pending', orgUnitPath: el.path }
      }),
      signatureName: {
        uid: signature?.template?.uid,
        name: signature?.template?.name,
        signature: signature?.template?.template
      }
    }
    try {
      const response = await createBulkSignature(data)
      setMessage({ success: true, error: false, value: 'Signature was assigned successfully' })
      setLoading(false)
      setOpen(false)
      setTimeout(() => {
        navigate('/tools/bulk-signatures')
      }, 1000)
    } catch (error) {
      setMessage({ success: false, error: true, value: 'There was an error while assigning the signature' })
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <StyledBox onClick={handleClickOpen}>
        <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 36 36'>
          <path fill='#34A853' d='M16 16v14h4V20z'></path>
          <path fill='#4285F4' d='M30 16H20l-4 4h14z'></path>
          <path fill='#FBBC05' d='M6 16v4h10l4-4z'></path>
          <path fill='#EA4335' d='M20 16V6h-4v14z'></path>
          <path fill='none' d='M0 0h36v36H0z'></path>
        </svg>
        <Typography sx={{ color: 'text.secondary', fontSize: '0.825rem', fontWeight: 600, whiteSpace: 'nowrap' }}>
          Save signature
        </Typography>
      </StyledBox>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle id='alert-dialog-title'>Bulk Signature Preview</DialogTitle>
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 600, fontSize: '0.875rem' }}>Operation Name* :</Typography>
              <TextField
                label='Operation name'
                variant='standard'
                value={operationName}
                onChange={handleChange}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 600, fontSize: '0.875rem' }}>Signature Name :</Typography>
              <Typography>{signature?.template?.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 600, fontSize: '0.875rem' }}>Created on :</Typography>
              <Typography>{moment(new Date(signature?.template?.created)).format('MMM DD, YYYY HH:mm:ss')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 600, fontSize: '0.875rem' }}>Description :</Typography>
              <Typography>{signature?.template?.description}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 600, fontSize: '0.875rem' }}>Targets :</Typography>
              <Box>
                {signature?.selectedUsers?.map((el, index) => {
                  if (el.kind?.includes('user')) return <Typography key={index}>{el.primaryEmail} </Typography>
                  else if (el.kind?.includes('orgUnit')) return <Typography key={index}>{el.name} </Typography>
                  else return <Typography key={index}>{el.email} </Typography>
                })}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 600, fontSize: '0.875rem' }}>Run within 5 minutes :</Typography>
              <Typography>No</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className='dialog-actions-dense' sx={{ mt: '1rem' }}>
          <Button disabled={loading} onClick={handleClose} size='small' variant='text' color='inherit'>
            Cancel
          </Button>
          <Button
            disabled={loading || !signature || !operationName}
            endIcon={loading ? <CircularProgress sx={{ color: 'common.white', mt: 0 }} size='0.825rem' /> : null}
            variant='contained'
            onClick={onSubmit}
            size='small'
            sx={{ fontWeight: 600, primary: 'common.blue' }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default AddBulkSignatureDialog
