// ** React Imports
import React, { Fragment, useEffect, useState } from 'react'

// ** MUI Imports
import { Snackbar, Alert, Grow, Box } from '@mui/material'

// ** Redux Imports
import { useDispatch } from 'react-redux'
import { cleanMessages } from 'store/users'


const AlertMessages = (props) => {

    // ** Hooks
    const dispatch = useDispatch()

    // ** State 
    const [open, setOpen] = useState(false)

    // ** Props 
    const { message, success, error, messageValue } = props

    // ** Clean up 
    useEffect(() => {
        if (success == messageValue || error == messageValue) {
            setOpen(true)
            setTimeout(() => {
                dispatch(cleanMessages())
            }, 2000)
        }

    }, [success, error])

    // ** Functions
    const handleCloseMessage = () => {
        setOpen(false)
    }


    return (
        <Fragment>
            {success && success == messageValue && <Snackbar
                sx={{ mt: "3rem" }}
                open={open}
                onClose={handleCloseMessage}
                autoHideDuration={2000}
                key={"top" + "right"}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                TransitionComponent={Grow}
                message={<Box sx={{ textAlign: 'center' }}>{message || "Action was done successfully"}</Box>}
            />}
            {/* {
                error == messageValue && <Snackbar
                    sx={{ mt: "3rem" }}
                    open={open}
                    onClose={handleCloseMessage}
                    autoHideDuration={2000}
                    key={"top" + "right"}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    TransitionComponent={Grow}
                >
                    <Alert
                        elevation={1}
                        variant='filled'
                        onClose={handleCloseMessage}
                        sx={{ backgroundColor: "error.light" }}
                    >
                        An error has occured please try again !
                    </Alert>
                </Snackbar>
            } */}
        </Fragment>
    )
}


export default AlertMessages 