// ** React Import
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** Redux Imports
import { useSelector, useDispatch } from 'react-redux'
import { usersSelector } from 'store/users'

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import { DataGrid } from '@mui/x-data-grid'
import Stack from '@mui/material/Stack'
import Snackbar from '@mui/material/Snackbar'
import Grow from '@mui/material/Grow'

// ** Custom Components
import CustomAvatar from 'core/components/mui/avatar'
import DeleteDialog from './components/dialogs/DeleteDialog'

// ** API CALLS
import { getAliasesList } from 'configs/axios/api_helper'
import AddDialog from './components/dialogs/AddDialog'


// ** renders client column
const renderClient = params => {
    const { row } = params
    return <CustomAvatar src={`/images/avatars/${row.avatar}`} sx={{ mr: 3, width: '1.875rem', height: '1.875rem' }} />
}

const AliasesTable = () => {


    // ** Hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // ** Selectors
    const { user } = useSelector(usersSelector);

    // ** State
    const [aliases, setAliases] = useState([])
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({
        success: false,
        error: false
    })

    // ** Fetch Aliases
    useEffect(() => {
        const fetchAliases = async () => {
            setLoading(true)
            try {
                const response = await getAliasesList(user.primaryEmail)
                const { data } = response
                setAliases(data?.aliases.map((el, index) => ({ ...el, parentId: el.id, id: index })))
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        if (user?.primaryEmail)
            fetchAliases()
    }, [])

    // ** Functions
    const handleCloseMessage = () => {
        setMessage({ success: false, error: false })
    }

    const deleteAlias = (alias) => {
        setAliases(aliases.filter(el => el.alias !== alias))
    }

    const addAliasHandler = (alias) =>{
        setAliases([...aliases, {...alias, parentId: alias.id, id: aliases.length}])
    }

    const columns = [
        {
            flex: 0.25,
            minWidth: 290,
            field: 'alias',
            headerName: 'Aliases',
            renderCell: params => {
                return (
                    <Typography
                        noWrap
                        variant='body2'
                        sx={{ color: 'common.blue' }}
                    >
                        {params.row.alias}
                    </Typography>
                )
            }
        },
        {
            flex: 0.175,
            minWidth: 120,
            headerName: 'Action',
            field: 'Action',
            align: "right",
            headerAlign: "right",
            renderCell: params => (
                <DeleteDialog
                    setMessage={setMessage}
                    deleteAlias={deleteAlias}
                    aliases={aliases}
                    alias={params.row} />
            )
        },
    ]

    return (
        <Card sx={{ borderRadius: 0, border: theme => `solid 1px ${theme.palette.grey[300]}` }}>
            <CardHeader
                sx={{
                    backgroundColor: theme => theme.palette.customColors.tableHeaderBg,
                    height: "58px"
                }}
                title={
                    <Stack direction="row" alignItems="center" gap={1} sx={{ px: "0.25rem" }}>
                        <Typography sx={{ fontWeight: 600, fontSize: "1rem" }}>
                            Aliases
                        </Typography>
                        <Typography sx={{ color: 'secondary.light', fontWeight: 600 }}>
                            | Display all aliases list
                        </Typography>
                        <Typography sx={{ ml: "2rem", color: 'common.blue', fontWeight: 400, fontSize: 14, cursor: 'pointer' }}>
                            <AddDialog 
                            addAliasHandler={addAliasHandler}
                            setMessage={setMessage}
                            />
                        </Typography>
                    </Stack>
                }
            />
            <Box sx={{ height: 10, px: 12, display: 'flex', alignItems: 'center' }}>
                {/* <Filters /> */}
            </Box>
            <DataGrid
                autoHeight
                rows={aliases}
                columns={columns}
                loading={loading}
                hideFooter
            // pageSize={pageSize}
            // rowsPerPageOptions={[ 50]}
            // onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            />
            <Snackbar
                sx={{ mt: "3rem" }}
                open={message.success || message.error}
                onClose={handleCloseMessage}
                autoHideDuration={2000}
                key={"top" + "right"}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                TransitionComponent={Grow}
                message={
                    <Box sx={{ textAlign: 'center' }}>
                        {message.success === "DELETE_ALIAS" && "Alias was deleted successfully"}
                        {message.error === "ERROR_DELETE" && "There was an error deleting the alias"}
                        {message.success === "ADD_ALIAS" && "Alias was created successfully"}
                        {message.error === "ERROR_ADD" && "There was an error while creating the alias"}

                    </Box>
                }
            />
        </Card>
    )
}

export default AliasesTable
