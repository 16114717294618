// ** React Imports
import React, { useEffect, useState, useMemo } from "react"
import { useLocation } from "react-router-dom"

// ** Redux Imports
import { useDispatch, useSelector } from "react-redux"
import { saveTemplate, templateSelector } from "store/template"

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'

// ** Custom Components
import DnDInputs from "./drag-drop-inputs/DnDInputs"
import AddFieldDialog from "./dialog/AddFieldDialog"

// ** Third Party Imports
import { useDebouncedCallback } from "use-debounce"

// ** Dummy Data
import { options } from "@fake-db/options"
import FileUploader from "pages/tools/components/FileUploader"
import AddTemplateDialog from "./dialog/AddTemplateDialog"
import AlertMessage from "pages/tools/components/CustomAlertMessage"

// ** Styled Box
const Container = styled(Box)(({ theme }) => ({
    padding: "1.425rem",
    backgroundColor: "#fff",
    transition: "all .2s ease-in-out",
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
    },
}
))

const initialPlaceHolders = [
    { name: "name", subType: "fullName", label: "Name", value: "" },
    { name: "jobTitle", subType: "title", label: "Job Title", value: "" },
    { name: "department", subType: "department", label: "Department", value: "" },
]



const TemplatePlaceHolders = (props) => {

    // ** Hooks 
    const dispatch = useDispatch()
    const { pathname } = useLocation()

    // ** Selectors
    const { template } = useSelector(templateSelector)

    // ** State
    const [show, setShow] = useState(false)
    const [initialOptions, setInitialOptions] = useState(options)
    const [placeHolders, setPlaceHolders] = useState(initialPlaceHolders)
    const [extraFields, setExtraFields] = useState([])
    const [greetings, setGreetings] = useState("Best Regards")
    const [deletedItem, setDeletedItem] = useState({})
    const [message, setMessage] = useState({ success: false, error: false, value: "" })

    // ** Fetch domains & organizations data
    useEffect(() => {
        if (!pathname.includes("update"))
            dispatch(saveTemplate({ ...template, placeHolders: placeHolders, extraFields: extraFields, greetings: greetings }))
    }, [])


    // ** Memo
    const updateStatesHandler = useMemo(() => {
        if (!!template && !!template.extraFields && template?.extraFields?.length > 0 && (pathname.includes("update") || template.customTemplate)) {
            setExtraFields(template?.extraFields?.map(el => {
                return { ...el, subType: el.subType }
            }))
        }
    }, [template])

    // ** Functions
    const toggleHandler = () => {
        if (!show)
            setShow(true)
    }

    const addFieldToForm = (field) => {
        setExtraFields(prevState => { return [...prevState, field] })
        dispatch(saveTemplate({ ...template, placeHolders: placeHolders, extraFields: [...extraFields, field], greetings: greetings }))

    }

    const onChangeSelect = (event) => {
        const { value, name } = event.target
        setPlaceHolders(placeHolders.map(item => item.name === name ? { ...item, subType: value } : item))
        dispatch(saveTemplate({ ...template, placeHolders: placeHolders.map(item => item.name === name ? { ...item, subType: value } : item), extraFields: extraFields, greetings: greetings }))
    }

    const debounced = useDebouncedCallback(
        (event) => {
            const { name, value } = event.target
            if (name !== "greetings") {
                setPlaceHolders(placeHolders.map(item => item.name === name ? { ...item, value: value } : item))
                dispatch(saveTemplate({ ...template, placeHolders: placeHolders.map(item => item.name === name ? { ...item, value: value } : item), extraFields: extraFields, greetings: greetings }))
            }
            else {
                setGreetings(value)
                dispatch(saveTemplate({ ...template, placeHolders: placeHolders, extraFields: extraFields, greetings: value }))
            }
        }, 500, { maxWait: 3000 }
    )

    const onChangeImage = (file) => {
        dispatch(saveTemplate({ ...template, placeHolders: placeHolders, extraFields: extraFields, greetings: greetings, image: file }))
    }

    const handleCloseMessage = () => {
        setMessage({ success: false, error: false, value: "" })
    }

    return (
        <Container onClick={toggleHandler}>
            <Grid item container xs={12} sx={{ position: 'relative' }}>
                <Grid item xl={3}>
                    <Typography sx={{ fontWeight: 600, fontSize: "0.775rem", mt: "1rem" }}>
                        Template Placeholders Information :
                    </Typography>
                </Grid>
                <Grid container item xl={9}>
                    <Grid item xs={12} md={10} sx={{ mb: "2rem" }}>
                        <FormControl fullWidth>
                            <TextField
                                variant="standard"
                                label="Greetings"
                                name="greetings"
                                onChange={debounced}
                                inputProps={{ style: { fontSize: "0.825rem" } }} // font size of input text
                                InputLabelProps={{ style: { fontSize: "0.825rem" } }} // font size of input label
                            />
                        </FormControl>
                    </Grid>
                    {placeHolders.map((el, index) => (
                        <Grid key={index} container item xs={12} spacing={8} sx={{ mb: 3 }}>
                            <Grid item xs={6} md={5}>
                                <FormControl fullWidth>
                                    <TextField
                                        variant="standard"
                                        label={el.label}
                                        name={el.name}
                                        onChange={debounced}
                                        inputProps={{ style: { fontSize: "0.825rem" } }} // font size of input text
                                        InputLabelProps={{ style: { fontSize: "0.825rem" } }} // font size of input label
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={5} sx={{ mb: 3 }}>
                                <FormControl size="small" variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-label" >{el.subType}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        MenuProps={{
                                            anchorOrigin: { vertical: "bottom", horizontal: "left" },
                                            transformOrigin: { vertical: "top", horizontal: "left" },
                                        }}
                                        value={el.subType}
                                        onChange={onChangeSelect}
                                        name={el.name}
                                    >
                                        {initialOptions[el.name]?.map((item, index) => (
                                            <MenuItem key={index} value={item}>{item}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>))}
                    <Grid item xs={12}>
                        <DnDInputs
                            extraFields={extraFields}
                            setExtraFields={setExtraFields}
                            options={initialOptions}
                            greetings={greetings}
                            placeHolders={placeHolders}
                            setInitialOptions={setInitialOptions}
                            setDeletedItem={setDeletedItem}
                            template={template}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AddFieldDialog
                            addFieldToForm={addFieldToForm}
                            setInitialOptions={setInitialOptions}
                            deletedItem={deletedItem}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: "1rem" }}>
                        <FileUploader dispatchImage={onChangeImage} />
                    </Grid>
                </Grid>
                <Divider sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", width: "100%", mb: 0, mt: "1rem" }} />
                <Grid item xs={12} md={11} sx={{ display: "flex", justifyContent: "end" }}>
                    <AddTemplateDialog setMessage={setMessage} />
                </Grid>
            </Grid>
            <AlertMessage
                message={message}
                handleCloseMessage={handleCloseMessage}
            />
        </Container >
    )

}


export default TemplatePlaceHolders