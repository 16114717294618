

// ** React imports
import React, { useEffect, useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { DataGrid } from '@mui/x-data-grid'
import Snackbar from '@mui/material/Snackbar'
import Box from '@mui/material/Box'
import Grow from '@mui/material/Grow'

// ** Custom Components
import DeleteDialog from './components/dialog/DeleteDialog'

// ** Icons Imports
import { getDelegationList } from 'configs/axios/api_helper'
import AddDialog from './components/dialog/AddDialog'


const AccountDelegationTable = (props) => {

    // ** Props
    const { user } = props

    // ** State
    const [show, setShow] = useState(false)
    const [delegates, setDelegates] = useState([])
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({ success: false, error: false })
    const [options, setOptions] = useState([])
    const [email, setEmail] = useState(null)

    // ** Fetch Delegations Data
    useEffect(() => {
        const fetchDelegationsData = async () => {
            setLoading(true)
            try {
                const response = await getDelegationList(user.primaryEmail)
                const { data: { delegates } } = response
                setDelegates(delegates.map((el, index) => ({ ...el, id: index })))
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        if (user?.primaryEmail)
            fetchDelegationsData()

    }, [user])

    // ** Functions
    const deleteDelegatesHandler = (id) => {

    }

    const handleCloseMessage = () => {
        setMessage({ success: false, error: false })
    }



    const columns = [
        {
            flex: 0.25,
            minWidth: 290,
            field: 'delegateEmail',
            headerName: 'Account Delegates',
            renderCell: params => {
                return (
                    <Typography
                        noWrap
                        variant='body2'
                        sx={{ color: 'common.blue' }}
                    >
                        {params.row.delegateEmail}
                    </Typography>
                )
            }
        },
        {
            flex: 0.25,
            minWidth: 290,
            field: 'verificationStatus',
            headerName: 'Verification Status',
            renderCell: params => {
                return (
                    <Typography
                        noWrap
                        variant='body2'
                    >
                        {params.row.verificationStatus}
                    </Typography>
                )
            }
        },
        {
            flex: 0.175,
            minWidth: 120,
            headerName: 'Action',
            field: 'Action',
            align: "right",
            headerAlign: "right",
            renderCell: params => (
                <DeleteDialog
                    setMessage={setMessage}
                    setDelegates={setDelegates}
                    delegate={params.row}
                    user={user}
                    delegates={delegates}
                />
            )
        },
    ]


    return (
        <Grid item container xs={12}>
            <Grid item xs={12}>
                <AddDialog
                    setMessage={setMessage}
                    setDelegates={setDelegates}
                />
            </Grid>
            <Grid item xs={12}>
                <DataGrid
                    autoHeight
                    rows={delegates}
                    columns={columns}
                    loading={loading}
                    hideFooter
                />
            </Grid>
            <Snackbar
                sx={{ mt: "3rem" }}
                open={message.success || message.error}
                onClose={handleCloseMessage}
                autoHideDuration={2000}
                key={"top" + "right"}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                TransitionComponent={Grow}
                message={
                    <Box sx={{ textAlign: 'center' }}>
                        {message.success === "DELETE_DELEGATE" && "Delegate was deleted successfully"}
                        {message.error === "ERROR_DELETE" && "There was an error deleting the delegate"}
                        {message.success === "ADD_DELEGATE" && "Delegate was added successfully"}
                        {message.error === "ERROR_ADD" && "There was an error while adding the delegate"}

                    </Box>
                }
            />
        </Grid >
    )

}

export default AccountDelegationTable