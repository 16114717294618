// ** React Imports
import React, { Fragment, useState, useEffect } from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { usersSelector } from 'store/users'

// ** API CALLS
import { getMimeTypeDriveFolders, moveDriveFolder, getDriveFolders } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'

// ** Icon Imports
import MoveIcon from 'mdi-material-ui/FolderSwapOutline'
import FolderDrive from 'mdi-material-ui/FolderGoogleDrive'
import ReturnIcon from 'mdi-material-ui/ArrowLeft'



const StyledBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: 5,
    boxShadow: theme.shadows[5],
    paddingTop: "0.125rem",
    paddingBottom: "0.125rem",
    paddingRight: "0.725rem",
    paddingLeft: "0.725rem",
    borderRadius: "1rem",
    cursor: "pointer",
    width: "fit-content"
}))


const defaultValues = {
    name: '',
}



const BulkMoveDialog = (props) => {

    // ** Props
    const { setFolders, setMessage, folders, setTreeFolders, rootFolders, setSelectionModel } = props

    // ** Hooks
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues })

    // ** Selectors
    const { user } = useSelector(usersSelector);

    // ** State
    const [open, setOpen] = useState(false)
    const [openSub, setOpenSub] = useState(false)
    const [parentFolders, setParentFolders] = useState([])
    const [selectedParent, setSelectedParent] = useState(null)
    const [loading, setLoading] = useState("")
    const [parentsPath, setParentsPath] = useState([{ id: "root", name: "My Drive" }])


    // * Fetch parent folders
    useEffect(() => {
        const fetchParentFolders = async () => {
            try {
                const response = await getMimeTypeDriveFolders(user.id, "root")
                const { data: { files } } = response
                setParentFolders(files)
                setParentsPath([{ id: "root", name: "my Drive" }])
            } catch (error) {

            }
        }
        if (user?.id && open)
            fetchParentFolders()
    }, [user, open])

    // ** Functions
    const handleClickOpen = (e) => {
        e.stopPropagation()
        setOpen(true)
    }
    const handleClose = () => setOpen(false)



    const onSubmit = async (body) => {
        setLoading("MOVE")
        Promise.all(
            folders.map(async (id) => {
                const result = await moveDriveFolder(user.id, id, { destination: parentsPath[parentsPath.length - 1 || 0]?.id, source: rootFolders.find(el => el.id === id)?.parents[0] })
            })
        )
            .then(async (data) => {
                const response = await getDriveFolders(user.id, 25, "trashed = false and 'root' in parents")
                const { data: { files } } = response
                setFolders(files)
                setTreeFolders([])
                setMessage({ success: true, error: false, value: "Folder was moved successfully" })
                setSelectionModel([])
                setLoading("")
                setOpen(false)
            })
            .catch(error => {
                setMessage({ success: false, error: true, value: "There was an error while moving the folder" })
                setLoading("")
            })
    }

    const fetchSubFolders = async (userId, id) => {
        try {
            setLoading("GET")
            const response = await getMimeTypeDriveFolders(userId, id)
            const { data: { files } } = response
            if (!!files) setParentFolders(files)
            else setParentFolders([])
            setLoading("")
        } catch (error) {
            setLoading("")
        }
    }

    const selectParentHandler = async ({ id, name }) => {
        fetchSubFolders(user.id, id)
        setParentsPath([...parentsPath, { id, name }])
    }

    const returnToPreviousParentHandler = async () => {
        const updatedArray = parentsPath.filter((element, index) => index < parentsPath.length - 1);
        const parent = updatedArray[updatedArray.length - 1]
        fetchSubFolders(user.id, parent.id)
        setParentsPath([...updatedArray])
    }


    return (
        <Fragment>
            <StyledBox onClick={handleClickOpen}>
                <MoveIcon sx={{ color: "text.secondary" }}/>
                <Typography variant="body2" sx={{ fontFamily: "Roboto", fontSize: "0.825rem", fontWeight: 600 }}>
                    Move Selected
                </Typography>
            </StyledBox>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                fullWidth={true}
                maxWidth="sm"
                keepMounted={false}
            >
                <DialogTitle id='alert-dialog-title' sx={{ display: "flex", alignItems: "center" }}>
                    {parentsPath.length > 1 &&
                        <IconButton size="medium" edge="start" sx={{ color: "text.secondary" }} onClick={returnToPreviousParentHandler} >
                            <ReturnIcon sx={{ fontSize: "1.225rem", mt: 0.5 }} />
                        </IconButton>
                    }
                    {parentsPath?.[parentsPath.length - 1]?.name}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            {!loading && parentFolders?.map((el, index) =>
                                <List key={index}>
                                    <ListItem disablePadding disabled={folders.some(item => item === el.id)} sx={{ my: "-0.5rem" }}>
                                        <ListItemButton
                                            selected={selectedParent === el.id}
                                            onClick={() => selectParentHandler({ id: el.id, name: el.name })}
                                        >
                                            <ListItemText primary={
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <FolderDrive sx={{ color: "text.secondary", mr: "0.725rem" }} />
                                                    <Typography
                                                        noWrap
                                                        variant='body2'
                                                        sx={{ color: 'common.black' }}
                                                    >
                                                        {el?.name}
                                                    </Typography>
                                                </Box>
                                            }
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            )}
                            {!loading && parentFolders.length < 1 &&
                                <Box sx={{ textAlign: "center" }}>
                                    <Typography noWrap variant="body2" sx={{ fontSize: "1rem" }}>
                                        This folder is empty
                                    </Typography>
                                </Box>
                            }
                            {loading === "GET" &&
                                <Box sx={{ textAlign: "center" }}>
                                    <CircularProgress sx={{ mt: 0 }} />
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className='dialog-actions-dense' sx={{ mt: "1rem" }}>
                    <Button
                        disabled={loading === "MOVE"}
                        onClick={handleClose}
                        size="small"
                        variant="text"
                        color="inherit"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={loading === "MOVE"}
                        endIcon={(loading === "MOVE" ?
                            <CircularProgress sx={{ color: 'common.white', mt: 0 }} size="0.825rem" /> : null
                        )}
                        variant="contained"
                        size="small"
                        sx={{ fontWeight: 600, primary: "common.blue", "&:disabled": { cursor: "none", backgroundColor: "text.secondary" } }}
                        onClick={onSubmit}
                    >
                        Move
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default React.memo(BulkMoveDialog)