// ** React Imports
import React, { useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'


// ** Icons Imports
import Download from "mdi-material-ui/Download"

// ** Third Party Imports
import { useDropzone } from 'react-dropzone'


// Styled header text
const HeadingTypography = styled(Typography)(({ theme }) => ({
  color: "white",
  fontSize: "0.785rem",
  fontWeight: 600,
  marginTop: 5,
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4)
  }
}))

// Styled img box container
const BoxContainer = styled(Box)(({ theme }) => ({
  textAlign: "center"
}))

// Styled component for the upload image dropzone box
const DropZoneBox = styled(Box)(({ theme }) => ({
  height: "100px",
  width: "160px",
  display: "flex",
  flexWrap: "wrap",
  flexDirection: 'column',
  cursor: "pointer",
  position: "relative",
  "-webkit-box-align": "center",
  alignItems: "center",
  "-webkit-box-pack": "center",
  justifyContent: "center",
  //padding: "0.725rem",
  borderRadius: "8px",
  //backgroundColor: "rgba(0,0,0,0.25)",
  border: "2px solid rgba(93, 89, 98, 0.22)",
}))

// Styled component for the upload image inside the dropzone area
const Img = styled('img')(({ theme }) => ({
  objectFit: "cover",
  width: "calc(100% - 5px)",
  height: "calc(100% - 5px)",
  borderRadius: "8px",
  [theme.breakpoints.down('md')]: {
    marginLeft: "-0.125rem"
  },
}))

const FileUploader = (props) => {

  // ** Props
  const { dispatchImage } = props
  // ** State
  const [files, setFiles] = useState([])

  // ** Hook
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.gif']
    },
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file)))
      dispatchImage(acceptedFiles.map(file => Object.assign(file)))
    }
  })

  const handleLinkClick = event => {
    event.preventDefault()
  }

  const img = files.map(file => (
    <Img key={file.name} alt={file.name} src={URL.createObjectURL(file)} />
  ))

  return (
    <DropZoneBox {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} />
      {files.length ? (
        img
      ) : (
        <BoxContainer>
          <Download sx={{ fontSize: "3rem", color: "rgba(93, 89, 98, 0.22)" }} />
          <Typography sx={{ color: "text.secondary" }}>
            Upload image
          </Typography>
        </BoxContainer>
      )}
    </DropZoneBox>
  )
}

export default FileUploader