// ** React Imports
import React, { useEffect, useRef, useState } from 'react'

// ** MUI Imports
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import DialogContentText from '@mui/material/DialogContentText'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

// ** Custom Imports
import CopyToClipboardButton from '../buttons/CopyToClipboard'

// ** Icon Imports
import CodeTags from 'mdi-material-ui/CodeTags'

// ** Data Imports
import { placeHolders } from '@fake-db/placeHolders'

const PlaceHoldersDialog = () => {
    // ** States
    const [open, setOpen] = useState(false)
    const [scroll, setScroll] = useState('paper')

    // ** Ref
    const descriptionElementRef = useRef(null)

    const handleClickOpen = scrollType => () => {
        setOpen(true)
        setScroll(scrollType)
    }
    const handleClose = () => setOpen(false)
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    return (
        <div className='demo-space-x'>
            <Box display="flex" gap="5px" alignItems="center">
                <Typography fontSize={12} fontWeight={500} variant="body2" mt="-1.75rem">
                    Show placeholders
                </Typography>
                <IconButton sx={{ mt: "-1.75rem" }} onClick={() => setOpen(true)}>
                    <CodeTags fontSize='small' />
                </IconButton>
            </Box>
            <Dialog
                open={open}
                scroll={scroll}
                onClose={handleClose}
                aria-labelledby='scroll-dialog-title'
                aria-describedby='scroll-dialog-description'
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id='scroll-dialog-title'>Place Holders List</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText id='scroll-dialog-description' ref={descriptionElementRef} tabIndex={-1}>
                        <Typography variant="body2" fontWeight={500} fontSize={16} mb='10px'> 
                            Please select one of the following placeholders: 
                        </Typography>
                        {placeHolders.map((item, index) => {
                            return (
                                <Box key={index} sx={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                                    <Typography>{item}</Typography>
                                    <CopyToClipboardButton text={`{|${item}|}`} />
                                </Box>
                            )
                        })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>OK</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}

export default PlaceHoldersDialog
