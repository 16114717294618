// ** React Imports
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

// ** Redux Imports
import { useDispatch } from "react-redux"
import { fetchGroupData } from "store/groups"

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

// ** Custom Components
import GeneralInfo from "../components/GeneralInfo"
import GeneralSettings from "../components/general-information/group-details"
import MembersSettings from "../components/general-information/group-members"
import PermissionsSettings from "../components/group-settings/permissions"
import GroupBusinessSettings from "../components/group-settings/general-information"



// ** Styled Grid Container
const Container = styled(Grid)(({ theme }) => ({
    paddingLeft: "2rem",
    paddingRight: "2rem",
    marginBottom: "2rem",
    [theme.breakpoints.down('lg')]: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },

}))

const tabs = ["General Information", "Business Group Settings"]


const GroupDetails = (props) => {

    // ** Hooks
    const dispatch = useDispatch()
    const { id } = useParams()

    // ** State
    const [activeTab, setActiveTab] = useState(0)

    // ** Fetch user data
    useEffect(() => {
        if (id) {
            dispatch(fetchGroupData(id))
        }
    }, [])

    // ** Functions
    const changeTabsHandler = (tab) => setActiveTab(tab)


    return (
        <Container container spacing={6}>
            <Grid item xs={12} md={4} lg={4} xl={3}>
                <GeneralInfo
                    tabs={tabs}
                    activeTab={activeTab}
                    changeTabsHandler={changeTabsHandler}
                />
            </Grid>
            <Grid container item xs={12} md={8} lg={8} xl={9}>
                {activeTab === 0 &&
                    <Grid item xs={12}>
                        <GeneralSettings />
                        <MembersSettings />
                    </Grid>
                }
                {activeTab === 1 &&
                    <Grid item xs={12}>
                        <GroupBusinessSettings />
                        <PermissionsSettings />
                    </Grid>
                }
            </Grid>
        </Container >
    )
}


export default GroupDetails