// ** React Imports
import React, { useState, useEffect } from 'react'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { fetchUsers } from 'store/users'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

// ** 3rd Party Libraries
import { useDebouncedCallback } from 'use-debounce'

// ** Icons Imports
import SearchIcon from 'mdi-material-ui/Magnify'

// ** Component Imports
import CustomTable from '../components/tables/CustomTable'
import { fetchGroups, groupsSelector } from 'store/groups'
import AddGroupDialog from '../components/dialog/AddGroupDialog'

// Styled Divider component
const StyledDivider = styled(Divider)(({ theme }) => ({
  borderBottomWidth: 1,
  color: theme.palette.grey[300]
}))

const GroupsTable = () => {
  // ** Hooks
  const dispatch = useDispatch()

  // ** State
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [queryPage, setQueryPage] = useState(0)

  // ** Selectors
  const { groups, nextPageToken, success, error, loading } = useSelector(groupsSelector)

  // ** Fetch users
  useEffect(() => {
    dispatch(fetchGroups({ limit: 10 }))
  }, [])

  // ** Functions
  const handleChangePage = (event, newPage) => {
    if (nextPageToken) {
      if (newPage > queryPage) {
        dispatch(fetchGroups({ limit: rowsPerPage, page: nextPageToken }))
        setQueryPage(newPage)
        setPage(newPage)
      } else {
        setPage(newPage)
      }
    } else {
      setPage(newPage)
    }
  }

  const handleChangeRowsPerPage = event => {
    dispatch(fetchGroups({ limit: parseInt(event.target.value, 10) }))
    setRowsPerPage(parseInt(event.target.value, 10))
    setQueryPage(0)
    setPage(0)
  }

  //   const debounced = useDebouncedCallback(
  //     event => {
  //       const { name, value } = event.target
  //       if (value) dispatch(fetchGroups({ limit: rowsPerPage, query: value }))
  //       else dispatch(fetchGroups({ limit: rowsPerPage }))
  //     },
  //     500,
  //     // The maximum time func is allowed to be delayed before it's invoked:
  //     { maxWait: 3000 }
  //   )

  return (
    <Card
      sx={{
        '&.MuiPaper-root': {
          borderRadius: '0px !important',
          boxShadow: 0,
          border: theme => `1px solid ${theme.palette.grey[300]}`
        },
        '& .customer-column-header': {
          backgroundColor: 'common.white',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 600
          }
        }
      }}
    >
      <CardHeader title={<Typography>Groups Management</Typography>} action={<AddGroupDialog />} />
      <StyledDivider />
      <CustomTable
        data={groups}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        columns={['GROUP EMAIL', 'NAME']}
        nextPageToken={nextPageToken}
        loading={loading}
        page={page}
        rowsPerPage={rowsPerPage}
        path={'/groups-management/group-details'}
      />
    </Card>
  )
}

export default GroupsTable
