// ** React Imports
import React from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'


const PermissionsHeader = (props) => {

    // ** Props
    const { group } = props



    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="body2" sx={{ fontWeight: 600, fontSize: "1rem" }}>
                    Access Settings
                </Typography>
            </Grid>
            <Grid item lg={4} sx={{ mt: "1.5rem" }}>
                <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mb: 3 }}>
                    Access type
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "0.825rem" }}>
                    Custom
                </Typography>
            </Grid>
            <Grid item lg={4} sx={{ mt: '1.5rem' }}>
                <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mb: 3 }}>
                    Access settings
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "0.825rem" }}>
                    Anyone can post content
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "0.825rem" }}>
                    All organization members  can view topics
                </Typography>
            </Grid>
            <Grid item xs={4} sx={{ mt: "1.5rem" }}>
                <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", mb: 3 }}>
                    Allow members outside your organization
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "0.825rem" }}>
                    No
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: "1.5rem" }}>
                <Typography variant="body2" sx={{ fontSize: "0.825rem", mb: 3 }}>
                    Membership settings | Who can join the group
                </Typography>
            </Grid>
        </Grid>
    )
}


export default PermissionsHeader