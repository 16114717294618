

// ** React imports
import React, { useEffect, useState } from 'react'

// ** APIs
import { getVerificationsCodesList } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import FormHelperText from '@mui/material/FormHelperText'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import Snackbar from '@mui/material/Snackbar'


// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'


// ** Icons Imports
import EyeOutline from 'mdi-material-ui/EyeOutline'
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline'
import KeyIcon from "mdi-material-ui/ShieldKeyOutline"

// ** Hooks
import { useAuth } from 'hooks/useAuth'

// ** Styled Box
const Container = styled(Box, { shouldForwardProp: (prop) => prop !== "hover" })(({ theme, hover }) => ({
    padding: "1.425rem",
    cursor: "pointer",
    backgroundColor: "#fff",
    transition: "all .1s ease-in-out",
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
    },
    ...(hover ? {
        boxShadow: theme.shadows[10],
        margin: "12px -12px",
        cursor: "default"
    } : {}),
    ...(!hover ? { "&:hover": { backgroundColor: theme.palette.grey[100] } } : {})
}
))

const defaultValues = {
    isEnforcedIn2Sv: false,
    isEnrolledIn2Sv: false
}



const TwoStepVerificationCard = (props) => {

    // ** Props
    const { user } = props

    // ** Hooks
    const auth = useAuth()
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({ defaultValues })

    // ** State
    const [show, setShow] = useState(false)
    const [verificationCodes, setVerificationCodes] = useState({
        loading: false,
        success: "",
        error: "",
        data: []
    })

    // ** Set Data
    useEffect(() => {
        if (user !== undefined) {
            reset({ isEnforcedIn2Sv: user.isEnforcedIn2Sv || false, isEnrolledIn2Sv: user.isEnrolledIn2Sv || false })
        }
    }, [user])

    // ** Functions
    const toggleHandler = (event) => {
        event.stopPropagation()
        if (!show)
            setShow(true)
    }

    const showBackCodesHandler = async (event) => {
        event.stopPropagation()
        setVerificationCodes({ ...verificationCodes, loading: true, success: "", error: "" })
        if (user?.primaryEmail) {
            try {
                const response = await getVerificationsCodesList(user?.primaryEmail)
                let { data } = response
                setVerificationCodes({ ...verificationCodes, data, loading: false, success: true })
                setShow(false)
            } catch (error) {
                setVerificationCodes({ ...verificationCodes, loading: false, success: "", error: true })
            }
        }
    }

    const handleClose = () => {
        setVerificationCodes({ ...verificationCodes, error: false })
    }

    const onSubmit = (data) => { }



    return (
        <Container hover={show} onClick={toggleHandler}>
            {show ?
                <Grid item container xs={12}>
                    <Grid item xl={3}>
                        <Typography sx={{ fontWeight: 600, fontSize: "0.775rem" }}>
                            2 Step verification
                        </Typography>
                    </Grid>
                    <Grid item xs={12} xl={9} sx={{ py: "0.5rem" }}>
                        <form id="security-step-form" onSubmit={handleSubmit(onSubmit)}>
                            <Grid item xs={12}>
                                <FormControl>
                                    <Controller
                                        name='isEnforcedIn2Sv'
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                label="Verification Enforced (read only)"
                                                sx={errors.checkbox ? { color: 'error.main' } : null}
                                                control={
                                                    <Switch
                                                        disabled
                                                        {...field}
                                                        name='validation-basic-checkbox'
                                                        sx={errors.checkbox ? { color: 'error.main' } : null}
                                                    />
                                                }
                                            />
                                        )}
                                    />
                                
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <Controller
                                        name='isEnrolledIn2Sv'
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                label="Verification Enrolled (read only)"
                                                sx={errors.checkbox ? { color: 'error.main' } : null}
                                                control={
                                                    <Switch
                                                        disabled
                                                        {...field}
                                                        name='validation-basic-checkbox'
                                                        sx={errors.checkbox ? { color: 'error.main' } : null}
                                                    />
                                                }
                                            />
                                        )}
                                    />
                                 
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sx={{ my: "1rem" }}>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ mr: "1rem" }}>
                                        Backup codes:
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        onClick={(e) => showBackCodesHandler(e)}
                                        endIcon={(
                                            verificationCodes.loading &&
                                            <CircularProgress sx={{ color: 'common.white' }} size="1rem" />
                                        )}>
                                        Show Backup codes
                                    </Button>
                                </Box>
                            </Grid>
                        </form>
                    </Grid>
                    <Divider sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", width: "100%" }} />
                    <Grid item xs={12} sx={{ textAlign: "end" }}>
                        <Button
                            onClick={() => setShow(false)}
                            variant="text"
                            sx={{ fontWeight: 600 }}
                        >
                            DONE
                        </Button>
                    </Grid>
                </Grid >
                :
                <Grid container item xs={12} spacing={6}>
                    <Grid item xs={12} xl={3}>
                        <Typography sx={{ fontWeight: 600, fontSize: "0.775rem" }}>
                            2 Step verification
                        </Typography>
                    </Grid>
                    <Grid item xs={12} xl={8}>
                        <Typography variant='body2' sx={{ fontSize: "0.925rem" }}>
                            <strong>{user?.isEnforcedIn2Sv ? "ON" : "OFF"}</strong> |
                            {user?.isEnforcedIn2Sv ? "Forced across your organization" : "Not enforced across your organization"}
                        </Typography>
                    </Grid>
                </Grid>
            }
            <Snackbar
                open={verificationCodes.error}
                onClose={handleClose}
                message='There was an error while getting backup codes.'
                autoHideDuration={1500}
                key={"top" + "right"}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            />
        </Container >
    )

}

export default TwoStepVerificationCard