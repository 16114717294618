// ** React Imports
import React from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'


const FiltersHeader = (props) => {

    // ** Props
    const { user } = props



    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="body2" sx={{ fontWeight: 600, fontSize: "1rem" }}>
                    Criteria and Action
                </Typography>
            </Grid>
            <Grid item lg={4} sx={{ mt: "1.5rem" }}>
                <Typography variant="body2">
                    Manage your gmail filters
                </Typography>
            </Grid>
        </Grid>
    )
}


export default FiltersHeader