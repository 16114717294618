// ** React Imports
import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { templateSelector } from 'store/template'

// ** MUI Imports
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

// ** Moment
import moment from 'moment'

// ** Custom Components
import DeleteBulkSignatureDialog from '../dialog/DeleteBulkSignatureDialog'


const Row = props => {

    // ** Hooks 
    const navigate = useNavigate()

    // ** Props
    const { row, path } = props

    // ** Selectors
    const { loading, success, error } = useSelector(templateSelector)


    return (
        <Fragment key={row.id}>
            <TableRow sx={{ '& > *': {}, cursor: 'pointer' }}>
                <TableCell component='th' scope='row' sx={{ color: 'common.blue' }}>
                    {row.name}
                </TableCell>
                <TableCell>{row.signatureName?.name || "----"}</TableCell>
                <TableCell>{moment(new Date(row.created)).format("MMM DD, YYYY")}</TableCell>
                <TableCell>{row.status || "----"}</TableCell>
                <TableCell>
                    <DeleteBulkSignatureDialog
                        template={row}
                        loading={loading}
                        success={success}
                    />
                </TableCell>
            </TableRow>
        </Fragment>
    )
}


export default Row