// ** React Imports
import React, { useState } from "react"

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

// ** Custom Components
import SideCard from "../components/SideCard"
import BulkSignatureFormCard from "../components/BulkSignatureFormCard"

// ** Styled Grid Container
const Container = styled(Grid)(({ theme }) => ({
    paddingLeft: "2rem",
    paddingRight: "2rem",
    marginBottom: "2rem",
    [theme.breakpoints.down('lg')]: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },

}))


const BulkSignatureFormContainer = (props) => {

    // ** State
    const [activeTab, setActiveTab] = useState(0)

    // ** Functions
    const changeTabsHandler = (tab) => setActiveTab(tab)

    return (
        <Container container spacing={6}>
            <Grid item xs={12} md={6} lg={4} xl={3}>
                <SideCard
                    activeTab={activeTab}
                    changeTabsHandler={changeTabsHandler}
                />
            </Grid>
            <Grid container item xs={12} md={6} lg={8} xl={9}>
                <Grid item xs={12}>
                    <BulkSignatureFormCard activeTab={activeTab} />
                </Grid>
            </Grid>
        </Container >
    )
}


export default BulkSignatureFormContainer