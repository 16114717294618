// ** React Imports
import React, { useEffect, useLayoutEffect, useState } from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { usersSelector } from 'store/users'

// ** Third Party Libraries
import moment from 'moment'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import SvgIcon from '@mui/material/SvgIcon'

// ** Custom Components
import FileUploader from '../others/FileUploader'

// ** Icon Imports
import SharedWitchIcon from 'mdi-material-ui/AccountMultipleOutline'
import StarredIcon from 'mdi-material-ui/StarOutline'
import TrashedIcon from 'mdi-material-ui/TrashCanOutline'
import SharedWithMeDriveIcon from 'mdi-material-ui/GoogleDrive'

// ** Styled Tab List
const Container = styled(Box)(({ theme }) => ({
  paddingLeft: '1rem',
  paddingTop: '1rem',
  marginBottom: '2rem',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'start',
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(5)
  }
}))

// ** Styled Link
const Link = styled(Typography, { shouldForwardProp: prop => prop !== 'active' })(({ theme, active }) => ({
  fontFamily: 'Roboto',
  textTransform: 'uppercase',
  fontSize: '0.925rem',
  fontWeight: 600,
  lineHeight: '16px',
  marginTop: '0.525rem',
  marginBottom: '0.525rem',
  paddingTop: '0.875rem',
  paddingBottom: '0.875rem',
  paddingLeft: '0.5rem',
  paddingRight: '0.5rem',
  borderRadius: 3,
  cursor: 'pointer',
  transition: '.25s',
  ...(active ? { backgroundColor: theme.palette.grey[300] } : {}),
  '&:hover': { backgroundColor: theme.palette.grey[300] }
}))

// ** Styled Sub Link for DRIVE
const SubLink = styled(Box, { shouldForwardProp: prop => prop !== 'active' })(({ theme, active }) => ({
  marginTop: '0.425rem',
  marginBottom: '0.425rem',
  paddingTop: '0.575rem',
  paddingBottom: '0.575rem',
  paddingLeft: '0.5rem',
  paddingRight: '0.5rem',
  borderRadius: 3,
  cursor: 'pointer',
  transition: '.1s',
  ...(active
    ? { backgroundColor: theme.palette.common.blueLight, color: theme.palette.primary.main, fontWeight: 700 }
    : {}),
  ...(!active ? { '&:hover': { backgroundColor: theme.palette.grey[200] } } : {})
}))

// ** Main Tabs
const tabs = ['General Settings', 'Aliases', 'Groups', 'Contacts', 'Gmail Settings', 'Calendars', 'Drive']
// ** Tabs for My Drive Tab
const subTabs = [
  {
    name: 'My Drive',
    icon: (
      <SvgIcon>
        <path d='M19 2H5C3.9 2 3 2.9 3 4V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V4C21 2.9 20.1 2 19 2ZM19 20H5V19H19V20ZM19 17H5V4H19V17Z'></path>
        <path d='M13.1215 6H10.8785C10.5514 6 10.271 6.18692 10.0841 6.46729L7.14019 11.6075C7 11.8878 7 12.215 7.14019 12.4953L8.26168 14.4579C8.40187 14.7383 8.72897 14.9252 9.05608 14.9252H15.0374C15.3645 14.9252 15.6449 14.7383 15.8318 14.4579L16.9533 12.4953C17.0935 12.215 17.0935 11.8878 16.9533 11.6075L13.9159 6.46729C13.7757 6.18692 13.4486 6 13.1215 6ZM10.1776 12.0748L12.0467 8.8972L13.8692 12.0748H10.1776Z'></path>
      </SvgIcon>
    )
  },
  {
    name: 'Owned by me',
    icon: (
      <SvgIcon>
        <path d='M19 2H5c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 2v13H5V4h14zM5 20v-1h14v1H5zm6-11.5c0 .83-.67 1.5-1.5 1.5S8 9.33 8 8.5 8.67 7 9.5 7s1.5.67 1.5 1.5zm5 0c0 .83-.67 1.5-1.5 1.5S13 9.33 13 8.5 13.67 7 14.5 7s1.5.67 1.5 1.5zM9.5 11c-1.16 0-3.5.55-3.5 1.65V14h7v-1.35c0-1.1-2.34-1.65-3.5-1.65zm8.5 1.65V14h-4v-1.35c0-.62-.3-1.12-.75-1.5.46-.1.9-.15 1.25-.15 1.16 0 3.5.55 3.5 1.65z'></path>
      </SvgIcon>
    )
  },
  { name: 'Shared with me', icon: <SharedWitchIcon /> },
  { name: 'Starred', icon: <StarredIcon /> },
  { name: 'Trashed', icon: <TrashedIcon /> },
  { name: 'Shared Drive', icon: <SharedWithMeDriveIcon /> }
]

const GeneralInfo = props => {
  // ** Props
  const { activeTab, changeTabsHandler, setActiveDriveTab, driveTab } = props
  // ** State
  const [units, setUnits] = useState([])

  // ** Selectors
  const { user } = useSelector(usersSelector)

  useEffect(() => {
    if (user?.orgUnitPath) {
      setUnits(user?.orgUnitPath?.split('/')?.filter(el => el !== ''))
    }
  }, [user])

  return (
    <Card
      sx={{
        borderRadius: 0,
        py: '0.5rem',
        border: theme => `solid 1px ${theme.palette.grey[300]}`
      }}
    >
      {user?.isAdmin ? (
        <Box
          sx={{
            mt: '1rem',
            ml: '1rem',
            py: '0.125rem',
            px: '0.525rem',
            borderRadius: '2px',
            width: 'fit-content',
            backgroundColor: theme => theme.palette.grey[200]
          }}
        >
          <Typography sx={{ fontSize: '0.975rem' }}>{user?.isAdmin ? 'ADMIN' : ''}</Typography>
        </Box>
      ) : null}
      <Container>
        <FileUploader file={user?.thumbnailPhotoUrl || ''} />
        <Box item xs={8} sx={{ display: 'flex', flexDirection: 'column', px: '1rem' }}>
          <Typography sx={{ fontSize: '1.725rem' }}>
            {(user?.name?.givenName || '----') + ' ' + (user?.name?.familyName || '----')}
          </Typography>
          <Typography sx={{ my: '0.325rem' }}>{user?.primaryEmail}</Typography>
          <Typography variant='body2' sx={{ fontSize: '0.755rem', color: `${user?.suspended ? 'red' : 'green'}` }}>
            {user?.suspended ? 'Suspended' : 'Active'}
          </Typography>
          <Typography variant='body2' sx={{ fontSize: '0.785rem' }}>
            Created: {moment(new Date(user?.creationTime)).format('MMM DD, YYYY')}
          </Typography>
          <Typography variant='body2' sx={{ fontSize: '0.785rem' }}>
            Last sign in: {moment(new Date(user?.lastLoginTime)).fromNow()}
          </Typography>
        </Box>
      </Container>
      <Divider sx={{ borderBottom: theme => `1px solid ${theme.palette.grey[300]}` }} />
      <Box sx={{ px: '1.525rem', my: 3 }}>
        <Typography variant='body2' sx={{ fontSize: '0.725rem', fontWeight: 600, mb: '0.325rem' }}>
          Organizational unit
        </Typography>

        {units.length === 1 ? (
          <Typography sx={{ textTransform: 'uppercase', fontSize: '0.925rem', fontWeight: 600 }}>{units[0]}</Typography>
        ) : null}
        {units.length === 2 ? (
          <Box sx={{ display: 'flex', alignItems: 'center', mr: '0.225rem' }}>
            <Typography variant='body2' sx={{ textTransform: 'uppercase', fontSize: '0.925rem', fontWeight: 600 }}>
              {units[0]} {'>'}
            </Typography>
            &nbsp;
            <Typography sx={{ fontSize: '0.925rem', fontWeight: 600 }}>{units[1]}</Typography>
          </Box>
        ) : null}
      </Box>
      <Divider sx={{ borderBottom: theme => `1px solid ${theme.palette.grey[300]}` }} />
      <Box sx={{ px: '1rem' }}>
        {tabs.map((el, index) => (
          <Link active={activeTab === index} variant='body2' key={index} onClick={() => changeTabsHandler(index)}>
            {el}
          </Link>
        ))}
      </Box>
      {/* Render When Drive tab selected */}
      {activeTab === tabs.length - 1 && (
        <Box sx={{ pl: '2rem', pr: '1rem', mt: '1rem' }}>
          {subTabs.map((el, index) => (
            <SubLink
              active={driveTab === index}
              key={index}
              sx={{ display: 'flex', alignItems: 'center', gap: 3 }}
              onClick={() => setActiveDriveTab(index)}
            >
              {el.icon}
              <Typography
                variant='body2'
                key={index}
                sx={{
                  ...(driveTab === index ? { fontWeight: 700, color: 'primary.main' } : { fontWeight: 600 }),
                  fontFamily: 'Roboto',
                  fontSize: '0.8525rem',
                  lineHeight: '16px'
                }}
              >
                {el.name}
              </Typography>
            </SubLink>
          ))}
        </Box>
      )}
    </Card>
  )
}

export default GeneralInfo
