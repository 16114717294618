

// ** React imports
import React, { useEffect, useState } from 'react'

// ** Redux Imports
import { editUserRole, usersSelector } from 'store/users'
import { useDispatch, useSelector } from 'react-redux'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'
import FormHelperText from '@mui/material/FormHelperText'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'


// ** Icons Imports
import EyeOutline from 'mdi-material-ui/EyeOutline'
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline'
import KeyIcon from "mdi-material-ui/ShieldKeyOutline"

// ** Hooks
import { useAuth } from 'hooks/useAuth'

// ** Styled Box
const Container = styled(Box, { shouldForwardProp: (prop) => prop !== "hover" })(({ theme, hover }) => ({
    padding: "1.425rem",
    cursor: "pointer",
    backgroundColor: "#fff",
    transition: "all .1s ease-in-out",
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
    },
    ...(hover ? {
        boxShadow: theme.shadows[10],
        margin: "12px -12px",
        cursor: "default"
    } : {}),
    ...(!hover ? { "&:hover": { backgroundColor: theme.palette.grey[100] } } : {})
}
))

const defaultValues = {
    makeAdmin: false,
}



const RoleCard = (props) => {

    // ** Hooks
    const auth = useAuth()
    const dispatch = useDispatch()
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({ defaultValues })

    // ** Selectors
    const { user, loading, success, error } = useSelector(usersSelector);

    // ** State
    const [show, setShow] = useState(false)

    // ** Set Data
    useEffect(() => {
        if (user !== undefined) {
            reset({ makeAdmin: user.isAdmin || false })
        }
    }, [user])

    console
    // ** Functions
    const toggleHandler = () => {
        if (!show)
            setShow(true)
    }


    const onSubmit = async (data) => {
        dispatch(editUserRole(user?.id, { adminStatus: data.makeAdmin }))
        setShow(false)
    }




    return (
        <Container hover={show} onClick={toggleHandler}>
            {show ?
                <Grid item container xs={12} sx={{ position: "relative" }}>
                    <Grid item xl={3}>
                        <Typography sx={{ fontWeight: 600, fontSize: "0.775rem" }}>
                            Role
                        </Typography>
                    </Grid>
                    <Grid item xs={12} xl={3} sx={{ py: "0.5rem" }}>
                        <form id="role-form" onSubmit={handleSubmit(onSubmit)}>
                            <FormControl>
                                <Controller
                                    name='makeAdmin'
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            label="Super Admin."
                                            sx={errors.checkbox ? { color: 'error.main' } : null}
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    checked={!!field.value}
                                                    name='validation-basic-checkbox'
                                                    sx={errors.checkbox ? { color: 'error.main' } : null}
                                                />
                                            }
                                        />
                                    )}
                                />
                                {errors.checkbox && (
                                    <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-checkbox'>
                                        This field is required
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </form>
                    </Grid>
                    <Divider sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", width: "100%" }} />
                    <Grid item xs={12} sx={{ textAlign: "end", mt: '1rem' }}>
                        <Button sx={{ fontWeight: 600, color: "text.secondary" }} variant="text" onClick={() => setShow(false)}>
                            CANCEL
                        </Button>
                        <Button
                            type='submit'
                            form="role-form"
                            variant="text"
                            sx={{ fontWeight: 600 }}
                        >
                            SAVE
                        </Button>
                    </Grid>
                    <Backdrop
                        open={loading == "UPDATE_USER"}
                        sx={{
                            position: 'absolute',
                            backgroundColor: "transparent",
                            backdropFilter: 'blur(5px)',
                            color: theme => theme.palette.common.blue,
                            zIndex: theme => theme.zIndex.mobileStepper - 1,
                        }}
                    >
                        <CircularProgress color='inherit' />
                    </Backdrop>
                </Grid >
                :
                <Grid container item xs={12} spacing={6}>
                    <Grid item xs={12} xl={3}>
                        <Typography sx={{ fontWeight: 600, fontSize: "0.775rem" }}>
                            Role
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} xl={8} spacing={6}>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ fontSize: "0.775rem" }}>
                                Change user role
                            </Typography>
                            <Typography sx={{ fontSize: "0.925rem" }}>
                                {user?.isAdmin ? "Admin" : "User"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Container >
    )

}

export default RoleCard