// ** Custom Menu Components
import VerticalNavLink from './nested/VerticalNavLink'
import VerticalNavGroup from './nested/VerticalNavGroup'
import VerticalNavSectionTitle from './nested/VerticalNavSectionTitle'

const resolveNavItemComponent = item => {
  if (item.sectionTitle) return VerticalNavSectionTitle
  if (item.children) return VerticalNavGroup
  return VerticalNavLink
}

const VerticalNavItems = props => {
  // ** Props
  const { verticalNavItems } = props


  const RenderMenuItems = verticalNavItems?.map((item, index) => {
    const TagName = resolveNavItemComponent(item)

    // eslint-disable-next-line react/react-in-jsx-scope
    return <TagName {...props} key={index} item={item} />
  })

  // eslint-disable-next-line react/react-in-jsx-scope
  return <>{RenderMenuItems}</>
}

export default VerticalNavItems
