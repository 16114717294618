// ** React Imports
import React, { useEffect, useState } from 'react'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserStats, usersSelector } from 'store/users'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import { useMediaQuery } from '@mui/material'

// ** Custom Components
import CloudIcon from 'mdi-material-ui/WeatherCloudy'

// ** Images Imports
import DriveLogo from 'images/google-drive.png'
import GmailLogo from 'images/google-gmail.png'
import GooglePhotosLogo from 'images/google-photos.png'

// ** Styled Tab List
const Img = styled('img')(({ theme }) => ({
  width: '24px',
  height: '24px'
}))

// ** Styled Link
const Link = styled(Typography)(({ theme }) => ({
  fontFamily: 'Roboto',
  textTransform: 'uppercase',
  fontSize: '0.925rem',
  fontWeight: 600,
  lineHeight: '16px',
  paddingTop: '0.875rem',
  paddingBottom: '0.875rem',
  paddingLeft: '0.5rem',
  paddingRight: '0.5rem',
  borderRadius: 3,
  cursor: 'pointer',
  transition: '.25s',
  '&:hover': {
    backgroundColor: theme.palette.grey[300]
  }
}))

const tabs = ['General Settings', 'Aliases', 'Groups', 'Contacts', 'Gmail Settings', 'Calendars', 'Drive']

const SettingsHeader = props => {
  // ** Hooks
  const small = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const dispatch = useDispatch()

  // ** State
  const [units, setUnits] = useState([])

  // ** Selectors
  const { user, stats } = useSelector(usersSelector)

  useEffect(() => {
    if (user?.orgUnitPath) {
      setUnits(user.orgUnitPath?.split('/')?.filter(el => el !== ''))
      dispatch(fetchUserStats(user?.id))
    }
  }, [user])

  return (
    <Card
      sx={{
        borderRadius: 0,
        py: '1rem',
        px: '1.525rem',
        border: theme => `solid 1px ${theme.palette.grey[300]}`
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography
            sx={{
              ...(small ? { fontSize: '0.925rem' } : { fontSize: '1.125rem' })
            }}
          >
            Storage use settings for{' '}
            <strong>{(user?.name?.givenName || '----') + ' ' + (user?.name?.familyName || '----')}</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} container>
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={3}
            xl={2.5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: '0.5rem'
            }}
          >
            <CloudIcon sx={{ color: theme => theme.palette.grey[600] }} />
            <Box
              sx={{
                textAlign: 'center',
                ...(small ? { mr: '0.525rem' } : { mr: '2.5rem' })
              }}
            >
              <Typography variant='body2' sx={{ ...(small ? { fontSize: '0.825rem' } : { fontSize: '0.975rem' }) }}>
                Total Used
              </Typography>
              <Typography sx={{ ...(small ? { fontSize: '1rem' } : { fontSize: '1.725rem' }) }}>
                {stats?.quotaBytesUsed || 0} bytes
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={8}
            sm={6}
            md={6}
            lg={5}
            xl={6}
            sx={{
              mb: '0.5rem',
              borderLeft: theme => `1px solid ${theme.palette.grey[300]}`,
              ...(!small ? { mx: '2rem' } : {})
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                ...(small ? { mx: '1rem' } : { mx: '2rem' })
              }}
            >
              <Box sx={{ textAlign: 'center' }}>
                <Img alt='gogole-drive' src={DriveLogo} />
                <Typography sx={{ fontSize: '0.625rem' }}>Drive</Typography>
                <Typography sx={{ fontSize: '0.825rem', fontWeight: 600 }}>
                  {stats?.quotaBytesByService?.find(item => item.serviceName === 'DRIVE')?.bytesUsed || 0} bytes
                </Typography>
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <Img alt='gogole-gmail' src={GmailLogo} />
                <Typography sx={{ fontSize: '0.625rem' }}>Gmail</Typography>
                <Typography sx={{ fontSize: '0.825rem', fontWeight: 600 }}>
                  {stats?.quotaBytesByService?.find(item => item.serviceName === 'GMAIL')?.bytesUsed || 0} bytes
                </Typography>
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <Img alt='gogole-photos' src={GooglePhotosLogo} />
                <Typography sx={{ fontSize: '0.625rem' }}>Photos</Typography>
                <Typography sx={{ fontSize: '0.825rem', fontWeight: 600 }}>
                  {stats?.quotaBytesByService?.find(item => item.serviceName === 'PHOTOS')?.bytesUsed || 0} bytes
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Divider sx={{ borderBottom: theme => `1px solid ${theme.palette.grey[600]}`, width: '100%' }} />
          <Grid item container xs={12} sx={{ my: '0.5rem', display: 'flex', alignItems: 'center' }}>
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <Typography sx={{ fontSize: '0.875rem', fontWeight: 600 }}>Storage limit</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='body2' sx={{}}>
                  Inherited from
                </Typography>
                &nbsp;
                <Typography sx={{ color: 'common.blue' }}>"{units[0] || '----'}"</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={8} xl={9}>
              <Typography sx={{ fontSize: '0.875rem' }}>Storage limit for user</Typography>
              <Typography variant='body2'>OFF</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default SettingsHeader
