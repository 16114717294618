// Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** APis
import {
  getBoardedCustomerApi,
  getInvoices,
  getDailyUsageInvoice,
  getMetaDataData,
  getInvoice,
  getGcpUsage
} from 'configs/ba-axios/api_helper'

export const initialState = {
  loading: null,
  invoiceInformation: [],
  customerInvoices: [],
  customerInformation: null,
  success: '',
  error: '',
  pagination: {
    nextPage: null,
    previousPage: null,
    limit: 0,
    total: 0,
    currentPage: 1
  }
}
// A slice
const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setCustomerSuccess: (state, action) => {
      state.customerInformation = { ...action.payload }
      state.success = 'GET'
      state.loading = null
    },
    setCustomerFailure: (state, action) => {
      state.error = action?.payload || ''
      state.loading = null
    },
    getInvoicesSuccess: (state, action) => {
      state.customerInvoices = action.payload
      state.invoiceInformation = action.payload
      state.loading = null
      state.success = 'GET_INVOICES'
    },

    getInvoiceSuccess: (state, action) => {
      state.invoiceInformation = action.payload
      state.loading = null
      state.success = 'GET_INVOICE'
    },

    getCustomersOnboardingFailure: state => {
      state.loading = null
      state.error = 'ERROR'
    },

    setErrorMessage: (state, action) => {
      state.loading = null
      state.error = action.payload
      state.success = ''
    },
    startLoading: (state, action) => {
      state.loading = action.payload
      state.error = ''
      state.success = ''
    },
    resetMessages: state => {
      state.success = ''
      state.error = ''
      state.loading = null
    }
  }
})

// Actions generated from the slice
const { getInvoicesSuccess, startLoading, setCustomerSuccess, getInvoiceSuccess, setCustomerFailure } =
  customersSlice.actions

// export user selector to get the slice in any component
export const customersSelector = state => state.billingData

// export The reducer
const customersReducer = customersSlice.reducer
export default customersReducer

export const getBoardedCustomerInformationOnly = domain => async dispatch => {
  dispatch(startLoading('GET'))
  try {
    const response = await getBoardedCustomerApi(domain)
    const { data: customer } = response
    dispatch(setCustomerSuccess(customer))
  } catch (error) {
    dispatch(setCustomerFailure())
  }
}

export const getCustomerInvoices = id => async dispatch => {
  try {
    dispatch(startLoading('GET_INVOICES'))
    const { data } = await getInvoices(`?customerId=${id}`)
    if (!!data.result) dispatch(getInvoicesSuccess(data?.result || []))
  } catch (error) {
    dispatch(setCustomerFailure('ERROR_CURRENT_SPENDING'))
  }
}

export const getCustomerInvoice =
  ({ customerId, invoiceId, show_gcp_usage }) =>
  async dispatch => {
    try {
      dispatch(startLoading('GET_INVOICE'))
      let gcpInvoice = []
      const { data: dailyUsageInvoices } = await getDailyUsageInvoice(invoiceId)
      const { data: currenciesResponse } = await getMetaDataData('type/currency')
      const { data } = await getInvoice(`?customerId=${customerId}&invoiceId=${invoiceId}`)

      const extraInvoice = dailyUsageInvoices
        ?.filter(
          item =>
            item.subscription?.toLowerCase() !== 'credit' &&
            !item.subscription?.toLowerCase()?.includes('subscription') &&
            !item.description?.toLowerCase()?.includes('gst')
        )
        ?.map(item => ({
          resource_type: item.resource_type,
          type: item.description?.toLowerCase(),
          label: item.description + ' ' + item.day,
          day: item.start_date,
          description: item.description,
          subscription_id: '',
          discount: [],
          sku_name: item.subscription,
          plan: null,
          sku_id: '',
          licence_type: '',
          price_cad: 0,
          price_usd: 0,
          seats: 1,
          prices: currenciesResponse.data?.map(element => ({
            currencyId: element.id,
            totalValue: item.price
          })),
          days: [item.start_date, item.day],
          quantity: 1,
          intervals: [
            {
              from: item.start_date,
              to: item.day
            }
          ]
        }))

      if (!!show_gcp_usage) {
        const { data: gcpUsage } = await getGcpUsage(invoiceId)
        const gcpInvoiceRange = gcpUsage?.filter(
          item =>
            moment(item.day).isSameOrAfter(moment(data.issue_date)) &&
            moment(item.day).isSameOrBefore(moment(data.due_date))
        )

        // Calculate total cost
        const totalCost = gcpInvoiceRange?.reduce((acc, obj) => acc + obj.cost, 0)
        gcpInvoice =
          !!show_gcp_usage && gcpInvoiceRange?.length > 0
            ? [
                {
                  resource_type: gcpInvoiceRange?.[0]?.service || 'GCP Usage',
                  type: '',
                  label: '',
                  day: data.issue_date,
                  description: '',
                  subscription_id: '',
                  discount: [],
                  sku_name: gcpInvoiceRange?.[0]?.service || 'GCP usage',
                  plan: null,
                  sku_id: '',
                  licence_type: '',
                  price_cad: 0,
                  price_usd: 0,
                  seats: 1,
                  prices: currenciesResponse.data?.map(element => ({
                    currencyId: element.id,
                    totalValue: gcpInvoiceRange?.[0]?.currency_id == element.id ? totalCost : 0
                  })),
                  days: [data.issue_date],
                  quantity: 1,
                  intervals: [
                    {
                      from: moment(data.issue_date).format('YYYY-MM-DD'),
                      to: moment(data.due_date).format('YYYY-MM-DD')
                    }
                  ]
                }
              ]
            : []
      }
      if (!!data)
        dispatch(getInvoiceSuccess(data ? { ...data, items: [...data.items, ...extraInvoice, ...gcpInvoice] } : []))
    } catch (error) {
      dispatch(setCustomerFailure())
    }
  }
