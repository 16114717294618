// ** React Imports
import React from 'react'

// ** MUI Imports
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'

// ** Third Party Imports
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

const SelectForm = props => {
  // ** Props
  const { name, control, label, placeholder, errors, fullWidth, size, options, multiple, menuProps, menuItem } = props
  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel id='demo-simple-select-label'>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select
            multiple={multiple}
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={value}
            label={label}
            onChange={onChange}
            placeholder={placeholder || ''}
            error={Boolean(errors?.[name])}
            sx={{ borderRadius: 0 }}
            MenuProps={menuProps}
          >
            {options.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {errors?.[name] && (
        <FormHelperText sx={{ color: 'error.main' }} id={`validation-basic-${name}`}>
          {errors?.[name].message}
        </FormHelperText>
      )}
    </FormControl>
  )
}

SelectForm.propTypes = {
  control: PropTypes.object.isRequired, // Required string prop
  errors: PropTypes.object.isRequired, // Optional string prop
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  options: PropTypes.array,
  menuProps: PropTypes.object
}

export default SelectForm
