// ** React Imports
import React, { useState, useEffect } from 'react'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'

// ** APIs
import { getAllSharedDrivesPage } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'

// ** Component Imports
import ActionsButton from '../components/buttons/ActionsButton'
import CustomTable from '../components/table/CustomTable'
import GoogleSpinner from 'core/components/google-spinner'
import CreateDrive from '../components/dialog/CreateDrive'
import AlertMessage from '../components/alert/CustomAlertMessage'

// ** Hooks
import { useAuth } from 'hooks/useAuth'

// Styled Divider component
const StyledDivider = styled(Divider)(({ theme }) => ({
  borderBottomWidth: 1,
  color: theme.palette.grey[300]
}))

const SharedDriveTable = () => {
  // ** Hooks
  const dispatch = useDispatch()
  const { user } = useAuth()

  // ** State
  const [loading, setLoading] = useState(false)
  const [sharedDrives, setSharedDrives] = useState([])
  const [message, setMessage] = useState({ success: false, error: false, value: '' })
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [nextPageToken, setNextPageToken] = useState('')
  const [query, setQuery] = useState('')
  const [queryPage, setQueryPage] = useState(0)

  // ** Selectors

  // ** Fetch users
  useEffect(() => {
    const fetchSharedDrive = async () => {
      setLoading(true)
      try {
        const response = await getAllSharedDrivesPage({ id: user.id, rows: rowsPerPage })
        setNextPageToken(response?.data?.nextPageToken || '')
        setSharedDrives(response?.data?.drives || [])
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    fetchSharedDrive()
  }, [])

  // ** Functions
  const handleCloseMessage = () => {
    setMessage({ success: false, error: false, value: '' })
  }
  const handleChangePage = async (event, newPage) => {
    if (nextPageToken) {
      if (newPage > queryPage) {
        const response = await getAllSharedDrivesPage({ id: user.id, rows: rowsPerPage, nextPageToken })
        setNextPageToken(response?.data?.nextPageToken || '')
        const data = response?.data?.drives || []
        setSharedDrives(state => [...state, ...data])
        setQueryPage(newPage)
        setPage(newPage)
      } else {
        setPage(newPage)
      }
    } else {
      setPage(newPage)
    }
  }

  const handleChangeRowsPerPage = async event => {
    const response = await getAllSharedDrivesPage({ id: user.id, rows: parseInt(event.target.value, 10) })
    setNextPageToken(response?.data?.nextPageToken || '')
    setSharedDrives(response?.data?.drives || [])
    setRowsPerPage(parseInt(event.target.value, 10))
    setQueryPage(0)
    setPage(0)
  }

  return (
    <Card
      sx={{
        '&.MuiPaper-root': {
          borderRadius: '0px !important',
          boxShadow: 0,
          border: theme => `1px solid ${theme.palette.grey[300]}`
        },
        '& .customer-column-header': {
          backgroundColor: 'common.white',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 600
          }
        }
      }}
    >
      <CardHeader
        sx={{ mb: '-1rem' }}
        title={<Typography>Shared Drives</Typography>}
        action={<CreateDrive setSharedDrives={setSharedDrives} setMessage={setMessage} />}
      />
      <StyledDivider />
      <CustomTable
        data={sharedDrives}
        columns={['Drive name', 'Creacted on', 'Action']}
        loading={loading}
        path={'/tools/templates'}
        setMessage={setMessage}
        setSharedDrives={setSharedDrives}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        nextPageToken={nextPageToken}
      />
      <AlertMessage message={message} handleCloseMessage={handleCloseMessage} />
    </Card>
  )
}

export default SharedDriveTable
