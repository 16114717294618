// ** React Imports
import React from 'react'

// ** MUI Imports
import { Grid } from "@mui/material"

// ** Component Imports
import UsersTable from './containers/UsersTable'



const Users = () => {


    return (
        <Grid container>
            <Grid item xs={12}>
                <UsersTable />
            </Grid>
        </Grid>
    )
}


export default Users