// ** React Imports
import React, { Fragment, useState } from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { usersSelector } from 'store/users'

// ** API CALLS
import { updateDriveFolder } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'



// ** Icon Imports
import EditIcon from 'mdi-material-ui/PencilOutline'



const UpdateDialog = (props) => {

    // ** Props
    const { setFolders, setMessage, folder } = props

    // ** Hooks
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues: { name: folder?.name } })

    // ** Selectors
    const { user } = useSelector(usersSelector);

    // ** State
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    // ** Functions
    const handleClickOpen = (e) => {
        e.stopPropagation()
        setOpen(true)
    }
    const handleClose = () => setOpen(false)



    const onSubmit = async (body) => {
        setLoading(true)
        try {
            const data = await updateDriveFolder(user.id, folder.id, { name: body?.name })
            setFolders(state => {
                return state.map(folder =>
                    folder.id.toString() === data.id.toString()
                        ? { folder, ...data }
                        : folder
                )
            })
            setMessage({ success: true, error: false, value: "Folder was updated successfully" })
            setLoading(false)
            setOpen(false)
        } catch (error) {
            setMessage({ success: false, error: true, value: "There was an error while updating the folder" })
            setLoading(false)
        }
    }


    return (
        <Fragment>
            <IconButton
                size="medium"
                edge="start"
                sx={{ color: "text.secondary", mx: "0.325rem" }}
                onClick={handleClickOpen}
            >
                <EditIcon />
            </IconButton>


            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle id='alert-dialog-title'>Update folder</DialogTitle>
                <DialogContent>
                    <form id="update-folder-form" onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Controller
                                        name='name'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <TextField
                                                variant="standard"
                                                value={value ?? ""}
                                                label='Name'
                                                onChange={onChange}
                                                error={Boolean(errors.name)}
                                                placeholder='folder xyz'
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions className='dialog-actions-dense' sx={{ mt: "1rem" }}>
                    <Button
                        disabled={loading}
                        onClick={handleClose}
                        size="small"
                        variant="text"
                        color="inherit"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={loading}
                        endIcon={(loading ?
                            <CircularProgress sx={{ color: 'common.white', mt: 0 }} size="0.825rem" /> : null
                        )}
                        type="submit"
                        form="update-folder-form"
                        variant="contained"
                        size="small"
                        sx={{ fontWeight: 600, primary: "common.blue" }}
                    >
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default UpdateDialog